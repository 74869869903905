import styled, { css } from 'styled-components';
import useProcesser from '../../hooks/processer/useProcesser';

const ButtonStyle = styled.button`
  padding: 12px;
  font-size: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;
  outline: none;
  border-radius: ${({ radius }) => radius ? `${radius}px` : "50px"};
  
  width: ${({ wt }) => wt && wt};
  // box-shadow: ${({ theme, shadow, blur, alpha }) => shadow ? `-11px 8px 0 ${theme.colors.main2}` : `-2px 2px ${blur}px #000000${alpha}`};
  
  cursor: ${({ processing }) => processing ? 'not-allowed' : 'pointer'};
  
  color: ${({ color }) => color ? color : "444444"};

  border:none;

  background: linear-gradient(to bottom, ${({ bg }) => bg}AA, ${({ bg }) => bg}AA, ${({ bg }) => bg}, ${({ bg }) => bg});
  
  ${({ shadow }) => shadow && css`
    box-shadow: 1px 2px 1px #00000025;
  `}
    
    & > svg {
    min-width: 30px;
  }
`;

const Button = ({ primary, secondary, shadow, bg, children, wt, blur, run, color, alpha, radius, id, style }) => {
  const { processing } = useProcesser();

  return (
    <ButtonStyle disabled={ processing ? true : false} primary={primary} secondary={secondary} shadow={shadow} bg={bg} wt={wt} blur={blur} onClick={run} color={color} alpha={alpha} radius={radius} processing={processing} id={id} style={style}>
      {children}
    </ButtonStyle>
  )
}

export default Button;