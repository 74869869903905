import { ProfileSetupStyle } from "./styles/S_ProfileSetup";
import Button from '../../../button/Button';
import { FaAngleDown, FaCamera } from "react-icons/fa";
import { useEffect, useState } from "react";
import { useTheme } from 'styled-components';
import axios from 'axios';
import useProxyContext from "../../../../hooks/global/useProxyContext";
import useErrHandler from "../../../../hooks/err/useErrHandler";
import useAuthContext from "../../../../hooks/auth/useAuthContext";
import usePopUpContext from "../../../../hooks/global/usePopUpContext";
import useCountries from "../../../../hooks/global/useCountries";
import useAccess from '../../../../hooks/access/useAccess';

const ProfileSetup = () => {
  const [file, setFile] = useState(null);
  const [fileName, setfileName] = useState("");
  const [male, setMale] = useState(true);
  const [ngn, setNgn] = useState(true);
  const [desc, setDesc] = useState('');
  const [ageRange, setAgeRange] = useState('');
  
  const theme = useTheme();
  const bg = theme.colors.blue;
  
  const { user } = useAuthContext();
  const { endpoint } = useProxyContext();
  const { notifier } = useErrHandler();
  const { stage, setShow } = usePopUpContext();
  const { setShowCountries, selected } = useCountries();

  const { userId } = useAccess();


  const firstTime = userId && (userId.profilePic ? false : true);
  const userPP = userId && (userId.profilePic ? userId.profilePic : fileName);
  
  // Validate profile pic
  const addProfilePic = async (skip) => {
    const container = document.querySelector("#file");
    const age = document.querySelector("#age");

    if(skip) {
      container.style.transform = `translateX(-100%)`
      age.style.transform = `translateX(-100%)`
      return;
    }

    try {
      if(!file) return notifier(true, "Select file first!");

      if(file) {
        const data = new FormData();
        data.append("name", userPP)
        data.append("file", file);

        try {
          const res = await axios.post(`${endpoint}/update/upload`, data, {
            headers: { "Authorization": `Bearer ${user.token}` }
          });

          notifier(false, res.data.message)

        } catch (err) {
          const errorMessage = err.response.data.message;
          notifier(true, errorMessage);
        }
      }

      const res = await axios.put(`${endpoint}/update/profilepic`, {
        fileName: fileName,
        firstTime: firstTime
      }, { headers: { 'Authorization': `Bearer ${user.token}` }})

      notifier(false, res.data.message);

      container.style.transform = `translateX(-100%)`
      age.style.transform = `translateX(-100%)`
    } catch (err) {
      console.log(err);
    }
    
  }
  
  // Validate date of birth
  const addAge = async (skip) => {
    const age = document.querySelector("#age");
    const gender = document.querySelector("#gender");

    if(skip) {
      age.style.transform = `translateX(-200%)`
      gender.style.transform = `translateX(-200%)`
      return;
    }

    try {
      if(!ageRange) return notifier(true, 'Select age from the list!');

      const res = await axios.put(`${endpoint}/update/age`, {
        age: ageRange
      }, { headers: {'Authorization': `Bearer ${user.token}`} })

      notifier(false, res.data.message);
      
      age.style.transform = `translateX(-200%)`
      gender.style.transform = `translateX(-200%)`
      
    } catch (err) {
      console.log(err);
    }
  }

  // Validate gender
  const addGender = async (skip) => {
    const gender = document.querySelector("#gender");
    const country = document.querySelector("#country");

    if(skip) {
      gender.style.transform = `translateX(-300%)`
      country.style.transform = `translateX(-300%)`
      return;
    }

    try {
      const res = await axios.put(`${endpoint}/update/gender`, {
        gender: male ? "Male" : "Female"
      }, { headers: {'Authorization': `Bearer ${user.token}`} })

      notifier(false, res.data.message);
      
      gender.style.transform = `translateX(-300%)`
      country.style.transform = `translateX(-300%)`
      
    } catch (err) {
      console.log(err);
    }
  }
  
  // Validate country
  const addCountry = async (skip) => {
    const country = document.querySelector("#country");
    const desc = document.querySelector("#desc");

    if(skip) {
      country.style.transform = `translateX(-400%)`
      desc.style.transform = `translateX(-400%)`
      return;
    }

    try {
      const res = await axios.put(`${endpoint}/update/country`, {
        country: selected ? selected.name : "Nigeria"
      }, { headers: {'Authorization': `Bearer ${user.token}` } })

      notifier(false, res.data.message);
      
      country.style.transform = `translateX(-400%)`
      desc.style.transform = `translateX(-400%)`
      
    } catch (err) {
      console.log(err);
    }
  }

  // Validate Description
  const addDesc = async (skip) => {
    const description = document.querySelector("#desc");
    const currency = document.querySelector("#currency");

    if(skip) {
      description.style.transform = `translateX(-500%)`
      currency.style.transform = `translateX(-500%)`
      return;
    }

    if(!desc) return notifier(true, "Field can't be empty!")

    try {
      const res = await axios.put(`${endpoint}/update/desc`, {
        desc: desc
      }, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      })

      notifier(false, res.data.message);

      description.style.transform = `translateX(-500%)`
      currency.style.transform = `translateX(-500%)`
      
    } catch (err) {
      console.log(err);
    }
  }

  // Validate Currency
  const addCurrency = async () => {

    try {
      const res = await axios.put(`${endpoint}/update/currency`, {
        currency: ngn ? "NGN" : "USD"
      }, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      })

      notifier(false, res.data.message);
      setShow(false);
      
    } catch (err) {
      console.log(err);
    }
  }

  // handle stage and date selection
  useEffect(() => {

    const container = document.querySelector("#file");
    const age = document.querySelector("#age");
    const country = document.querySelector("#country");
    const desc = document.querySelector("#desc");
    const gender = document.querySelector("#gender");
    const currency = document.querySelector("#currency");

    // handle stage
    const handleStage = () => {
      if(stage === "all") {
          return
      } else if(stage === "age") {
          container.style.transform = `translateX(-100%)`
          age.style.transform = `translateX(-100%)`
          return
      } else if(stage === "gender") {
          container.style.transform = `translateX(-200%)`
          age.style.transform = `translateX(-200%)`
          gender.style.transform = `translateX(-200%)`
          return;
      } else if(stage === "country") {
          container.style.transform = `translateX(-200%)`
          gender.style.transform = `translateX(-300%)`
          country.style.transform = `translateX(-300%)`
          return
      } else if(stage === "desc") {
          container.style.transform = `translateX(-200%)`
          country.style.transform = `translateX(-400%)`
          desc.style.transform = `translateX(-400%)`
          return;
        } else if(stage === "currency") {
          container.style.transform = `translateX(-200%)`
          desc.style.transform = `translateX(-500%)`
          currency.style.transform = `translateX(-500%)`
      }
    }

    if(container) {
      if(container.style === undefined) {
        setTimeout(() => {
          handleStage();
        }, 1000);
      } else { handleStage(); }
    }

  }, [stage])

  useEffect(() => {
    const ageContainer = document.querySelector('#ageContainer');

    for (let i = 0; i < ageContainer.children.length; i++) {
      const element = ageContainer.children[i];
      
      element.addEventListener('click', () => {
        setAgeRange(element.textContent);
      })
      
    }
  }, [])

  return (
    <>
    <ProfileSetupStyle>
      <h2>Setup Your Profile</h2>

      <div id="container">
        {/* Profile */}
        <div id="file">
          <p>Profile Picture</p>

          <div>
            <div>
              <label htmlFor="profilePics"></label>
              <input 
                accept="image/*" 
                onChange={(e) => {
                  setFile(e.target.files[0])
                  setfileName(`profile-${Date.now()}-${e.target.files[0].name}`)
                }}
                type="file" 
                id="profilePics"
              />
              { file && <img src={ URL.createObjectURL(file) } alt=""/>}
              <FaCamera />
            </div>

            <small>Choose an image</small>
          </div>

          <span onClick={() => addProfilePic(true)}>Skip &rarr;</span>
          <Button bg={bg} run={() => addProfilePic(false)} >Next</Button>
        </div>

        {/* Date of birth */}
        <div id="age">
          <p>Age</p>
          
          <div id="ageContainer">
            <span>13 - 17</span>
            <span>17 - 21</span>
            <span>21 - 25</span>
            <span>25 - 29</span>
            <span>29 - 33</span>
            <span>33 - 37</span>
            <span>37 - 41</span>
            <span>Others</span>
          </div>

          <span onClick={() => addAge(true)}>Skip &rarr;</span>
          <Button bg={bg} run={() => addAge(false)}>Next</Button>
        </div>

        {/* Gender */}
        <div id="gender">
          <p>Gender</p>
          
          <div>
            <div onClick={() => setMale(true)}>
              <div style={{ background: male ? theme.colors.main : 'transparent'}}></div>
              <span>Male</span>
            </div>

            <div onClick={() => setMale(false)}>
              <div style={{ background: !male ? theme.colors.main : 'transparent'}}></div>
              <span>Female</span>
            </div>
          </div>

          <span onClick={() => addGender(true)}>Skip &rarr;</span>
          <Button bg={bg} run={() => addGender(false)}>Next</Button>
        </div>

        {/* Country */}
        <div id="country">
          <p>Country</p>
          
          <div onClick={() => setShowCountries(true)}>
            <div>
              <img src={selected ? selected.flag : "/images/Flag-Nigeria.webp"} alt=""/>
              <p>{selected ? selected.name : "Nigeria"}</p>
            </div>

            <FaAngleDown />
          </div>

          <span onClick={() => addCountry(true)}>Skip &rarr;</span>
          <Button bg={bg} run={() => addCountry(false)}>Next</Button>
        </div>
      
        {/* Description */}
        <div id="desc">
          <p>Description</p>

          <div>
            <textarea 
              rows="3" 
              placeholder="Bio"
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
            ></textarea>
          </div>

          <span onClick={() => addDesc(true)}>Skip &rarr;</span>
          <Button bg={bg} run={() => addDesc()}>Next</Button>
        </div>

        {/* Currency */}
        <div id="currency">
          <p>Currency</p>
          
          <div>
            <div onClick={() => setNgn(true)}>
              <div style={{ background: ngn ? theme.colors.main : 'transparent'}}></div>
              <span>NGN</span>
            </div>

            <div onClick={() => setNgn(false)}>
              <div style={{ background: !ngn ? theme.colors.main : 'transparent'}}></div>
              <span>USD</span>
            </div>
          </div>

          <Button bg={bg} run={addCurrency}>Finish</Button>
        </div>
      
      </div>
    </ProfileSetupStyle>
    </>
  )
}

export default ProfileSetup;