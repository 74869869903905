import styled from 'styled-components';

export const CreateStyle = styled.div`
  & button {
    background: linear-gradient(to top right, ${({ theme }) => `${ theme.colors.main }50 , ${ theme.colors.main2 }50`});
   
    &:hover {
      background: linear-gradient(to bottom, ${({ theme }) => `${ theme.colors.main2 }AA, ${ theme.colors.main }AA` });
    }
  }
  
  & > div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 5px;

    & > div[id="collIcon"] {
      min-width: 100%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
  
      & > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        
        & input {
          display: none;
        }
        
        & img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }

        & p {
          font-size: 15px;
        }
        
        & > div {
          position: relative;
          padding: 5px;
          border-radius: 50%;
          height: 70px;
          width: 70px;
          background: ${({ theme }) => theme.colors.white}AA;
          cursor: pointer;
          
          & svg {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            font-size: 30px;
            color: ${({ theme }) => theme.colors.black}70;
          }

          & label {
            position: absolute;
            left: 0;
            top: 0;
            border-radius: 50%;
            height: 100%;
            width: 100%;
            z-index: 1;
            cursor: pointer;
          }
        }
      }
    }

    & > div[id="public"] {
      display: flex;
      align-items: center;
      justify-content: center;

      & span {
        padding: 10px 20px;
        display: flex;
        align-items: center;
        gap: 10px;
        background: ${({ global }) => global ? "#4bb54350" : "#FFA50050"};
        color: ${({ theme }) => theme.colors.black};
        border-radius: 50px;
        cursor: pointer;
      }
    }

    & > form {
      display: flex;
      flex-direction: column;
      gap: 15px;

      & > div {
        display: flex;
        flex-direction: column;
        gap: 10px;

        & label {
          color: ${({ theme }) => theme.colors.black};
        }

        & input {
          border: none;
          padding: 15px;
          box-shadow: 1px 1px 3px #00000025;
          font-size: 19px;
          color: ${({ theme }) => theme.colors.black};
          background: ${({ theme }) => theme.colors.lightgrey}20;
          border: 1px solid ${({ theme }) => theme.colors.black}50;
          border-radius: 5px;
        }
      }
    }
  }
`;