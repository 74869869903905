import { ReportsStyle } from "./S_Reports";
import { HiSwitchHorizontal } from 'react-icons/hi';
import { Link } from 'react-router-dom';

const Reports = () => {
  return (
    <ReportsStyle className="general">
      <div id="header">
        <h2>Reports</h2>
      </div>

      <div id="collections">
        <div>
          <div>
            <h3>Post name</h3>
            <div>
              <span>Active <HiSwitchHorizontal /></span> 
              <span>Suspend <HiSwitchHorizontal /></span>
            </div>
          </div>

          <img src="" alt=""/>

          <Link to="" >View Post</Link>
        </div>

      </div>
    </ReportsStyle>
  )
}

export default Reports;