import { createContext, useState } from 'react';
import useErrHandler from '../../hooks/err/useErrHandler'

export const Processer = createContext();

const ProcesserProvider = ({ children }) => {
  const [processing, setProcessing] = useState(false);

  const { notifier, removeError } = useErrHandler();

  const process = (type, message, rmNotifier = true,) => {
    if(type === 'STARTED') {
      setProcessing(true);
      notifier(false, message);
      return
    } else if(type === 'ENDED') {
      setProcessing(false);
      rmNotifier && removeError();
      return
    }
  }

  return (
    <Processer.Provider value={{ process, processing }}>
      {children}
    </Processer.Provider>
  )
}

export default ProcesserProvider;