import styled from 'styled-components';

export const AdminStl = styled.div`
  & > div {
    & > h1 {
      font-size: 30px;
      margin: 30px 0;
      font-family: 'Jost', sans-serif;
      font-weight: 500;
      color: ${({ theme }) => theme.colors.black};
      text-align: center;

      @media (max-width: 530px) {
        font-size: 20px;
      }
    }
  }
`;

export const Notifications = styled.div`
  display: flex;
  gap: 20px;
  flex-flow: wrap;
  justify-content: center;
  
  & > span {
    padding: 20px;
    border-radius: 50px;
    border: 1px solid #C2C2C2;
    background: #F3FFE9;

    &:last-child {
      border: 1px solid #BC9D9D;
      background: #FFF6F6;
    }
  }

  @media (max-width: 530px) {
    gap: 10px;

    & > span {
      padding: 15px;
      font-size: 15px;
    }
  }
`;

export const MostRecent = styled.div`
  margin: 30px 0 100px 0;

  & > div:first-child {
    // background: #F7FDFB;

    & > div {
      display: flex;
      justify-content: space-between;
      padding: 20px 40px;
      max-width: 1300px;
      margin: auto;
      
      & button {
        padding: 7px 17px;
        background: ${({ theme }) => theme.colors.black};
        color: ${({ theme }) => theme.colors.white};
        border-radius: 50px;
      }

      @media (max-width: 530px) {
        & > h2 {
          font-size: 17px;
        }

        & button {
          font-size: 10px;
        }
      }
    }

  }

  & > div:last-child {
    & button {
      padding: 20px;
      background: ${({ theme }) => theme.colors.black};
      color: ${({ theme }) => theme.colors.white};
      border-radius: 50px;
      font-size: 17px;
      margin-top: 20px;
    }

    & > main {

      & > div:first-child {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 20px 0;
        padding: 20px;
        background: #fff;
        box-shadow: 2px 2px 7px rgba(0,0,0,0.2);
        border-left: 3px solid ${({ theme }) => theme.colors.main};
        border-radius: 10px;

        & > div {
          position: relative;
          
          & svg {
            font-size: 35px;
            cursor: pointer;
            padding: 7px;
          }
        }

        & > main {
          display: flex;
          gap: 30px;
          // align-items: center;

          & > img {
            width: 150px;
            max-height: 100px;
            border-radius: 10px;
            
          }

          @media (max-width: ${({ theme }) => theme.mobile.is500}) {
            gap: 20px;

            & > img {
              max-width: 50px;
              max-height: 40px;
              border-radius: 5px;
            }
          }

          & > div {
            & h1 {
              font-size: 30px;
              font-weight: 500;
              font-family: 'Jost', sans-serif;
              color: ${({ theme }) => theme.colors.black};
            }
  
            @media (max-width: 530px) {
              & h1 {
                font-size: 20px;
                color: ${({ theme }) => theme.colors.main};
              }
  
              & article {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
`;

export const Options = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 2px 2px 7px rgba(0,0,0,0.3);
  padding: 12px;
  position: absolute;
  right: 50px;
  top: -10px;
  z-index: 95;
  background: #FFFFFF;
  border-radius: 5px;
  
  & > div {
    padding: 12px;
    background: #fff;
    position: absolute;
    right: -10px;
    height: 5px;
    width: 5px;
    transform: rotate(45deg);
    box-shadow: 2px -2px 2px rgba(0,0,0,0.1);
  }
  
  & a, span {
    font-size: 17px;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.black};
    padding: 9px 17px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 20px;
  }

  & a:hover {
    transform: scale(1.2);
    border-left: 3px solid ${({ theme }) => theme.colors.main};
  }

  & span {
    box-shadow: 1px 1px 3px #00000025;
    background: ${({ theme }) => theme.colors.lightgrey}20;
    border: 1px solid ${({ theme }) => theme.colors.black}50;
    border-radius: 50px;
    cursor: pointer;
  }
`;