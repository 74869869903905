import styled from 'styled-components';

export const ProfileSetupStyle = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 30px;

  & > div {
    overflow: hidden;
    display: flex;
    
    & > div {
      min-width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 30px;
      transition: 0.9s cubic-bezier(0.35,-0.43, 0.11, 1.08);

      & > span {
        user-select: none;
        cursor: pointer;
        padding: 10px;
        color: ${({ theme }) => theme.colors.main2};
      }

      & button { 
        width: 50%; 
      
        @media (max-width: ${({ theme }) => theme.mobile.is460}) {
          width: 30%;
        }
      }
    }

    & > div[id="file"] {
      & > div {
       
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;

        & > div {
            height: 150px;
            width: 150px;
            position: relative;
            & input { display: none }
            
          & img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
          
          & svg, label {
            position: absolute;
            font-size: 50px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            opacity: 0.5;
          }

          & label {
            background: ${({ theme }) => theme.colors.black}50;
            height: 100%;
            width: 100%;
            border-radius: 50%;
            z-index: 22;
            cursor: pointer;
          }
        }
      }
    }

    & > div[id="age"] {
      & > div {
        display: flex;
        flex-direction: column;
        height: 100px;
        overflow: auto;
        width: 30%;
        padding-right: 10px;

        &::-webkit-scrollbar { 
          width: 10px;
          border-radius: 50px;
          background: ${({ theme }) => theme.colors.lightgrey}
        }

        &::-webkit-scrollbar-thumb { 
          border-radius: 50px;
          background: ${({ theme }) => theme.colors.blue}AA;
        }

        & > span {
          margin-bottom: 15px;
          padding: 15px;
          border-radius: 10px;
          border: 5px solid ${({ theme }) => theme.colors.lightgrey};
          text-align: center;
          cursor: pointer;

          &:last-child { margin-bottom: 0; }

          &.active {
            background: blue;
          }
        }
      }
    }

    & > div[id="gender"], div[id="currency"] {
      & > div {
        display: flex;
        flex-direction: column;
        gap: 10px;

        & > div {
          padding: 12px;
          background: ${({ theme }) => theme.colors.white}AA;
          border-radius: 5px;
          display: flex;
          align-items: center;
          gap: 20px;
          cursor: pointer;

          & > div {
            height: 20px;
            width: 20px;
            border: 2px solid ${({ theme }) => theme.colors.black}AA;
          }
        }
      }
    }

    & > div[id="country"] {
      & > div {
        width: 50%;
        padding: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        box-shadow: 1px 1px 3px #00000025;
        background: ${({ theme }) => theme.colors.lightgrey}20;
        border: 1px solid ${({ theme }) => theme.colors.black}50;
        border-radius: 5px;

        & > div {
          display: flex;
          align-items: center;
          gap: 20px;

          & img {
            width: 50px;
            height: 40px;
            object-fit: cover;
            border-radius: 5px;
          }
        }

        @media (max-width: ${({ theme }) => theme.mobile.is460}) {
          width: 30%;
        }
      }
    }

    & > div[id="desc"] {
      & > div {
        width: 50%;

        & textarea {
          font-size: 19px;
          width: 100%;
          padding: 12px;
          resize: none;

          box-shadow: 1px 1px 3px #00000025;
          background: ${({ theme }) => theme.colors.lightgrey}20;
          border: 1px solid ${({ theme }) => theme.colors.black}50;
          border-radius: 5px;
        }

        @media (max-width: ${({ theme }) => theme.mobile.is460}) {
          width: 30%;
        }
      }
    }

  }
`;