import styled, { css } from 'styled-components';

export const Item = styled.div`
  padding: 15px;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 1px 1px 3px #00000025;

  ${({ fit }) => !fit && css`
    max-width: 300px;
    min-width: 300px;
  `}

  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;

  & > small {
    display: flex;
    align-items: center;

    & > svg {
      margin-left: 10px;
      margin-right: 5px;
      color: ${({ theme }) => theme.colors.black}AA;
    }
  }

  opacity: 0;
  transform: translateY(5%);

  &.fade-in { opacity: 0; animation: fadeInFromBottom 0.5s ease-in forwards; }
  @keyframes fadeInFromBottom { from { opacity: 0; transform: translateY(5%); } to { opacity: 1; transform: translateY(0); } }
  
  & > main {

    width: 100%;

    position: relative;
    // box-shadow: 2px 4px 2px #00000012;
    border-radius: 10px;
    overflow: hidden;
    transition: all cubic-bezier(0.42, 0, 0, 1.57) 0.2s;

    &:hover div[id="item_footer"] {
      display: block;
    }

    &:hover div[id="image"] {
      filter: brightness(50%);
      transform: scale(1.1);
    }

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & div[id="image"] { // the image
      height: 100%;
      display: flex;
      justify-content: center;
      transition: all cubic-bezier(0.42, 0, 0, 1.57) 0.2s;
      
      & img {
        border-radius: 10px;
        background: ${({ theme }) => theme.colors.white};
      }
    }

    & div[id="item_footer"] { // the bottom part of the item (user profile and download options)
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 10px;
      background: ${({ theme }) => theme.colors.white};
      border-radius: 50px;
      margin: 10px;
      display: none;
    }

    &:hover {
      transform: scale(0.95);
    }
  }

  & > div[id="private"] {
    display: felx;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    gap: 10px;

    & > div {
      height: 20px;
      width: 20px;
      border-radius: 50%;
    }

    & > span {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 5px 10px;
      border-radius: 50px;
      cursor: pointer;
      color: ${({ theme }) => theme.colors.black};
      font-size: 13px;
      
      &[id="status"] {
        background: ${({ theme }) => theme.colors.lightgrey}20;
        border: 2px solid ${({ approved }) => approved ? "#4bb54350" : "#FFA50050"};
      
        & svg {
          color: ${({ approved }) => approved ? "#4bb543" : "#FFA500"};
        }
      }

      &[id="removeBtn"] {
        background: linear-gradient(to top right, ${({ theme }) => `${ theme.colors.main }50 , ${ theme.colors.main2 }50`});
        
        &:hover {
          background: ${({ theme }) => theme.colors.main}AA;
        }
      }
    }
  }

  &[id="showAll"] {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & > div[id="showAllBtn"] {
    width: 130px;
    padding: 15px;
    border-radius: 50px;
    display: grid;
    place-items: center;
    display: flex;
    align-items: center;
    gap: 10px;
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black};
    border: 1px solid ${({ theme }) => theme.colors.main2};
    cursor: pointer;

    &:hover{
      background: linear-gradient(to right top, #f32170, #cf23cf, #eedd44);
      border: none;
      color: ${({ theme }) => theme.colors.white};

      & svg {
        color: ${({ theme }) => theme.colors.white};
      }
    }
    
    & svg {
      font-size: 20px;
      color: ${({ theme }) => theme.colors.black};
    }
  }

  & > h3[id="title"] {
    // margin-top: 5px;
    // padding: 10px;
    // background: ${({ theme }) => theme.colors.white};
    // border-radius: 10px;
    // box-shadow: 1px 1px 3px #00000025;
    font-size: 17px;
    font-weight: 400;
    line-height: 1.6em;
  }
`;