import styled from 'styled-components';

const CollectionSkeletonStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: hidden;
  animation: fade 1s infinite;

  @keyframes fade {
    0% {
      opacity: 0.5;
    } 
    50% {
      opacity: 1;
    } 
    100% {
      opacity: 0.5;
    }
  }

  & > div:first-child {
    display: flex;
    align-items: center;
    gap: 10px;

    // THE UPPER SIDE
    & > div:first-child {
      height: 50px;
      min-width: 50px;
      background: ${({ theme }) => theme.colors.lightgrey}AA;
      border-radius: 50%;
    }
    
    & > div:last-child {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;

      & > div:first-child {
        background: ${({ theme }) => theme.colors.lightgrey}AA;
        height: 20px;
        width: 120px;
        border-radius: 10px;
      }
  
      & > div:last-child {
        background: ${({ theme }) => theme.colors.lightgrey}AA;
        height: 10px;
        width: 50px;
        border-radius: 10px;
      }
    }
  }

  // THE DOWN SIDE
  & > div:last-child {
    display: flex;
    gap: 30px;

    & > div {
      height: 300px;
      min-width: 300px;
      background: ${({ theme }) => theme.colors.lightgrey}AA;
      border-radius: 10px;

      @media (max-width: ${({ theme }) => theme.mobile.is690}) {
        height: 200px;
        min-width: 200px;
      }
    }
  }
`;

const CollectionsSkeleton = () => {
  return (
    <CollectionSkeletonStyle>
      <div>
        <div></div> {/* Icon */}
        <div>
          <div></div> {/* Header */}
          <div></div> {/* Number of posts */}
        </div>
      </div>
      <div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </CollectionSkeletonStyle>
  )
}

export default CollectionsSkeleton;