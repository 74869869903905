import { createContext, useEffect, useReducer } from 'react'
import Reducer from'./Reducer';

const INITIAL_STATE = {
  admin: JSON.parse(localStorage.getItem('admin')) || null,
  isFetching: false,
  error: false
}

export const AdminAuthContext = createContext(INITIAL_STATE);

const AdminAuthContextProvider = ({children}) => {
  const [state, dispatch] = useReducer(Reducer, INITIAL_STATE);

  useEffect(() => {
    localStorage.setItem('admin', JSON.stringify(state.admin));
  },[state.admin])

  return (
    <AdminAuthContext.Provider value={{ 
      admin: state.admin, 
      error: state.error,
      isFetching: state.isFetching,
      dispatch
    }}> 
      {children} 
    </AdminAuthContext.Provider>
  )
}

export default AdminAuthContextProvider;