import { Container } from '../../../components/global/Container';
import { OptionsStyle } from './S_Options';
import { GiWallet } from 'react-icons/gi';
import { MdBubbleChart, MdKey } from 'react-icons/md'
import { useNavigate } from 'react-router-dom';
import useProfile from '../../../hooks/profile/useProfile';

const Options = () => {
  const navigate = useNavigate();

  const { profileSetup } = useProfile();

  return (
    <OptionsStyle>
      <Container>
        <div onClick={ async () => {
          await profileSetup(() => {
            navigate("/wallet")
          });
        }}><span><GiWallet /></span>My Wallet</div>
        <div onClick={() => navigate("/api-key")}><span><MdKey /></span>API Key</div>
        <div><span><MdBubbleChart /></span> Activities</div>
        {/* <div><span><GrStatusGoodSmall /></span> Account status</div> */}
      </Container>
    </OptionsStyle>
  )
}

export default Options;