import styled from 'styled-components';

export const PaymentStyle = styled.div`
  & > div {
    display: flex;
    overflow: auto;

    &::-webkit-scrollbar {display: none}

    & > div {
      min-width: 100%;
    }
    
    & > div[id="intro"] {
      display: flex;
      flex-direction: column;
      gap: 30px;

      & > div[id="header"] {
        & > h2 {
          text-align: center;
          font-weight: 900;
          background: linear-gradient(to right, #f32170, #ff6b08, #cf23cf, #eedd44);
          -webkit-text-fill-color: transparent;
          -webkit-background-clip: text;
        }
      }

      & > p {
        text-align: center;
      }

      & > div[id="option"] {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;

        & > div[id="num"] {
          height: 50px;
          width: 50px;
          font-size: 20px;
          color: #000000;
          display: grid;
          place-items: center;
          border-radius: 50%;
          background: linear-gradient(to right bottom, #f32170, #ff6b08, #cf23cf, #eedd44);
        }
        
        & > h2 {
          text-align: center;
        }

        & > div[id="text"] {

          border-radius: 10px;
          width: 100%;
          padding: 10px;
          background: linear-gradient(to right bottom, #f3217010, #ff6b0810, #cf23cf10, #eedd4410);
          
          & > p {
          }
        }
      }
    }

  
    & > div[id="payment"] {}
    }
  }
`;

export const PaymentForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;

  & > div[id="walletBalance"] {
    padding: 20px;
    background: ${({ theme }) => theme.colors.main2}20;
    border-radius: 10px;
    border: 2px solid ${({ theme }) => theme.colors.main2}50;
    cursor: pointer;
  }

  & input {
    border: none;
    padding: 15px;
    box-shadow: 1px 1px 3px #00000025;
    font-size: 19px;
    color: ${({ theme }) => theme.colors.black};
    background: ${({ theme }) => theme.colors.lightgrey}20;
    border: 1px solid ${({ theme }) => theme.colors.black}50;
    border-radius: 5px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:[type=number] {
      -moz-appearance: textfield;
    }
  }

  & label {
    color: ${({ theme }) => theme.colors.black};
  }

  & > div[id="fieldContainer"] {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  & > div[id="items"] {
    display: flex;
    flex-direction: column;
    gap: 20px;

    & > div {
      display: flex;
      align-items: center;
      gap: 20px;

      & > div[id="item"] {
        padding: 10px;
        // background: ${({ theme }) => theme.colors.white}AA;
        width: 200px;
        border-radius: 10px;

        box-shadow: 1px 1px 3px #00000025;
        background: ${({ theme }) => theme.colors.lightgrey}20;
        border: 1px solid ${({ theme }) => theme.colors.black}50;
        border-radius: 5px;

        & > div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 20px;
          flex-wrap: wrap;
        }

        & > img {
          width: 100%;
          object-fit: cover;
          border-radius: 10px;
        }
      }

      & > div[id="addMore"] {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        border: 1px solid ${({ theme }) => theme.colors.main};
        display: grid;
        place-items: center;
      }
    }
  }

  & > div[id="plans"] {
    display: flex;
    flex-direction: column;
    gap: 20px;

    & > div {
      display: flex;
      gap: 10px;

      & > div {
        cursor: pointer;
        border-radius: 15px;
        padding: 5px;
        transition: all ease-in-out 0.4s;
        
        & > div {
          display: flex;
          padding: 10px;
          flex-direction: column;
          border-radius: 10px;
          gap: 10px;

          &[id="monthly"] {
            background: #ff6b0850;
          }
  
          &[id="anually"] {
            background: #cf23cf50;
          }
        }
        
      }
    }
  }

  & > div[id="total"] {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    border-top: 1px solid ${({ theme }) => theme.colors.black};
  }

  & > small {
    padding: 10px;
    border-radius: 10px;
    font-size: 15px;
    background: ${({ theme, error }) => error ? "#FF000F" : theme.colors.main2}BB; 
    color: #FFFFFF;
    display: ${({ message }) => message ? 'block' : 'none'};
  }
`;