import styled, { css } from "styled-components";

export const UserOverviewStyle = styled.div`
  & > div {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
  
    ${({ button }) => button && `
      flex-direction: row;
    `}
      
    @media(max-width: ${({ theme }) =>theme.mobile.is500}) {
      flex-direction: column;
    }
  
    & svg {
      margin-right: 5px;
      color: ${({ theme }) => theme.colors.main2};
      font-size: 20px;
    }
  
    & > strong {
      color: ${({ theme }) => theme.dark ? "#efefef" : "#000000"};
      margin-right: 20px;
      display: flex;
      width: 100%;
      justify-content: center;
      font-weight: 500;
  
      
      & > span {
        margin-left: 15px;
        display: flex;
        align-items: center;
        white-space: nowrap;
  
        @media(max-width: ${({ theme }) => theme.mobile.is400}) {
          font-size: 14px;
  
          & > svg {
            font-size: 14px;
          }
        }
  
        ${({ button }) => button && css`
          justify-content: center;
          width: 100%;
          padding: 10px;
          border-radius: 5px;
          border: 1px solid ${({ theme }) => theme.colors.main2}50;
          background: ${({ theme }) => theme.colors.main2}05;
        `}
        
        &:first-child { margin-left: 0 }
      }
    }
  
    // follow button
    & > span {
      display: flex;
      align-items: center;
      color: ${({ theme }) => theme.colors.main2};
      cursor: pointer;
      padding: 10px;
      border-radius: 5px;
      background: ${({ theme }) => theme.colors.main2}20;
      transition: all cubic-bezier(0.42, 0, 0, 1.57) 0.2s;
  
      
      @media(max-width: ${({ theme }) =>theme.mobile.is500}) {
        justify-content: center;
        margin-top: 20px;
      }
  
      &:hover {
        transform: scale(0.9);
      }
    }
  }

  & > div[id="sendMessageBtn"] {
    color: ${({ theme }) => theme.colors.black}AA;
  }

  & > div:nth-child(2) {
    display: flex;
    align-items: center;
    white-space: nowrap;
    justify-content: center;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.colors.main2}50;
    background: ${({ theme }) => theme.colors.main2}05;
    cursor: pointer;
    transition: all cubic-bezier(0.42, 0, 0, 1.57) 0.2s;

    &:hover {
      transform: scale(0.9);
    }
  }
`;