import useThemeContext from "../../../../hooks/useThemeContext";
import { Items, CollectionStl } from "./S_Collections";
import { AiFillDelete } from 'react-icons/ai';
import useProxyContext from "../../../../hooks/global/useProxyContext";
import { MdPending } from "react-icons/md";
import { HiSwitchHorizontal } from "react-icons/hi";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { FaCloudUploadAlt, FaEllipsisV, FaRegEye } from "react-icons/fa";
import { MdThumbUp } from 'react-icons/md';
import axios from 'axios';
import useAuthContext from "../../../../hooks/auth/useAuthContext";
import useErrHandler from "../../../../hooks/err/useErrHandler";
import useRefreshContext from "../../../../hooks/global/useRefreshContext";
import { useNavigate } from "react-router"; 
import { Post, PostInPrivate } from "../../../../components/post/Post";
import useImgHandler from "../../../../hooks/img/useImgHandler";
import { useEffect, useState } from "react";
import useAccess from "../../../../hooks/access/useAccess";
import { useTheme } from "styled-components";
import useConstructor from "../../../../hooks/constructor/useConstructor";

const Collection = ({ collection, global }) => {
  const [likes, setLikes] = useState('');
  const [liked, setLiked] = useState(false);

  const [seen, setSeen] = useState('');
  const [hadSeen, setHadSeen] = useState(false);

  // images from a collection
  const [firstFive, setFirstFive] = useState(null);

  // first five in public collection
  const [firstFiveApproved, setFirstFiveApproved] = useState(null);

  const { darkTheme } = useThemeContext();
  const { endpoint, imageEndpoint } = useProxyContext(); 

  const { user } = useAuthContext();

  const { notifier } = useErrHandler();

  const { reRender } = useRefreshContext(); 

  const { userId } = useAccess();

  const { approvedItems, toggleCollectionStatus, getLength } = useImgHandler();

  const { getRoute, getRouteRA } = useConstructor();

  const { reachRoute } = useImgHandler();

  const theme = useTheme();

  const navigate = useNavigate();

  // set the number of likes
  useEffect(() => {
    // set the number of likes
    setLikes(collection.likes.length);
    setSeen(collection.seen.length);
  }, [collection])

  // check if this user already liked
  useEffect(() => {
    if(!userId) return;

    // check if user already liked 
    const liked = collection.likes.some(like => like.userId === userId._id)

    // update liked state
    setLiked(liked);

    // check if user already seen
    const seen = collection.seen.some(sn => sn.userId === userId._id);

    // update had seen state 
    setHadSeen(seen);

  }, [userId, collection])

  // Delete collection
  const deleteCollection = async () => {

    const confirm = window.confirm('Are you sure you want to delete this collection ? ');

    if(!confirm) return;
    
    try {
      const res = await axios.put(`${endpoint}/del/collection`, {
        collectionId: collection._id
      }, {
        headers: {
          "Authorization": `Bearer ${user.token}`
        }
      })

      reRender();
      notifier(false, res.data.message);

    } catch (err) {
      console.log(err);
      const errMessage = err.response.data.message;
      notifier(true, errMessage);
    }
  }

  // call functon that get number of items in collection
  useEffect(() => {
    getLength(collection._id);
  }, [collection, getLength])

  // number of items in collection
  useEffect(() => {
    if(firstFiveApproved) return;
    if(!collection) return;
    if(!global) return;

    const controller = new AbortController();
    const signal = controller.signal;

    getRoute((data) => {
      setFirstFiveApproved(data.firstFiveApproved);
    }, `/images/first/five/approved/in/collection/${collection._id}`, signal)

    return () => {
      controller.abort();
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collection])

  useEffect(() => {
    if(!collection) return;
    if(global) return;

    const controller = new AbortController();
    const signal = controller.signal;

    getRouteRA((data) => {
      setFirstFive(data.firstFive);
    }, `/images/first/five/in/collection/${collection._id}/auth/user`, signal)

    return () => {
      controller.abort();
    }

    // disable warning for getRouteRA and global
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collection, userId])

  return collection && (
    <CollectionStl>
      
      <Items atHome={global} darkTheme={darkTheme} global={collection.global} approved={collection.approved}>
        <div id="header">
          <div id="status">
            <header>
              <img onClick={() => navigate(`/collection/${collection._id}`)} src={ imageEndpoint + "/images/" + collection.icon } alt=""/>

              <span>
                <div id="collection_info">
                  <h2>{collection.header}</h2> {/* Header */}
                  <div>
                    <span>
                      <span id="likeBtn" onClick={() => {
                        if(!userId) return notifier(true, "Want to like this collection? Please sign in to continue.")
            
                        // e.target.classList.toggle('clicked');

                        if (liked) {
                          setLikes(likes - 1);
                          setLiked(false);
                        } else {
                          setLikes(likes + 1);
                          setLiked(true);
                        }

                        reachRoute(() => {}, collection._id, '/toggle/like/collection')

                      }}><MdThumbUp style={{
                        color: liked ? `${theme.colors.main2}AA` : `${theme.colors.black}AA`
                      }} /> {likes}</span> 

                      <span><FaCloudUploadAlt /> {approvedItems}</span> 

                    <span><FaRegEye style={{
                      color: hadSeen ? `${theme.colors.main2}AA` : `${theme.colors.black}AA`
                    }} /> {seen}</span> 
                    
                    </span>
                  </div>
                </div>
              </span>
            </header>
          </div>

          {/* if this collection is not global */}
          {!(global) ? (
            <>
            <div id="options">
              <span onClick={() => toggleCollectionStatus(collection._id, collection.global)} id="stateSwitch"> 
                <small> { collection.global ? "Public" : "Private" } </small> 
                <HiSwitchHorizontal style={{
                    transform: collection.global ? `rotate(360deg)` : `rotate(0deg)`,
                    transition: "all ease-in-out 0.4s",
                  }}/>  
              </span>
              
              <span id="pending"> {collection.approved ? <> <small> Approved</small> <IoMdCheckmarkCircleOutline /> </> : <> <small>Pending</small> <MdPending /> </>} </span>
              
              <span id="deleteBtn" onClick={() => deleteCollection()}> <AiFillDelete /></span>
            </div>

            <div id="optionsMenu">
              <FaEllipsisV />
            </div>
            </>
          ):(
            <div id="publicOptions">
              <h4 id="showAll" onClick={() => navigate(`/collection/${collection._id}`)} style={{
                cursor: 'pointer'
              }}>Show all <span>&#8594;</span> </h4>
            </div>
          )}

        </div>

        <div id="itemsContainer"> {/* Items container */}
          <div>

            {global ? (
              // FILTER DIRECTLY FROM SERVER
              // fetch all images that is approved
              firstFiveApproved && firstFiveApproved.map((item, i) => (
                <Post key={i} item={item} />
              )
            )):(
              firstFive && firstFive.map((item, i) => (
                <PostInPrivate key={i} collection={collection} item={item} />
              )
            ))}

            {/* Show all btn was once here incase you need it back */}

          </div>
        </div>
      </Items>

    </CollectionStl>

  )
}

export default Collection;