import { FiDownload } from "react-icons/fi";
import usePopUpContext from "../../../../hooks/global/usePopUpContext";
import Button from "../../../button/Button";
import { BiUpload } from "react-icons/bi";
import useProxyContext from "../../../../hooks/global/useProxyContext";
import axios from "axios";
import useProcesser from "../../../../hooks/processer/useProcesser";
import useAuthContext from "../../../../hooks/auth/useAuthContext";
import useErrHandler from "../../../../hooks/err/useErrHandler";
import useProfile from "../../../../hooks/profile/useProfile";
import useConstructor from '../../../../hooks/constructor/useConstructor';

const GenPreview = () => {
  const { genPrevData } = usePopUpContext();

  const { endpoint } = useProxyContext();

  const { process, processing } = useProcesser();

  const { user } = useAuthContext();

  const { notifier } = useErrHandler();

  const { profileSetup } = useProfile();

  const { encodeImageToBlurhash } = useConstructor();

  const imageUrl = genPrevData.imageUrl;
  const prompt = genPrevData.prompt

  async function downloadImage() {
    process('STARTED', 'Starting download ...')

    const a = document.createElement("a");
    a.href = await toDataURL();
    a.download = 'modelgrapix.com -image-gen.png';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    process('ENDED');
  }

  async function toDataURL() {
    try {
      const res = await axios.post(`${endpoint}/proxy/download/gen`, { imageUrl }, { responseType: 'blob' })
      
      return URL.createObjectURL(res.data);

    } catch (err) {
      console.log(err);
      process('ENDED');
    }
  }

  // Upload generated image to model grapix
  const uploadToModelGrapix = async () => {

    if(!user) return notifier(true, "Sign In to continue!")

    if(processing) return;

    
    profileSetup(async () => {
      process('STARTED', 'Uploading...')

      try {
        
        // fetch image and generate blob
        const response = await fetch(imageUrl);

        if(!response.ok) return notifier(true, 'Something went wrong!');

        const blob = await response.blob();

        const imageBlob = URL.createObjectURL(blob)

        const placeholder = await encodeImageToBlurhash(imageBlob);

        console.log(placeholder);

        const res = await axios.post(`${endpoint}/gen/ai/create/upload`, 
          { imageUrl, prompt, placeholder }, 
          {
            headers: { Authorization: `Bearer ${user.token}` }
          }
        )
        
        process('ENDED');
        notifier(false, res.data.message);
  
      } catch (err) {
        process('ENDED');
        notifier(true, "An error occurred! Try again")
      }
    })
  }

  return (
    <div>
      <img src={genPrevData.imageUrl} alt="" />

      <div id="options">
        {/* Options */}
        <Button run={downloadImage}>Download <FiDownload /></Button>
        <Button run={uploadToModelGrapix}>Upload <BiUpload /></Button>
      </div>
    </div>
  );
}
 
export default GenPreview;