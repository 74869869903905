import { useNavigate } from 'react-router-dom';
import { Container } from '../../../../components/global/Container';
import useThemeContext from '../../../../hooks/useThemeContext';
import { Left, WelcomeScreenStl } from './S_WelcomeScreen';
import { useEffect } from 'react';
import { useTheme } from 'styled-components';

const WelcomeScreen = ({ type }) => {
  const { darkTheme } = useThemeContext();

  const theme = useTheme();

  const navigate = useNavigate();

  function writeOptionsContinuously(elementId, options, interval) {
    let element = document.getElementById(elementId);
    let index = 0;
    setInterval(function() {
      element.classList.add("fade-out"); // add the fade-out class
      setTimeout(function() {
        element.innerHTML = options[index];
        index = (index + 1) % options.length;
        element.classList.remove("fade-out"); // remove the fade-out class
      }, 500); // wait for the fade-out animation to finish
    }, interval);
  }

  function changeBackground() {
    const images = ["background_6.png", "background_5.png", "background_3.png", "background_4.png", "background_2.png", "background_1.png"];
    const background = document.getElementById("welcomeScreen");
    let index = 0;
  
    // Change the background image every minute
    setInterval(() => {
      background.style.backgroundImage = `linear-gradient(#000000AA, ${theme.colors.main2}20), url(/images/background/${images[index]})`;
      index = (index + 1) % images.length;
    }, 30000);
  }

  
  useEffect(() => {
    writeOptionsContinuously("option", ["Developers", "Collaborators", "Creators", "Design explorers", "Art lovers",  "Join Model Grapix", "Designers"], 3000);
    changeBackground();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <WelcomeScreenStl id="welcomeScreen" darkTheme={darkTheme}>

        <Container> 
          <Left>
            <h1><span id="option">Designers</span></h1>
            {/* <p>Design inspiration at your fingertips.</p> */}
            <p>
              {/* We are a platform for creatives who want to create, share, and connect with others,
              we aim to simplify the creative process and make it more accessible and fun */}

              {/* Do you love creating, sharing, and connecting with other creatives?
              Do you want to boost your creativity and make a difference in the world?
              If yes, then you should sign up today! */}

              Discover, create and connect with Model Grapix, welcome home creative people.
              
              {/* We have awesome AI tools to speed up your work process.
              You can easily create stunning visuals, edit them, and share them with others.
              You can also learn from other creatives, get feedback, and join our community.
              Our website is fun, friendly, and easy to use. */}
            </p>
            
            <form>
              <input 
                type="search" 
                placeholder='Search eg 3D Models, Vector graphics, AI portraits, Icons' 
              />
            </form>

            <div id="searchOptions">
              <div onClick={() => navigate('/all')} style={{
                background: type === 'ALL' && 'linear-gradient(to right top, #f32170, #cf23cf, #eedd44)',
                color: type === 'ALL' && '#FFFFFF'
              }}>All</div>

              <div onClick={() => navigate('/uploads')} style={{
                background: type === 'UPLOADS' && 'linear-gradient(to right top, #f32170, #cf23cf, #eedd44)',
                color: type === 'UPLOADS' && '#FFFFFF'
              }}>Uploads</div>

              <div onClick={() => navigate('/collections')} style={{
                background: type === 'COLLECTIONS' && 'linear-gradient(to right top, #f32170, #cf23cf, #eedd44)',
                color: type === 'COLLECTIONS' && '#FFFFFF'
              }}>Collections</div>

              <div onClick={() => navigate('/users')} style={{
                background: type === 'USERS' && 'linear-gradient(to right top, #f32170, #cf23cf, #eedd44)',
                color: type === 'USERS' && '#FFFFFF'
              }}>Users</div>
            </div>

          </Left>
        </Container> 
        
      </WelcomeScreenStl>
  );
}

export default WelcomeScreen;