import { useContext } from "react"
import { ConstructorContext } from "../../contexts/constructor/ConstructorContext";

const useConstructor = () => {
  const context = useContext(ConstructorContext);

  if(!context) throw Error("Cannot use this context here!");
  

  return context;
}

export default useConstructor;