import { useContext } from "react"
import { AdminAuthContext } from "../../../contexts/admin/auth/AuthContext";

const useAdminAuthContext = () => {
  const context = useContext(AdminAuthContext);

  if(!context) throw Error("Cannot use this context here");
  

  return context;
}

export default useAdminAuthContext;