import styled, {css} from "styled-components";

export const ApiKeyStyle = styled.div`
  & > div:nth-child(2) {
    margin-top: 50px;

    & > div {
      padding-bottom: 50px;

      & > h1 {
        margin-bottom: 30px;
      }

      // Paint
    & > div[id="grapixStatus"] {
      user-select: none;
      background: indigo;
      
      & > div[id="select"] {
        position: absolute;
        right: 40px;
        top: 30px;
        border-radius: 5px;
        overflow: hidden;
        padding: 10px;
        z-index: 97;
        transition: 0.5s cubic-bezier(0, 1.14, 0.78, 0.82);
        opacity: 0;
        transform: scale(1.1) translateY(20px);
        pointer-events: none;
        
        box-shadow: 0px 17px 25px #00000025;
        background: ${({ theme }) => theme.colors.white};
        
        & > div {
          display: flex;
          flex-direction: column;
          background: ${({ theme }) => theme.colors.main2}10;
          padding: 5px 20px 5px 5px;
          border-radius: 5px;
          border: 1px solid ${({ theme }) => theme.colors.black}50;
          
          &:hover {
            background: ${({ theme }) => theme.colors.main2}20;
          }

          &:first-child { margin-bottom: 10px }
          
          & > div {
            display: flex;
            align-items: center;
            grid-gap: 10px;
            
            & > img {
              width: 30px;
            }

            & > span {
              font-size: 30px;
              font-weight: 600;
              color: ${({ theme }) => theme.colors.black}AA;
            }

            & > div {
              display: flex;
              flex-direction: column;
              width: 100%;
              text-align: right;

              & > span {
                font-weight: 600;

                color: ${({ theme }) => theme.colors.main2};
              }

              & > small {
                font-size: 10px;
              }
            }
          }
        }

        & > small {
          margin-top: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          color: ${({ theme }) => theme.colors.main2};
          padding: 10px;

          & > svg {
            margin-right: 5px;
          }
        }

        ${({ openGrapix }) => openGrapix && css`
          opacity: 1;
          height: 120px;
          transform: scale(1) translate(0);
          pointer-events: visible;
          z-index: 98;
        `}
      }

      & > div[id="openSelectBtn"] {
        position: absolute;
        right: 40px;
        top: 0;
        border-radius: 5px;
        height: 30px;
        display: flex;
        align-items: center;
        grid-gap: 5px;
        padding: 5px 10px 5px 5px;
        z-index: 97;
        cursor: pointer;

        box-shadow: 1px 1px 3px #00000025;
        background: ${({ theme }) => theme.colors.white};
        border: 1px solid ${({ theme }) => theme.colors.black}50;

        & > img {
          width: 20px;
        }

        & > span {
          color: ${({ theme }) => theme.colors.main2}
        }
      }
    }

      & > div[id="apiContainer"] {
        display: flex;
        flex-direction: column;
        grid-gap: 10px;

        & svg {
          color: ${({ theme }) => theme.colors.black};
        }

        & > div {
          padding: 20px;
          border-radius: 16px;
          background: ${({ theme }) => theme.colors.white};
          box-shadow: 0px 5px 125px 0px rgba(0, 0, 0, 0.06);
          display: flex;
          justify-content: space-between;
          align-items: center;
          grid-gap: 20px;
          font-size: 17px;
          cursor: pointer;

          overflow: hidden;

          &:first-child {
            cursor: default;

            & > p {
              overflow: hidden;
              white-space: nowrap;
            }

            & > div {
              display: flex;
              grid-gap: 10px;

              & > span {
                display: grid;
                place-items: center;
                flex-direction: column;
                grid-tap: 10px;
                height: 50px;
                width: 50px;
                border-radius: 50%;
                background: ${({ theme }) => theme.colors.main2}10;
                cursor: pointer;
              }
            }
          }
        }
      }

      & > div[id="slogan"] {
        margin-top: 100px;
        color: rgba(0, 0, 0, 0.47);
        text-align: center;
      }
    }
  }
`;