import { Frame, PopUpStyle } from './styles/S_PopUp';
import usePopUpContext from '../../hooks/global/usePopUpContext';
import Create from './modules/Create';
import MyCollections from './modules/lists/MyCollections';
import Types from './modules/lists/Types';
import AllCollections from './modules/lists/AllCollections';
import ProfileSetup from './modules/profileSetup/ProfileSetup';
import useAuthContext from '../../hooks/auth/useAuthContext';
import Payment from './modules/payment/Payment';
import AdvancedSetup from './modules/profileSetup/Advanced';
import { useEffect } from 'react';
import Tools from './modules/tools/Tools';
import CreateOps from './modules/createOps/CreateOps';
import GenPreview from './modules/genPreview/GenPreview';
import FundWallet from './modules/fundWallet/FundWallet';
import InitiatePaymentVerification from './modules/initiatePaymentVerification/InitiatePaymentVerification';
import Paint from './modules/tools/Paint';
import TextPreview from './modules/text/TextPreview';
import Grapix from './modules/grapix/Grapix';

const PopUp = () => {
  const { closePopup, type, show, message } = usePopUpContext()

  const { user } = useAuthContext();

  useEffect(() => {
    const anm = document.querySelectorAll('.anm')
    
    for (let i = 0; i < anm.length; i++) {
      anm[i].classList.add('fade-in');
    }

  }, [show])

  const ifType = (str) => type === str

  return show && ( <>

    <PopUpStyle className='anm' id="PopupContainer" onClick={(e) => {
      if(e.target.id === "PopupContainer") {
        closePopup();
      }
    }}>

      {(ifType('types') || ifType('collections')) ? (

        <Frame className='anm' id="list">

          {/* Create Component for creating collection or type */}
          <Create type={type} />
          {/* Create Component for creating collection or type */}

          {/* My collections and their status */}
          {type === "collections" && <MyCollections />}
          {/* My collections and their status */}

          {/* Types */}
          {type === "types" && <Types />}
          {/* Types */}

          {/* All Collections */}
          {type === "collections" && <AllCollections />}
          {/* All Collections */}
        </Frame>

      ) : (user && (ifType('setup')) ? (

        <Frame className='anm' id="setup">
          <ProfileSetup />
        </Frame>

      ) : ((ifType('payment')) ? (

        <Frame className='anm' id="payment">
          <Payment />
        </Frame>

      ) : (user && (ifType('advanced')) ? (

        <Frame className='anm'>
          <AdvancedSetup />
        </Frame>

      ) : ((ifType('create')) ? ( // create options button at topbar

        <Frame className='anm' id="createOptions">
          <CreateOps />
        </Frame>

      ) : ((ifType('tools')) ? (

        <Frame className='anm' id="tools">
          <Tools />
        </Frame>

      ) : ((ifType('gen-preview')) ? (
        <Frame className='anm' id='genPreview'>
          <GenPreview />
        </Frame>
      ) : ((ifType('fund-wallet')) ? (
        <Frame className="anm" id="fundWallet">
          <FundWallet />
        </Frame>
      ) : ((ifType('initiate-payment-verification')) ? (
        <Frame className='anm' id="initiatePaymentVerification">
          <InitiatePaymentVerification />
        </Frame>
      ) : ((ifType('paint')) ? (
        <Frame className='anm' id="paint">
          <Paint />
        </Frame>
      ) : ((ifType('message')) ? (
        <Frame className='anm'>
          <TextPreview text={message} />
        </Frame>
      ) : ((ifType('grapix')) && (
        <Frame className='anm'>
          <Grapix />
        </Frame>
      ))))))))))))}

    </PopUpStyle>
  </>)
}

export default PopUp;