import { useEffect, useState } from "react";
import { PostStyle } from './styles/S_Post';
import useProxyContext from "../../../hooks/global/useProxyContext";
import useImgHandler from "../../../hooks/img/useImgHandler";
import Comments from "../../../components/popup/modules/comments/Comments";
import PostInfo from '../../../components/post/postInfo/PostInfo';
import { useNavigate } from 'react-router-dom';
import useAccess from "../../../hooks/access/useAccess";
import useErrHandler from "../../../hooks/err/useErrHandler";
import TopBar from "../../../components/topbar/TopBar";
import useRefreshContext from "../../../hooks/global/useRefreshContext";
import { useTheme } from 'styled-components';
import { Helmet } from "react-helmet-async";

import hljs from 'highlight.js';
import 'highlight.js/styles/atom-one-dark.css';
import { FaExternalLinkAlt } from "react-icons/fa";

import DOMPurify from 'dompurify';
import useVisits from "../../../hooks/stats/visits/useVisits";
import usePopUpContext from "../../../hooks/global/usePopUpContext";
import Processing from "../../../components/loader/Processing";
import useConstructor from "../../../hooks/constructor/useConstructor";

const renderCode = (match, code) => {
  const highlightedCode = hljs.highlightAuto(code).value;
  return `<div id="code"><span id="copy">Copy</span><code id="code-preview" class="javascript">${highlightedCode}</code></div>`;
};

const Post = () => {
  useVisits();

  const [overview, setOverview] = useState(null);

  const { imageEndpoint } = useProxyContext();

  const { /*getResolution,  resolution, */ addToStared, reachRoute } = useImgHandler();

  const { userId } = useAccess();

  const navigate = useNavigate();

  const { notifier } = useErrHandler();

  const { refresh } = useRefreshContext();

  const { closePopup, setUploadPreviewOpen } = usePopUpContext();

  const { getRoute, getRouteRA } = useConstructor();

  const postId = document.baseURI.split("/")[4];

  const theme = useTheme();

  // add seen to upload
  useEffect(() => {
    if(!overview) return;
    if(!userId) return;

    reachRoute(() => {}, overview._id, '/add/seen/upload')
  }, [overview, userId, reachRoute])

  // fetch data
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if(userId) {
      getRouteRA((data) => {
        setOverview(data);
      }, `/get/approved/image/${postId}`, signal, ((err) => {
        notifier(true, err.message)
      }))
    } else {
      getRoute((data) => {
        setOverview(data);
      }, `/get/image/${postId}`, signal, ((err) => {
        notifier(true, err.message);
      }))
    }

    return () => {
      controller.abort();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, refresh])
      
  // check image natural height and natureal width (resolution) 
/*   useEffect(() => {
    getResolution(overview);

    setTimeout(() => {
      console.log({ resolution })
      getResolution(overview);
    }, 3000);
  }, [overview, getResolution]) */

  // if overview
  useEffect(() => {
    const post = document.querySelector('#postStyle')

    if(post) {
      if(overview) {
        post.classList.add('fade-in');
      }
    }

  }, [overview])

  const preview = (e) => {
    // all image to preview
    const image = document.querySelectorAll('#image');

    // remove active color from all
    for (let i = 0; i < image.length; i++) {
      image[i].style.border = `5px solid ${theme.colors.black}50`
    }

    // The image previewd
    const previewContainer = document.querySelector('#previewImage')
    
    //Animation
    setTimeout(() => {
      previewContainer.style.opacity = 0;
      previewContainer.style.transform = "translateY(-5%)";
    }, 0);

    setTimeout(() => {
      previewContainer.style.transform = "translateY(5%)";
    }, 150);
    
    setTimeout(() => {
      previewContainer.style.opacity = 1;
      previewContainer.style.transform = "translateY(0%)";
      previewContainer.src = e.target.src;
    }, 300);
    
    // add active color to the clicked image 
    e.target.style.border = `5px solid ${theme.colors.main2}`
  }

  const navigateTo = async (url) => {
    closePopup();
    setUploadPreviewOpen(false);
    navigate(url);
  }

  return (
  <div>
    <Helmet>
      <title>{ `Model Grapix - ${overview ? overview.name : "Loading ..."}` }</title>

      <meta name="description" content={ `${overview && overview.desc}` } />

      <meta property="og:title" content={ `${overview && overview.name}` }/>
      <meta property="og:description" content={ `${overview && overview.desc}` } />
      <meta property="og:image" content={ `${imageEndpoint}/images/${overview && overview.src}` } />
      <meta property="og:url" content="htts://modelgrapix.com" />

      <link rel="icon" href='/images/logos/20230125_074855.png' />
    </Helmet>

    <TopBar />
    { overview ? (
      <PostStyle pro={overview.pro} id="postStyle">
        <div id="preview">
          <img id="previewImage" src={imageEndpoint + "/images/" + overview.src} alt=" "/>
          
          {/* <div id="resolution">
            <span>{resolution}</span>
          </div> */}

          { overview.images && overview.images.length !== 1 &&
          <div id="images">
            {overview.images.map((image, i) => (
              <img id="image" onClick={preview} key={i} src={imageEndpoint + "/images/" + image} alt=" "/>
            ))}
          </div>
          }
        </div>

        <div id="details">
          <PostInfo 
            post={overview}
            star={() => addToStared(overview._id, overview.src, overview.type)} 
            userPP={ overview.ownerPP ? (imageEndpoint + "/images/" + overview.ownerPP) : ('/images/profile.jpg') }
          />

          <h2>{overview.name}</h2>

          <main>
            <Desc content={overview.desc} />
          </main>

          <div id="addition">
            {overview.pro && 
              <div className="flex" id="price">
                <h3>Price</h3>
                <span><strong>{`${overview.currency} ${overview.price}`}</strong></span>
              </div>
            }

            {overview.collectionName && 
            <div className="flex">
              <h3>Collection</h3>
              <span 
                style={{ cursor: 'pointer' }}
                onClick={() => navigateTo(`/collection/${overview.collectionId}`)}
                id="collection"
              ><FaExternalLinkAlt /> <strong>{overview.collectionName}</strong>  </span>
            </div>
            }

            <div className="flex">
              <h3>Type</h3>
              <span>{overview.type}</span>
            </div>

            <div id="tags">
              {overview.tags.map((tag, i) => (
                <span key={i}>{tag.value}</span>
              ))}
            </div>
          </div>


          <div>
            <Comments post={overview} />
          </div>

        </div>
      </PostStyle>
    ):(
      <Processing />
    )}
  </div>
  )
}

const Desc = ({ content }) => {

  function copyCode(codeElement, copyElement) {
    const range = document.createRange();
    range.selectNodeContents(codeElement);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand('copy');
    selection.removeAllRanges();
    copyElement.innerHTML = 'Copied!'
    setTimeout(() => copyElement.innerHTML = 'Copy', 3000)
  }
  
  useEffect(() => {
    const copyElement = document.querySelectorAll('#copy');
    const codeElement = document.querySelectorAll('#code-preview');

    for (let i = 0; i < copyElement.length; i++) {
      const copyEl = copyElement[i];
      const codeEl = codeElement[i];
      
      copyEl.addEventListener('click', () => {
        copyCode(codeEl, copyEl)
      })
    }
  }, [])
  
  // Replace code blocks with <pre><code> tags
  const contentWithCode = content.replace(
    /```([\s\S]*?)```/gm,
    (match, code) => renderCode(match, code)
  );

  // Replace URLs with <a> tags
  const contentWithLinks = contentWithCode.replace(
    /(https?:\/\/[^\s]+)/g,
    '<a id="linkTag" href="$1">$1</a>'
  );

  // Replace *text* with <strong> tags
  const contentWithStrong = contentWithLinks.replace(
    /\*\*(.+?)\*\*/g,
    '<strong>$1</strong>'
  );

  // Replace _text_ with <em> tags
  const contentWithEm = contentWithStrong.replace(
    /_(.+?)_/g,
    '<em>$1</em>'
  );

  // Encode less-than (<) and greater-than (>) characters in code blocks
  const contentWithEncodedCode = contentWithEm.replace(
    /<pre><code class="javascript">([\s\S]*?)<\/code><\/pre>/gm,
    match => match.replace(/</g, '&lt;').replace(/>/g, '&gt;')
  );

  // Wrap content in a <p> tag
  const contentWithP = `<p>${contentWithEncodedCode}</p>`;

  // Purify
  const sanitizedHTML = DOMPurify.sanitize(contentWithP);

  return <pre id="desc" dangerouslySetInnerHTML={{ __html: sanitizedHTML }} ></pre>

}

export default Post;