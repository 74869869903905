import styled from 'styled-components';

export const CpanelStyle = styled.div`
  & > div[id="container"] {
    padding-top: 50px;
    padding-bottom: 50px;

    & div[id="header"] > h2 {
      margin-bottom: 20px;
    }
  }
`;