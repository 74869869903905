import { createContext } from 'react';

export const ProxyContext = createContext();

const ProxyContextProvider = ({ children }) => {
  // const reactAppClientEndpoint = process.env.REACT_APP_CLIENT_ENDPOINT
  // const reactAppServerEndpoint = process.env.REACT_APP_SERVER_ENDPOINT
  // const endpoint = process.env.REACT_APP_API_ENDPOINT;
  // const imageEndpoint = process.env.REACT_APP_IMAGE_ENDPOINT;
  
  const reactAppClientEndpoint = 'https://model-grapix-api.onrender.com'
  const reactAppServerEndpoint = 'https://model-grapix-api.onrender.com'
  const endpoint = 'https://model-grapix-api.onrender.com/api'
  const imageEndpoint = 'https://res.cloudinary.com/drrd1lwey/image/upload/v1675023065/model-grapix'

  return (
    <ProxyContext.Provider value={{ endpoint, imageEndpoint, reactAppClientEndpoint, reactAppServerEndpoint }}>
      {children}
    </ProxyContext.Provider>
  )
}

export default ProxyContextProvider;