import TopBar from "../../components/topbar/TopBar";
import Footer from "../../components/footer/Footer";
import { Container } from "../../components/global/Container";
import styled from 'styled-components';
import { Helmet } from "react-helmet-async";
import useVisits from "../../hooks/stats/visits/useVisits";

const CopyrightPolicyStyle = styled.div`
  // HEADER
  & > div[id="header"] {
    padding: 50px 0;
    margin: 0 0 20px 0;
    text-align: center;
    background: linear-gradient(to bottom right, ${({ theme }) => theme.colors.main }10, ${({ theme }) => theme.colors.main2 }10);
    
    & > h2 {
      font-weight: 900;
      background: linear-gradient(to right, #f32170, #ff6b08, #cf23cf, #eedd44);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
    }
  }

  & > div:nth-child(3) {
    display: flex;
    flex-direction: column;
    gap: 20px;

    & > pre {
      font-family: 'Poppins', sans-serif;
      font-size: 19px;
      line-height: 2em;
      white-space: pre-wrap;

      & li {
        margin-left: 20px;
      }
    }
  }
`;

const CopyrightPolicy = () => {
  useVisits();

  const policy = 
`Model Grapix respects the intellectual property rights of others and expects our users to do the same. We take claims of copyright infringement seriously and will respond to notices of alleged copyright infringement that comply with applicable law.

If you believe that your work has been copied in a way that constitutes copyright infringement, please provide us with a written notice containing the following information:
<ul>
<li>Your name, address, telephone number, and email address (if available)</li>
<li>A description of the copyrighted work that you claim has been infringed</li>
<li>A description of where the allegedly infringing material is located on the Model Grapix platform</li>
<li>A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law</li>
<li>A statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf</li>
<li>Notices of alleged copyright infringement should be sent to our designated copyright agent at modelgrapix@gmail.com. We may, in appropriate circumstances, remove or disable access to material on our platform that infringes on the rights of others. We may also terminate the accounts of users who repeatedly infringe the copyrights of others.</li>
</ul>By using Model Grapix, you agree to respect the intellectual property rights of others and to only upload content that you have the right to use and share. You retain all ownership rights to the content that you upload, but by submitting content to our platform, you grant us a non-exclusive, royalty-free license to use, reproduce, modify, and distribute the content for the purposes of operating and promoting Model Grapix.

Last updated: <strong>27th, Feb. 2023</strong>

`;

  return (
    <CopyrightPolicyStyle>
      <Helmet>
        <title>Model Grapix - Copyright Policy</title>

        <meta name="description" content={policy} />

        <meta property="og:title" content="Model Grapix - Copyright Policy" />
        <meta property="og:description" content={policy} />

        <link rel="icon" href='/images/logos/20230125_074855.png' />
      </Helmet>

      <TopBar />

      <div id="header">
        <h2>Copyright Policy</h2>
        <p>Respect for Creativity: Model Grapix Copyright Policy</p>
      </div>

      <Container>
        <pre dangerouslySetInnerHTML={{ __html: policy }}></pre>
      </Container>

      <Footer />

    </CopyrightPolicyStyle>

  )
}

export default CopyrightPolicy;