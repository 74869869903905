import useThemeContext from "../../hooks/useThemeContext";

export const useColorTheme = () => {
  const { darkTheme } = useThemeContext();
  // #f32170
  // #cf23cf

  // #2B8AE2
  // #2FC89A
  
  const colorTheme = {
    colors:
      darkTheme ?
    {
      black: "#FFFFFF", // ♦
      darkBlue: "#F9F9F9", // ♦
      main2: "#cf23cf",
      fadeblack2: "#73667E",
      fadeblack: "#333",
      main: "#ff6b08",
      purple: "#9900CC",
      gold: "#D8C520",
      crimson: "#E24027",
      white: "#000000", // ♦
      lightgrey: "#D9D9D9",
      theme: "#121212", // ♦
      blue: "#2B8AE2",
      green: "#2FC89A"
      // primary: "",
      // border: "" 
    } : {
      black: "#000000",
      darkBlue: "#113658",
      main2: "#cf23cf",
      fadeblack2: "#73667E",
      fadeblack: "#333",
      main: "#ff6b08",
      purple: "#9900CC",
      gold: "#D8C520",
      crimson: "#E24027",
      white: "#FFFFFF",
      lightgrey: "#D9D9D9",
      theme: "#fafafa",
      blue: "#2B8AE2",
      green: "#2FC89A"
      // primary: "",
      // border: "" 
    }
  }

  return colorTheme;
}