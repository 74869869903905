import TopBar from "../../components/topbar/TopBar";
import Footer from "../../components/footer/Footer";
import { Container } from "../../components/global/Container";
import styled from 'styled-components';
import { Helmet } from "react-helmet-async";
import useVisits from "../../hooks/stats/visits/useVisits";

const TermsAndConditionStyle = styled.div`
  // HEADER
  & > div[id="header"] {
    padding: 50px 0;
    margin: 0 0 20px 0;
    text-align: center;
    background: linear-gradient(to bottom right, ${({ theme }) => theme.colors.main }10, ${({ theme }) => theme.colors.main2 }10);
    
    & > h2 {
      font-weight: 900;
      background: linear-gradient(to right, #f32170, #ff6b08, #cf23cf, #eedd44);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
    }
  }

  & > div:nth-child(3) {
    display: flex;
    flex-direction: column;
    gap: 20px;

    & > pre {
      font-family: 'Poppins', sans-serif;
      font-size: 19px;
      line-height: 2em;
      white-space: pre-wrap;
    }
  }
`;

const TermsAndCondition = () => {
  useVisits();

  const policy = 
`
<h3>TERMS AND CONDITIONS</h3>
Welcome to Model Grapix! By accessing or using our website, you agree to comply with and be bound by the following Terms and Conditions.

<h3>USE OF OUR SERVICES</h3>
a. You are responsible for maintaining the confidentiality of your account and password, and for restricting access to your computer and account.

b. You are solely responsible for your use of our services and any content that you create or upload on our platform.

c. You must not use our services for any illegal or unauthorized purpose.

<h3>CONTENT AND OWNERSHIP</h3>
a. All content created or uploaded on our platform remains the property of the respective owner.

b. You grant Model Grapix a non-exclusive, transferable, sub-licensable, royalty-free, worldwide license to use, distribute, modify, copy, publicly display and perform, and create derivative works of your content.

<h3>FEES AND PAYMENTS</h3>
a. You may be required to pay a fee to use certain features of our services.

b. All fees are non-refundable.

c. Model Grapix reserves the right to change its fees and payment policies at any time.

<h3>DISCLAIMER OF WARRANTIES</h3>
a. Model Grapix does not guarantee the accuracy, completeness, reliability, or availability of any information on our website.

b. Model Grapix does not warrant that our services will be uninterrupted, secure, or error-free.

<h3>LIMITATION OF LIABILITY</h3>
a. Model Grapix will not be liable for any damages, whether direct, indirect, incidental, punitive, or consequential, arising out of or in connection with the use or inability to use our services.

b. Model Grapix's liability for any claim related to our services will not exceed the amount paid by you for such services.

<h3>MODIFICATIONS TO TERMS AND CONDITIONS</h3>
Model Grapix reserves the right to modify or update these Terms and Conditions at any time without prior notice. Your continued use of our website after any such modifications or updates constitutes your acceptance of the new Terms and Conditions.

Last updated: <strong>27th, Feb. 2023</strong>

`;

  return (
    <TermsAndConditionStyle>
      <Helmet>
        <title>Model Grapix - Terms and Condition</title>

        <meta name="description" content={policy} />

        <meta property="og:title" content="Model Grapix - Terms and Condition" />
        <meta property="og:description" content={policy} />

        <link rel="icon" href='/images/logos/20230125_074855.png' />
      </Helmet>

      <TopBar />

      <div id="header">
        <h2>Terms and Conditions</h2>
        <p>Establishing a Clear Agreement: Our Terms and Conditions for a Safe and Secure Online Experince</p>
      </div>

      <Container>
        <pre dangerouslySetInnerHTML={{ __html: policy }}></pre>
      </Container>

      <Footer />

    </TermsAndConditionStyle>

  )
}

export default TermsAndCondition;