import { HiPlusSm, HiUsers } from 'react-icons/hi';
import { MdCloudUpload } from 'react-icons/md';
import useAccess from '../../hooks/access/useAccess';
import { useEffect, useState } from 'react';
import useImgHandler from '../../hooks/img/useImgHandler';
import useErrHandler from '../../hooks/err/useErrHandler';
import { UserOverviewStyle } from './styles/S_UserOverview';
import axios from 'axios';
import useProxyContext from '../../hooks/global/useProxyContext';
import useAuthContext from '../../hooks/auth/useAuthContext';
import useConstructor from '../../hooks/constructor/useConstructor';

const UserOverview = ({ userData, button }) => {

  const [followersCount, setFollowersCount] = useState('');

  const [directive, setDirective] = useState('');

  const [uploadsLength, setUploadsLength] = useState('');

  const { userId } = useAccess();

  const { getRoute, reachRoute } = useImgHandler();

  const { notifier } = useErrHandler();

  const { endpoint } = useProxyContext();

  const { user } = useAuthContext();

  const { setExpandChat, setOpenMessage, setChat } = useConstructor();

  useEffect(() => {
    // set the number of followers
    setFollowersCount(userData.followers.length);

    if(!userId) return setDirective('Follow');

    // check if user already followed 
    const follower = userData.followers.some(follower => follower._id === userId._id);

    if(follower) { setDirective('Unfollow') } else { setDirective('Follow') }

  }, [userData, userId])

  // get uploads length
  useEffect(() => {
    getRoute((data) => {
      setUploadsLength(data);
    }, `/get/uploads/length/${userData._id}`);
  }, [userData, getRoute])

  // note: the userId parameter is the user id of the external user to make connection with
  const connectChat = async (userId) => {
    try {
      const res = await axios.post(`${endpoint}/chats/create`, {
        userId
      }, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      })

      setExpandChat(true);
      setOpenMessage(true);
      setChat(res.data);

    } catch (err) {
      console.log(err);
    }
  }

  return (
    <UserOverviewStyle button={button}>
      <div>

        {/* uploads and follow count component  */}
        <strong>
          <span><HiUsers /> {followersCount} Followers</span>
          <span><MdCloudUpload />{uploadsLength} Uploads</span>
        </strong> 

        {/* Follow button component  */}
        { ((userId && userId._id) === userData._id || !button) ? "" : button ? 
          (
            <span onClick={() => {
              if(!userId) return notifier(true, "Want to follow this user? Please sign in to continue.")
              
              if (directive === 'Follow') {
                setDirective('Unfollow')
                setFollowersCount(followersCount + 1);
              } else {
                setDirective('Follow')
                setFollowersCount(followersCount - 1);
              }

              reachRoute((data) => {}, userData._id, '/toggle/follow')
              
            }}>
              { directive === 'Follow' ? <HiPlusSm /> : ""} { directive }
            </span>
          ) : ""
        }
      </div>

      {/* Send message Component  */}
      { ((userId && userId._id) === userData._id || !button) ? ("") : ( userId &&
        <div id="sendMessageBtn" onClick={() => {
          if(!userId) return setExpandChat(true);
          connectChat(userData._id)
        }}>Send message</div>
      ) }

    </UserOverviewStyle>
  )
}

export default UserOverview;