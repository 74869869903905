import { useContext } from "react";
import { ErrHandler } from "../../contexts/err/ErrHandler";

const useErrHandler = () => {
  const context = useContext(ErrHandler);

  if(!context) throw Error('You can only use this context inside its wrapped contents');

  return context;
}
 
export default useErrHandler;