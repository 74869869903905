import { useContext } from "react";
import { ThemeContext } from "../contexts/ThemeContext"

const useThemeContext = () => {
  const context = useContext(ThemeContext);

  if(!context) throw Error('You can only use this context inside its wrapped contents');

  return context;
}
 
export default useThemeContext;