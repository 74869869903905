import { Container } from "../../components/global/Container"
import { CreateStl, Editor, FormatingBoxStl, Box, Add } from "./styles/S_Create";
import { FaPlus } from 'react-icons/fa';
import { FaCamera } from 'react-icons/fa';
import { useRef, useState } from "react";
import { useEditor } from "./useEditor";
import useErrHandler from '../../hooks/err/useErrHandler';

const Create = () => {
  const editorBody = useRef();
  const postDefault = useRef();
  const tagsContainer = useRef();

  const { addSpace, handlePublish, edited, posting } = useEditor({ editorBody, postDefault, tagsContainer });

  return (
    <CreateStl>
      <Container>

        <h1>Create Post</h1>

        <div>
          <Editor>
            <div>
              {/* Editing tools header */}
              <p></p>

              <button 
                onClick={handlePublish}
                style={{
                  cursor: posting ? 'not-allowed' : 'pointer',
                  pointerEvents: posting ? 'none' : 'visible'
                }}
              >{posting ? 'Publishing...' : 'Publish'}</button>
            </div>

            <div ref={postDefault}>
              <FormatingFrame title={true} name='Post Title...' />
              <FormatingFrame  icon={ <FaCamera /> } image={true} />
            </div>

            <div ref={editorBody} className='editableFrameContainer'>
              {/* append Editable frames from javascript */}
            </div>

            {edited && (
              <Add id="addOptions">
                <div onClick={addSpace}>
                  <FaPlus />
                </div>
                <div></div>
              </Add>
            )}

          </Editor>

          <div  ref={tagsContainer} id="tags" contentEditable={true} suppressContentEditableWarning={true}>
            #Design #UI/UX #Designtips
          </div>

        </div>

      </Container>

    </CreateStl>
  )
};

export const FormatingFrame = ({ icon, name, title, image }) => {
  const [featuredImg, setFeaturedImg] = useState(null);

  const { notifier } = useErrHandler();

  return (
    <FormatingBoxStl>

      <Box>
        <div> {/* <<<< */}
          {!featuredImg && 
            title && (
              <p 
                contentEditable={title} 
                style={{
                  width: "100%",
                  fontWeight: '600',
                  fontFamily: 'Jost, sans-serif',
                  // color: '#8D3F6E',
                }} 
                onFocus={(e) => {
                  if(e.target.innerHTML !== ' ' && e.target.innerHTML === 'Post Title...') {
                    (e.target.style.fontStyle = 'normal');
                    (e.target.innerHTML = ' ');
                  }
                }} 
                onBlur={(e) => {
                  if(e.target.innerHTML.length === 0 || e.target.innerHTML.length === 1 ) {
                    (e.target.innerHTML = 'Post Title...');
                    (e.target.style.fontStyle = 'italic');
                  }
                }}
                suppressContentEditableWarning={true}
              >{ name }</p>
            )
          }

          { image && (
            <form action="">
              <img src="/images/background.jpg" alt="" />

              <input 
                type="file" 
                accept="image/*"
                name="featured" 
                id="featured" 
                onChange={(e) => {
                  const file = e.target.files[0];
                  const fileSizeInMB = file.size / (1024 * 1024);
                  const imgPreview = e.target.parentElement.children[0];
                  
                  if(fileSizeInMB > 3) {
                    notifier(true, "This selected file is too large. Please select a file under 2MB.")
                    imgPreview.src = URL.createObjectURL(file);
                    imgPreview.style.border = "5px solid red";
                    return;
                  }
                  
                  imgPreview.src = URL.createObjectURL(file);
                  imgPreview.style.border = "none";
                  setFeaturedImg(file)
                }}
              />
              <label htmlFor="featured">
                {!featuredImg ? 'Upload Featured Image' : 'Reselect'} { icon }
              </label>
            </form>
          )}
        </div>

        {/* <IoEllipsisHorizontalOutline /> <<<< */}

      </Box>

    </FormatingBoxStl>
  )
}

export default Create;