import { useEffect } from "react";
import useProxyContext from "../../global/useProxyContext";

const useVisits = () => {
  const { endpoint } = useProxyContext();

  const generatedId = Math.random(25).toString(25).substring(2);

  const v_id = localStorage.getItem('v_id');

  if(!v_id) localStorage.setItem('v_id', generatedId);

  const detectBrowser = () => {
    const userAgent = window.navigator.userAgent;

    if(userAgent.indexOf('Firefox') > -1) return 'Mozilla Firefox';
    if(userAgent.indexOf('Chrome') > -1) return 'Google Chrome';
    if(userAgent.indexOf('Safari') > -1) return 'Apple Safari';
    if(userAgent.indexOf('Opera') > -1) return 'Opera';
    if(userAgent.indexOf('Edge') > -1) return 'Microsoft Edge';
    return 'Unknown browser';
  }

  const sendBrowserInfo = (browser, page) => {
    const url = `${endpoint}/visitor`;
    const data = { browser, page, v_id: v_id ?  v_id : generatedId };

    if(v_id === "1234567890__ABC") return;
    
    const submitVisitor = () => {
      fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(() => {})
        .then(() => {})
        .catch(() => {});
      }

      if(window.location.protocol === 'https:') {
        submitVisitor();
      } else {
        setTimeout(() => {
          if(window.location.protocol === 'https:') {
            submitVisitor();
          } else {
            submitVisitor();
          }
        }, 2000)
      }
  }

  const browserName = detectBrowser();
  const page = window.location.href;
  
  useEffect(() => {
    sendBrowserInfo(browserName, page);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
 
export default useVisits;