import useProxyContext from "../../../../hooks/global/useProxyContext";
import { FaAngleDown, FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import useDate from "../../../../hooks/global/useDate";

const Stared = ({ blog }) => {

  const history = blog.history.sort((a, b) => b._d - a._d);

  return (
    <div>
      <div>
        <h2>Blog</h2>
        <div>
          <h3>History</h3>
          <FaAngleDown />
        </div>
      </div>

      {history && 
      <div className="flex">
        {history.map((item, i) => (
          <Item key={i} item={item} />
        ))}
      </div>
      }
    </div>
  )
}

const Item = ({ item }) => {

  const { imageEndpoint } = useProxyContext();

  const { getDate } = useDate();

  const navigate = useNavigate();

  // animate
  useEffect(() => {
    const imgPreview = document.querySelectorAll('.itemPreview')
    
    if(item) {
      for (let i = 0; i < imgPreview.length; i++) {
        const element = imgPreview[i];
        element.classList.add('fade-in');
      }
    }
  }, [item])

  return (
    <div>
      <main className="itemPreview">
        
        <main id="uploadImg" className="box-shadow" onClick={() => navigate(`/blog/post/${item.postId}`)}>
          <img src={`${imageEndpoint}/images/${item.postFeaturedImageName}`} alt=""/>
        </main>
        
        <div id="stared">
          <span><FaTimes /></span>
        </div>
      </main>
      
      <header>
        <h4>{item.postTitle}</h4>
        <small>{getDate(item._d)}</small>
      </header>

    </div>
  )
}

export default Stared;