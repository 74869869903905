import Single from './modules/single/Single';
import Topbar from '../../components/topbar/TopBar';
import Footer from '../../components/footer/Footer';
import useVisits from '../../hooks/stats/visits/useVisits';

const SingleBlog = () => {
  useVisits();

  return (
    <>
      <Topbar />
      <Single />
      <Footer />
    </>
  )
}

export default SingleBlog;