import { useEffect, useState } from "react";
import { Post } from "../../../../components/post/Post";
import useImgHandler from "../../../../hooks/img/useImgHandler";
import { ImagesStyle } from "./styles/S_Images";
import Button from '../../../../components/button/Button';
import PostSkeleton from "../../../../components/loader/skeleton/PostSkeleton";
import useDataContext from "../../../../hooks/global/useDataContext";

const Images = () => {
  const [count, setCount] = useState(6);

  const { getRoute } = useImgHandler();

  const { uploads, setUploads } = useDataContext();

  useEffect(() => {
    if(uploads) return;
    
    getRoute((data) => {
      setUploads(data);
    }, '/approved/in/collections')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return uploads ? (
    <ImagesStyle>
      <div id="posts">
        <div>
          { uploads.slice(0, count).map((image, i) => (
            <Post key={i} item={image} fit></Post>
          ))}
        </div>
      </div>

      { uploads.length > count &&
      <div id="btn">
        <Button run={() => setCount(count + 6)}>... Load More</Button>
      </div>
      }

    </ImagesStyle>
  ):(
    <PostSkeleton />
  )
}

export default Images;