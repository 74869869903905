import styled from 'styled-components';
import axios from 'axios';
import { useEffect, useState } from 'react';
import useCountries from '../../hooks/global/useCountries';

const CountriesStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0000000;
  z-index: 701;

  & > div {
    width: 600px;
    min-height: 300px;
    max-height: 80vh;
    background: #FFF;
    padding: 20px;
    border-radius: 10px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;

    &::-webkit-scrollbar { display: none }

    & > div {
      display: flex;
      align-items: center;
      gap: 20px;
      padding: 10px;
      background: ${({ theme }) => theme.colors.black}10;
      border-radius: 5px;
      cursor: pointer;
      
      &:hover {
        background: ${({ theme }) => theme.colors.black}05;
      }

      & img {
        width: 50px;
        height: 40px;
        object-fit: cover;
        border-radius: 5px;
      }
    }
  }
`;

const Countries = () => {
  const [data, setData] = useState(null);
  const { setSelected, setShowCountries } = useCountries();

  useEffect(() => {
    (async function(){
      const res = await axios.get("https://restcountries.com/v3.1/all");
      setData(res.data);
    }())
  }, []) 

  return data && (
    <CountriesStyle>
      <div>
        {/* Make a fetch */}
        {data.sort((a, b) => (a.name.common > b.name.common ? 1 : -1)).map((item, i) => (
          <div key={i} onClick={() => {
            setSelected({ name: item.name.common, flag: item.flags.png });
            setShowCountries(false);
          }}>
            <img src={item.flags.png} alt=""/>
            <div>{item.name.common}</div>
          </div>
        ))}
      </div>
    </CountriesStyle>
  )
}

export default Countries;