import { useEffect, useState } from "react";
import useProxyContext from "../../hooks/global/useProxyContext";
import { Blurhash } from "react-blurhash";

const Img = ({ src, placeholder, className, id }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const { imageEndpoint } = useProxyContext();

  useEffect(() => {
    const img = new Image();

    img.onload = () => {
      setImageLoaded(true);
    }

    img.src = `${imageEndpoint}/images/${src}`
  }, [imageEndpoint, src])

  return (
    <>
      {!imageLoaded && placeholder ? (
        <Blurhash 
          id={id}
          className={className}
          hash={placeholder}
          width="100%"
          height="100%"
          resolutionX={32}
          resolutionY={32}
          punch={1}
        />
        ):(
        <img
          id={id}
          className={className}
          src={`${imageEndpoint}/images/${src}`}
          alt="Model Grapix"
        />
      )}
    </>
  )
}
 
export default Img;