import useAuthContext from "../auth/useAuthContext";
import axios from 'axios';
import useErrHandler from "../err/useErrHandler";
import { useNavigate } from 'react-router-dom';
import useProxyContext from "../global/useProxyContext";
import useProcesser from "../processer/useProcesser";

const useSignIn = () => {
  const { dispatch } = useAuthContext();

  const { notifier } = useErrHandler();

  const { endpoint } = useProxyContext();
  
  const navigate = useNavigate();

  const { process } = useProcesser();

  const handleLogin = async ({ email, password }) => {
    dispatch({ type: 'LOGIN_START'});
    process('STARTED', 'Processing...');

    try {
      const res = await axios.post(`${endpoint}/auth/login`, { email, password });

      process('ENDED');

      // update state
      dispatch({ type: 'LOGIN_SUCCESS', payload: res.data.data});

      // set success message
      notifier(false, res.data.message);

      // redirect user to dashboard
      navigate(`/${res.data.user.userName.split(" ")[0]}/profile/${res.data.user.userId}/uploads`);

    } catch (err) {
      process('ENDED');
  
      if(err.code === 'ERR_NETWORK') {
        notifier(true, 'Network Error!');
      } else {
  
        const errorMessage = err.response.data;
        dispatch({ type: 'LOGIN_FAILURE', payload: errorMessage });
  
        // set error message
        notifier(true, errorMessage.message, errorMessage.warning);
      }

    }
  }

  const register = async ({ userName, email, password }) => {
    process('STARTED', 'Processing...');
    dispatch({ type: 'REGISTRATION_START' });

    try {
      const res = await axios.post(`${endpoint}/auth/register`, { userName, email, password });

      process('ENDED');

      // update state 
      dispatch({ type: 'REGISTRATION_SUCCESS' })

      // set success message
      notifier(false, res.data.message);

      //redirect user to dashbaord
      navigate("/sign-in");

    } catch (err) {
      process('ENDED');

      if(err.code === 'ERR_NETWORK') {
        notifier(true, 'Network Error!');
      } else {
        const errorMessage = err.response.data.message;
        dispatch({ type: 'REGISTRATION_FAILURE', payload: errorMessage })
  
        // set error message
        notifier(true, errorMessage);
      }
    }
  }

  const logout = async () => {
    
    try {
      dispatch({ type: "LOGOUT" });
      notifier(false, "You logged out!!");

      window.location.reload();

    } catch (err) {
      console.log(err);
    }

  }

  return { handleLogin, register, logout };
}

export default useSignIn;