import { FaAngleDown, FaAngleRight } from "react-icons/fa";
import { NavStyle } from "./styles/S_Nav";
import { useState } from "react";

const Nav = () => {
  const [expandUseOfPlatform, setExpandUseOfPlatform] = useState(true);
  const [expandUseOfApi, setExpandUseOfApi] = useState(false);

  const scrollToUseOfApi = () => {
    const apiIntroduction = document.querySelector('#apiIntroduction');

    const targetDivOffset = apiIntroduction.offsetTop;
    window.scrollTo({
      top: targetDivOffset,
      behavior: 'smooth',
    });
  }

  const scrollToUseOfPlatform = () => {
    const introduction = document.querySelector('#introduction');

    const targetDivOffset = introduction.offsetTop;
    window.scrollTo({
      top: targetDivOffset,
      behavior: 'smooth',
    });
  }

  return (
    <NavStyle expandUseOfPlatform={expandUseOfPlatform} expandUseOfApi={expandUseOfApi}>

      <div>
        <div>
          <h3 onClick={() => {
            scrollToUseOfPlatform()
            setExpandUseOfPlatform(!expandUseOfPlatform)
          }}>
            { expandUseOfPlatform ? <FaAngleDown /> : <FaAngleRight />}
            Use Of Platform</h3>
          <div>
            <a href="#introduction">
            <h4>Introduction</h4>
            <div>
              <small>Overview of Model Grapix</small>
              <small>Purpose of this documentation</small>
            </div>
          </a>

          <a href="#gettingStarted">
            <h4>Getting Started</h4>
            <div>
              <small>Creating an account</small>
              <small>Navigating the platform</small>
              <small>Setting up your profile</small>
            </div>
          </a>

          <a href="#usingModelGrapix">
            <h4>Using Model Grapix</h4>
            <div>
              <small>Displaying your projects</small>
              <small>Creating collections</small>
              <small>Interacting with Content </small>
              <small>Using AI Tools</small>
              <small>Sharing your profile</small>
            </div>
          </a>

          <a href="#bestPractices">
            <h4>Best Practices</h4>
            <div>
              <small>Optimizing your profile for search engines</small>
              <small>Collaborating with other users</small>
              <small>Monetizing your designs</small>
            </div>
          </a>

          <a href="#troubleshooting">
            <h4>Troubleshooting</h4>
            <div>
              <small>Common issues and solutions</small>
              <small>Contacting support</small>
            </div>
          </a>

          <a href="#conclusion">
            <h4>Conclusion</h4>
            <div>
              <small>Recap of key features</small>
              <small>Final thoughts and suggestions</small>
            </div>
          </a>
          </div>
        </div>

        <div>
          <h3 onClick={() => {
            scrollToUseOfApi()
            setExpandUseOfApi(!expandUseOfApi)
          }}>
            { expandUseOfApi ? <FaAngleDown /> : <FaAngleRight />}
            Use Of API</h3>
          <div>
            <a href="#apiIntroduction">
            <h4>Introduction</h4>
            <div>
              <small>Introduction</small>
            </div>
          </a>

          <a href="#apiGettingStarted">
            <h4>Getting Started</h4>
            <div>
              <small>Get an API Key</small>
              <small>Make Your First Request</small>
              <small>Integrate the API</small>
            </div>
          </a>

          <a href="#apiImageGeneration">
            <h4>Image Generation API</h4>
            <div>
              <small>Authentication</small>
              <small>API Endpoint</small>
              <small>Request Parameters</small>
              <small>. . .</small>
            </div>
          </a>

          <a href="#apiBackgroundRemoval">
            <h4>Background Removal API</h4>
            <div>
              <small>Authentication</small>
              <small>API Endpoint</small>
              <small>Request</small>
              <small>. . .</small>
            </div>
          </a>

          <a href="#apiInpainting">
            <h4>Inpainting API</h4>
            <div>
              <small>Authentication</small>
              <small>API Endpoint</small>
              <small>Request</small>
              <small>. . .</small>
            </div>
          </a>

          <a href="#apiStatusCodes">
            <h4>Status codes</h4>
            <div>
              <small>Status codes</small>
            </div>
          </a>

          <a href="#apiConclusion">
            <h4>Conclusion</h4>
            <div>
              <small>Summarizing</small>
            </div>
          </a>
          </div>
        </div>
      </div>

    </NavStyle>
  )
}

export default Nav;