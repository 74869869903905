export const image_gen_curl = 
`\`\`\`
curl -X POST "https://api.modelgrapix.com/api/image-gen-v1" \\
  -H "Authorization: Bearer YOUR_API_KEY" \\
  -H "Content-Type: application/json" \\
  -d '{
    "prompt": "A cute baby elephant",
    "upscale": true,
    "display": "square",
    "paint": "PUBLIC"
  }'
\`\`\``

export const bg_remover_curl = 
`\`\`\`
curl -X POST "https://api.modelgrapix.com/api/bg-remover-v1" \\
  -H "Authorization: Bearer YOUR_API_KEY" \\
  -F "image_file=@/path/to/your/imageFile.jpg"
\`\`\``

export const inpainting_curl = 
`\`\`\`
curl -X POST "https://api.modelgrapix.com/api/inpainting" \\
  -H "Authorization: Bearer YOUR_API_KEY" \\
  -F "image_file=@/path/to/your/originalImage.jpg" \\
  -F "mask_file=@/path/to/your/maskImage.jpg"
\`\`\``