import usePopUpContext from "../../../../hooks/global/usePopUpContext";
import useAuthContext from "../../../../hooks/auth/useAuthContext";
import useErrHandler from "../../../../hooks/err/useErrHandler";
import axios from 'axios';
import { useState } from "react";
import useProxyContext from "../../../../hooks/global/useProxyContext";
import Button from "../../../button/Button";
import Cookies from "js-cookie";
import useProcesser from "../../../../hooks/processer/useProcesser";

const InitiatePaymentVerification = () => {
  const [message, setMessage] = useState('');

  const { reference, closePopup } = usePopUpContext();

  const { user } = useAuthContext();
  
  const { notifier } = useErrHandler();

  const { endpoint } = useProxyContext();

  const { process, processing } = useProcesser();

  const verifyPayment = async () => {
    if(processing) return;

    process('STARTED', 'Sending verification link ...');

    try {
      if(!reference) return;

      const res = await axios.post(`${endpoint}/payment/create/verification/url`, {
        reference
      }, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      })
      // Remove token from URL in browser history
      const newUrl = window.location.href.split('?')[0];
      window.history.pushState({}, document.title, newUrl);

      process('ENDED');
      setMessage(res.data.message);
      notifier(false, res.data.message);

      Cookies.remove('unsettled_ref');
      
    } catch (err) {
      setMessage(err.response.data.message);
      process('ENDED')
    }
  }

  return (
    <div>
      <h1>Start verification</h1>
      <p>Please verify your payment by clicking the button below. Upon clicking, a verification link will be sent to your email account for confirmation.</p>
      <Button run={verifyPayment}>Send Verification Link</Button>

      <Button id="confirmVerification" run={() => {
        const confirm = window.confirm('Confirm');
        if(!confirm) return;
        Cookies.remove('unsettled_ref');
        closePopup();
      }}>I have done this!</Button>

      {message && <small style={{
        background: '#FFA500AA',
        padding: '10px',
        borderRadius: '5px'
      }}>
        {message}
      </small>}
    </div>
  )
}
 
export default InitiatePaymentVerification;