import { UploadStyle } from "./styles/S_Upload";
import { Container } from '../../components/global/Container';
import TopBar from "../../components/topbar/TopBar";
import { IoMdCloudUpload } from 'react-icons/io';
import { FaPlus, FaAngleDown } from "react-icons/fa";
import Button from "../../components/button/Button";
import { useEffect, useState } from 'react';
import usePopUpContext from '../../hooks/global/usePopUpContext';
import useProxyContext from '../../hooks/global/useProxyContext';
import axios from 'axios';
import useAuthContext from "../../hooks/auth/useAuthContext";
import useErrHandler from "../../hooks/err/useErrHandler";
import { useNavigate } from "react-router-dom";
import useAccess from "../../hooks/access/useAccess";
import useProcesser from "../../hooks/processer/useProcesser";
import useProfile from "../../hooks/profile/useProfile";
import { Helmet } from "react-helmet-async";
import useVisits from "../../hooks/stats/visits/useVisits";
import useConstructor from "../../hooks/constructor/useConstructor";

const getNum = (str) => str.replace(/\D/g, ''); 

const Update = () => {
  useVisits();

  const [data, setData] = useState(null);
  
  const { userId } = useAccess();

  const { notifier } = useErrHandler();

  const { getRouteRA } = useConstructor();

  const postId = document.baseURI.split('/')[5];

  // fetch the upload to be updated
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if(!userId) return;

    getRouteRA((data) => {
      console.log(data);
      setData(data);
    }, `/get/approved/image/${postId}`, signal, ((err) => {
      console.log(err);
    }))

    return () => {
      controller.abort();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postId, userId])

  useEffect(() => {
    notifier(false, "Image file must not be more than 2MB!")

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return data && (
    <UploadStyle>
      <Helmet>
        <title>Update</title>

        <link rel="icon" href='/images/logos/20230125_074855.png' />
      </Helmet>

      <TopBar />

      <Container> {/* Container */}
        { data && 
          <Content data={data} />
        }
      </Container>
 
    </UploadStyle>
  )
}

const Content = ({ data }) => {
  const [name, setName] = useState(data.name);
  const [desc, setDesc] = useState(data.desc);
  const [pro, setPro] = useState(data.pro);
  const [downloadable, setDownloadable] = useState(data.downloadable);
  const [price, setPrice] = useState(data.price);

  const [reload, setReload] = useState(false);

  const { endpoint, imageEndpoint } = useProxyContext();
  
  const { popUp, selectedType, selectedCollection, setSelectedType, setSelectedCollection } = usePopUpContext();

  const { user } = useAuthContext();

  const { notifier } = useErrHandler();

  const navigate = useNavigate();
  
  const { userId } = useAccess();
  
  const { process } = useProcesser();

  const { profileSetup } = useProfile();

  const postId = document.baseURI.split('/')[5];

  useEffect(() => {
    setSelectedType(data.type);
    setSelectedCollection(data.collectionName);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Create post
  const createPost = async () => {

    // first submit tags
    const tagsContainer = document.querySelector('#tagsContainer');
    let arr = []
    
    for (let i = 0; i < tagsContainer.children.length; i++) {
      const element = tagsContainer.children[i];

      const tags = {
        value: element.innerText
      }

      arr.push(tags);
    }

    try {
      // check profile before running upload
      await profileSetup( async () => {
        if(name.length < 5) return notifier(true, "Complete name field");

        if(!selectedType) return notifier(true, "Select type");

        if(!selectedCollection) return notifier(true, "Select collection!");

        if(pro && !getNum(price)) return notifier(true, "Set Price for your pro Design!");
        
        process('STARTED', 'Updating...');

        try {

          const res = await axios.put(`${endpoint}/update/upload/${postId}`, {
            collection: selectedCollection, 
            name, 
            desc,
            type: selectedType, 
            pro,
            downloadable: pro ? true : downloadable,
            price: pro ? price : "", 
            tags: arr
          },{
            headers: {
              "Authorization": `Bearer ${user.token}`
            }
          });
          
          process('ENDED');
          notifier(false, res.data.message);
          navigate(`/${userId.userName.split(" ")[0]}/profile/${userId._id}/uploads`);
          
        } catch (err) {
          process('ENDED');

          console.log(err)
          const errorMessage = err.response.data.message;
          notifier(true, errorMessage);
        }
      });

    } catch (err) {
      process('ENDED');

      console.log(err);
      const errorMessage = err.response.data.message;
      notifier(true, errorMessage);
      return
    }
  }

  const setTags = async (e) => {
    e.preventDefault();
    
    let input = e.target.children[0].value;

    try {
      if(input.length <= 1) return notifier(true, 'Must be up to two characters!');
      if(input.length >= 21) return notifier(true, 'Too long!');

      const tagsContainer = document.querySelector('#tagsContainer')
      const newElement = document.createElement('span');
      
      const newTag = `
        ${input} <div></div>
      `;

      newElement.innerHTML = newTag;

      tagsContainer.appendChild(newElement);

      e.target.children[0].value = '';
      setReload(!reload);

    } catch (err) {
      console.log(err);
    }
  }
  
  useEffect(() => {
    const tagsContainer = document.querySelector('#tagsContainer');

    const removeTag = () => {

      for (let i = 0; i < tagsContainer.children.length; i++) {
        const element = tagsContainer.children[i];

        element.addEventListener('click', () => {
          element.remove();
        })
      }
    }

    if(tagsContainer.children === undefined) {
      setTimeout(() => {
        removeTag();
      }, 1000);
    } else {
      removeTag();
    }

  }, [reload])

  return (
    <>
      <div>
        <div id="upload"  >

          <div id="mainCover">
            <div className="box-shadow" style={{ pointerEvents: 'none' }}>
              <img id="mainImg" src={`${imageEndpoint}/images/${data.src}`} style={{ display: "block" }} alt="" />

              <label htmlFor="fileUpload">Select Image <IoMdCloudUpload /></label>
            </div>
          </div>

          <div id="others">
            {/* There must be main image - display preview */}
            <div>
              <img id="main" src={`${imageEndpoint}/images/${data.src}`} style={{ display: "block" }} alt="" />
              <FaPlus />
            </div>

            {/* If There's image 1 display image preview */}
            <div>
              <img src={`${imageEndpoint}/images/${data.images[1]}`} style={{ display: data.images[1] && "block" }} alt="" />
              <FaPlus />
            </div>

            {/* If There's image 2 display image preview */}
            <div>
              <img src={`${imageEndpoint}/images/${data.images[2]}`} style={{ display: data.images[2] && "block" }} alt="" />
              <FaPlus />
            </div>
          </div>
          
        </div>

        <div id="details">
          <span>
            <input 
              type="text" 
              placeholder="name" 
              autoFocus={true}
              value={name}
              onChange={(e) => setName(e.target.value)}
              maxLength={100}
            ></input>

            <div>
              <small>{name.length}/100</small>
              <small>Minimum 5 characters</small>
            </div>
          </span>

          <span>
            <textarea 
              rows="3" 
              placeholder="About design" 
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
              maxLength={3000}
            ></textarea>

            <div>
              <small>{desc.length}/3000</small>
              {/* <small>Minimum 50 characters</small> */}
            </div>
          </span>

          <div id="tags" style={{ position: "relative" }}>
            <p>Tags</p>
            <div>
              <div>
                <div id="tagsContainer">
                  {data.tags.map((tag, i) => (
                    <span key={i}>{tag.value}<div></div> </span>
                  ))}  
                </div>
                <form onSubmit={setTags}>
                  <input style={{ width: "100%" }} id="tagInput" type="text" placeholder="Write"></input>
                </form>
              </div>
            </div>
          </div>

          <div id="pro">
            <input 
              style={{height: "20px", width: "20px"}}
              type="checkbox"
              checked={pro}
              onChange={(e) =>  setPro(e.target.checked)}
            />
            <p>Pro</p>
          </div>

          {pro && userId && (
            <input 
              type="text" 
              placeholder={userId.currency} 
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            ></input>
          )}

          {!pro && 
          <div id="pro">
            <input 
              style={{height: "20px", width: "20px"}}
              type="checkbox"
              checked={downloadable}
              onChange={(e) =>  setDownloadable(e.target.checked)}
            />
            <p>Downloadable</p>
          </div>}


          <div id="collection" onClick={() => popUp("types", null) }>
            <div>{selectedType ? selectedType : "Type"}</div>
            <FaAngleDown />
          </div>

          <div id="collection" onClick={() => popUp("collections", null) }>
            <div>{selectedCollection ? selectedCollection : "Collection"}</div>
            <FaAngleDown />
          </div>

          <Button run={createPost} >Update <IoMdCloudUpload /></Button>
        </div>
      </div>

      <div></div>

      <div id="uploadMore">
        <span><FaPlus /></span>
      </div>
    </>
  )
}

export default Update;