import { useState, useEffect } from "react";
import { CreateStyle } from "./styles/S_Create";
import { FaCamera, FaPlus } from 'react-icons/fa'
import { HiSwitchHorizontal } from "react-icons/hi";
import Button from "../../button/Button";
import axios from "axios";
import useAuthContext from "../../../hooks/auth/useAuthContext";
import useProxyContext from "../../../hooks/global/useProxyContext";
import useErrHandler from '../../../hooks/err/useErrHandler';
import { useTheme } from 'styled-components';
import useAccess from '../../../hooks/access/useAccess';
import usePopUpContext from "../../../hooks/global/usePopUpContext";
import useProcesser from "../../../hooks/processer/useProcesser";

const Create = ({ type }) => {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [upload, setUpload] = useState(false);
  const [proceed, setProceed] = useState(false);

  const [header, setHeader] = useState('');
  const [about, setAbout] = useState('');
  const [global, setGlobal] = useState(true);
  const [typeOf, setTypeOf] = useState('');

  const { user } = useAuthContext();

  const { endpoint, imageEndpoint } = useProxyContext();

  const { notifier, message, error } = useErrHandler();

  const theme = useTheme();

  const { userId } = useAccess();

  const { setShow } = usePopUpContext();

  const { process } = useProcesser();

  // Create collection
  const createCollection = async (e) => {
    e.preventDefault();

    if(!userId) return notifier(true, "Sign In to continue!")

    if(!header || !about || !typeOf) return notifier(true, "All field is required!");

    // LETER CREATE A ROUTE THAT CHECKS IMAGE JUST CHECK

    process('STARTED', 'Creating your collection ...');
    try {
      const res = await axios.post(`${endpoint}/create/collection`, {
        header, 
        about,
        icon: upload ? fileName : userId.profilePic,
        profileAsIcon: upload ? false : true,
        type: typeOf,
        global,
      },{
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      })

      setProceed(true);
      notifier(false, res.data.message);
      
    } catch (err) {
      process('ENDED');

      const errMessage = err.response.data.message;
      notifier(true, errMessage);
    }
  }

  // proceed to collection creation 
  useEffect(() => {
    
  if(proceed) {
    (async function(){
      if(file) {

        try {
          const data = new FormData();
          data.append("name", fileName)
          data.append("file", file);
  
          await axios.post(`${endpoint}/upload`, data, {
            headers: {
              "Authorization": `Bearer ${user.token}`
            }
          });
          
          process('ENDED');
          setShow(false);
  
        } catch (err) {
          process('ENDED');
  
          const errMessage = err.response.data.message;
          notifier(true, errMessage);
  
          return;
        }
      } else {
        process('ENDED');
        setShow(false)
      }
    }())
  }
    
  }, [proceed, endpoint, file, notifier, process, user, setShow, fileName])

  return (
    <CreateStyle global={global}>
      <div>
        {type === "collections" ? (
          <>
          
            <div id="collIcon">
      {/* */} <div>
                <div style={{
                  background: upload ? theme.colors.green : `${theme.colors.white}AA`,
                }}> {/* add a tag that shows which one is selected here */}
                  <img src="" alt="" style={{ opacity: 0 }} />
                  <label htmlFor="icon"></label>
                  <input 
                  onChange={(e) => {
                    if(e.target.files.length > 0) {
                      const file = e.target.files[0];
                      const fileSizeInMB = file.size / (1024 * 1024);
                      const imgPreview = e.target.parentElement.children[0]
                      imgPreview.src = URL.createObjectURL(file);
                      imgPreview.style.opacity = '1';

                      if(fileSizeInMB > 2) {
                        notifier(true, "This selected file is too large. Please select a file under 2MB.")
                        imgPreview.style.opacity = 1; // display image
                        imgPreview.src = URL.createObjectURL(file);
                        imgPreview.style.border = "5px solid red";
                        return;
                      }
                      
                      imgPreview.style.border = "none";
                      imgPreview.src = URL.createObjectURL(file);
                      process('ENDED'); 
                      setUpload(true);
                      setFile(file);
                      setFileName(`profile-${Date.now()}-${file.name}`);
                    }
                  }} 
                    id="icon" 
                    type="file"
                    accept="image/*"
                  />
                  <FaCamera/>
                </div>

                <p>Upload</p>
      {/* */} </div>

              {userId && userId.profilePic &&
              <div>
                <div onClick={() => {
                  process('ENDED');
                  setUpload(false)
                  setFile(null);
                }} style={{
                  background: upload ? `${theme.colors.white}AA` : theme.colors.green,
                }}>
                  <img src={ imageEndpoint+ '/images/' + userId.profilePic } alt=""/>
                </div>

                <p>Use my Profile</p>
              </div>}
            </div>

            <div id="public">
              <span onClick={() => setGlobal(!global)}>
                {global ? "Public" : "Private"} <HiSwitchHorizontal style={{
                  transform: global ? `rotate(360deg)` : `rotate(0deg)`,
                  transition: "all ease-in-out 0.4s",
                }} />
              </span>
            </div>

          <form onSubmit={createCollection}>
            <div>
              <label htmlFor="header">Collection Name</label>
              <input 
                type="text" 
                id='header' 
                placeholder="Collection name"
                value={header}
                onChange={(e) => setHeader(e.target.value)}
              />
            </div>
            
            <div>
              <label htmlFor="about">About</label>
              <input 
                type="text" 
                id="about" 
                placeholder="What is this collection all about ?"
                value={about}
                onChange={(e) => setAbout(e.target.value)}
              />
            </div>
            
            <div>
              <label htmlFor="type">Type</label>
              <input 
                type="text" 
                id="type" 
                placeholder="eg '3D Models', 'Graphics Design', 'Logos'"
                value={typeOf}
                onChange={(e) => setTypeOf(e.target.value)}
              />
            </div>

            <Button>
              <FaPlus /> Create
            </Button>

            { error && message && 
            <span style={{
              background: 'red',
              color: '#FFFFFF',
              padding: '10px',
              borderRadius: '10px',
              textAlign: 'center'
            }}> { message } </span>
            }
          </form>

          </>
        ) : (
          <form onSubmit={e => e.preventDefault()}>
            <div>
              <label htmlFor="type">Type</label>
              <input disabled={true} type="text" id="type" placeholder="eg '3D Models', 'Graphics Design', 'Logos'"/>
            </div>

            <Button>
              <FaPlus /> Create
            </Button>
          </form>
        )}

      </div>
    </CreateStyle>
  )
}

export default Create;