import { useContext } from "react";
import { AccessContext } from "../../contexts/access/AccessContext";

const useAccess = () => {
  const context = useContext(AccessContext);

  if(!context) throw Error('You can only use this context inside its wrapped contents');

  return context;
}
 
export default useAccess;