import { CollectionsStyle } from "./S_Collections";
import { HiSwitchHorizontal } from 'react-icons/hi';
import useAdminAuthContext from '../../../../hooks/admin/auth/useAdminAuthContext';
import useProxyContext from '../../../../hooks/global/useProxyContext';
import axios from 'axios';
import useRefreshContext from '../../../../hooks/global/useRefreshContext';

const Collections = ({ collections }) => {

  const { admin } = useAdminAuthContext();

  const { endpoint } = useProxyContext();

  const { reRender } = useRefreshContext();

  // Approve Collection
  const approveColl = async (collectionId, approved) => {
    try {
  
      await axios.put(`${endpoint}/toggle/approve`, {
        collectionId: collectionId,
        approved: approved
      }, {
        headers: {
          'Authorization': `Bearer ${admin.token}`
        }
      });
      
      reRender();
        
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <CollectionsStyle>
      <div id="header">
        <h2>Collections</h2>
      </div>

      <div id="collections">
        
      {collections && collections.map((coll, i) => (

      <div key={i}>
        <div>
          <h3>{coll.header}</h3>
          <div style={{
            background: coll.approved ? '#00FF90' : '#FF9000'
          }} onClick={() => approveColl(coll._id, coll.approved) }> 
            {coll.approved ? "Approved" : "Pending"} 
            <HiSwitchHorizontal style={{
              transform: coll.approved ? 'rotate(360deg)' : 'rotate(0deg)',
              transition: 'all ease-in-out 0.4s'
            }} /> 
          </div>
        </div>

        <p>{coll.about}</p>
      </div>

      ))}

      </div>
    </CollectionsStyle>
  )
}

export default Collections;