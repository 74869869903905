import styled from 'styled-components';

const SearchStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  padding: 80px;
  background-image: linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.3)), url('/images/background_2.jpg');
  background-size: cover;
  background-position: center;
  text-align: center;
  
  @media(max-width: ${({ theme }) => theme.mobile.is580}) {
    padding: 80px 20px;
  }

  & h1 {
    color: #eeeeee;

    @media(max-width: ${({ theme }) => theme.mobile.is580}) {
      font-size: 25px;
      font-weight: 900;
    }
  }

  & p {
    color: #ffffffDA;
  }

  & > form {
    width: 100%;
    display: flex;
    justify-content: center;
    
    & > input {
      box-sizing: border-box;
      width: 500px;
      padding: 20px;
      border-radius: 10px;
      background: #FFFFFF;
      font-size: 19px;
      border: none;
      outline: none;
      box-shadow: -5px 5px 1px rgba(0,0,0,0.3);
      
      @media(max-width: ${({ theme }) => theme.mobile.is580}) {
        width: 100%;
      }
    }
  }
`;

const Search = () => {
  return (
    <SearchStyle>
      <h1>Model Grapix Blog</h1>

      <p>Get the Inside Scoop on the Latest Trends</p>

      <form>
        <input type="search" id="search" placeholder="Search blog .." />
      </form>
    </SearchStyle>
  )
}

export default Search;