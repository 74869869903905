import { Container } from '../global/Container';
import { Logo, ProfileOptionsStyle, TopBarStl } from './styles/S_Topbar';
import useThemeContext from '../../hooks/useThemeContext';
import { BsFillChatTextFill, BsFillMoonStarsFill } from 'react-icons/bs';
import { HiLightBulb, HiMenuAlt4 } from 'react-icons/hi';
import { FaAngleDoubleDown } from'react-icons/fa';
import { AiFillSetting, AiOutlinePlus } from'react-icons/ai';
import { IoDocumentText } from'react-icons/io5';
import { FiLogOut, FiPenTool } from'react-icons/fi';
import { MdDarkMode, MdWork } from'react-icons/md';
import { useEffect } from 'react';
import Button from '../button/Button';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Alerter from '../alerter/Alerter';
import useErrHandler from '../../hooks/err/useErrHandler';
import useAuthContext from '../../hooks/auth/useAuthContext';
import useSignIn from '../../hooks/auth/useSignIn';
import { IoMdNotifications } from 'react-icons/io';
import useAccess from '../../hooks/access/useAccess';
import useRefreshContext from '../../hooks/global/useRefreshContext';
import Notifications from '../notifications.js/Notifications';
import useProfile from '../../hooks/profile/useProfile';
import usePopUpContext from '../../hooks/global/usePopUpContext';
import { TbListSearch } from 'react-icons/tb';
import useConstructor from '../../hooks/constructor/useConstructor';

const TopBar = () => {
  const { darkTheme, changeTheme } = useThemeContext();

  const { user } = useAuthContext();

  const { userId } = useAccess();

  const navigate = useNavigate();

  const { error, message } = useErrHandler();

  const { getProfilePicture } = useProfile()

  const { popUp } = usePopUpContext();

  useEffect(() => {
    const topbar = document.getElementById('topbar');
    let prevScroll = window.pageYOffset;
    let timeout = null;
    
    window.addEventListener('scroll', () => {
      const currentScroll = window.pageYOffset;

      if(currentScroll > prevScroll) {
        topbar.classList.add('topbar--hidden')
      } else {
        topbar.classList.remove('topbar--hidden')
      }

      prevScroll = currentScroll;

      // clear timeout if it has been set
      if(timeout != null) {
        clearTimeout(timeout);
      }

      // set a new timeout
      timeout = setTimeout(() => {
        prevScroll = currentScroll
      }, 200);
    })
  }, [])

  // animate
  useEffect(() => {
    const anm = document.querySelectorAll('.anm')
    
    for (let i = 0; i < anm.length; i++) {
      anm[i].classList.add('fade-in');
    }

  }, [])

  useEffect(() => {
    const userProfilePicture = document.querySelectorAll('#userProfilePicture');
    getProfilePicture(userProfilePicture, userId);
  }, [userId, getProfilePicture])

  return (
    <>
      <TopBarStl darkTheme={darkTheme} id='topbar'>
        <Container>
          {/* Left - logo */}
          <Logo onClick={() => navigate(`/`)}>
            <img src="/images/logos/20230125_072534.png" alt=""/>
          </Logo>

          {/* Middle - Topbar links */}
          <div id="topbarMiddle">
            <span onClick={() => popUp('create') }><AiOutlinePlus /><span>Create</span></span>
            <span onClick={() => popUp('tools')}><FiPenTool /><span>Tools</span></span>
          </div>

          {/* Right - profile menu and login button */}
          <div id="topbarRight">

            {/* Notification */}
            {user &&
              <div id="notification">
                <div>
                  <div></div>
                  <IoMdNotifications />
                </div>

                <div>
                  <Notifications />
                </div>
              </div>
            }

            {/* Theme toggle */}
            <div id="themeToggle">
              <span onClick={() => changeTheme()}>
                { !darkTheme ? <BsFillMoonStarsFill /> : <HiLightBulb style={{color: "black", fontSize: "30px"}} /> }
              </span>
            </div>

            {/* Sign in and profile menu button */}
            {!user ? (
              <div id="notSignedInMenu">
                <Link className="link" to="/sign-in">
                  <Button blur="7">Sign In</Button>
                </Link>

                <div>
                  <span><HiMenuAlt4 /></span>
                  <ProfileOptions />
                </div>
              </div>
            ):(
              <div id="profile">
                <p id="userName">{userId && `${userId.userName.slice(0, 10)}..`}</p>

                <div>
                  <div id="profileMenu">
                    <div>
                      <div>
                        <img id="userProfilePicture" src='/images/profile.jpg' alt=""/>
                      </div>
                      <FaAngleDoubleDown />
                    </div>

                    <ProfileOptions />

                  </div>

                </div>
              </div>
            )}

          </div>

        </Container>

        {/* Alert Notifier */}
        <Alerter error={error} message={message} />
      </TopBarStl>

    </>
  )
}

const ProfileOptions = () => {

  const { logout } = useSignIn();

  const { reRender } = useRefreshContext();

  const { userId } = useAccess();

  const navigate = useNavigate();

  const { popUp } = usePopUpContext();

  const { changeTheme } = useThemeContext();

  const { user } = useAuthContext();

  const { setExpandChat } = useConstructor();

  return (
    <ProfileOptionsStyle id="profileOptions">
      <div id="account" className="anm" onClick={() => {
        reRender();
        userId && navigate(`/${userId.userName.split(' ')[0]}/profile/${userId._id}/uploads`)
      }}>
      <img id="userProfilePicture" src='/images/profile.jpg' alt=""/>

        <div>
          <h3>{ user && userId ? userId.userName : "Sign In" }</h3>
          <small>{userId && userId.email}</small>
        </div>
      </div>

      <div id="options" className="anm">
        <div onClick={() => popUp('create')}>
          <p>Create</p>
          <AiOutlinePlus />
        </div>

        <div onClick={() => popUp('tools')}>
          <p>Tools</p>
          <FiPenTool />
        </div>

        <center style={{ height: '4px' }}></center>

        <div onClick={() => setExpandChat(true)}>
          <p>Chats</p>
          <BsFillChatTextFill />
        </div>

        <center style={{ height: '4px' }}></center>

        <div>
          <p>Jobs</p>
          <MdWork />
        </div>

        <div onClick={() => navigate('/documentation')}>
          <p>Documentation</p>
          <IoDocumentText />
        </div>

        <div onClick={() => navigate('/blog')}>
          <p>Blog</p>
          <TbListSearch />
        </div>

        <center style={{ height: '4px' }}></center>

        <div onClick={() => changeTheme()}>
          <p>Toggle theme</p>
          <MdDarkMode />
        </div>

        { user &&
        <div onClick={() => navigate("/settings")}>
          <p>Settings</p>
          <AiFillSetting />
        </div>
        }

        { user ?
        <div onClick={logout}>
          <p>Logout</p>
          <FiLogOut />
        </div>
        : 
        <div onClick={() => navigate('/sign-in')}>
          <p>Sign In</p>
          <FiLogOut style={{ transform: "rotate(180deg)"}} />
        </div>
        }
      </div>
    </ProfileOptionsStyle>
  )
}

export default TopBar;