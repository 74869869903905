import styled from 'styled-components';

export const SettingsStyle = styled.div`

  // The container
  & > div:nth-child(2) {
    margin-bottom: 100px;
    

    // Users information header
    & > div[id="profile"] {
      display: flex;
      gap: 5px;
      flex-direction: column;
      align-items: center;
      padding: 50px 0;

      // The User profile
      & > img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    & > div[id="btn"] {
      display: flex;
      justify-content: center;
    }

    // Settings Options Section
    & > div[id="options"] {
      max-width: 600px;
      margin: auto;

      // Options header
      & > h4 {
        padding: 30px 0 10px 0;
        color: ${({ theme }) => theme.colors.black};
      }

      // Options Container
      & > div {
        display: flex;
        flex-direction: column;
        gap: 20px;
        
        // Option
        & > div {
          padding: 20px;
          background: ${({ theme }) => theme.colors.main2}10;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          border-radius: 5px;

          & > div {
            display: flex;
            align-items: center;
            gap: 15px;
          }

          &[id="deleteAcc"] {
            background: #FF00AA50;
          }
        }
      }

      // All Svg Icons
      & svg {
        font-size: 20px;
        color: ${({ theme }) => theme.colors.black};

        // Only svg with the id of "toggle"
        &[id="toggle"] {
          font-size: 35px;
          cursor: pointer;
        }
      }
    }
  }
`;