import styled, { keyframes } from 'styled-components';

// Define the rotating animation using keyframes
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const ProcessingStyle = styled.div`
  padding: 50px 0;
  display: flex;
  justify-content: center;

  /* Apply the rotating animation to the SVG */
  & > svg {
    font-size: 80px;
    opacity: 0.6;
    animation: ${rotate} 1s linear infinite; /* Adjust the duration and timing function as needed */
  }
`;
