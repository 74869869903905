import { useContext } from "react";
import { PopUpContext } from "../../contexts/global/PopUpContext";

const usePopUpContext = () => {
  const context = useContext(PopUpContext);

  if(!context) throw Error('You can only use this context inside its wrapped contents');

  return context;
}
 
export default usePopUpContext;