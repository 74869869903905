import { useContext } from "react";
import { DataContext } from "../../contexts/global/DataContext";

const useDataContext = () => {
  const context = useContext(DataContext);

  if(!context) throw Error('You can only use this context inside its wrapped contents');

  return context;
}
 
export default useDataContext;