import Button from "../../../button/Button";
import { useState } from "react";
import { useEffect } from "react";
import useAccess from "../../../../hooks/access/useAccess";
import { FaMinus, FaPlus } from "react-icons/fa";
import { HiOutlineArrowLeft, HiOutlineArrowRight } from "react-icons/hi";
import useProcesser from "../../../../hooks/processer/useProcesser";
import useErrHandler from "../../../../hooks/err/useErrHandler";
import { useNavigate } from "react-router-dom";
import usePopUpContext from "../../../../hooks/global/usePopUpContext";
import { GrapixPopUpStyle, GrapixPurchaseConfirm } from "./styles/S_Grapix";
import useConstructor from "../../../../hooks/constructor/useConstructor";

const Grapix = () => {
  const [defaultCurrency, setDefaultCurrency] = useState('');
  const [grapix, setGrapix] = useState(10);
  const [confirm, setConfirm] = useState(false);
  const [price, setPrice] = useState('');
  const [walletBalance, setWalletBalance] = useState('');
  const [confirmButtonText, setConfirmButtonText] = useState('Proceed');
  
  const { userId } = useAccess();

  const { process, processing }  = useProcesser();

  const { notifier, message, error, removeError } = useErrHandler();

  const { closePopup } = usePopUpContext();

  const { postToRouteRA } = useConstructor();

  const navigate = useNavigate();

  const grapixInNGN = 99.00;
  const grapixInUSD = 0.13;

  const addCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  useEffect(() => {
    if(!userId) return;
    setDefaultCurrency(userId.currency);

    if(!defaultCurrency) return;
    const balance = userId.wallet[defaultCurrency].balance.toFixed(2);
    setWalletBalance(addCommas(balance));

  }, [userId, defaultCurrency])

  // one paint is equal to 99 in naira and 0.13 in usd
  // create a function that increment grapix value and update grapix price base on the active curency (default currency)
  // two function one that increments and one that decrements

  const incrementGrapix = () => {
    setGrapix(prevGrapix => prevGrapix + 1);
  };
  
  const decrementGrapix = () => {
    if (grapix > 1) {
      setGrapix(prevGrapix => prevGrapix - 1);
    }
  };
  
  const getPrice = () => {
    if(!defaultCurrency) return;
    
    let price;
    let currencySymbol;
    
    if (defaultCurrency === 'NGN') {
      price = grapix * grapixInNGN;
      currencySymbol = '₦';
    } else if (defaultCurrency === 'USD') {
      price = grapix * grapixInUSD;
      currencySymbol = '$';
    }

    const formattedPrice = price.toFixed(2);

    return `${currencySymbol}${addCommas(formattedPrice)}`;
  };

  const callConfirm = () => {
    setPrice(getPrice());
    setConfirm(true);
    setConfirmButtonText('Proceed');
    removeError();
  }

  const handlePayment = async () => {
    if(processing) return;

    process('STARTED', 'Processing ...');

    const amountInNumber = parseFloat(price.replace(/[$,₦]/g, ''));

    try {
      postToRouteRA((data, response) => {

        process('ENDED');
        
        if(response.status === 402) {
          notifier(true, data.message);
        } else {
          notifier(false, data.message);
          setConfirmButtonText('Pay again')
        }
      }, {
        amount: amountInNumber,
        numberOfGrapix: grapix,
      }, `/wallet/payment/grapix`)

    } catch (err) {
      console.log('BIG ERROR')
      process('ENDED');
      
      console.log(err);
    }
  }

  const handleNavigate = (url) => {
    navigate(url);
    closePopup();
  }

  return !confirm ? (
    <GrapixPopUpStyle>
      <header>
        <h1>Unlock AI Magic with More Grapix</h1>
      </header>

      <p>Grapix is the currency you need to access our powerful API services. With grapix, you can make more API requests and access our AI tools </p>

      <div id="selector">
        <span onClick={decrementGrapix} id="icon"><FaMinus /></span>
        <span id="option"> <span><img src="/images/icons/grapix.png" alt="personal" /> {grapix} </span> </span>
        <span onClick={incrementGrapix} id="icon"><FaPlus /></span>
      </div>
      
      <Button run={() => callConfirm()} id="payPersonal">Pay {getPrice()} </Button>

    </GrapixPopUpStyle>
  ) : (
    <GrapixPurchaseConfirm error={error} message={message}>
      <span onClick={() => setConfirm(false)}>
        <HiOutlineArrowLeft />
      </span>

      <div onClick={() => handleNavigate('/wallet')}>
        <h4>Wallet balance</h4>
        <h3>{defaultCurrency} {walletBalance}</h3>
      </div>

      <p>
        <strong>{price} </strong> will be deducted from your wallet for <strong>{grapix} grapix</strong>. Click <strong>Proceed</strong> to continue."
      </p>

      <Button run={() => handlePayment()}>{confirmButtonText} <HiOutlineArrowRight /></Button>
      
      <small>{ message }</small>
    </GrapixPurchaseConfirm>
  );
}

export default Grapix;