import { CommentBox, FeaturedImage, PostBody, PostFooter, SingleStl } from "./S_Single";
import { FiUser } from 'react-icons/fi';
import { RiCalendarTodoFill } from 'react-icons/ri';
import { MdOutlineCategory } from 'react-icons/md';
import { useEffect, useState } from "react";
import axios from 'axios';
import { useLocation } from "react-router-dom";
import useAuthContext, { } from '../../../../hooks/auth/useAuthContext'; // resolved
import useProxyContext from "../../../../hooks/global/useProxyContext" // resolved
import { Container } from "../../../../components/global/Container"; // resolved
import { Helmet } from 'react-helmet-async'
import useDate from "../../../../hooks/global/useDate";
import useRefreshContext from '../../../../hooks/global/useRefreshContext'
import useProcesser from '../../../../hooks/processer/useProcesser';
import useErrHandler from '../../../../hooks/err/useErrHandler';
import { TagsStyle } from "../posts/S_Posts";

import hljs from 'highlight.js';
import 'highlight.js/styles/atom-one-dark.css';

import DOMPurify from "dompurify";

const renderCode = (match, code) => {
  const highlightedCode = hljs.highlightAuto(code).value;
  return `<div id="code"><span id="copy">Copy</span><code id="code-preview" class="javascript">${highlightedCode}</code></div>`;
};

const Single = () => {
  const [post, setPost] = useState(null);

  const [comment, setComment] = useState('');
  
  const location = useLocation();
  
  const { endpoint, imageEndpoint } = useProxyContext();

  const { user } = useAuthContext();

  const { reRender, refresh } = useRefreshContext();

  const { process, processing } = useProcesser();

  const { notifier } = useErrHandler();

  const postId = location.pathname.split('/')[3];

  // add post to users recent 
  useEffect(() => {
    (async function() {

      if(!post) return;

      if(!user) return;

      try {
        await axios.put(`${endpoint}/add/to/history`, {
          postId: post._id,
          postTitle: post.postTitle, 
          postFeaturedImageName: post.postFeaturedImg,
        },{ 
        headers: {
          'Authorization': `Bearer ${user.token}`
        }})

      } catch (err) { }
    }())
  }, [post, user, endpoint])

  // add to seen immediatly
  useEffect(() => {
    if(!post) return;

    const timer = setTimeout( async () => {
      try {
        await axios.put(`${endpoint}/read/${post._id}`);
      } catch (err) { console.log(err) }
    }, 10000)

    async function postSeen(){
      try {
        await axios.put(`${endpoint}/seen/${post._id}`);
      } catch (err) { console.log(err) }
    }

    postSeen();

    return () => {
      clearTimeout(timer)
    }
  }, [endpoint, post]);

  // scroll to top 
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [endpoint, post]);

  // fetch post
  useEffect(() => {
    (async function getPostData() {
      try {
        const res = await axios.get(`${endpoint}/fetch/post/${postId}`);
        
        if(!res.data.approved) return notifier(true, "Post is private! try again soon")
        setPost(res.data);

      } catch (err) {
        console.log(err);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endpoint, postId, refresh])

  // post comment
  const postComment = async (e) => {
    e.preventDefault();

    if(comment.trim().length < 1) return;

    if(processing) return;

    process('STARTED', 'Posting...')

    try {
      const res = await axios.put(`${endpoint}/comment-on-blog-post`, { 
        comment, 
        postId: post._id 
      }, {
        headers: { 'Authorization': `Bearer ${user.token}` }
      })

      process('ENDED');
      notifier(false, res.data.message);
      setComment("");
      reRender();

    } catch (err) {
      process('ENDED');
      console.log(err);
    }
  }
  
  return (
    <SingleStl>
      <Helmet>
        <title>{ post && `${post.postTitle}` }</title>

        <meta name="description" content="post description here" />

        <meta property="og:title" content={ post && `${post.postTitle}` } />
        <meta property="og:description" content="post description here" />
        <meta property="og:image" content={ post && (post.postFeaturedImg ? `${imageEndpoint}/images/${post.postFeaturedImg}` : '/images/default.jpg') } />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={ post && `${`htts://modelgrapix.com/blog/${post._id}`}` } />

        <link rel="icon" href='/images/logos/20230125_074855.png' />
      </Helmet>

      { post && <>
      <Container>
        <h1>{ post.postTitle ? post.postTitle : '- - -'}</h1>
      </Container>

      <Container>
        <PostFooterCls date={post.createdAt} post={post} />
      </Container>

      <FeaturedImage>
        { post.postFeaturedImg && <img src={`${imageEndpoint}/images/${post.postFeaturedImg}`} alt=" " />}
      </FeaturedImage>

      <Container id="postBody">

        <PostBody>

          {/* <AdComponent /> */}

          { post.postBody && post.postBody.map((box, i) => (
            <main id="content" key={i}>
              { box.htmlTag === 'SPAN' && <img src={`${imageEndpoint}/images/${box.text}`} alt=" "/> }
              { box.htmlTag === 'H1' && <h1 >{box.text}</h1>}
              { box.htmlTag === 'P' && <Paragraph content={box.text} /> }
              { box.htmlTag === 'PRE' && <Pre content={box.text} /> }
            </main>
          ))}

          {/* <AdComponent /> */}

          <PostFooterCls date={post.createdAt} post={post} />
          
        </PostBody>

        <CommentBox>
          <div id="comments">
            <h2>Comments</h2>
            {post.comments.map((comment, i) => (
              <Comment key={i} comment={comment} />
            ))}
          </div>

          <form onSubmit={postComment}>
            <h2>Leave a comment</h2>
            <input 
              type="text" 
              placeholder="Write your comment.." 
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            <button>Post Comment</button>
          </form>
        </CommentBox>

        <div>
          <h2 style={{ marginBottom: '20px' }}>Tags</h2>
          <Tags tags={post.tags} />
        </div>
        
      </Container>
      </>}
    </SingleStl>
  )
}

const Pre = ({ content }) => {

  function copyCode(codeElement, copyElement) {
    const range = document.createRange();
    range.selectNodeContents(codeElement);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand('copy');
    selection.removeAllRanges();
    copyElement.innerHTML = 'Copied!'
    setTimeout(() => copyElement.innerHTML = 'Copy', 3000)
  }
  
  useEffect(() => {
    const copyElement = document.querySelectorAll('#copy');
    const codeElement = document.querySelectorAll('#code-preview');

    for (let i = 0; i < copyElement.length; i++) {
      const copyEl = copyElement[i];
      const codeEl = codeElement[i];
      
      copyEl.addEventListener('click', () => {
        copyCode(codeEl, copyEl)
      })
    }
  }, [])

  // Replace code blocks with <pre><code> tags
  const contentWithCode = content.replace(
    /```([\s\S]*?)```/gm,
    (match, code) => renderCode(match, code)
  );

  // Replace URLs with <a> tags
  const contentWithLinks = contentWithCode.replace(
    /(https?:\/\/[^\s]+)/g,
    '<a id="linkTag" href="$1">$1</a>'
  );

  // Replace *text* with <strong> tags
  const contentWithStrong = contentWithLinks.replace(
    /\*\*(.+?)\*\*/g,
    '<strong>$1</strong>'
  );

  // Replace _text_ with <em> tags
  const contentWithEm = contentWithStrong.replace(
    /_(.+?)_/g,
    '<em>$1</em>'
  );

  // Encode less-than (<) and greater-than (>) characters in code blocks
  const contentWithEncodedCode = contentWithEm.replace(
    /<pre><code class="javascript">([\s\S]*?)<\/code><\/pre>/gm,
    match => match.replace(/</g, '&lt;').replace(/>/g, '&gt;')
  );

  // Wrap content in a <p> tag
  const contentWithP = `<p>${contentWithEncodedCode}</p>`;

  // Purify
  const sanitizedHTML = DOMPurify.sanitize(contentWithP);
  

  return <pre dangerouslySetInnerHTML={{ __html: sanitizedHTML }} ></pre>
  
}

const Paragraph = ({ content }) => {

  // Replace code blocks with <pre><code> tags
  const contentWithCode = content.replace(
    /```(.+?)```/gs,
    '<code>$1</code>'
  );

  // Replace URLs with <a> tags
  const contentWithLinks = contentWithCode.replace(
    /(https?:\/\/[^\s]+)/g,
    '<a id="linkTag" href="$1">$1</a>'
  );

  // Replace *text* with <strong> tags
  const contentWithStrong = contentWithLinks.replace(
    /\*\*(.+?)\*\*/g,
    '<strong>$1</strong>'
  );

  // Replace _text_ with <em> tags
  const contentWithEm = contentWithStrong.replace(
    /_(.+?)_/g,
    '<em>$1</em>'
  );

  // Wrap content in a <p> tag
  const contentWithP = `<p>${contentWithEm}</p>`;

  return <article dangerouslySetInnerHTML={{ __html: contentWithP }} ></article>
  
}

const PostFooterCls = ({ date, post }) => {
  const { getDate } = useDate();

  return (
    <PostFooter>
      <small><FiUser /> Goodluck Chiemeria </small>
      <small><RiCalendarTodoFill /> { getDate(post.createdAt) } </small>
      <small><MdOutlineCategory /> { post.tags.length > 0 ? post.tags.slice(0,1) : "Learn" } </small>
    </PostFooter>
  )
}

const Comment = ({ comment }) => {
  const { getDate } = useDate();

  const [commenter, setCommenter] = useState(null);

  const { endpoint, imageEndpoint } = useProxyContext();
  
  // fetch commenter
  useEffect(() => {
    (async function(){ // later create a hook that fetches user info whenever needed
      try {
        const res = await axios.get(`${endpoint}/user/profile/${comment.commenterId}`)
        setCommenter(res.data);

      } catch (err) { console.log(err); }
    }())
  }, [endpoint, comment.commenterId])

  return commenter && (
    <div>
      <div>
        <main>
          <img src={commenter.profilePic ? `${imageEndpoint}/images/${commenter.profilePic}` : '/images/background.jpg' } alt="" />
          <h2>{commenter.userName}</h2> <span>{ getDate(comment._date) }</span>
        </main>

        {/* <MdDelete /> */}
      </div>

      <article>
        {comment.comment}
      </article>
    </div>
  )
}

export const Tags = ({ tags }) => {

  if(!tags) return null

  return (
    <TagsStyle id="tags">
      {tags.map((tag, i) => (
        <span key={i} id="tag">{tag}</span>
      ))}
    </TagsStyle>
  )
}

export default Single;
