import styled from 'styled-components';

export const SingleStl = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 50px 0;

  & > div {
    & > h1 {
      text-align: center;
      font-size: 50px;
      font-weight: 800;

      background: linear-gradient(to right, #f32170, #ff6b08, #cf23cf, #eedd44);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
    
      @media (max-width: 466px) {
        font-size: 30px; 
      }
    }
  }

  & div[id="postBody"] {
    width: 100%;
  }
`;

export const FeaturedImage = styled.div`
  & > img {
    height: 300px;
    object-fit: cover;

    @media (max-width: 466px) {
      height: 200px;
    }
  }

`;

export const PostBody = styled.div`
  max-width: 800px;
  padding-bottom: 50px;
  margin: auto;
  display: flex;
  flex-direction: column;
  grid-gap: 50px;

  & > main[id="content"] {
    & pre {
      white-space: pre-wrap;
      font-family: 'Poppins', sans-serif;
      font-size: 18px;
      line-height: 28px;
      color: ${({ theme }) => theme.colors.black};
      text-align: justify;
    }

    & h1, h2, h3 {
      text-align: left;
      line-height: 38px;
    }

    & div[id="code"] {
      position: relative;
      overflow-x: scroll;
      border-radius: 10px;
      background: ${({ theme }) => theme.colors.black};
      max-width: 100%;
  
      &::-webkit-scrollbar { display: none }
  
      & > span[id="copy"] {
        position: sticky;
        display: block;
        width: 100%;
        left: 0;
        text-align: right;
        font-size: 12px;
        margin-top: 0;
        padding: 0px 10px;
        background: #444444;
        color: #FFFFFF;
        cursor: pointer;
      }
      
      & code {
        display: block;
        width: 500%;
        border-radius: 10px;
        background: ${({ theme }) => theme.colors.black};
        color: ${({ theme }) => theme.colors.white};
        // margin-top: -35px;
        padding: 10px;
      }
    }

    & ol > li { margin-left: 15px; }

    & ul > li { margin-left: 20px; }

    & h1 {
      font-size: 30px;
      font-weight: 700;
    }
  }

  & h1, h2, p, div, pre, article, a {
    word-wrap: break-word;
  }

  & a[id="linkTag"] {
    border-radius: 30px;
    padding: 5px;
    margin-bottom: -10px;
    background: ${({ theme }) => theme.colors.black}05;

    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
  }

  & img { border-radius: 20px; }

  & article {
    font-size: 18px;
    line-height: 28px;
    color: ${({ theme }) => theme.colors.black};
    text-align: justify;
  }
`;

export const PostFooter = styled.div`
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  gap: 30px;
  
  font-size: 20px;

  @media (max-width: 466px) {
    gap: 20px;
  }
  `;
  
  export const CommentBox = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.main};
 
  & > div[id="comments"] {

    & > h2 {
      margin: 50px 0 20px 0;
    }

    & > div {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      
      background: ${({ theme }) => theme.colors.lightgrey}20;
      box-shadow: 0px 1px 2px rgba(0,0,0,0.3);
      padding: 12px;
      border-radius: 5px;
      
      & > div {
        margin-top: 20px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > main {
          display: flex;
          align-items: center;

          & > h2 {
            font-size: 17px;
            margin-right: 10px;
          }

          & > img {
            margin-right: 20px;
            min-width: 30px;
            max-width: 30px;
            height: 30px;
            border-radius: 50%;
          }
        }

        & > svg {
          font-size: 30px;
        }

      }
      
      & > article {
      }
    }
  }

  & > form {
    margin-top: 25px;
    width: 100%;
    padding: 50px 0;
    display: flex;
    flex-direction: column;

    & > h2 {
      margin-bottom: 12px;
    }

    & input {
      width: 100%;
      padding: 20px;
      box-sizing: border-box;
      font-size: 17px;
    }

    & button {
      padding: 12px;
      margin-top: 12px;
    }
  }
`;