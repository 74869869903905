import styled from 'styled-components';

export const CollectionStyle = styled.div`
  & > div {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    gap: 50px;

    & > div[id="collection_info"] {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 30px;

      & > img {
        height: 70px;
        min-width: 70px;
        max-width: 70px;
        object-fit: cover;
        border-radius: 50%;
      }

      & > div {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        & > small {
          display: flex;
          align-items: center;
          gap: 10px;


          & div[id="dot"] {
            margin-top: -3px;
            height: 5px;
            width: 5px;
            background: ${({ theme }) => theme.colors.black}50;
            border-radius: 50%;
          }
        }

      }
    }

    & > div[id="items"] {
      display: flex;
      justify-content: center;
      
      & > div {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 30px;

        @media (max-width: ${({ theme }) => theme.mobile.is1070}) {
          grid-template-columns: repeat(2, 1fr);
        }
        
        @media (max-width: ${({ theme }) => theme.mobile.is749}) {
          grid-template-columns: repeat(1, 1fr);
        }

      }
    }

    & > div[id="btn"] {
      display: flex;
      justify-content: center;
  
      & > button { 
        margin: 50px 0;
        background: linear-gradient(to right top, #f32170, #cf23cf, #eedd44);
        color: #FFFFFF;
      }
    }
  }
`;