import { useEffect, useState } from "react";
import useAccess from "../../../hooks/access/useAccess";
import useConstructor from "../../../hooks/constructor/useConstructor";
import useDate from "../../../hooks/global/useDate";
import { RiCheckDoubleFill } from "react-icons/ri";
import { useTheme } from "styled-components";
import useProxyContext from "../../../hooks/global/useProxyContext";
import { io } from 'socket.io-client';

let socket;

// const ENDPOINT = process.env.REACT_APP_SERVER_ENDPOINT;
const ENDPOINT = 'https://model-grapix-api.onrender.com';

/* let handlePushNotification = (name, message) => {
  Notification.requestPermission().then((perm) => {
    if(perm === "granted") {
      let notification = new Notification(`${name} Sent you a message!`, {
        body: message,
        icon: "/images/logo.png",
      })

      notification.addEventListener('show', (e) => {
        console.log(e);
      })
    }
  })
} */

export const Inboxes = () => {
  const { getRouteRA, expandChat, setInforBar, chats, setChats } = useConstructor();

  const { userId } = useAccess();

  // fetch inboxes
  useEffect(() => {
    setInforBar((options) => ({ ...options, title: "Messages" }));

    const controller = new AbortController();
    const signal = controller.signal;

    getRouteRA((data) => {setChats(data)}, '/chats/fetch', signal)
    
    return () => {
      controller.abort();
    }

    // disable warning for getRouteRA() and setChats() 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandChat])

  return chats && userId && (
    <div id="inboxes">

      {chats.map((chat, i) => (
        <Inbox key={i} userId={userId} chat={chat} />
      ))}
     
    </div>
  )
}

const Inbox = ({ chat, userId }) => {
  const [endUser, setEndUser] = useState(null);

  const { setChat, openMessage, setOpenMessage, message, messages, setChats, textDeletion } = useConstructor();

  const { getDate } = useDate();

  const { getRouteRA } = useConstructor();

  const name = userId.userName;

  const opponentId = chat.members.filter(member => member !== userId._id)

  // Open chat
  const openChat = (chat) => {
    setChat(chat)
    setOpenMessage(true);
  }

  // Connect socket.io and handle join
  useEffect(() => {
    socket = io(ENDPOINT, {
      forceNew: true,
      reconnection: true,
      reconnectionDelay: 500,
      reconnectionAttempts: 20
    });

    if(!userId) return;

    const data = {
      name, 
      chat: chat._id,
      userId: userId._id
    }

    // Join chat
    socket.emit('join', data, () => {});

  }, [chat, name, userId]);

  // Spread a single message 
  useEffect(() => {
    socket.on('message', () => {
      // push notification
      // handlePushNotification(message.user, message.text);

      // refresh chats
      getRouteRA((data) => {setChats(data)}, '/chats/fetch')
    })

    return () => {
      socket.off();
    }

    // preventing warning for getRouteRA() and setChats() missing dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  // Refrect seen once user opens chat
  useEffect(() => {

    socket.on('seenStatus', () => {
      getRouteRA((data) => {setChats(data)}, '/chats/fetch');
    })

    return () => {
      socket.off();
    }

    // preventing warning for getRouteRA() and setChats() missing dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openMessage])

  // Handle socket disconnection
  useEffect(() => {
    socket.on('disconnect', () => {
      // handle disconnection here
    });
  }, [chat, message]);

  // Reflect text deletion on chat preview
  useEffect(() => {
    socket.on('textDeletion', () => {
      getRouteRA((data) => {setChats(data)}, '/chats/fetch');
    })

    // preventing warning for getRouteRA() and setChats() missing dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textDeletion])

  // Fetch opponent
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    getRouteRA((data) => { 
      setEndUser(data) 
    }, `/user/profile/basic/${opponentId}`, signal);

    return () => {
      controller.abort();
    }

    // preventing warning for getRouteRA() missing dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chat])

  return endUser && (
    <div onClick={() => openChat(chat)}>
      <User chat={chat} endUser={endUser} />

      <div id="status">
        <span>
          <span id="time">{getDate(chat.messages.slice(-1)[0].createdAt, true)}</span>
          <Status chat={chat} endUser={endUser} />
        </span>
      </div>
    </div>
  )
}

const Status = ({ chat, endUser }) => {
  const [read, setRead] = useState(true);
  const [seenByAllMembers, setSeenByAllMembers] = useState(false);
  const [unseenMessageCount, setUnseenMessageCount] = useState('');
  
  const { userId } = useAccess();

  const { imageEndpoint } = useProxyContext();

  useEffect(() => {
    if(!userId) return;

    if(chat.status.endUser === 0 && chat.status.initiator === 0) {
      setRead(false);
      return setSeenByAllMembers(true)
    }

    setSeenByAllMembers(false)

    if(userId._id === chat.initiator) {
      if(chat.status.endUser === 0) return setRead(true);

      setRead(false);
      setUnseenMessageCount(chat.status.endUser)
    } else {
      if(chat.status.initiator === 0) return setRead(true);
      
      setRead(false);
      setUnseenMessageCount(chat.status.initiator)
    }

  }, [chat, userId])

  return (
    <>
      { read ? (
        <span id="read"><RiCheckDoubleFill /></span>
      ):( seenByAllMembers ? (
        <span id="seenByAllMembers">
          <img src={ endUser.profilePic ? `${imageEndpoint}/images/${endUser.profilePic}` : `/images/profile_1.jpg`} alt="" />
        </span>
      ):(
        <div>{ unseenMessageCount }</div>
      ))}
      
    </>
  )
}

const User = ({ chat, endUser }) => {
  const { imageEndpoint } = useProxyContext();

  const { userId } = useAccess();

  const theme = useTheme();

  const newMessageSnippet = chat.messages.slice(-1)[0].text.slice(0,30)
  const lastMessageByCurrentUser = chat.messages.slice(-1)[0].senderId === userId._id && "You: "

  return (
    <div id="user">
      <img src={endUser.profilePic ? `${imageEndpoint}/images/${endUser.profilePic}` : `/images/profile_1.jpg`} alt="Profile" />

      { <div id="onlineStatus" style={{ background: endUser.online ? theme.colors.main2 : `${theme.colors.black}50` }} /> }

      <div>
        <h3> {endUser.userName}</h3>
        <small><strong>{ lastMessageByCurrentUser }</strong> { newMessageSnippet } </small>
      </div>
    </div>
  )
}