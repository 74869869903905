import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useTheme } from 'styled-components';
import useAccess from '../../../../hooks/access/useAccess';
import useRefreshContext from '../../../../hooks/global/useRefreshContext';

const NavStyle = styled.nav`
  margin: 50px 0;

  font-size: 19px;
  overflow: auto;
  display flex;
  align-items: center;
  gap: 25px;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
    width: 0px;
    height: 0px;
  }

  & > a {
    text-decoration: none;
  }
`;

const Nav = ({ userProfile }) => {
  const { reRender } = useRefreshContext();

  const { userId } = useAccess();

  const name = document.baseURI.split("/")[3];
  const id = document.baseURI.split("/")[5];
  const display = document.baseURI.split("/")[6];

  const url = (path) => `/${name}/profile/${id}/${path}`;

  const theme = useTheme();

  return (
    <NavStyle>
      <Link onClick={reRender} style={{
        color: display === 'uploads' && theme.colors.main2
      }} to={url("uploads")}>Uploads</Link>

    {userId && userId._id === userProfile._id && 
    <>
      <Link onClick={reRender} style={{
        color: display === 'collections' && theme.colors.main2
      }} to={url("collections")}>Collections</Link>

      <Link onClick={reRender} style={{
        color: display === 'downloads' && theme.colors.main2
      }} to={url("downloads")}>Downloads</Link>

      <Link onClick={reRender} style={{
        color: display === 'purchases' && theme.colors.main2
      }} to={url("purchases")}>Purchases</Link>


      <Link onClick={reRender} style={{
        color: display === 'blog' && theme.colors.main2
      }} to={url("blog")}>Blog</Link>
    </>
    }

      <Link onClick={reRender} style={{
        color: display === 'stared' && theme.colors.main2
      }} to={url("stared")}>Stared</Link>

      <Link onClick={reRender} style={{
        color: display === 'about' && theme.colors.main2
      }} to={url("about")}>About</Link>
      
    </NavStyle>
  )
}

export default Nav;