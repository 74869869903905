import { Container } from "../../components/global/Container"
import { CreateStl, Editor, FormatingBoxStl, Box, Add } from "./styles/S_Create";
import { FaPlus } from 'react-icons/fa';
import { FaCamera } from 'react-icons/fa';
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import axios from 'axios';
import { useEditor } from "./useEditor";
import useProxyContext from "../../hooks/global/useProxyContext"; //
import useAdminAuthContext from '../../hooks/admin/auth/useAdminAuthContext';
import useErrHandler from '../../hooks/err/useErrHandler';

const trim = (str) => str.replace(/\.([^.]*$)/, "$1").replace(/[.\s]/g, "");

const Update = () => {
  const { endpoint, imageEndpoint } = useProxyContext();

  const [data, setData] = useState(null);

  const path = useLocation().pathname.split('/')[3].split('-')[1];

  const editorBody = useRef();
  const postDefault = useRef();
  const tagsContainer = useRef();

  const { admin } = useAdminAuthContext();

  const { notifier } = useErrHandler();

  // CALL EDITOR LOGICS, SEND AND GET EVENTS OR ELEMENTS 
  const { addSpace, handlePublish, getID, edited, posting } = useEditor({ editorBody, postDefault, featuredImageName: data && data.postFeaturedImg, path, tagsContainer });

  // Fetch Post to edit 
  useEffect(() => {
    (async function fetchPost() {
      try {
        const res = await axios.get(`${endpoint}/fetch/post/${path}`)
        setData(res.data);

      } catch (err) {}
    })();
  }, [path, endpoint, admin.token]);

  // Listen for file change in the postBody input fields and create object url 
  useEffect(() => {
    if(!data) return;
    
    const editorBodyContainer = document.querySelector('.editableFrameContainer').children;

    for (let i = 0; i < editorBodyContainer.length; i++) {
      const frame = editorBodyContainer[i];

      if(frame.children[0].tagName === 'SPAN') {

        const imgFileName = frame.children[0];
        const inputField = frame.children[1].children[1];
        const imgPreview = frame.children[1].children[0];

        inputField.addEventListener('change', (e) => {
          const file = e.target.files[0];
          const fileSizeInMB = file.size / (1024 * 1024);
          const generatedName = `${Date.now()}-${getID(36)}-${file.name}`

          if(fileSizeInMB > 3) {
            notifier(true, "This selected file is too large. Please select a file under 2MB.")
            imgPreview.src = URL.createObjectURL(file);
            imgFileName.innerHTML = '';
            imgPreview.style.border = "5px solid red";
            return;
          }
          
          imgPreview.src = URL.createObjectURL(file);
          imgFileName.innerHTML = trim(generatedName); // construct image file name
          imgPreview.style.border = "none";
        })
      }
    }

    // console.log(editorBodyContainer.children);
  }, [data, getID, notifier])

  return data ? (
    <CreateStl>

      <Container>
        <h1>Edit Post</h1>
        <div>
          <Editor>
          <div>
              {/* Editing tools header */}
              <p></p>

              <button 
                onClick={handlePublish}
                style={{
                  cursor: posting ? 'not-allowed' : 'pointer',
                  pointerEvents: posting ? 'none' : 'visible'
                }}
              >{posting ? 'Updating...' : 'Update'}</button>
            </div>

            <div ref={postDefault}>
              {/* <FormatingFrame icon={ <MdTitle /> } name="Post Title..." title={true} /> */}
              <FormatingFrame title={true} name={data && data.postTitle} />
              <FormatingFrame  icon={ <FaCamera /> } image={true} data={data} />
            </div>

            <div ref={editorBody} className='editableFrameContainer'>
              {data.postBody && data.postBody.map((item, index) => (
                <main key={index}>
                  {item.htmlTag === 'SPAN' && (
                    <>
                    <span style={{display:'none'}}>{item.text}</span>
                      <form>
                        <img src={`${imageEndpoint}/images/${item.text}`} alt="" />
                        <input
                          type="file"
                          accept="image/*" 
                        />
                      </form>
                    </>
                  )}
                  
                  {item.htmlTag === 'PRE' && (
                    <pre suppressContentEditableWarning="true" contentEditable="true" >{item.text}</pre>
                  )}

                  {item.htmlTag === 'P' && (
                    <p suppressContentEditableWarning="true" contentEditable="true" >{item.text}</p>
                  )}

                  {item.htmlTag === 'H1' && (
                    <h1 suppressContentEditableWarning="true" contentEditable="true" >{item.text}</h1>
                  )}

                  {item.htmlTag === 'DIV' && (
                    <p suppressContentEditableWarning="true" contentEditable="true">Paragraph ...</p>
                  ) }
                </main>
              ))}
            </div>

            {edited && (
              <Add>
                <div onClick={addSpace}>
                  <FaPlus />
                </div>
                <div></div>
              </Add>
            )}

          </Editor>

          <div  ref={tagsContainer} id="tags" contentEditable={true} suppressContentEditableWarning={true}>
            #Design #UI/UX #Designtips
          </div>

        </div>
      </Container>

    </CreateStl>
  ):(
    <h2>Loading...</h2>
  )
}

export const FormatingFrame = ({ icon, name, title, image, data }) => {
  const [featuredImg, setFeaturedImg] = useState(null);

  const { imageEndpoint } = useProxyContext();

  const { notifier } = useErrHandler();

  return (
    <FormatingBoxStl>

      <Box>
        <div> {/* <<<< */}
          {!featuredImg && 
            title && (
              <p 
                contentEditable={title} 
                style={{
                  width: '100%',
                  fontWeight: title && '600',
                  fontFamily: title && 'Jost, sans-serif',
                  // color: title && '#8D3F6E'
                }} 
                onFocus={(e) => {
                  if(e.target.innerHTML !== ' ' && e.target.innerHTML === 'Post Title...') {
                    title && (e.target.style.fontStyle = 'normal');
                    title && (e.target.innerHTML = ' ');
                  }
                }} 
                onBlur={(e) => {
                  if(e.target.innerHTML.length === 0 || e.target.innerHTML.length === 1 ) {
                    title && (e.target.innerHTML = 'Post Title...');
                    title && (e.target.style.fontStyle = 'italic');
                  }
                }}
                suppressContentEditableWarning={true}
              >{ name }</p>
            )
          }

          { image && (
            <form action="" style={{
              width: (featuredImg || data) && '100%'
            }}>
              { image && (
                  <img 
                    src={ featuredImg ? URL.createObjectURL(featuredImg) : (data && data.postFeaturedImg) && `${imageEndpoint}/images/${data.postFeaturedImg}` } 
                    alt=" "
                  />
              )}

              <input 
                type="file" 
                accept="image/*"
                name="featured" 
                id="featured" 
                onChange={(e) => {
                  const file = e.target.files[0];
                  const fileSizeInMB = file.size / (1024 * 1024);
                  const imgPreview = e.target.parentElement.children[0];
                  
                  if(fileSizeInMB > 3) {
                    notifier(true, "This selected file is too large. Please select a file under 2MB.")
                    imgPreview.src = URL.createObjectURL(file);
                    imgPreview.style.border = "5px solid red";
                    return;
                  }
                  
                  imgPreview.style.border = "none";
                  setFeaturedImg(file)
                }}
              />
              <label htmlFor="featured">
                {!featuredImg ? 'Upload Featured Image' : 'Reselect'} { icon }
              </label>
            </form>
          )}
        </div>

      </Box>

    </FormatingBoxStl>
  )
}

export default Update;