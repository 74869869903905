import styled from 'styled-components';
import useAccess from '../../hooks/access/useAccess';
import Button from '../button/Button';
import usePopUpContext from '../../hooks/global/usePopUpContext';
import { useNavigate } from 'react-router-dom';
import { MdDelete } from 'react-icons/md';
import { useEffect, useState } from 'react';
import axios from 'axios';
import useProxyContext from '../../hooks/global/useProxyContext';
import useAuthContext from '../../hooks/auth/useAuthContext';
import useConstructor from '../../hooks/constructor/useConstructor';

const NotificationsStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  & button {
    background: linear-gradient(to right top, #f32170, #cf23cf, #eedd44);
    color: #FFFFFF;
    font-size: 17px;
  }
  
  & > span {
    display: flex;
    flex-direction: column;
    gap: 10px;
    
    & > button {
      font-size: 12px;
      padding: 5px;
    }

    opacity: 0;
    transform: translateY(5%);
  
    &.fade-in { opacity: 0; animation: fadeInFromBottom 0.5s ease-in forwards; }
    @keyframes fadeInFromBottom { from { opacity: 0; transform: translateY(5%); } to { opacity: 1; transform: translateY(0); } }
  }
  
  & > div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    
    opacity: 0;
    transform: translateY(5%);
  
    &.fade-in { opacity: 0; animation: fadeInFromBottom 0.5s ease-in forwards; }
    @keyframes fadeInFromBottom { from { opacity: 0; transform: translateY(5%); } to { opacity: 1; transform: translateY(0); } }

    & > div {
      border-radius: 10px;
      background: ${({ theme }) => theme.colors.lightgrey}50;
      padding: 10px;
      box-shadow: 1px 2px 1px #00000025;
  
      display: flex;
      flex-direction: column;
      gap: 5px;
  
      & > header {
        font-weight: 600;
      }
  
      & > div {
        display: flex;
        flex-direction: column;
        gap: 5px;

        margin-top: 10px;
  
        & button {
          padding: 9px;
          font-size: 17px;
        }
  
        & > p[id="postName"] {
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }
`;

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);

  const { userId } = useAccess();

  const { popUp } = usePopUpContext();

  const navigate = useNavigate();

  const { endpoint } = useProxyContext();

  const { user } = useAuthContext();

  const { handleUploadPreview } = useConstructor();

  // set notifications
  useEffect(() => {
    if(userId) {
      setNotifications(userId.notifications.sort((a, b) => b._date - a._date))
    }
  }, [userId])

  const deleteAllNotifications = async () => {
    try {
      await axios.put(`${endpoint}/dell/all/notifications`, {}, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      })

      setNotifications([])

    } catch (err) {
      console.log(err)
    }
  }

  return (
    <NotificationsStyle>

    <span className="anm">
      <h2>Notifications</h2>

      { notifications && notifications.length > 0 && 
      <Button run={deleteAllNotifications}><MdDelete /> Delete All</Button>
      }
    </span>

    <div className="anm">

      { notifications && notifications.length === 0 && 
      <div>
        <small>No notifications to display!</small>
      </div>
      }

      {notifications && notifications.map((notification, i) => (

      <div key={i}>
        <header>{notification.header}</header>
        <small>{notification.message}</small>

        { notification.reference.type === 'ACCOUNT_CREATION' &&
        <div>
          <div><Button run={() => popUp('setup')}>Proceed to setup</Button></div>
        </div>
        }
        
        { notification.reference.type === "COMMENT_ON_POST" &&
        <div>
          <p id="postName">{notification.reference.name}</p>
          <small>{notification.reference.comment}</small>
          <div><Button run={() => handleUploadPreview(notification.reference)}>Visit post</Button></div>
        </div>
        }

        {/* LETTER CONSIDER JOINING THESE TOGETHER CREATE A PATTERN THAT ACCEPTS ALL */}

        { notification.reference.type === "ITEM_IN_MY_COLLECTION" &&
        <div>
          <div><Button run={() => navigate(`/collection/${notification.reference._id}`)}>Open collection</Button></div>
        </div>
        }

        { notification.reference.type === "TOGGLE_ITEM_STATUS" &&
        <div>
          <p><strong>Design name:</strong> {notification.reference.postName}</p>
          <div><Button run={() => navigate(`/collection/${notification.reference._id}`)}>Open collection</Button></div>
        </div>
        }

        { notification.reference.type === "ITEM_REMOVED" &&
        <div>
          <p><strong>Design name:</strong> {notification.reference.postName}</p>
          <div><Button run={() => navigate(`/collection/${notification.reference._id}`)}>Open collection</Button></div>
        </div>
        }

        { notification.reference.type === "COLLECTION_STATUS_TOGGLE" &&
        <div>
          <p><strong>Collection name:</strong> {notification.reference.name}</p>
          <div><Button run={() => navigate(`/collection/${notification.reference._id}`)}>Open collection</Button></div>
        </div>
        }
      </div>
      ))}
    </div>

    </NotificationsStyle>
  )
}

export default Notifications;