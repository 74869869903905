import { useContext } from "react";
import { ProxyContext } from "../../contexts/global/ProxyContext";

const useProxyContext = () => {
  const context = useContext(ProxyContext);

  if(!context) throw Error('You can only use this context inside its wrapped contents');

  return context;
}
 
export default useProxyContext;