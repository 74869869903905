import { useState } from "react";
import Button from "../../../button/Button";
import { TextPreviewStyle } from "./S_TextPreview";

const TextPreview = ({ text }) => {
  const [directive, setDirective] = useState('Copy');

  // Copy option Text to clipborad
  const copyToClipboard = () => {
    navigator.clipboard.writeText(text)
      .then(() => {
        setDirective('Copied!')

        setTimeout(() => setDirective('Copy'), 3000)
      })
      .catch(err => {
        // handle error
      });
  };

  return (
    <TextPreviewStyle>
      <span>{text}</span>
      <div>
        <Button run={copyToClipboard}>{ directive }</Button>
      </div>
    </TextPreviewStyle>
  );
}
 
export default TextPreview;