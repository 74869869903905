import { Wrapper } from "./styles/S_Home";
import TopBar from '../../components/topbar/TopBar';
import WelcomeScreen from "./sections/welcomeScreen/WelcomeScreen";
import Footer from "../../components/footer/Footer";
import Collections from "./sections/collections/Collections";
import { Container } from "../../components/global/Container";
import Images from "../modules/display/images/Images";
import { Helmet } from "react-helmet-async";
import UsersDisplay from "../modules/display/users/UsersDisplay";
import useVisits from "../../hooks/stats/visits/useVisits";
import { useEffect } from "react";
import usePopUpContext from "../../hooks/global/usePopUpContext";

const Home = ({ tools, type }) => {
  useVisits();

  const { popUp } = usePopUpContext();

  useEffect(() => {
    console.log('ran');
    
    if(!tools) return;

    popUp('tools')
  }, [tools, popUp])

  return (
    <Wrapper>
      <Helmet>
        <title>Model Grapix | Home</title>

        <meta name="description" content="Welcome to Model Grapix - We connects creatives and provides a space for them to create, share, and connect with others. Join us today" />

        <meta property="og:title" content="Create | Share | Connect" />
        <meta property="og:description" content="Welcome to Model Grapix - We connects creatives and provides a space for them to create, share, and connect with others. Join us today" />
        <meta property="og:image" content="https://res.cloudinary.com/drrd1lwey/image/upload/v1677448174/model-grapix/images/23ii46c50aai1-20230125_074727png.png" />
        <meta property="og:url" content="htts://modelgrapix.com" />
      
        <link rel="icon" href="/images/logos/20230125_074855.png" />
      </Helmet>

      <TopBar />
      <WelcomeScreen type={type} />
      <Container id="container">
        {type === 'ALL' && <Collections global={true} />}
        {type === 'UPLOADS' && <Images />}
        {type === 'USERS' && <UsersDisplay />}
      </Container>
      <Footer />
    </Wrapper>
  )
}

export default Home;