import { createContext, useState } from 'react';

export const RefreshContext = createContext();

const RefreshContextProvider = ({ children }) => {
  const [refresh, setRefresh] = useState(false);

  const reRender = () => {
    setRefresh(!refresh);
  }
  
  return (
    <RefreshContext.Provider value={{ refresh, setRefresh, reRender }}>
      {children}
    </RefreshContext.Provider>
  )
}

export default RefreshContextProvider;