import { Container } from '../../../components/global/Container';
import { UserProfileSkeletonStyle } from './styles/S_UserProfileSkeleton';
const UserProfileSkeleton = () => {

  return (
    <UserProfileSkeletonStyle>
      <Container>
        {/* Personal info */}
        <div>

          <div>
            <img src="/images/profile.jpg" alt=' ' />  
          </div> {/* Profile pics */}
          
          <div> {/* User info */}
            <span></span>
            <span>
              <div></div>
              <div></div>
            </span>
            <span></span>
          </div>

        </div>
      </Container>
    </UserProfileSkeletonStyle>
  );
}
 
export default UserProfileSkeleton;