import styled from 'styled-components';

export const WalletStyle = styled.div`
	& > div[id="container"] {
		padding-top: 100px;
		padding-bottom: 100px;
		display: flex;
		flex-direction: column;
		gap: 100px;

		& > div {
			display: flex;
			flex-direction column;
			gap: 30px;
		}

		& > div[id="wallet"] {
			& > div[id="walletInfo"] {
				display: flex;
				align-items: center;
				gap: 30px;

				@media(max-width: ${({ theme }) => theme.mobile.is600}) {
					flex-direction: column;
				}

				& > div {
					width: 100%;
					display: flex;
					flex-direction: column;
					gap: 10px;
					padding: 20px;
					border-radius: 10px;
					background: linear-gradient(to right top, ${({ theme }) => `${ theme.colors.main2 }50, ${ theme.colors.main }50` });
					
		      &:hover {
		        background: linear-gradient(to bottom, ${({ theme }) => `${ theme.colors.main2 }AA, ${ theme.colors.main }AA` });
		      }
					
		      & svg {
						font-size: 30px;
		      }
					
		      & h2 {
						color: ${({ theme }) => theme.colors.black};
		      }
					
					@media (max-width: ${({ theme }) => theme.mobile.is460}) {
						width: 100%;
					}
				}
			}
			
			& > div[id="button"] {
				display: flex;
				grid-gap: 10px;

				@media(max-width: ${({ theme }) => theme.mobile.is600}) {
					flex-direction: column;
				}

				& > button {
					// border-radius: 5px;
					width: 100%;

					&:first-child {
						background: linear-gradient(to right top, ${({ theme }) => `${ theme.colors.main2 }50, ${ theme.colors.blue }50` });
					}

					&:last-child {
						border: 1px solid ${({ theme }) => theme.colors.black}50;
					}
				}
			}
		}

		& > div[id="transactions"] {

			& > div {
				& > div {
					padding: 20px 0;
					border-bottom: 1px solid ${({ theme }) => theme.colors.main2}CC;
					display: flex;
					flex-wrap: wrap;
					gap: 20px;
					align-items: center;
					justify-content: space-between;

					& > div[id="payer"] {

						display: flex;
						grid-gap: 20px;

						& > img {
							width: 30px;
							height: 30px;
							border-radius: 50%;
						}
						
						& > div {
							display: flex;
							flex-direction: column;
							gap: 10px;
						}
					}

					& > button {
						font-size: 15px;
					}
				}
			}
		}
	}
`;