import styled from "styled-components";

export const UsersDisplayStyle = styled.div`
  margin-top: 50px;

  & > div[id="users"] {
    display: grid;
    grid-template-columns: repeat(3, 1fr );
    grid-gap: 30px;
    grid-row-gap: 100px; /* add a gap between rows */
    
    @media(max-width: ${({ theme }) => theme.mobile.is992}) {
      grid-template-columns: repeat(2, 1fr );
    }
    
    @media(max-width: ${({ theme }) => theme.mobile.is640}) {
      grid-row-gap: 60px; /* add a gap between rows */
    }
    
    @media(max-width: ${({ theme }) => theme.mobile.is600}) {
      grid-template-columns: repeat(1, 1fr );
    }
    
    & > div {
      padding: 30px;
      box-shadow: 2px 4px 25px rgba(0,0,0,0.1);
      border-radius: 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: center;
      background: ${({ theme }) => theme.colors.white};
      
      @media(max-width: ${({ theme }) => theme.mobile.is640}) {
        padding: 10px;
      }
      
      opacity: 0;
      transform: translateY(5%);
    
      &.fade-in { opacity: 0; animation: fadeInFromBottom 0.5s ease-in forwards; }
      @keyframes fadeInFromBottom { from { opacity: 0; transform: translateY(5%); } to { opacity: 1; transform: translateY(0); } }
      
      & > span {
        & > img {
          min-height: 100px;
          max-height: 100px;
          min-width: 100px;
          max-width: 100px;
          border-radius: 50%;
          margin-top: -100px;
          box-shadow: 0px 4px 2px #00000020;
          background: ${({ theme }) => theme.colors.white};
          
          @media(max-width: ${({ theme }) => theme.mobile.is640}) {
            min-height: 60px;
            max-height: 60px;
            min-width: 60px;
            max-width: 60px;
          }
        }
        
        & > div {
        }
        
        & > h2 {
          margin-bottom: 10px;
          font-size: 20px;
        }
      }
      
      & > button {
        margin-top: 30px;
        background: linear-gradient(to right top, #f3217020, #cf23cf20);
        color: ${({ theme }) => theme.colors.black};
      }
    }
  }

  & > div[id="btn"] {
    display: flex;
    justify-content: center;

    & > button { 
      margin: 50px 0;
      background: linear-gradient(to right top, #f32170, #cf23cf, #eedd44);
      color: #FFFFFF;
    }
  }
`;