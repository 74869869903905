import { useEffect, useState } from "react";
import { UsersDisplayStyle } from "./styles/S_UsersDisplay";
import useImgHandler from "../../../../hooks/img/useImgHandler";
import Button from "../../../../components/button/Button";
import { HiMinusSm, HiPlusSm } from "react-icons/hi";
import UserOverview from '../../../../components/user/UserOverview'
import useProxyContext from "../../../../hooks/global/useProxyContext";
import { useNavigate } from 'react-router-dom';
import useAccess from "../../../../hooks/access/useAccess";
import useProfile from "../../../../hooks/profile/useProfile";
import Processing from "../../../../components/loader/Processing";
import useDataContext from '../../../../hooks/global/useDataContext';

const UsersDisplay = () => {
  const [count, setCount] = useState(6);

  const { getRoute } = useImgHandler();

  const { users, setUsers } = useDataContext();

  useEffect(() => {
    if(users) return;
    
    const controller = new AbortController();
    const signal = controller.signal;

    getRoute((data) => {
      setUsers(data);
    }, '/get/users', signal)

    return () => {
      controller.abort()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return users ? (
    <UsersDisplayStyle>

    <div id="users">
      {users.slice(0, count).map((user, i) => (
        <User key={i} user={user} />
      ))}
    </div>

    {users.length > count && <div id="btn">
      <Button run={() => setCount(count + count)}>... Load More</Button>
    </div>}

    </UsersDisplayStyle>
  ) : (
    <Processing />
  )
}

const User = ({ user }) => {
  const { imageEndpoint } = useProxyContext();

  const { userId } = useAccess();

  const { followButtonFunction, setupDirective, directive } = useProfile();
  
  const navigate = useNavigate();

  useEffect(() => {
    setupDirective(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, user])

  // animate
  useEffect(() => {
    const user = document.querySelectorAll('.user')
    
    if(user) {
      for (let i = 0; i < user.length; i++) {
        const element = user[i];
        element.classList.add('fade-in');
      }
    }

  }, [user])

  return (
    <div className="user">
      <span>
        <img onClick={() => {
          navigate(`/${user.userName.split(' ')[0]}/profile/${user._id}/uploads`)
        }} src={user.profilePic ? `${imageEndpoint}/images/${user.profilePic}` : "/images/profile.jpg"} alt={user.userName} />
        <UserOverview userData={user} button={false} />
        <h2>{user.userName}</h2>
        <small>{user.desc}</small>
      </span>
      <Button run={() => followButtonFunction(user)}>{ userId && userId._id === user._id ? "" : (directive === 'Follow') ? <HiPlusSm /> : <HiMinusSm /> } { userId && userId._id === user._id ? "Your Profile" : directive } </Button>
    </div>
  )
}

export default UsersDisplay;