import { useEffect, useState } from "react";
import { ModulesStyle } from "../../styles/S_Documentation";

import hljs from 'highlight.js';
import 'highlight.js/styles/atom-one-dark.css';

import DOMPurify from "dompurify";
import { bg_remover_js, image_gen_js, inpainting_js } from "./modules/js";
import { bg_remover_py, image_gen_py, inpainting_py } from "./modules/python";
import { bg_remover_curl, image_gen_curl, inpainting_curl } from "./modules/curl";

const renderCode = (match, code) => {
  const highlightedCode = hljs.highlightAuto(code).value;
  return `<div id="code"><span id="copy">Copy</span><code id="code-preview" class="javascript">${highlightedCode}</code></div>`;
};

const UseOfApi = () => {
  const [stack, setStack] = useState({
    nodejs: true,
    python: false,
    curl: false
  })

  const stacks = 
`<div id="stacks">
<div id="nodejs"><img src="/images/icons/nodejs.png" alt="" /><span>NodeJs</span></div>
<div id="python"><img src="/images/icons/python.png" alt="" /><span>Python</span></div>
<div id="curl"><img src="/images/icons/curl.png" alt="" /><span>CURL</span></div>
</div>`

  const content = 
`
<h1 id="apiIntroduction">Introduction to Use of API</h1>
The ModelGrapix AI API provides access to our cutting-edge AI tools for generating images, removing backgrounds, and inpainting images. Integrate these AI models into your applications with our simple REST API.

The API is designed for any developer wanting to include AI-powered image editing and generation in their products. From startups building new apps to established companies looking to upgrade, our API service makes it easy to implement complex AI with minimal effort.

<h1 id="apiGettingStarted">Getting Started</h1>
Getting started with the ModelGrapix API is quick and easy.

<h2>Get an API Key</h2>
First, you'll need to get an API key from your profile settings <a href="/api-key">click here</a> to get it. This API key authenticates your API requests.

<h2>Make Your First Request</h2>
Once you have your API key, you can start making requests to our API endpoints. Continue reading documentation for example requests and parameters.

<h2>Integrate the API</h2>
Use the language and platform of your choice. Our REST API integrates easily into any stack.

<h1 id="apiImageGeneration">Using the AI Image Generator API</h1>
The AI image generator API allows you to generate images through API requests. Here are the details on using this API:

**Authentication**
To use the API, you will need an API key. Include this API key in the request headers:
\`\`\`
Authorization: Bearer YOUR_API_KEY
\`\`\`

**API Endpoint**
\`\`\`
POST "https://api.modelgrapix.com/api/image-gen-v1"
\`\`\`

**Request Parameters**
The following parameters are required in the request body:

<ul>#**prompt** (string): The text prompt to generate the image from.#
#**upscale** (boolean): Whether to upscale the generated image.#
#**display** (string): Aspect ratio of the generated image. Allowed values: **portrait**, **landscape**, or **square**.#
#**paint** (string): paint usage Allowed values: **PERSONAL** or **PUBLIC**.#</ul>

**Example Request**

${stacks}
${stack.nodejs ? (
image_gen_js
):(stack.python ? (
image_gen_py
):(
image_gen_curl
))}
**Response**
The API will return a JSON response with the generated image URL:
\`\`\`
Response: {
  "image_url": "https://api.modelgrapix.com/api/images/4be05a21-a9ba-4aa1-85bb-9e2ba9f8c0af-1693856667724.png", 
  "prompt": "A cute baby elephant"
}
\`\`\`
You can then download the image from the provided URL.

<h1 id="apiBackgroundRemoval">Background Removal API</h1>
The background removal API allows you to remove image backgrounds through an API request.

**Authentication**
Include your API key in the request headers:
\`\`\`
Authorization: Bearer YOUR_API_KEY
\`\`\`
**API Endpoint**
\`\`\`
POST "https://api.modelgrapix.com/api/bg-remover-v1"
\`\`\`

**Supported Data Types**

Below are some of the common data types that can be used in the **image_file**

**image_file**
The image_file field accepts JPEG and PNG image data in various formats:

<ul>#File object - obtained from an **input** element#
#Blob object - created from the image file#
#ArrayBuffer - containing the raw image bytes#
#Any type - that contains or references the raw image data.#</ul>

**Request**
Send the image file in the request body as form data:

${stacks}
${stack.nodejs ? (
bg_remover_js
):(stack.python ? (
bg_remover_py
):(
bg_remover_curl
))}
**Response**
The API will return a JSON response containing the URL of the processed image:
\`\`\`
Response: "https://api.modelgrapix.com/api/images/ccb07914-bf1a-467b-aedb-dd7385a74186-1693750789796.png"
\`\`\`

<h1 id="apiInpainting">Inpainting API</h1>
The inpainting API allows you to remove unwanted objects or blemishes from images through an API request.

**Authentication**
Include your API key in the request headers:
\`\`\`
Authorization: Bearer YOUR_API_KEY
\`\`\`
**API Endpoint**
\`\`\`
POST "https://api.modelgrapix.com/api/inpainting-v1"
\`\`\`
**Request**
Send the original image file and mask file in the request body as FormData:

**Request Format**
The request must be a HTTP POST with a **multipart/form-data** body containing the following:

<ul>#**image_file** - The original image to inpaint. Allowed formats: JPG, PNG. Max resolution: 16 megapixels. Max size: 30MB.#
#**mask_file** - The mask image defining inpaint areas. Must be PNG with same dimensions as original image. Max size: 30MB.#</ul>

**Mask Image Rules**

The mask image must follow these rules:

<ul>#Black and white PNG with no grayscale (only 0 and 255 pixel values).#
#0 indicates pixels to keep unchanged.#
#255 indicates pixels to inpaint.#
#Must have same resolution as original image.#<ul>

<img src="/images/inpainting.png" alt="" />

*Supported Data Types*

The image_file and mask_file fields accept image data in various formats. The API is flexible in terms of the data types used to provide the image and mask files in the request body.

Below are some of the common data types that can be used in the **image_file** and **mask_file**

**image_file**
The image_file field accepts JPEG and PNG image data in various formats:

<ul>#File object - obtained from an **input** element#
#Blob object - created from the image file#
#ArrayBuffer - containing the raw image bytes#
#Any type - that contains or references the raw image data.#</ul>

**mask_file**
The mask_file field only accepts PNG images provided as:

<ul>#File object#
#Blob - containing the raw PNG data#
#ArrayBuffer - with the PNG bytes#
#etc.#</ul>

${stacks}
${stack.nodejs ? (
inpainting_js
):(stack.python ? (
inpainting_py
):(
inpainting_curl
))}
**Response**
On success the API responds with:
\`\`\`
Response: "http://localhost:3001/api/images/71603362-9af7-4c53-8d71-5009f0de8455-original_image.jpg"
\`\`\`

<h1 id="apiStatusCodes">Status codes</h1>
The API uses standard HTTP status codes to indicate success or failure of the API requests. Here are some of the potential error codes:

<ul>#**200 OK**- The request was successful.#
#**400 Bad Request** - The request parameters are invalid.#
#**401 Unauthorized** - The API key is invalid or not provided.#
#**402 Payment Required** - Your account has no remaining grapix. You can purchase more grapix on your account page.#
#**403 Forbidden** - The API key does not have permissions to access this endpoint.#
#**413 Payload Too Large** - The prompt text exceeds the character limit.#
#**429 Too Many Requests** - You have exceeded the rate limit for requests. To avoid this error, space out your requests over time or contact us to discuss increasing your request quota.#
#**501 Internal Server Error** - The image generator failed to generate the image.#</ul>

The error response will also contain a JSON body providing more details on the error:
\`\`\`
{
  "error": {
    "code": 429,
    "message": "Too many requests"
  }
}
\`\`\`

<h1 id="apiConclusion">Conclusion</h1>
The ModelGrapix AI API provides powerful image generation, background removal, and inpainting capabilities through simple API calls.

With the endpoints, parameters, and examples provided in this documentation, you have everything you need to start integrating these advanced AI tools into your applications.

The documentation covers the technical details on making API requests as well as provides examples for each endpoint to help you get up and running quickly.

If you need any clarification or have additional questions not covered in the docs, please reach out to our support team through the email and contact form provided on our website.

We look forward to seeing all the creative ways you utilize the ModelGrapix API for image generation, editing, and more. Our team is here to help support you every step of the way.


`

  useEffect(() => {
    const nodejs = document.querySelectorAll('#nodejs');
    const python = document.querySelectorAll('#python');
    const curl = document.querySelectorAll('#curl');

    for (let i = 0; i < nodejs.length; i++) {
      const element = nodejs[i];
      
      element.addEventListener('click', () => {
        setStack(() => ({ python: false, curl: false, nodejs: true }))
      })
    }
    
    for (let i = 0; i < python.length; i++) {
      const element = python[i];
      element.addEventListener('click', () => {
        setStack(() => ({ python: true, curl: false, nodejs: false }))
      })
    }

    for (let i = 0; i < curl.length; i++) {
      const element = curl[i];
      element.addEventListener('click', () => {
        setStack(() => ({ python: false, curl: true, nodejs: false }))
      })
    }
  }, [stack])

  return (
    <ModulesStyle stack={stack}>
      <Pre content={content} stack={stack} />
    </ModulesStyle>
  )
}

const Pre = ({ content, stack }) => {

  function copyCode(codeElement, copyElement) {
    const range = document.createRange();
    range.selectNodeContents(codeElement);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand('copy');
    selection.removeAllRanges();
    copyElement.innerHTML = 'Copied!'
    setTimeout(() => copyElement.innerHTML = 'Copy', 3000)
  }
  
  useEffect(() => {
    const copyElement = document.querySelectorAll('#copy');
    const codeElement = document.querySelectorAll('#code-preview');

    for (let i = 0; i < copyElement.length; i++) {
      const copyEl = copyElement[i];
      const codeEl = codeElement[i];
      
      copyEl.addEventListener('click', () => {
        copyCode(codeEl, copyEl)
      })
    }
  }, [stack])

  // Replace code blocks with <pre><code> tags
  const contentWithCode = content.replace(
    /```([\s\S]*?)```/gm,
    (match, code) => renderCode(match, code)
  );

/*   // Replace URLs with <a> tags
  const contentWithLinks = contentWithCode.replace(
    /(https?:\/\/[^\s]+)/g,
    '<a id="linkTag" href="$1">$1</a>'
  );
 */
  // Replace *text* with <strong> tags
  const contentWithStrong = contentWithCode.replace(
    /\*\*(.+?)\*\*/g,
    '<strong>$1</strong>'
  );

  // Replace _text_ with <em> tags
  const contentWithEm = contentWithStrong.replace(
    /__(.+?)__/g,
    '<em>$1</em>'
  );

    // Replace #text# with <ul></ul>
    const contentWithLi = contentWithEm.replace(
      /#(.+?)#/g,
      '<li>$1</li>'
    );

  // Encode less-than (<) and greater-than (>) characters in code blocks
  const contentWithEncodedCode = contentWithLi.replace(
    /<pre><code class="javascript">([\s\S]*?)<\/code><\/pre>/gm,
    match => match.replace(/</g, '&lt;').replace(/>/g, '&gt;')
  );

  // Wrap content in a <p> tag
  const contentWithP = `<p>${contentWithEncodedCode}</p>`;

  // Purify
  const sanitizedHTML = DOMPurify.sanitize(contentWithP);
  

  return <pre dangerouslySetInnerHTML={{ __html: sanitizedHTML }} ></pre>
  
}

export default UseOfApi;