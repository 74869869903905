import { Helmet } from "react-helmet-async";
import AiWriterComp from "../../../components/tools/aiWriterComp/AiWriterComp";
import { AiWriterStyle } from "./styles/S_AiWriter";

const AiWriter = () => {
  return (
    <AiWriterStyle>
      <Helmet>
        <title>AI Writer | Experience the Future of Writing with AI Technology. </title>

        <meta name="description" content="Experience the best in AI technology. Try our image background remover for stunningly neat results that will amaze you." />

        <meta property="og:title" content="AI Writer: Your Ultimate Content Creation Partner" />
        <meta property="og:description" content="Meet AI Writer, the AI-powered content creation tool that takes your writing to the next level. Whether you're a blogger, a marketer, or a small business owner, AI Writer can help you produce high-quality, engaging content in minutes." />
        <meta property="og:image" content="https://res.cloudinary.com/drrd1lwey/image/upload/v1677448174/model-grapix/images/23ii46c50aai1-20230125_074727png.png" />
        <meta property="og:url" content="htts://modelgrapix.com" />
      
        <link rel="icon" href="/images/logos/20230125_074855.png" />
      </Helmet>

      <AiWriterComp />
    </AiWriterStyle>  
  );
}
 
export default AiWriter;