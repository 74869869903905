import styled from 'styled-components';

export const WelcomeScreenStl = styled.div`
  background-image: linear-gradient(#000000AA, ${({ theme }) => theme.colors.main2}20), url("/images/background/background_1.png");
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 500px;
  padding-bottom: 30px;
`;

export const Left = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding-top: 130px;

  & h1 {
    text-align: center;
    font-size: 75px;
    text-shadow: 1px 1px rgba(0,0,0,0.3);
    font-weight: 900;
    line-height: 1.2;
    color: #ffffff;
    transition: height 0.5s ease-in-out;

    & > span {
      opacity: 1;
      position: relative;
      top: 0;
      transition: opacity 0.5s ease-in-out, top 0.5s ease-in-out;
      
     &.fade-out {
        opacity: 0;
        top: 20px;
      }
    }
  }
  
  & p {
    text-align: center;
    font-size: 20px;
    margin: 30px 0 20px 0;
    font-weight: 400;
    width: 80%;
    color: #FFFFFF;
    text-shadow: 1px 1px rgba(0,0,0,0.3);
  }

  & form {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 10px;
    
    & input {
      padding: 15px;
      font-size: 19px;
      width: 700px;
      border-radius: 6px;
      background: #fff;
      border: none;
      outline: none;
      box-shadow: 4px 4px rgba(0,0,0,0.3);
    }
  }

  & > div[id="searchOptions"] {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    color: ${({ theme }) => theme.colors.black};
    margin-top: 50px;

    & > div {
      padding: 12px;
      border-radius: 50px;
      background: ${({ theme }) => theme.colors.white};
      backdrop-filter: blur(5px);
      cursor: pointer;
      transition: all cubic-bezier(0.42, 0, 0, 1.57) 0.2s;

      &:hover {
        background: linear-gradient(to right top, #f32170, #cf23cf, #eedd44);
        color: #FFFFFF;
        transform: scale(0.9);
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.mobile.is915}) {
    & h1 {
      font-size: 40px;
    }

    & > form {
      & input {
        width: 500px;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.mobile.is690}) {
    & h1 {
      font-size: 30px;
    }

    & p {
      font-size: 17px;
    }

    & > form {
      width: 100%;
      
      & input {
        width: 100%;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.mobile.is400}) {
    & > div[id="searchOptions"] {

      & > div {
        padding: 10px;
        font-size: 15px;
      }
    }
  }
`;

export const Line = styled.div`
  height: 13px;
  width: 100%;
  background: linear-gradient(to left, #2B8AE2, #0ACF83);
  margin: auto;
`;