import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from './components/global/Global';
import { useThemeHook } from './components/theme';
import { AdminSignin, PassReset, Write } from './routes';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import useAuthContext from './hooks/auth/useAuthContext';
import useAdminAuthContext from './hooks/admin/auth/useAdminAuthContext';
import useCountries from './hooks/global/useCountries';
import Countries from './components/countries/Countries';
import usePopUpContext from './hooks/global/usePopUpContext';
import PopUp from './components/popup/PopUp';

import Post from './pages/overview/post/Post'
import Collection from './pages/overview/collection/Collection'
import Cpanel from './admin/cpanel/Cpanel';
import Home from './pages/home/Home';
import SignIn from './pages/signin/SignIn';
import Dashboard from './pages/dashboard/Dashboard';

import Documentation from './pages/documentation/Documentation';
import PrivacyPolicy from './pages/policies/PrivacyPolicy';
import ContentPolicy from './pages/policies/ContentPolicy';
import TermsAndCondition from './pages/policies/TermsAndCondition';
import CopyrightPolicy from './pages/policies/CopyrightPolicy';

import Blog from './pages/blog/Blog';
import SingleBlog from './pages/blog/SingleBlog';
import ImageGen from './pages/tools/imageGen/ImageGen';
import Upload from './pages/upload/Upload';
import Update from './pages/upload/Update';
import InPainting from './pages/tools/inPainting/InPainting';
import BgRemover from './pages/tools/bgRemover/BgRemover';
import UploadPreview from './components/popup/modules/upload/UploadPreview';
import Wallet from './pages/wallet/Wallet';
import Settings from './pages/settings/Settings';
import ImageToText from './pages/tools/imageToText/ImageToText';
import AiWriter from './pages/tools/aiWriter/AiWriter';
import ApiKey from './pages/apiKey/ApiKey';
import Chat from './components/chat/Chat';
import useConstructor from './hooks/constructor/useConstructor';
// import PhotoEnhancePro from './pages/tools/photoEnhancePro/PhotoEnhancePro';

const App = () => {
  const theme = useThemeHook();
  const { user } = useAuthContext();
  const { admin } = useAdminAuthContext();
  const { showCountries } = useCountries();
  const { type, items } = usePopUpContext();
  const { uploadPreviewDetails, uploadPreviewOpen } = useConstructor();

  return (
    <ThemeProvider theme={theme}>
    <GlobalStyle />


    <Router>
      { showCountries && <Countries /> }
      <PopUp type={type} items={items} />
      
      { uploadPreviewOpen && <UploadPreview details={uploadPreviewDetails} /> }

      { /* false && userId && userId.tester && */ <Chat /> }
      
      <Routes>
        <Route path="/*" element={ <Navigate to="/" /> } />
        <Route path="/" element={ <Home type="ALL" /> } />
        <Route path="/all" element={ <Home type="ALL" /> } />
        <Route path="/uploads" element={ <Home type="UPLOADS" /> } />
        <Route path="/collections" element={ <Home type="COLLECTIONS" /> } />
        <Route path="/users" element={ <Home type="USERS" /> } />

        <Route path="/:name/profile/:profileId/:display" element={ <Dashboard /> } /> {/* Later consider changing this name Dashboard to Profile */}
        <Route path="/sign-in" element={ !user ? <SignIn /> : <Navigate to="/Dashboard" /> } />
        <Route path="/upload" element={ user ? <Upload /> : <Navigate to="/sign-in" /> } />
        <Route path="/write" element={ user ? <Write /> : <Navigate to="/sign-in" /> } />
        <Route path="/edit/upload/:id" element={ user ? <Update /> : <Navigate to="/sign-in" /> } />
        <Route path="/Settings" element={ user ? <Settings /> : <Navigate to="/home" /> }  />
        <Route path="/wallet" element={ user ? <Wallet /> : <Navigate to="/home" /> }  />
        <Route path='/collection/:id' element={ <Collection /> } />
        <Route path='/api-key' element={ user ? <ApiKey /> : <Navigate to="/sign-in" /> } />

        <Route path='/post/:id' element={ <Post /> } />
        <Route path='/upload/:id' element={ <Post /> } />

        {/* Tools */}
        <Route path='/tools' element={< Home tools /> } />
        <Route path='/tools/image-gen' element={<ImageGen />} />
        <Route path='/tools/inpainting' element={<InPainting />} />
        <Route path='/tools/bg-remover' element={<BgRemover />} />
        <Route path='/tools/image-to-text' element={<ImageToText />} />
        <Route path='/tools/ai-writer' element={<AiWriter />} />
        <Route path='/tools/ai-writer/conversation/:id' element={<AiWriter />} />
        {/* <Route path='/tools/photo-enhance-pro' element={<PhotoEnhancePro />} /> */}
        
        {/* Polices and documentation */}
        <Route path="/documentation" element={ <Documentation /> } />
        <Route path="/content-policy" element={ <ContentPolicy /> } />
        <Route path="/privacy-policy" element={ <PrivacyPolicy /> } />
        <Route path="/terms-and-condition" element={ <TermsAndCondition /> } />
        <Route path="/copyright-policy" element={ <CopyrightPolicy /> } />

        {/* Password Reset */}
        <Route path="/user/:userId/verify/:token/reset/password" element={ <PassReset /> } />
        
        {/* Admin */}
        <Route path="/admin/signin" element={ !admin ? <AdminSignin /> : <Navigate to="/admin/cpanel/collections" /> } />
        <Route path="/admin/cpanel/:path" element={ admin ? <Cpanel /> : <Navigate to="/admin/signin" /> } />

        {/* Blog */}
        <Route path="/blog" element={ <Blog /> } />
        <Route path="/blog/post/:id" element={ <SingleBlog /> } />

      </Routes>
    </Router>
    </ThemeProvider>
  )
}

export default App;