import { AiOutlineLoading } from "react-icons/ai";
import { ProcessingStyle } from "./skeleton/styles/S_Processing";

const Processing = () => {
  return (
    <ProcessingStyle>
      <AiOutlineLoading />
    </ProcessingStyle>
  );
}
 
export default Processing;