import loadable from '@loadable/component';

export const Write = loadable(() => import('../pages/write/Write'));

// Password reset
export const PassReset = loadable(() => import('../pages/signin/PassReset'));

// Admin
export const AdminSignin = loadable(() => import('../admin/signin/Login'));

// Blog