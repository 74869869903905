import styled from 'styled-components';

export const CollectionsStyle = styled.div`
  & > div[id="collections"] {
    display: flex;
    flex-direction: column;
    gap: 30px;

    & > div {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 20px;
      background: ${({ theme }) => theme.colors.lightgrey}50;
      border-radius: 10px;

      & > div {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > div {
          display: flex;
          align-items: center;
          gap: 10px;
          padding: 10px;
          border-radius: 50px;
          cursor: pointer;
        }
      }
    }
  }
`;