import { useContext } from "react"
import { CountriesContext } from "../../contexts/global/CountriesContext";

const useCountries = () => {
  const context = useContext(CountriesContext);

  if(!context) throw Error("context used in the wrong file!")

  return context;
}

export default useCountries;