import { useState } from 'react';
import Button from '../../components/button/Button';
import { Container } from '../../components/global/Container';
import Topbar from '../../components/topbar/TopBar';
import useSignIn from '../../hooks/auth/useSignIn';
import { SignInStyle } from './styles/S_SignIn';
import { useTheme } from 'styled-components';
import axios from 'axios';
import useProxyContext from '../../hooks/global/useProxyContext';
import useProcesser from '../../hooks/processer/useProcesser';
import useErrHandler from '../../hooks/err/useErrHandler';
import { Helmet } from 'react-helmet-async';
import { FcGoogle } from 'react-icons/fc';
import useVisits from '../../hooks/stats/visits/useVisits';

const SignIn = () => {
  useVisits();

  const [signin, setSignin] = useState(true);
  const [login, setLogin] = useState(true);
  const [forgottenPass, setForgottenPass] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const theme = useTheme();

  const { endpoint } = useProxyContext();

  const { handleLogin, register } = useSignIn();

  const googleAuth = () => {
    window.open(
      `${endpoint}/auth/google`,
      "_self"
    )
  }

  return (
    <SignInStyle>
      <Helmet>
        <title>Model Grapix - Sign In</title>

        <meta name='description' content='Sign In to Model Grapix' />

        <meta property="og:title" content="Model Grapix - Sign In" />
        <meta property="og:description" content={`Sign In to Model Grapix`} />

        <link rel="icon" href='/images/logos/20230125_074855.png' />
      </Helmet>

      <Topbar />
      <Container>
        {signin && login && (
          <form onSubmit={(e) => {
            e.preventDefault();
          }}>
            <h2>Log In</h2>
            <input 
              autoFocus={true}
              type="email" 
              placeholder="Enter Email" 
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input 
              type="password" 
              placeholder="Enter Password" 
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <Button radius="10" run={() => handleLogin({ email, password })} bg={theme.colors.main}>Login</Button>
            
            <p onClick={() => {
              setSignin(false);
              setForgottenPass(true);
            }}><strong>Forgot password</strong></p>

            <p>or</p>
            <Button radius="10" run={googleAuth} shadow>
              <FcGoogle />
              <span>Sign in with Google</span>
            </Button>

            <p>Don't have an account? <strong onClick={() => setLogin(!login)}>Create Account</strong></p>
          </form>
        )}

        {signin && !login && 
          <Register login={login} setLogin={setLogin} register={register} theme={theme} />
        }

        {!signin && forgottenPass && (
          <ForgottenPassword setSignin={setSignin} theme={theme} setLogin={setLogin} />
        )}

      </Container>
    </SignInStyle>
  )
}

const Register = ({ login, setLogin, register, theme }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [userName, setUserName] = useState('');

  const { endpoint } = useProxyContext();

  const googleAuth = () => {
    window.open(
      `${endpoint}/auth/google`,
      "_self"
    )
  }

  return (
    <form onSubmit={(e) => {
      e.preventDefault();
      register({email, password, userName})
    }}>
      <h2>Create Account</h2>
      <input 
        autoFocus={true}
        type="text" 
        placeholder="Enter Username" 
        value={userName}
        onChange={(e) => setUserName(e.target.value)}
      />
      <input 
        type="text" 
        placeholder="Enter Email" 
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input 
        type="password" 
        placeholder="Enter Password" 
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <Button bg={theme.colors.main2} radius="10">Register</Button>

      <p>or</p>
      <Button shadow radius="10" run={googleAuth}>
        <FcGoogle />
        <span>Sign up with Google</span>
      </Button>

      <p>Aready have an account? <strong onClick={() => setLogin(!login)}>Log In</strong></p>
    </form>
  )
}

const ForgottenPassword = ({ setSignin, theme, setLogin }) => {
  const [email, setEmail] = useState('');

  const { endpoint } = useProxyContext();

  const { process } = useProcesser();

  const { notifier } = useErrHandler();

  const sendEmail = async () => {
    process('STARTED', 'Processing...');

    try {
      const res = await axios.post(`${endpoint}/auth/forgotten/pass`, { email })

      process('ENDED');
      notifier(false, res.data.message);

    } catch (err) {
      process('ENDED');
      notifier(true, err.response.data.message);
      console.log(err)
    }
  }

  return (
    <form onSubmit={(e) =>  {
      e.preventDefault()
      sendEmail()
    }}>
      <h2>Forgotten Password</h2>

      <input 
        type="text" 
        placeholder="Enter Email" 
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />

      <Button bg={theme.colors.main2} radius="10">Send Email</Button>
      <p><strong onClick={() => setSignin(true)}>Sign In</strong></p>
    </form>
  )
}

export default SignIn;