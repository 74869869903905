import useAdminAuthContext from '../../../../hooks/admin/auth/useAdminAuthContext';
import useProxyContext from '../../../../hooks/global/useProxyContext';
import axios from 'axios';
import { useState, useEffect } from 'react';
import useDate from '../../../../hooks/global/useDate';
import { VisitorsStyle } from './styles/S_Visitors';


const Visitors = () => {
  const [visitors, setVisitors] = useState(null);
  const [urlStats, setUrlStats] = useState(null);

  const { admin } = useAdminAuthContext();

  const { endpoint } = useProxyContext();

  const { getDate } = useDate();

   // FETCH ALL VISITORS
   useEffect(() => {
    if(admin) {
      (async function(){
        try {
  
          const res = await axios.get(`${endpoint}/visitors`, {
            headers: { 'Authorization': `Bearer ${admin.token}` }
          });
          
          setVisitors(res.data);
          
        } catch (err) {
          console.log(err);
        }
      }())
    }
  }, [admin, endpoint])

  const fetchUrlStats = async (url) => {
    try {
      const res = await axios.get(`${endpoint}/url-stats/`, {
        headers: {
          'Authorization': `Bearer ${admin.token}`,
          'page': `${url}`
        }
      })

      setUrlStats(res.data);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <VisitorsStyle>
      <h1>{visitors && visitors.length} Page visits</h1>

      <div>
        <div>
          {urlStats ? (
            <>
              <main>
                <small>{urlStats.page}</small>
              </main>

              <div><span>Today</span> {urlStats.today}</div>
              <div><span>Yesterday</span> {urlStats.yesterday}</div>
              <div><span>Last 30 days</span> {urlStats.last30Days}</div>
              <div><span>Total</span> {urlStats.total}</div>
              <div><span>... ...</span>... ...</div>
              <div><span>All Today</span> {urlStats.generalVisits}</div>

            </>
          ):(
            "Loading data ..."
          )}
        </div>

        <div>

          {visitors ? visitors.map((visit, i) => (
            <main key={i} onClick={() => fetchUrlStats(visit.page)}>
              <small>
                { visit.page }
              </small>
              <small> {getDate(visit.createdAt)}</small>
              <small> {visit.v_id}</small>
            </main>
          )):(
            "Loading urls ..."
          )}

        </div>
      </div>
    </VisitorsStyle>
  )
}

// const Item = ({ visitor }) => {

//   const { getDate } = useDate();
//   return (
//     <div>
//       <h4>{visitor.browser}</h4>
//       <small>{visitor.page}</small>
//       <h5>{visitor.v_id}</h5>
//       <small>{getDate(visitor.createdAt)}</small>
//     </div>
//   )
// }

export default Visitors;