import axios from "axios";
import { InPaintingCompStyle } from "../inPaintingComp/styles/S_InpaintingComp";
import { useState } from "react";
import usePopUpContext from "../../../hooks/global/usePopUpContext";
import useProxyContext from "../../../hooks/global/useProxyContext";
import useProcesser from "../../../hooks/processer/useProcesser";
import useErrHandler from "../../../hooks/err/useErrHandler";

const ImageToTextComp = () => {
  const [file, setFile] = useState(null);

  const { popUp } = usePopUpContext();

  const { endpoint } = useProxyContext();

  const { process, processing } = useProcesser();

  const { notifier } = useErrHandler();

  const removeBackground = async () => {
    if(processing) return;

    process('STARTED', 'Hold on tight, Reading image ...');

    try{

      const form = new FormData()
      form.append('image_file', file)

      const res = await axios.post(`${endpoint}/gen/ai/process/image-to-text`, form, { 
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      process('ENDED');

      notifier(false, 'DONE! Hope you like it 🙂')

      popUp('message', res.data.generated_text);

    } catch (error) {
      process('ENDED');
      console.log(error);
    }
  }

  return (
    <InPaintingCompStyle>
      <div>
        <h1>AI Image Captioning: Unveiling the Magic of Automatic Descriptions</h1>

        <div id="select">
          <input type="file" id="fileInput" onChange={(e) => {
            if(e.target.files[0]) {
              setFile(e.target.files[0])
            }
          }} />
          <label htmlFor="fileInput">Select Image file</label>
        </div>

        <div id="editor">
          <img
            src={file && URL.createObjectURL(file)}
            alt="Original"
            style={{
              opacity: file ? 1 : 0
            }}
          />
        </div>

        <button onClick={removeBackground} style={{
          opacity: file ? 1 : 0.5,
          bottom: processing ? '-100px' : '20px',
          pointerEvents: file ? 'visible' : 'none'
        }}>Read image</button>

      </div>
    </InPaintingCompStyle>
  )

}

export default ImageToTextComp;