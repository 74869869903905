import TopBar from "../../components/topbar/TopBar";
import Footer from "../../components/footer/Footer";
import { Container } from "../../components/global/Container";
import styled from 'styled-components';
import { Helmet } from "react-helmet-async";
import useVisits from "../../hooks/stats/visits/useVisits";

const ConetentPolicyStyle = styled.div`
  // HEADER
  & > div[id="header"] {
    padding: 50px 0;
    margin: 0 0 20px 0;
    text-align: center;
    background: linear-gradient(to bottom right, ${({ theme }) => theme.colors.main }10, ${({ theme }) => theme.colors.main2 }10);
    
    & > h2 {
      font-weight: 900;
      background: linear-gradient(to right, #f32170, #ff6b08, #cf23cf, #eedd44);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
    }
  }

  & > div:nth-child(3) {
    display: flex;
    flex-direction: column;
    gap: 20px;

    & > pre {
      font-family: 'Poppins', sans-serif;
      font-size: 19px;
      line-height: 2em;
      white-space: pre-wrap;
    }
  }
`;

const ContentPolicy = () => {
  useVisits();

  const policy = 
`
<h3>Model Grapix Content Policy:</h3>
Model Grapix is a community of creative professionals who believe in showcasing the best work possible. To ensure a safe and respectful environment, we have established the following content policy:

<strong>Respectful Communication:</strong> Model Grapix is a professional community that values constructive and respectful communication. We do not tolerate any content that contains offensive language, personal attacks, or discrimination against race, gender, religion, sexual orientation, or any other protected group.

<strong>Originality:</strong> We believe in the originality and authenticity of every member's work. Plagiarism, piracy, or any other form of intellectual property infringement is strictly prohibited. We encourage every member to be transparent about their creative process and give credit to any third-party sources used in their work.

<strong>Quality and Relevance:</strong> Model Grapix is a community of creative professionals who value high-quality work. We encourage members to share work that is relevant to their industry, showcases their skills, and represents their best work.

<strong>Appropriate Content:</strong> We do not allow any explicit, vulgar, or offensive content that may be inappropriate for a professional community. We do not permit content that promotes or encourages any illegal activity.

<strong>Self-promotion:</strong> We encourage self-promotion within the community, but we also expect members to promote their work in a respectful and non-spammy way. We do not tolerate any form of self-promotion that is unrelated or disruptive to the community's goals.

Compliance with the Law: We require every member to comply with all applicable laws and regulations regarding their work and content shared on Model Grapix.

<strong>Reporting Violations:</strong> We encourage all members to report any violations of this policy by contacting the Model Grapix support team. Any content that violates this policy may be removed, and the member may be removed from the community.

By joining Model Grapix, you agree to abide by this content policy. We reserve the right to update and modify this policy at any time.

Last updated: <strong>27th, Feb. 2023</strong>

`;

  return (
    <ConetentPolicyStyle>
      <Helmet>
        <title>Model Grapix - Content Policy</title>

        <meta name="description" content={policy} />

        <meta property="og:title" content="Model Grapix - Content Policy" />
        <meta property="og:description" content={policy} />

        <link rel="icon" href='/images/logos/20230125_074855.png' />
      </Helmet>

      <TopBar />

      <div id="header">
        <h2>Content Policy</h2>
        <p>Defining the Standards for Acceptable Content: Our Approch to Content Policy</p>
      </div>

      <Container>
        <pre dangerouslySetInnerHTML={{ __html: policy }}></pre>
      </Container>

      <Footer />

    </ConetentPolicyStyle>

  )
}

export default ContentPolicy;