import axios from 'axios';
import useProxyContext from "../global/useProxyContext";
import useAuthContext from "../auth/useAuthContext";
import useErrHandler from '../err/useErrHandler';
import { useState } from 'react';
import useRefreshContext from '../global/useRefreshContext';

const useImgHandler = () => {
  const [resolution, setResolution] = useState('');

  const [allItems, setAllItems] = useState('');
  const [approvedItems, setApprovedItems] = useState('');
  
  const { reRender } = useRefreshContext();

  const { endpoint } = useProxyContext();
  const { user } = useAuthContext();
  const { notifier } = useErrHandler();

  // function to add image to stared in users profile
  const addToStared = async (id, src, type) => {
    if(user) {
      try {
        const res = await axios.put(`${endpoint}/stared`, {
          imageId: id,
          src: src,
          type: type
        }, {
          headers: {
            "Authorization": `Bearer ${user.token}`
          }
        })
  
        notifier(false, res.data.message);
        reRender();
  
      } catch (err) {
        const errMessage = err.response.data.message;
        notifier(true, errMessage);
      }
    } else {
      notifier(false, "You have to login first!")
    }
  }
  
  // function to get Image resolution values
  const getResolution = (overview) => {
    if(overview && overview.src) {
      const url = endpoint + "/images/" + overview.src;
      const image = new Image();
      image.src = url;
      
      setResolution(`H${image.naturalHeight} x W${image.naturalWidth}`);
    }
  }
  
  // function to remove item from collections
  const removeItemFrColl = async (imageId, collectionName) => {
    try {
      const res = await axios.put(`${endpoint}/rm/item/fr/coll`, {
        imageId,
        collectionName
      }, {
        headers: {
          "Authorization": `Bearer ${user.token}`
        }
      })
  
      notifier(false, res.data.message);
      reRender();
  
    } catch (err) {
      const errMessage = err.response.data.message;
      notifier(true, errMessage);
    }
    
  }

  // function to toggle item from public to private
  const toggleItemStatus = async (imageId, status) => {
    try {
      const res = await axios.put(`${endpoint}/toggle/item/status`, {
        imageId,
        status
      }, {
        headers: {
          "Authorization": `Bearer ${user.token}`
        }
      })
  
      notifier(false, res.data.message);
      reRender();
  
    } catch (err) {
      const errMessage = err.response.data.message;
      notifier(true, errMessage);
    }
  }

  // function to toggle item from public to private
  const toggleCollectionStatus = async (collectionId, status) => {
    try {
      const res = await axios.put(`${endpoint}/toggle/collection/status`, {
        collectionId,
        status
      }, { headers: {"Authorization": `Bearer ${user.token}`} })
  
      notifier(false, res.data.message);
      reRender();
  
    } catch (err) {
      const errMessage = err.response.data.message;
      notifier(true, errMessage);
    }
    
  }

  const reachRoute = async (callback, prop, route) => {
    if(!user) return notifier(false, "Please Sign In to continue!");

    try {
      const res = await axios.put(`${endpoint}${route}`, {
        prop
      }, { headers: { 'Authorization': `Bearer ${user.token}` }});

      callback(res.data);

    } catch (err) { 
      const errMessage = err.response.data.message;

      notifier(true, errMessage);
    }
  }

  const postToRoute = async (callback, prop, route) => {
    if(!user) return notifier(false, "Please Sign In to continue!");

    try {
      const res = await axios.post(`${endpoint}${route}`, {
        prop
      }, { headers: { 'Authorization': `Bearer ${user.token}` }});

      callback(res.data);

    } catch (err) { 
      const errMessage = err.response.data.message;

      notifier(true, errMessage);
    }
  }

  const getRoute = async (callback, route, signal) => {
    try {
      const res = await axios.get(`${endpoint}${route}`, { signal: signal });
      
      callback(res.data)
      
    } catch (err) {
      // handle error
    }
  }

  // length of item in a collection
  const getLength = async (collectionId) => {
    try {
      const res = await axios.get(`${endpoint}/get/length/${collectionId}`);
      setAllItems(res.data.allItems);
      setApprovedItems(res.data.approvedItems);
    } catch (err) {
      console.log(err);
    }
  }

  return { 
    approvedItems, 
    allItems, 
    addToStared, 
    getResolution, 
    resolution, 
    removeItemFrColl, 
    toggleItemStatus, 
    toggleCollectionStatus, 
    getLength,
    getRoute, // get to [route] then call [callback] with data reusable across multiple get requests
    reachRoute, // reach to [route] in item with  [_id] then call [callback] reusable across multiple put requests
    postToRoute // post to [route] in item with [prop] and [route] then call [callback] reusable across multiple put requests
  };
}

export default useImgHandler;