import styled from 'styled-components';

export const PostsStyle = styled.div`
  padding: 100px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;

  @media(max-width: ${({ theme }) => theme.mobile.is1150}) {
    padding: 100px 0;
  }
  
  @media(max-width: ${({ theme }) => theme.mobile.is950}) {
    grid-template-columns: repeat(2, 1fr);
    gap: 20;
  }

  @media(max-width: ${({ theme }) => theme.mobile.is580}) {
    grid-template-columns: repeat(1, 1fr);
    padding: 50px 0;
  }
  
  & > main {
    padding: 20px;
    border-radius: 10px;
    background: ${({ theme }) => theme.colors.white};
    box-shadow: 0 2px 2px rgba(0,0,0,0.3);
    display: flex;
    flex-direction: column;

    & > div {
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    & > div[id="user"] {
      display: flex;
      justify-content: space-between;
      align-items: center;
      
      & > div {
        display: flex;
        align-items: center;
        margin-right: 5px;
        
        & img {
          min-width: 30px;
          max-width: 30px;
          height: 30px;
          object-fit: cover;
          border-radius: 50%;
          margin-right: 5px;
        }

        & > div {
          display: flex;
          flex-direction: column;
          gap: 0;

          & h4 {
            font-size: 15px;
          }
          
          & small {
            font-size: 10px;
          }
        }
      }

      & > svg {
        padding: 5px;
        font-size: 30px;
        border-radius: 50%;
        box-shadow: 0px 0px 2px rgba(0,0,0,0.3);
        cursor: pointer;
      }
    }

    & > img {
      height: 150px;
      width: 100%;
      border-radius: 10px;
      object-fill: cover;
    }

    & > div[id="seen"] {
      display: flex;
      align-items: center;
      margin-top: 10px;
      
      & > span {
        display: flex;
        align-items: center;

        & > svg {
          margin-right: 5px;
          color: ${({ theme }) => theme.colors.black}AA;
        }
      }

      & > div {
        height: 3px;
        width: 3px;
        border-radius: 50px;
        background: #444;
        margin: -3px 10px 0 10px;
      }
    }

    & > div[id="postInfo"] {
      display: flex;
      flex-direction: column;
      
      & h1 {
        font-size: 19px;
        cursor: pointer;
        margin-bottom: 10px;
      }

      & > article {
        color: ${({ theme }) => theme.colors.black}AA;
      }
    }
  }
`;

export const TagsStyle = styled.div`
  display: flex;
  flex-flow: wrap;

  & span[id="tag"] {
    padding: 5px 10px;
    margin-right: 5px;
    margin-bottom: 5px;
    font-size: 15px;
    border-radius: 50px;
    border: 1px solid ${({ theme }) => theme.colors.main2};
    color: ${({ theme }) => theme.colors.black}AA;
  }
`;