import styled, { css } from 'styled-components';

export const ChatStyle = styled.div`
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 400px;
  height: 70vh;
  border-radius: 20px;
  background: ${({ theme }) => theme.colors.theme};
  box-shadow: 2px 2px 20px rgba(0,0,0,0.3);
  z-index: 1000;
  transition: 0.3s cubic-bezier(0.14, 1.28, 0.94, 1.53);
  overflow: hidden;
  opacity: 1;
  pointer-events: visible;

  &.changePosition {
    right: -200px;
  }

  ${({ expandChat }) => !expandChat && css`
    opacity: 0;
    pointer-events: none;
    height: 50px;
    width: 50px;
  `}

  @media(max-width: ${({ theme }) => theme.mobile.is800}) {
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
    
    ${({ expandChat }) => !expandChat && css`
      height: 50px;
      width: 50px;
      border-radius: 50px;
      right: 10px;
      bottom: 10px;
    `}
  }

  & pre {
    white-space: pre-wrap;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    pointer-events: none;
  }
  
  & > button {
    position: absolute;
    top: 0;
    left: 0;
    height: 50px;
    width: 50px;
    color: #FFFFFF;
    background: transparent;
    z-index: 1;
  }

  & > div[id="inforBar"] {
    position: sticky;
    top: 0;
    left: 0;
    height: 50px;
    width: 100%;
    background: ${({ theme }) => theme.colors.main2};
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    & > div[id="details"] {
      display: flex;
      align-items: center;
      grid-gap: 10px;
      padding: 0 50px;
      height: 100%;
      overflow: hidden;

      & > img {
        min-width: 30px;
        max-width: 30px;
        min-height: 30px;
        max-height: 30px;
        border-radius: 30px;
      }

      & > span {
        display: flex;
        flex-direction: column;

        & > h3 {
          position: 
          font-size: 15px;
          color: #FFFFFF;
          white-space: nowrap;
        }

        & > small {
          margin-top: -3px;
          font-size: 10px;
          color: #FFFFFF;
        }
      }
    }

    & > div[id="mode"] {
      height: 100%;
      display: grid;
      place-items: center;
      padding-left: 10px;

      & > span {
        margin-right: 20px;
        font-size: 20px;
        color: #FFFFFF;
        cursor: pointer;
      }
    }
  }

  & > div[id="inboxes"] {
    position: absolute;
    left: 0;
    top: 50px;
    width: 100%;
    height: calc(100% - 50px);
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    padding: 20px;
    background: ${({ theme }) => theme.colors.theme};
    overflow-y: auto;


    // chat profiles, username and last message snippet
    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      & > div[id="user"] {
        position: relative;
        left: -20px;
        padding-left: 20px;
        display: flex;
        grid-gap: 10px;
        align-items: center;

        width: 70%;
        overflow: hidden;

        & > img {
          min-width: 50px;
          max-width: 50px;
          max-height: 50px;
          border-radius: 50%;
        }

        & > div[id="onlineStatus"] {
          position: absolute;
          left: -5px;
          height: 50px;
          width: 10px;
          border-radius: 10px;
        }

        & > div {
          & > h3 {
            white-space: nowrap;
          }

          & > small {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block; 
          }
        }
      }

      // Time and read status
      & > div[id="status"] {
        position: relative;
        display: flex;
        justify-content: flex-end;
        width: 30%;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: -49px;
          bottom: 0;
          width: 50px;
          background: linear-gradient(to right, #FFFFFF00, ${ ({ theme }) => theme.colors.theme }, ${ ({ theme }) => theme.colors.theme });
        }

        & > span {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          & > span[id="time"] {
            white-space: nowrap;
            color: ${({ theme }) => theme.colors.black};
          }

          & > span[id="read"] {
            height: 25px;
            width: 25px;
            border-radius: 50%;
            background: ${({ theme }) => theme.colors.main2}20;
            color: ${({ theme }) => theme.colors.black}AA;
            display: grid;
            place-items: center;
          }
          
          & > span[id="seenByAllMembers"] {
            height: 20px;
            width: 20px;

            & > img {
              height: 20px;
              width: 20px;
              border-radius: 50%;
            }
          }

          & > div {
            height: 25px;
            width: 25px;
            border-radius: 50%;
            background: ${({ theme }) => theme.colors.main2};
            color: #FFFFFF;
            display: grid;
            place-items: center;
          }
        }
      }
    }
  }

  & div[id="messages"] {
    position: absolute;
    left: 0;
    top: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 50px);
    background: ${({ theme }) => theme.colors.theme};
    overflow-y: auto;
    z-index: ${({ openMessage }) => openMessage ? "1" : "-1"};
  
    &::-webkit-scrollbar { display: none }
  }

  & div[id="message"] {

    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    padding: 20px;

    & > div[id="date"] {
      position: sticky;
      top: 10px;

      display: flex;
      justify-content: center;
      
      & > span {
        font-size: 15px;
        padding: 5px;
        border-radius: 50px;
        background: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.black}AA;
        box-shadow: 0px 5px 5px rgba(0,0,0,0.1);
      }
    }
  }

  & div[id="input"] {
    position: sticky;
    bottom: 0px;
    left: 0;
    width: 100%;
    padding: 0 20px;
    background: ${({ theme }) => theme.colors.theme};
    
    & > div[id="replyRef"] {
      padding: 10px;
      border-radius: 10px;
      background: ${({ theme }) => theme.colors.black}10;
      margin-bottom: 5px;
      cursor: pointer;
      max-height: 7.2em; /* Adjust as needed */
      overflow: hidden;
      
      & > pre { 
        font-size: 13px 
      }
    }

    & > span {
      display: grid;
      place-items: center;
      cursor: pointer;
      border-radius: 50px;
      background: ${({ theme }) => theme.colors.main2};
      color: #FFFFFF;
    }

    & > span[id="toggleExpand"] {
      position: absolute;
      bottom: 5px;
      left: 15px;
      width: 20px;
      height: 20px;
      font-size: 10px;
    }

    & > textarea {
      box-sizing: border-box;
      resize: none;
      border-radius: 10px;
      width: 100%;
      padding: 15px 30px 15px 15px ;
      font-size: 17px;
      font-family: 'Poppins', sans-serif;

      &::-webkit-scrollbar { display: none }
    }

    & > span[id="sendBtn"] {
      position: absolute;
      right: 30px;
      bottom: 20px;
      height: 30px;
      width: 30px;
    }
  }
`;

export const TextStyle = styled.div`

  & > div {
    display: flex;

    & > div {
      width: 100%;
      
      & > div {
        display: flex;
        flex-direction: column;
        max-width: 80%;

        & > pre {
          background: ${({ theme }) => theme.colors.main}10;
          padding: 15px;
          border-radius: 30px;
          margin-bottom: 10px;
        }

        & > div {
          padding: 15px;
          border-radius: 30px;
        }
      }
    }
  }

  & > div[id="justifyEnd"] {
    justify-content: flex-end;
    
    & > div {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      
      & > div {
        justify-content: flex-end;
        align-items: flex-end;
      }
      
      & > small {
        text-align: right;
        color: ${({ theme }) => theme.colors.black}50;
        margin-right: 10px;
      }
    }
  }

  & > div[id="justifyStart"] {

    & > div {
      & > div {
        align-items: flex-start;
      }

      & > small {
        color: ${({ theme }) => theme.colors.black}50;
        margin-left: 10px;
      }
    }
  }

  & div[id="backgroundMain"] {
    background: ${({ theme }) => theme.colors.main2};
    
    & > pre { color: #FFFFFF }
  }

  & div[id="backgroundLight"] {
    background: ${({ theme }) => theme.colors.black}20;
  }

  & > div > div > div {
      
    ${({ messageType }) => messageType === 'ADMIN' && css`
      min-width: 100%;
      
      & > div[id="backgroundLight"] {
        min-width: 100%;
        background: transparent; 
        padding: 0;

        & > pre {
          font-size: 12px;
          text-align: center;
          color: ${({ theme }) => theme.colors.black}90;
        }
      }
    `}
  }
`;

export const OptionsMenuStyle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000050;
  padding: 20px;
  display: grid;
  place-items: center;
  z-index: 1001;
  
  & > div {
    height: 70%;
    width: 70%;
    overflow: auto;
    border-radius: 10px;
    display: flex;
    align-items: center;

    &::-webkit-scrollbar { display: none }

    & > div {
      width: 100%;
      background: #FFFFFF;
      border-radius: 10px;
      display: flex;
      grid-gap: 5px;
      flex-direction: column;
      padding: 10px;
      
      & > span {
        display: flex;
        align-items: center;
        gap: 30px;
        padding: 10px;
        border-radius: 10px;
        background: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.black};
        
        &:hover {
          background: 00000010;
          box-shadow: 2px 2px 25px rgba(0,0,0,0.3);
        }
      }
    }
  }
`;

export const SignInStyle = styled.div`
    display: flex;
    grid-gap: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80%;
    padding: 20px;

    & > button {
      width: 100%;
    }
`;