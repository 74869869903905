import styled, { css } from "styled-components";

export const NavStyle = styled.div`
  min-width: 300px;
  position: sticky;
  top: 100px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-right: 30px;

  max-height: 80vh;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    background: ${({ theme }) => theme.colors.main2}10;
    border-radius: 50px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.black}50;
    border-radius: 50px;
  }

  @media(max-width: ${({ theme }) => theme.mobile.is1070}) {
    position: fixed;
    left: 0;
    top: 70px;
    bottom: 0;
    min-height: 100vh;
    background: ${({ theme }) => theme.colors.white};
    padding-left: 40px;
    display: none;
  }

  & > div {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    
    & > div:nth-child(1) {
      ${({ expandUseOfPlatform }) => expandUseOfPlatform && css`
        height: auto;
      `}
    }
    
    & > div:nth-child(2) {
      ${({ expandUseOfApi }) => expandUseOfApi && css`
        height: auto;
      `}
    }
    
    & > div {
      height: 50px;
      overflow: hidden;
      border-radius: 5px;

      & > h3 {
        height: 50px;
        display: flex;
        align-items: center;
        cursor: pointer;

        & > svg {
          margin-right: 10px;
          margin-left: -9px;
          color: ${({ theme }) => theme.colors.main2}50;
        }
      }
    }

    // NAV
    & > div > div {
      padding-left: 20px;
      border-left: 2px solid ${({ theme }) => theme.colors.main2}50;

      &::-webkit-scrollbar {
        background: ${({ theme }) => theme.colors.main2}10;
        border-radius: 50px;
      }
      
      &::-webkit-scrollbar-thumb {
        background: ${({ theme }) => theme.colors.blue}50;
        border-radius: 50px;
      }

      & > a {
        text-decoration: none;
        display: flex;
        flex-direction: column;
        gap: 5px;

        & > div {
          padding-left: 10px;
          border-left: 2px solid ${({ theme }) => theme.colors.main2}50;
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
      }
    }
  }
`;