import { PostsStyle } from './S_Posts';
import { AiOutlineEye, AiOutlineStar } from 'react-icons/ai';
import { BiCheckDouble } from 'react-icons/bi';
import useProxyContext from '../../../../hooks/global/useProxyContext';
import { Link, useNavigate } from 'react-router-dom';
import useAuthContext from '../../../../hooks/auth/useAuthContext';
import useErrHandler from '../../../../hooks/err/useErrHandler';
import axios from 'axios';
import useDate from '../../../../hooks/global/useDate';
import { Tags } from '../single/Single';

const Posts = ({ posts }) => {
  return (
    <PostsStyle>
      {posts && posts.filter(post => post.approved === true).map((post, i) => (
        <Post key={i} post={post} />
      ))}
    </PostsStyle>
  )
}

const Post = ({ post }) => {

  const { endpoint, imageEndpoint } = useProxyContext();

  const { user } = useAuthContext();

  const { notifier } = useErrHandler();

  const { getDate } = useDate();

  const navigate = useNavigate();

  const addToStared = async (postId, postTitle, postFeaturedImageName) => {
      if(!user) return notifier(true, 'Please login first!');

      try {
        const res = await axios.put(`${endpoint}/add/to/stared`, {
          postId,
          postTitle,
          postFeaturedImageName
        },{ 
        headers: {
          'Authorization': `Bearer ${user.token}`
        }})

        console.log(res.data.message);
        notifier(false, res.data.message);

      } catch (err) {
        notifier(true, err.response.data.message);
      }
  }
  
  return (
    <main>

      {/* user */}
      <div id="user">
        <div>
          <img src="https://res.cloudinary.com/drrd1lwey/image/upload/v1675023065/model-grapix/images/profile-1677324483883-images-2023-02-23T193622401jpeg" alt="Goodluck Chiemeria Profile" />
          
          <div>
            <h4>Goodluck</h4>
            <small>{getDate(post.createdAt)}</small>
          </div>
        </div>

        <AiOutlineStar onClick={() => addToStared( post._id, post.postTitle, post.postFeaturedImg )} />
      </div>
      
      {post.postFeaturedImg ? ( 
        <img src={`${imageEndpoint}/images/${post.postFeaturedImg}`} alt="" />
      ):(
        <img src="/images/background.jpg" alt="" />
      )}

      <div id="seen">
        <span title='Seen' >
          <AiOutlineEye />
          <small>{post.seen}</small>
        </span>

        <div></div>

        <span title='Read'>
          <BiCheckDouble />
          <small>{post.read}</small>
        </span>
      </div>

      {/* Post Info */}
      <div id="postInfo">
        <h1 onClick={() => navigate(`/blog/post/${post._id}`)}>{ post.postTitle }</h1>
        {/* <article> */}
        {
             ( post.postBody[0] && ( post.postBody[0].htmlTag === 'P' || post.postBody[0].htmlTag === 'PRE' ) && <Paragraph content={post.postBody[0].text.slice(0, 100)} /> ) 
          || ( post.postBody[1] && ( post.postBody[1].htmlTag === 'P' || post.postBody[1].htmlTag === 'PRE' ) && <Paragraph content={post.postBody[1].text.slice(0, 100)} /> ) 
          || ( post.postBody[2] && ( post.postBody[2].htmlTag === 'P' || post.postBody[2].htmlTag === 'PRE' ) && <Paragraph content={post.postBody[2].text.slice(0, 100)} /> )
          || ( post.postBody[3] && ( post.postBody[3].htmlTag === 'P' || post.postBody[3].htmlTag === 'PRE' ) && <Paragraph content={post.postBody[3].text.slice(0, 100)} /> )
        } ... <Link to={`/blog/post/${post._id}`}>Read More</Link>
        {/* </article> */}
      </div>
      
      <Tags tags={post.tags.slice(0, 3)} />
    </main>
  )
}

const Paragraph = ({ content }) => {

  // Replace code blocks with <pre><code> tags
  const contentWithCode = content.replace(
    /```(.+?)```/gs,
    '<code>$1</code>'
  );

  // Replace URLs with <a> tags
  const contentWithLinks = contentWithCode.replace(
    /https?:\/\/([^\s]+)/g,
    '<a href="https://$1">$1</a>'
  );

  // Replace *text* with <strong> tags
  const contentWithStrong = contentWithLinks.replace(
    /\*\*(.+?)\*\*/g,
    '<strong>$1</strong>'
  );

  // Replace _text_ with <em> tags
  const contentWithEm = contentWithStrong.replace(
    /(^|[^_])_([^_]+?)_(?!_)/g,
    '$1<em>$2</em>'
  );

  // Wrap content in a <p> tag
  const contentWithP = `<p>${contentWithEm}</p>`;

  return <article dangerouslySetInnerHTML={{ __html: contentWithP }} ></article>
  
}

export default Posts;