import { SettingsStyle } from './styles/S_Settings';
import { Container } from '../../components/global/Container';
import TopBar from '../../components/topbar/TopBar';
import { FaAngleRight, FaUserEdit } from "react-icons/fa";
import { MdDarkMode, MdLanguage, MdToggleOff, MdToggleOn } from "react-icons/md";
import { TbBellRinging } from "react-icons/tb";
import Footer from '../../components/footer/Footer';
import { AiFillDelete } from 'react-icons/ai';
import { CgDetailsMore } from 'react-icons/cg';
import { FiLogOut } from 'react-icons/fi';
import usePopUpContext from '../../hooks/global/usePopUpContext';
import useThemeContext from '../../hooks/useThemeContext';
import useSignIn from '../../hooks/auth/useSignIn';
import useAccess from '../../hooks/access/useAccess';
import useProxyContext from '../../hooks/global/useProxyContext';
import Button from '../../components/button/Button';
import { useTheme } from 'styled-components';
import { Helmet } from 'react-helmet-async';
import useProfile from '../../hooks/profile/useProfile';
import useVisits from '../../hooks/stats/visits/useVisits';
import { useNavigate } from 'react-router-dom';

const Settings = () => {
  useVisits();

  const { popUp } = usePopUpContext();

  const { darkTheme, changeTheme } = useThemeContext();

  const { logout } = useSignIn();

  const { userId } = useAccess();

  const { imageEndpoint } = useProxyContext();

  const { profileSetup } = useProfile();

  const navigate = useNavigate();

  const degree = darkTheme ? "rotate(180deg)" : "rotate(0deg)";

  const theme = useTheme();

  return (
    <SettingsStyle>
      <Helmet>
        <title>Account settings</title>

        <link rel="icon" href='/images/logos/20230125_074855.png' />
      </Helmet>

      <TopBar />
      <Container>
        <div id="profile">
          { userId && <>
            {userId.profilePic ? <img src={ imageEndpoint+ '/images/' + userId.profilePic } alt=""/> : <img src="/images/profile.jpg" alt=""/>}
            <h3>{userId.userName}</h3>
            <small>User</small> 
          </>}
        </div>

        <div id="btn">
          <Button run={ async () => {
            await profileSetup(() => {
              popUp('payment')
            });
          }} bg={theme.colors.gold} >
            Go Pro
          </Button>
        </div>

        <div id="options">
          <h4>Personal Information</h4>
          <div>
            <div onClick={() => popUp("setup", "all")}>
              <div>
                <FaUserEdit />
                <p>Profile Setup</p>
              </div>
              
              <FaAngleRight />
            </div>

            <div onClick={() => popUp("advanced")}>
              <div>
                <CgDetailsMore />
                <p>Advanced</p>
              </div>
              
              <FaAngleRight />
            </div>
          </div>

          <h4>API</h4>
          <div>
            <div onClick={() => navigate("/api-key")}>
              <div>
                <FaUserEdit />
                <p>Get API Key</p>
              </div>
              
              <FaAngleRight />
            </div>

            <div onClick={() => navigate("/documentation#apiIntroduction")}>
              <div>
                <CgDetailsMore />
                <p>API Documentation</p>
              </div>
              
              <FaAngleRight />
            </div>
          </div>

          <h4>Preferences Settings</h4>
          <div>
            <div>
              <div>
                <MdLanguage />
                <p>Language</p>
              </div>

              <div>
                <p>English</p>
                <FaAngleRight />
              </div>
            </div>

            <div onClick={() => changeTheme()}>
              <div>
                <MdDarkMode />
                <p>Dark Mode </p>
              </div>

              <MdToggleOff id="toggle" style={{
                transform: degree,
                transition: "all ease-in-out 0.2s"
              }} />
            </div>

            <div>
              <div>
                <TbBellRinging />
                <p>Recieve Notifications</p>
              </div>

              <MdToggleOn id="toggle" />
            </div>
          </div>

          <h4>Sign Out</h4>
          <div>
            <div onClick={logout}>
              <div>
                <FiLogOut />
                <p>Log Out</p>
              </div>
              
              <FaAngleRight />
            </div>

            <div id='deleteAcc'>
              <div>
                <AiFillDelete />
                <p>Delete Account</p>
              </div>
              
              <FaAngleRight />
            </div>
          </div>
        </div>

      </Container>
      <Footer />
    </SettingsStyle>
  )
}

export default Settings;