import styled from 'styled-components';
import { RiErrorWarningFill } from 'react-icons/ri';
import { IoMdCheckmarkCircleOutline, IoMdClose } from 'react-icons/io';
import { Container } from '../global/Container';
import useErrHandler from '../../hooks/err/useErrHandler';
import { useEffect } from 'react';

const AlerterWrapper = styled.div`
  position: relative;
  overflow: hidden;
  pointer-events: none;

  opacity: 0;
  transform: translateY(5%);

  &.fade-in { opacity: 0; animation: fadeInFromBottom 0.5s ease-in forwards; }
  @keyframes fadeInFromBottom { from { opacity: 0; transform: translateY(5%); } to { opacity: 1; transform: translateY(0); } }
  
  z-index: 700;

  & > div {
    pointer-events: visible;
    background: ${({ theme, error }) => error ? "#FF000F": theme.colors.main2}EE;
    position: sticky;
    float: right;
    margin-right: 20px;
    margin-top: 10px;
    width: 400px;
    border-radius: 10px;
    padding: 20px;
    color: #FFFFFF;
    text-shadow: 1px 1px 0px #000000;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: ${({ theme }) => theme.mobile.is500}) {
      width: calc(100% - 40px);
      
    }
    
    & > div {
      display: flex;
      align-items: center;
      gap: 20px;

      padding-right: 50px;
    }

    & svg {
      font-size: 20px;
      min-width: 30px;
      cursor: pointer;
    }
  }
`;

const Alerter = ({ abs, error, message }) => {
  const { removeError } = useErrHandler();

  useEffect(() => {
    const alerter = document.querySelector('.alerter');

    if(message) {
      alerter.classList.add('fade-in');
    }
  }, [message])

  return (
    <AlerterWrapper className="alerter" abs={abs} error={error} style={{ height: message ? "auto" : "0"}}>
      <Container>
          <div>
            { error ? <RiErrorWarningFill /> : <IoMdCheckmarkCircleOutline /> }
            { message } 
          </div>

          <IoMdClose onClick={removeError} />
      </Container>
    </AlerterWrapper>
  )
}

export default Alerter;