import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  max-width: 1300px;
  padding: 0 40px;
  margin: auto;

  & img{
    max-width: 100%;
  }

  @media (max-width: ${({ theme }) => theme.mobile.is760}) {
    padding: 0 20px;
  }
`