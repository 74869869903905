import axios from "axios";
import { InPaintingCompStyle } from "./styles/S_InpaintingComp";
import { useRef, useState, useEffect } from "react";
import usePopUpContext from "../../../hooks/global/usePopUpContext";
import useProxyContext from "../../../hooks/global/useProxyContext";
import useProcesser from "../../../hooks/processer/useProcesser";
import { FaAngleLeft, FaPen } from 'react-icons/fa';
import { BsEraserFill } from "react-icons/bs";
import useErrHandler from "../../../hooks/err/useErrHandler";

const InPaintingComp = () => {
  const containerRef = useRef(null);
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [start, setStart] = useState({ x: 0, y: 0 });

  const [penWeight, setPenWeight] = useState(30);

  const [penMode, setPenMode] = useState(true);

  const [expandResults, setExpandResults] = useState(false);

  const [edited, setEdited] = useState(false);
  
  const [file, setFile] = useState(null);

  const { popUp, show } = usePopUpContext();

  const { endpoint } = useProxyContext();

  const { process, processing } = useProcesser();

  const { notifier } = useErrHandler();

  useEffect(() => {
    if(!file) return;

    const originalImage = new Image();
    originalImage.src = URL.createObjectURL(file);
    originalImage.onload = () => {
      const aspectRatio = originalImage.width / originalImage.height;
      const containerWidth = containerRef.current.offsetWidth;

      canvasRef.current.width = containerWidth;
      canvasRef.current.height = containerWidth / aspectRatio;

      const ctx = canvasRef.current.getContext('2d');
      ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      ctx.fillStyle = 'black';
      ctx.fillRect(0, 0, canvasRef.current.width, canvasRef.current.height);

      // setCtx(ctx);
    };
  }, [file]);

  // handle start for drawing begins here
  const handleStart = (x, y) => {
    setIsDrawing(true);
    setStart({ x, y });
  };
  
  const handleMove = (x, y) => {
    if (!isDrawing) return;

    setEdited(true);
    console.log('edited');
  
    const ctx = canvasRef.current.getContext('2d');
    ctx.lineCap = 'round';
    ctx.lineJoin = 'round';
    ctx.lineWidth = penWeight;

    if (penMode) {
      // Drawing mode
      ctx.strokeStyle = 'white';
    } else {
      // Erasing mode
      ctx.strokeStyle = 'black';
    }

    ctx.beginPath();
    ctx.moveTo(start.x, start.y);
    ctx.lineTo(x, y);
    ctx.stroke();
  
    setStart({ x, y });
  };
  
  const handleEnd = () => {
    setIsDrawing(false);
  };
  
  // For desktop (mouse events)
  const handleMouseDown = (e) => {
    const x = e.clientX - canvasRef.current.getBoundingClientRect().left;
    const y = e.clientY - canvasRef.current.getBoundingClientRect().top;
    handleStart(x, y);
  };
  
  const handleMouseMove = (e) => {
    const x = e.clientX - canvasRef.current.getBoundingClientRect().left;
    const y = e.clientY - canvasRef.current.getBoundingClientRect().top;
    handleMove(x, y);
  };
  
  const handleMouseUp = () => {
    handleEnd();
  };
  
  const handleMouseOut = () => {
    handleEnd();
  };
  
  // For smartphones (touch events)
  const handleTouchStart = (e) => {
    e.preventDefault();
    const x = e.touches[0].clientX - canvasRef.current.getBoundingClientRect().left;
    const y = e.touches[0].clientY - canvasRef.current.getBoundingClientRect().top;
    handleStart(x, y);
  };
  
  const handleTouchMove = (e) => {
    e.preventDefault();
    const x = e.touches[0].clientX - canvasRef.current.getBoundingClientRect().left;
    const y = e.touches[0].clientY - canvasRef.current.getBoundingClientRect().top;
    handleMove(x, y);
  };
  
  const handleTouchEnd = () => {
    handleEnd();
  };
  
  const handleTouchCancel = () => {
    handleEnd();
  };

  // handle start for drawing ends here

  // Function to convert data URL to Blob
  const dataURLToBlob = (dataURL) => {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  const handleRender = async () => {
    if(processing) return;

    process('STARTED', 'Hold on tight, Processing ...');

    const originalImage = new Image();
    originalImage.src = URL.createObjectURL(file);
    originalImage.onload = async () => {

      if (!originalImage) {
        return;
      }
    
      // Create a new canvas with the same resolution as the original image
      const renderCanvas = document.createElement('canvas');
      renderCanvas.width = originalImage.width;
      renderCanvas.height = originalImage.height;

      const renderCtx = renderCanvas.getContext('2d');

      // Draw the content of the drawing canvas onto the new canvas
      renderCtx.drawImage(canvasRef.current, 0, 0, renderCanvas.width, renderCanvas.height);

      // Get the image data from the new canvas
      const imageData = renderCanvas.toDataURL('image/png');
      const blob = dataURLToBlob(imageData);

      const outputContainer = document.querySelector('#outputContainer');

      try{

        const form = new FormData()
        form.append('image_file', file)
        form.append('mask_file', blob)

        const res = await axios.post(`${endpoint}/gen/ai/process/inpainting`, form, { 
          headers: { 'Content-Type': 'multipart/form-data' }
        });

        process('ENDED');

        notifier(false, 'DONE! Hope you like it 🙂')

        const imageUrl = res.data;

        // Create new result
        const img = document.createElement('img');
        img.setAttribute(`data-set-image_url`, `${imageUrl}`)
        img.src = imageUrl;
        img.alt = "Preview"

        // append new result to outputContainer
        outputContainer.appendChild(img);

        // Expand results
        setExpandResults(true);

        const prompt = '- Inpainting AI -'

        const extra = { imageUrl, prompt}
        popUp('gen-preview', extra);

      } catch (error) {
        process('ENDED');
        console.log(error);
      }
    }

    
  };

  useEffect(() => {
    const outputContainer = document.querySelector('#outputContainer').children;

    for (let i = 0; i < outputContainer.length; i++) {
      const element = outputContainer[i];
      
      element.addEventListener('click', () => {
        const prompt = '- Inpainting AI -'
        const imageUrl = element.dataset.setImage_url;

        const extra = { imageUrl, prompt}
        popUp('gen-preview', extra);
      })
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandResults, show]);

  const togglePenMode = () => {
    setPenMode((prevPenMode) => !prevPenMode);
  };
  

  return (
    <InPaintingCompStyle expandResults={expandResults}>
      <div>
        <h1>Erasing Unwanted Objects Made Easy with Model Grapix Inpainting AI</h1>

        <div id="select">
          <input type="file" id="fileInput" onChange={(e) => {
            if(e.target.files[0]) {
              setFile(e.target.files[0])
              setEdited(false);
            }
          }} />
          <label htmlFor="fileInput">Select Image file</label>
        </div>

        <div id="editor_options">
          <span
            className={penMode ? 'active' : ''}
            onClick={togglePenMode}
          >
            <FaPen />
          </span>
          <span
            className={!penMode ? 'active' : ''}
            onClick={togglePenMode}
          >
            <BsEraserFill />
          </span>

          <input type="number" defaultValue={30} onChange={(e) => setPenWeight(e.target.value)} />
        </div>

        <div id="editor" ref={containerRef}>
          {!file ? (
            <div id="prev_text">Please select an image to start with</div>
          ):(
            <canvas
              ref={canvasRef}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onMouseOut={handleMouseOut}
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
              onTouchCancel={handleTouchCancel}
            ></canvas>
          )}

          <img
            src={file && URL.createObjectURL(file)}
            alt="Original"
            style={{
              opacity: file ? 0.7 : 0
            }}
          />
        </div>

        <button onClick={handleRender} style={{
          opacity: edited ? 1 : 0.5,
          bottom: processing ? '-100px' : '20px',
          pointerEvents: edited ? 'visible' : 'none'
        }}>Process</button>

        <div id="launch_preview">
          <div onClick={() => setExpandResults(!expandResults)}><FaAngleLeft /></div>
          <div id="outputContainer">
            {/* Dynamically appended through Javascript */}
          </div>
        </div>

      </div>
    </InPaintingCompStyle>
  )

}

export default InPaintingComp;