import styled from 'styled-components';

export const TopBarStl = styled.div`
  position: sticky;
  top: 0;
  height: 70px;
  z-index: 99;
  user-select: none;
  background: ${({ theme }) => theme.colors.white};

  &.topbar--hidden {
    top: -70px;
    transition: 0.9s cubic-bezier(0.15, 0.06, 0.31, 1.26);
  }

  & > div:first-child { // container
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div[id="topbarMiddle"] {
      display: flex;

      @media(max-width: ${({ theme }) => theme.mobile.is560}) {
        display: none;
      }

      & > span:first-child {
        background: ${({ theme }) => theme.colors.main2}20;
        border: 1px solid ${({ theme }) => theme.colors.main2}AA;
      }

      & > span {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 13px 20px;
        color: ${({ theme }) => theme.colors.black}BB;
        border-radius: 5px;
        cursor: pointer;

        
        & svg {
          margin-right: 5px;
          color: ${({ theme }) => theme.colors.main2};
        }

        @media (max-width: ${({ theme }) => theme.mobile.is860}) {
          & > span {
            display: none;
          }

          &  svg {
            margin-right: 0;
          }
        }
      }
    }

    & > div[id="topbarRight"] {
      display: flex;
      grid-gap: 30px;
      align-items: center;

      & > div[id="notification"] {
        position: relative;

        &:hover div:nth-child(2) { display: flex }

        & > div:nth-child(1) {
          position: relative;
          cursor: pointer;
          padding: 8px;
          border-radius: 5px;
          background: ${({ theme }) => theme.colors.main2}20;

          display: flex;
          align-items: center;
          justify-content: center;

          & > div {
            position: absolute;
            right: 10px;
            top: 10px;
            height: 10px;
            width: 10px;
            border-radius: 50%;
            background: red;
          }

          & svg {
            font-size: 30px;
            color: ${({ theme }) => theme.colors.black}AA;
          }
        }

        & > div:nth-child(2) {
          position: absolute;
          right: 0;
          top: 47px;
          padding: 20px;
          border-radius: 5px;
          background: ${({ theme }) => theme.colors.white};
          box-shadow: -2px 2px 7px ${({ theme }) => theme.colors.black}20;
          width: 300px;
          max-height: 80vh;
          overflow-y: auto;
          display: flex;
          flex-direction: column;
          grid-gap: 20px;
          z-index: 200;

          display: none;

          &::-webkit-scrollbar { display: none }

        }

        @media (max-width: ${({ theme }) => theme.mobile.is660}) {
          position: static;

          & > div:nth-child(2) {
            top: 80px;
            margin: 0 20px;
            min-width: calc(100% - 40px);
          }
        }

      }

      & > div[id="themeToggle"] {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: ${({ theme }) => theme.mobile.is460}) {
          display: none;
        }

        & span {
          height: 30px;
          width: 30px;
          border-radius: 50%;
          display: grid;
          place-items: center;
          font-size: 20px;
          margin: 10px 0;
          background: ${({ theme }) => theme.colors.black}AA;
          color: #fff;
        }

        @media (max-width: ${({ theme }) => theme.mobile.is500}) {
          height: 40px;
          width: 40px;
        }
      }

      & > div[id="notSignedInMenu"] {
        display: flex;
        align-items: center;

        & > a {
          & button {
            background: linear-gradient(to right top, #f32170, #cf23cf, #eedd44);
            color: #FFFFFF;
            font-size: 17px;
          }

          @media(max-width: ${({ theme }) => theme.mobile.is350}) {
            display: none;
          }
        }

        & > div {
          position: relative;

          &:hover div[id="profileOptions"] {
            display: flex;
          }

          @media (max-width: ${({ theme }) => theme.mobile.is660}) {
            position: static;
          }

          & > span {
            height: 40px;
            width: 40px;
            display: grid;
            place-items: center;
            border-radius: 50%;
            border: 1px solid ${({ theme }) => theme.colors.black}50;
            box-shadow: 1px 1px 3px #00000025;
            margin-left: 20px;
            cursor: pointer;

            & > svg {
              font-size: 20px;
              color: ${({ theme }) => theme.colors.black};
            }
          }
        }
      }

      & div[id="profile"] {
        display: flex;
        align-items: center;
        grid-gap: 15px;
        
        & > p {
          padding: 12px;
          background: ${({ theme }) => theme.colors.lightgrey}50;
          border-radius: 6px;

          @media (max-width: ${({ theme }) => theme.mobile.is760}) {
            display: none;
          }
        }

        & > div {
          position: relative;

          & > div[id="profileMenu"] {
            & > div:nth-child(1) {
              padding: 10px;
              background: linear-gradient(to right top, #f32170, #cf23cf, #eedd44);
              border-radius: 50px;
              display: flex;
              grid-gap: 10px;
              align-items: center;
              cursor: pointer;
    
              & > div {
                height: 30px;
                width: 30px;
                background: ${({ theme }) => theme.colors.black}50;
                border-radius: 50%;

                & img {
                  height: 30px;
                  min-width: 30px;
                  max-width: 30px;
                  object-fit: cover;
                  border-radius: 50%;
                }
              }
    
              & svg {
                font-size: 17px;
                color: #FFFFFF;
              }
            }

            &:hover div[id="profileOptions"] {
              display: flex;
            }
          }

          @media (max-width: ${({ theme }) => theme.mobile.is660}) {
            position: static;
          }
        }
        
      }
    }
  }
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  max-height: 70px;
  overflow: hidden;

  
  & > img {
    margin-left: -30px;
    min-width: 220px;
    
    @media (max-width: ${({ theme }) => theme.mobile.is460}) {
      min-width: 200px;
    }
  }

  & > h3 {

    @media (max-width: ${({ theme }) => theme.mobile.is500}) {
      font-size: 17px;
    }
  }


  & img {
    width: 50px;

    @media (max-width: ${({ theme }) => theme.mobile.is500}) {
      width: 40px;
    }
  }
`;

export const ProfileOptionsStyle = styled.div`
  position: absolute;
  right: 0;
  padding: 20px;
  border-radius: 5px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: -2px 2px 7px ${({ theme }) => theme.colors.black}20;
  width: 300px;
  max-height: 80vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  grid-gap: 30px;
  z-index: 701;
  display: none;

  @media (max-width: ${({ theme }) => theme.mobile.is660}) {
    left: 50%;
    transform: translate(-50%);
    width: calc(100% - 40px);
    top: 90px;
  }

  &::-webkit-scrollbar { display: none }
  
  & > div {
    display: flex;
    border-radius: 10px;
    
    opacity: 0;
    transform: translateY(5%);

    &.fade-in { opacity: 0; animation: fadeInFromBottom 0.5s ease-in forwards; }
    @keyframes fadeInFromBottom { from { opacity: 0; transform: translateY(5%); } to { opacity: 1; transform: translateY(0); } }
  }

  & div[id="account"] {
    align-items: center;
    grid-gap: 10px;
    cursor: pointer;
    padding: 10px;

    & h3 {
      font-weight: 900;
      background: linear-gradient(to right, #f32170, #ff6b08, #cf23cf, #eedd44);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
    }

    white-space: nowrap;

    & > img {
      height: 45px;
      min-width: 45px;
      max-width: 45px;
      border-radius: 50%;
      object-fit: cover;
    }

    & > div {
      overflow: hidden;
    }
  }

  & div[id="options"] {
    flex-direction: column;
    grid-gap: 10px;

    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.black}20;
      background: ${({ theme }) => theme.colors.lightgrey}50;
      border-radius: 50px;
      cursor: pointer;

      &:hover {
        // opacity: 0.7;
      }

      & > svg {
        color: ${({ theme }) => theme.colors.black};
        font-size: 20px;
      }
    }
  }
`