import { AiFillStar } from 'react-icons/ai';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { FaEllipsisV, FaFlag } from 'react-icons/fa';
import styled from 'styled-components';
import { MdThumbUp } from 'react-icons/md';
import { BiDownload } from 'react-icons/bi';
import axios from 'axios';
import useAuthContext from '../../../hooks/auth/useAuthContext';
import useProxyContext from '../../../hooks/global/useProxyContext';
import { useEffect, useState } from 'react';
import usePopUpContext from '../../../hooks/global/usePopUpContext';
import useErrHandler from '../../../hooks/err/useErrHandler';
import { useNavigate } from 'react-router-dom';
import useAccess from '../../../hooks/access/useAccess';
import { useTheme } from 'styled-components';
import useImgHandler from '../../../hooks/img/useImgHandler';
import { IoCopy } from 'react-icons/io5';
import useProfile from '../../../hooks/profile/useProfile';
import useConstructor from '../../../hooks/constructor/useConstructor';
import useProcesser from '../../../hooks/processer/useProcesser';

const PostInfoStyle = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  & div[id="profile"] {
    position: relative;
    width: 100%;
    position: relative;
    display: flex;
    grid-gap: 10px;
    align-items: center;
    z-index: 600;
    overflow: hidden;
    
    & > h3 {
      position: absolute;
      left: 45px;
      white-space: nowrap;
    }

    & img {
      min-width: 35px;
      max-width: 35px;
      height: 35px;
      border-radius 50%;
      object-fit: cover;
      cursor: pointer;
    }
  }

  & > div[id="options"] {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 600;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -40px;
      bottom: 0;
      width: 40px;
      background: linear-gradient(to right, #FFFFFF00, ${ ({ theme, uploadPreviewOpen }) => uploadPreviewOpen ? theme.colors.theme : theme.colors.white }, ${ ({ theme, uploadPreviewOpen }) => uploadPreviewOpen ? theme.colors.theme : theme.colors.white });
    }
        
    & > span[id="btn"] {
      margin-left: 5px;
      position: relative;
      padding: 12px;
      height: 40px;
      border-radius: 50px;
      background: #FFFFFF20;
      color: ${({ theme }) => theme.colors.black};
      display: flex;
      align-items: center;
      justify-content: center;

      &:first-child {
        margin-left: 0;

        & > span[id="icon"] {
          margin-right: 5px;  
        }
      }
      
      & > span[id="icon"] {
        display: grid;
        place-items: center;
      }
      
      & > div[id="dropOptions"] {
        position: absolute;
        bottom: 35px;
        right: 0;
        padding: 12px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        background: ${({ theme }) => theme.colors.white};
        border-radius: 10px;
        display: none;
        box-shadow: 2px 2px 25px rgba(0,0,0,0.3);
        min-width: 200px;
        

        & > div {
          display: flex;
          align-items: center;
          gap: 30px;
          padding: 10px;
          border-radius: 10px;
          background: ${({ theme }) => theme.colors.white};

          & > p {
            white-space: nowrap;
          }
          
          &:hover {
            background: ${({ theme }) => theme.colors.lightgrey}25;
            box-shadow: 2px 2px 25px rgba(0,0,0,0.3);
          }
          
          &:hover > p {
            color: ${({ theme }) => theme.colors.black};
          }
        }
      }

      cursor: pointer;
      box-shadow: 0 0 2px #000000AA;
    }

    & > span[class="dropOptionsBtn"] {
      &:hover > div[id="dropOptions"] {
        display: flex;
      }
    }
  }
`;

const PostInfo = ({ star, userPP, post }) => {

  const [userProfile, setUserProfile] = useState(null);

  const [likes, setLikes] = useState('');

  const [liked, setLiked] = useState(false);

  const { user } = useAuthContext();

  const { userId } = useAccess();

  const { endpoint, imageEndpoint, reactAppClientEndpoint } = useProxyContext()

  const { popUp } = usePopUpContext();

  const { getRoute, uploadPreviewOpen, setUploadPreviewOpen } = useConstructor();

  const { notifier } = useErrHandler();

  const { reachRoute } = useImgHandler();

  const { profileSetup } = useProfile();

  const { process, processing } = useProcesser();

  const navigate = useNavigate();

  const theme = useTheme();

  useEffect(() => {
    // set the number of likes
    setLikes(post.likes.length);
  }, [post])

  useEffect(() => {
    if(!userId) return;

    // check if user already liked 
    const liked = post.likes.some(like => like.userId === userId._id)

    // update liked state
    setLiked(liked);

  }, [userId, post])
  
  async function downloadImage() {
    if(!userId) return notifier(false, "Login to download!");

    process('STARTED', 'Downloading ...')

    if(processing) return;

    if(post.images && post.images.length > 0) {
      for (let i = 0; i < post.images.length; i++) {
        const src = post.images[i];
        const imageUrl = `${imageEndpoint}/images/${src}`;

        const a = document.createElement("a");

        a.href = await toDataURL(imageUrl)

        a.download = post.src;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    
        try {
          const res = await axios.put(`${endpoint}/download`, {
            imageId: post._id, 
            src: post.src, 
            type: ""
          }, {
            headers: {
              'Authorization': `Bearer ${user.token}`
            }
          })
    
          notifier(false, res.data.message);
          
        } catch (err) {
          const errMessage = err.response.data.message;
          notifier(true, errMessage);
        }
      }

      process('ENDED')

    } else {
      const imageUrl = `${imageEndpoint}/images/${post.src}`;

      const a = document.createElement("a");
      a.href = await toDataURL(imageUrl);
      a.download = post.src;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
  
      try {
        const res = await axios.put(`${endpoint}/download`, {
          imageId: post._id, 
          src: post.src, 
          type: "",
        }, {
          headers: {
            'Authorization': `Bearer ${user.token}`
          }
        })
  
        notifier(false, res.data.message);
        
      } catch (err) {
        const errMessage = err.response.data.message;
        notifier(true, errMessage);
      }
    }

    process('ENDED');
  }

  function toDataURL(src) {
    return fetch(src)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        return URL.createObjectURL(blob)
      })
  }

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    getRoute((data) => {
      setUserProfile(data);
    }, `/user/profile/basic/${post.ownerId}`, signal)
        
    return () => {
      controller.abort();
    }

    // disable warning for getRoute() missing dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post])

  const profilePage = userProfile && `/${userProfile.userName.split(" ")[0]}/profile/${userProfile._id}/uploads`
    
  function copyUrl(urlElement, urlBtnText) {
    const range = document.createRange();
    range.selectNodeContents(urlElement);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand('copy');
    selection.removeAllRanges();
    urlBtnText.innerHTML = 'Copied!'
    setTimeout(() => urlBtnText.innerHTML = 'Copy Link', 3000)
  }
  
  useEffect(() => {
    const urlBtn = document.querySelectorAll('#urlBtn');
    const urlBtnText = document.querySelectorAll('#urlBtnText');
    const urlElement = document.querySelectorAll('#urlElement');
    
    for (let i = 0; i < urlBtn.length; i++) {
      const urlBt = urlBtn[i];
      const urlEl = urlElement[i];
      const urlBtTe = urlBtnText[i];
      
      urlBt.addEventListener('click', () => {
        copyUrl(urlEl, urlBtTe)
      })
    }
  }, [post])

  const navigateTo = (url) => {
    navigate(url);
    setUploadPreviewOpen(false);
  }

  return (
    <PostInfoStyle id="downloadBtn" uploadPreviewOpen={uploadPreviewOpen}>
      <div id="profile" onClick={() => {
        navigateTo(profilePage)
      }}>
        <img src={ userPP } alt=""/>
        <h3>{ userProfile && userProfile.userName }</h3>
      </div>

      <div id="options">
        <span id="btn" onClick={() => {
          if(!userId) return notifier(true, "Want to like this post? Please sign in to continue.")
          
          if (liked) {
            setLikes(likes - 1);
            setLiked(false);
          } else {
            setLikes(likes + 1);
            setLiked(true);
          }

          reachRoute(() => {}, post._id, '/toggle/like')
          
        }} style={{
          color: liked ? theme.colors.main2 : `${theme.colors.black}AA`
        }}><span id="icon"><MdThumbUp /></span> <span>{likes}</span> </span>

        <span id="btn" onClick={star}><span id="icon"><AiFillStar /></span></span>

        {post.downloadable && <span id="btn" onClick={ async () => {
          if(post.pro) {
            if(!userId) {
              notifier(true, 'Sign in to continue!')
            } else {
              setUploadPreviewOpen(false);
              
              await profileSetup(() => {
                popUp('payment', post)
              })
            }
          } else {
            downloadImage();
          }
        }}><span id="icon"><BiDownload /></span></span>}

        <span id="btn" className="dropOptionsBtn">
          <span id="icon"><FaEllipsisV /></span>
          <div id="dropOptions">

            <div id="urlBtn">
              <IoCopy />
              <p id="urlBtnText">Copy Link</p>
            </div>

            <div>
              <FaFlag />
              <p>Report</p>
            </div>

            <div>
              <BsFillInfoCircleFill />
              <p>Details</p>
            </div>

          </div>
        </span>
      </div>

      <code style={{ 
        position: 'absolute',
        width: '50px',
        overflow: 'hidden',
        pointerEvents: 'none',
        opacity: '0',
      }} id="urlElement">{ `${reactAppClientEndpoint}/upload/${post._id}` }</code>

    </PostInfoStyle>
  )
}

export default PostInfo;