import styled from "styled-components";

export const UserProfileSkeletonStyle = styled.div`
  & > div { // container
    display: flex;
    justify-content: space-between;
    padding-top: 100px;
    padding-bottom: 100px;

    animation: fade 1s infinite;

    @keyframes fade {
      0% {
        opacity: 0.5;
      } 
      50% {
        opacity: 1;
      } 
      100% {
        opacity: 0.5;
      }
    }

    @media(max-width: ${({ theme }) => theme.mobile.is950}) {
      padding-top: 50px;
      padding-bottom: 50px;

      & > div:first-child {
        flex-direction: column;
        margin-left: 50%;
        transform: translate(-50%);

        & > div:last-child {
          align-items: center;
        }
      }
    }

    & > div {
      display: flex;
      align-items: center;
      grid-gap: 50px;
      min-width: 100%;

      & > div:first-child { // Profile pics
        position: relative;
        min-height: 250px;
        min-width: 250px;
        border-radius: 50%;
        box-shadow: 1px 20px 20px rgba(0,0,0,0.2);

        @media (max-width: ${({ theme }) => theme.mobile.is749}) {
          min-height: 170px;
          min-width: 170px;
        }
        
        & > img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          opacity: 0.4;
        }
      }

      & > div:last-child { // User info
        display: flex;
        grid-gap: 20px;
        flex-direction: column;
        width: 100%;

        & > span { // span
          display: flex;
          grid-gap: 20px;
          height: 40px;
          
          &:nth-child(1) { // span 1
            width: 300px;
            border-radius: 5px;
            background: ${({ theme }) => theme.colors.lightgrey}AA;
          }
          
          &:nth-child(2) { // span 2
            width: 350px;

            & > div {
              width: 50%;
              border-radius: 5px;
              background: ${({ theme }) => theme.colors.lightgrey}AA;
            }
          }

          &:last-child { // last span
            width: 100px;
            border-radius: 5px;
            background: ${({ theme }) => theme.colors.lightgrey}AA;
          }

          @media(max-width: ${({ theme }) => theme.mobile.is400}) {
            &:nth-child(1) { // span 1
              width: 100%
            }

            &:nth-child(2) { // span 2
              width: 100%;
            }
          }
        }
      }
    }
  }
`;