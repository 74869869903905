import styled from 'styled-components';

export const DocumentationStyle = styled.div`
  // HEADER
  & > div[id="header"] {
    padding: 50px 0;
    margin: 0 0 20px 0;
    text-align: center;
    background: linear-gradient(to bottom right, ${({ theme }) => theme.colors.main }10, ${({ theme }) => theme.colors.main2 }10);
    
    & > h2 {
      font-weight: 900;
      background: linear-gradient(to right, #f32170, #ff6b08, #cf23cf, #eedd44);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
    }
  }

  & > div[id="container"] {
    position: relative;

    

    // WRAPPER | Nav and contents
    & > div[id="wrapper"] {
      display: flex;
      grid-gap: 50px;

      @media(max-width: ${({ theme }) => theme.mobile.is1070}) {
        grid-gap: 0;
      }
    }
  }
`;

export const ModulesStyle = styled.div`
  max-width: 100%;

  & h1 {
    padding: 20px 0;
    color: ${({ theme }) => theme.colors.main2};

    @media(max-width: ${({ theme }) => theme.mobile.is359}) {
      font-size: 30px;
    }
  }

  & ul {
    & > li {
      list-style: disc;
      margin-bottom: -30px;
      margin-left: 40px;
    }
  }
  
  & div[id="stacks"] {
    display: flex;
    align-items: center;
    margin-bottom: -30px;
    grid-gap: 20px;

    & > div {
      display: flex;
      align-items: center;
      grid-gap: 10px;
      padding: 5px 10px;
      border-radius: 5px;
      background: ${({ theme }) => theme.colors.black}10;
      cursor: pointer;
      
      &:nth-child(1) {
        border-bottom: ${({ stack, theme }) => stack && stack.nodejs ? (
          `5px solid ${theme.colors.main2}50`
          ):(
          `none`
        )}
      }

      &:nth-child(2) {
        border-bottom: ${({ stack, theme }) => stack && stack.python ? (
          `5px solid ${theme.colors.main2}50`
          ):(
          `none`
        )}
      }

      &:nth-child(3) {
        border-bottom: ${({ stack, theme }) => stack && stack.curl ? (
          `5px solid ${theme.colors.main2}50`
          ):(
          `none`
        )}
      }
    }

    & img {
      width: 30px
    }
    
    @media(max-width: ${({ theme }) => theme.mobile.is500}) {
      grid-gap: 5px;

      & > div {
        width: 100%;
        padding: 3px 5px;
        justify-content: center;
      }

      & img {
        width: 15px
      }

      & span {
        font-size: 13px;
      }
    }
  }
      
  & > pre {
    font-family: 'Poppins', sans-serif;
    font-size: 19px;
    line-height: 2em;
    white-space: pre-wrap;
  }

  & div[id="code"] {
    position: relative;
    overflow-x: scroll;
    border-radius: 10px;
    max-width: 100%;
    scrollbar-width: none;

    &::-webkit-scrollbar { display: none }

    & > span[id="copy"] {
      position: sticky;
      display: block;
      width: 100%;
      left: 0;
      text-align: right;
      font-size: 12px;
      margin-top: 0;
      padding: 0px 10px;
      background: #444444;
      color: #FFFFFF;
      cursor: pointer;
    }
    
    & code {
      display: block;
      width: 500%;
      border-radius: 10px;
      background: ${({ theme }) => theme.colors.black};
      color: ${({ theme }) => theme.colors.white};
      margin-top: -35px;
      padding: 10px;
    }
  }
`;