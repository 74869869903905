import { useState } from 'react';
import styled from 'styled-components';
import useAuthContext from '../../../../hooks/auth/useAuthContext';
import useErrHandler from '../../../../hooks/err/useErrHandler';
import useProxyContext from '../../../../hooks/global/useProxyContext';
import Button from '../../../button/Button';
import axios from 'axios';
import useAccess from '../../../../hooks/access/useAccess';

const trim = (str) => str.replace(/\s+/g, '');

const AdvancedSetupStyle = styled.div`
  & > form {
    display: flex;
    flex-direction: column;
    gap: 20px;

    & > div {
      display: flex;
      flex-direction: column;
      gap: 10px;

      & > input, textarea {
        padding: 15px;
        font-size: 19px;
        color: ${({ theme }) => theme.colors.black};
        resize: none;

        box-shadow: 1px 1px 3px #00000025;
        background: ${({ theme }) => theme.colors.lightgrey}20;
        border: 1px solid ${({ theme }) => theme.colors.black}50;
        border-radius: 5px;
      }
    }

    & > button {
      background: linear-gradient(to top right, ${({ theme }) => `${ theme.colors.main }50 , ${ theme.colors.main2 }50`});
      
      &:hover {
        background: linear-gradient(to bottom, ${({ theme }) => `${ theme.colors.main2 }AA, ${ theme.colors.green }AA` });
      }
    }
  }
`;

const AdvancedSetup = () => {
  const [cta, setCta] = useState('');
  const [about, setAbout] = useState('')

  const { endpoint } = useProxyContext();

  const { user } = useAuthContext();

  const { notifier } = useErrHandler();

  const { userId } = useAccess();

  const updateAdvanced = async (e) => {
    e.preventDefault();

    if(trim(cta).length < 1) return notifier(true, "cta must be valid https url");
    
    if(trim(about).length <= 100) return notifier(true, "About must be minimun of 100 characters");

    try {
      const res = await axios.put(`${endpoint}/advanced/setup`, {
        cta,
        about
      }, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      })

      notifier(false, res.data.message);

    } catch (err) {
      console.log(err);
    }
  }

  return (
    <AdvancedSetupStyle>
      {userId &&
      <form onSubmit={updateAdvanced}>
        <div>
          <label htmlFor="cta">Call to Action</label>
          <input 
            type="text" 
            placeholder={userId.cta ? userId.cta : "eg. facebook, whatsapp, twitter e.t.c"} 
            id="cta"
            value={cta}
            onChange={(e) => setCta(e.target.value)}
          />
        </div>

        <div>
          <label htmlFor="about">About</label>
          <textarea 
            id="about" rows="4" 
            placeholder={userId.about ? userId.about : "About me"}
            value={about}
            onChange={(e) => setAbout(e.target.value)}
          ></textarea>
        </div>

        <Button>Submit</Button>
      </form>
      }
    </AdvancedSetupStyle>
  )
}

export default AdvancedSetup;