import { FaAngleDown } from "react-icons/fa";
import { ImageGenCompStyle } from "./styles/S_ImageGenComp";
import { AiOutlinePlus } from "react-icons/ai";
import { useEffect, useState } from "react";
import axios from "axios";
import useProxyContext from "../../../hooks/global/useProxyContext";
import { BsArrowRightSquareFill } from "react-icons/bs";
import useProcesser from "../../../hooks/processer/useProcesser";
import useErrHandler from "../../../hooks/err/useErrHandler";
import { BiCheck } from "react-icons/bi";
import { TfiAngleDown, TfiAngleLeft, TfiAngleRight } from "react-icons/tfi";
import useAccess from '../../../hooks/access/useAccess';
import usePopUpContext from '../../../hooks/global/usePopUpContext';
import useRefreshContext from "../../../hooks/global/useRefreshContext";
import useProfile from '../../../hooks/profile/useProfile';
import { useNavigate } from "react-router-dom";
import useAuthContext from "../../../hooks/auth/useAuthContext";
import useConstructor from "../../../hooks/constructor/useConstructor";

const ImageGenComp = () => {
  // store prompt and manage space
  const [emptySpace, setEmptySpace] = useState(true);
  let [prompt, setPrompt] = useState('');

  // settings variables
  const [openSettings, setOpenSettings] = useState(false);
  const [openDisplay, setOpenDisplay] = useState(false);
  const [openQuality, setOpenQuality] = useState(false);
  const [openNumber, setOpenNumber] = useState(false);
  const [upscale, setUpscale] = useState(false);
  const [display, setDisplay] = useState('portrait');

  // models
  const [model, setModel] = useState('--v1');
  const [openModel, setOpenModel] = useState(true);
  
  // paint variables
  const [openPaint, setOpenPaint] = useState(false);
  const [publicPaintInUse, setPublicPaintInUse] = useState(true);

  const [publicPaint, setPublicPaint] = useState('0');
  const [personalPaint, setPersonalPaint] = useState('0');

  // hooks from other files
  const { endpoint } = useProxyContext();
  const { process, processing } = useProcesser();
  const { notifier } = useErrHandler();
  const { userId } = useAccess();
  const { popUp } = usePopUpContext();
  const { reRender, refresh } = useRefreshContext();
  const { postToRouteRA } = useConstructor();
  const { profileSetup } = useProfile();
  const { user } = useAuthContext();

  const navigate = useNavigate();

  // New space template
  const newSpaceTemplate = `
    <div>
      
    </div>
  `

  // Manage creating of new space
  const createNewSpace = () => {
    if(emptySpace) return;
    const generatedImages = document.querySelector('#generatedImages');

    setEmptySpace(true);

    const newSpace = document.createElement('div');
    newSpace.innerHTML = newSpaceTemplate;

    generatedImages.appendChild(newSpace);
  }

  // Manage appending of generated images
  const appendImage = (imageUrl, prompt) => {
    const generatedImages = document.querySelector('#generatedImages');

    const spaces = generatedImages.children;
    const lastSpace = spaces[spaces.length - 1];
    const lastSpaceImagePreview = lastSpace.children[0]

    if(lastSpaceImagePreview.tagName === 'SPAN') {
      const previousSpace = spaces[spaces.length - 2].children[0];
      previousSpace.style.backgroundImage = `url(${imageUrl})`;
      previousSpace.dataset.prompt = prompt;
      
      
    } else {
      lastSpaceImagePreview.style.backgroundImage = `url(${imageUrl})`;
      lastSpaceImagePreview.dataset.prompt = prompt;
      lastSpace.style.border = 'none';
    }
    
    setEmptySpace(false);
  }

  // Generate image
  const generateImage = async (e) => {
    
    e.preventDefault();
    
    if(!prompt) return notifier(false, 'Please describe the image you want to generate!')
    
    if(!userId) return notifier(false, 'Sign In to continue!');
    
    if(processing) return;

    prompt = `${prompt} ${model}`;

    process('STARTED', 'Hold on tight, the image is coming...');

    try {
      const res = await axios.post(`${endpoint}/gen/ai/generate-image`, {
        prompt,
        upscale,
        ownerId: userId ? userId._id : "",
        paint: publicPaintInUse ? "PUBLIC" : "PERSONAL",
        display
      }, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      })

      reRender();
      appendImage(res.data.image_url, res.data.prompt);
      process('ENDED');
      notifier(false, 'DONE! Hope you like it 🙂')

    } catch (err) {
      console.log(err);
      process('ENDED');
      const errMessage = err.response.data.message;
      notifier(true, errMessage);
    }
  }

  // open full preview - popUp alongside with generated image info onclick
  // fetch and display paints,

  useEffect(() => {
    // call preview on click
    const generatedImages = document.querySelector('#generatedImages').children;

    for (let i = 0; i < generatedImages.length; i++) {
      let image = generatedImages[i];
  
      image.addEventListener('click', () => {
        if(image.firstElementChild.tagName === 'DIV' && image.firstElementChild.style.backgroundImage) {
          const previewBlock = image.firstElementChild;
          const imageUrl = previewBlock.style.backgroundImage.split('"')[1];

          const prompt = 
`**Prompt:** \`\`\`${previewBlock.dataset.prompt}\`\`\`
**Dive into a collection of countless prompting ideas for the image generator by clicking the link below 👇👇 **
https://modelgrapix.com/blog/post/648dc0de9ed6c924d844de7a`;

          const extra = { imageUrl, prompt}
          popUp('gen-preview', extra);
        }
      })
    }

    postToRouteRA((data) => {
      setPersonalPaint(data.personalPaint);
      setPublicPaint(data.publicPaint);
    }, {}, `/gen/ai/get/paints`);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, userId])

  // active size handler 
  const isUpscale = (str) => upscale === str;
  const isDisplay = (str) => display === str;
  const isModel = (str) => model === str;

  const publicPaintIcon = '/images/badge/paint_public.png';
  const personalPaintIcon = '/images/badge/paint_personal.png';

  return (
    <ImageGenCompStyle isPro={userId && userId.pro} openPaint={openPaint} openSettings={openSettings} openDisplay={openDisplay} openQuality={openQuality} openNumber={openNumber} openModel={openModel}>
      <div id="header">
        <h2>Image generator </h2>
        <small>v0.0.7</small>
      </div>

      <div id="paintStatus">
        <div onClick={() => setOpenPaint(!openPaint)} id="select">
          <div onClick={() => setPublicPaintInUse(false)}>
            <div>
              <img src="/images/badge/paint_personal.png" alt="" />
              
              <span>{personalPaint}</span>

              <div>
                <span>paint</span>
                <small>Personal</small>
              </div>
            </div>
          </div>

          <div onClick={() => setPublicPaintInUse(true)}>
            <div>
              <img src="/images/badge/paint_public.png" alt="" />
            
              <span>{publicPaint}</span>

              <div>
                <span>paint</span>
                <small>Public</small>
              </div>
            </div>
          </div>

          { userId ? (
          <small onClick={() => {
            if(!userId) return notifier(false, 'Sign In to continue!')

            profileSetup(() => {
              popUp('paint')
            })
          }}><AiOutlinePlus /> Add paint</small>
          ) : (
            <small onClick={() => navigate('/sign-in')}>Sign In</small>
          )}

        </div>

        <div onClick={() => setOpenPaint(!openPaint)} id="openSelectBtn">
          <img src={publicPaintInUse ? publicPaintIcon : personalPaintIcon} alt=" " />
          <span>{publicPaintInUse ? publicPaint : personalPaint}</span>
        </div>
      </div>

      <form onSubmit={generateImage}>
        <div>
          <input  
            type="text" 
            placeholder='Describe an image 🙂, eg "Cute little girl"' 
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
          />

          <span onClick={generateImage}><BsArrowRightSquareFill /></span>
        </div>
      </form>

      <span>
        <div id="generatedImages">
          <div>
            <div data-set-prompt="">
              
            </div>
          </div>

          { !emptySpace &&
          <div onClick={createNewSpace} id="createSpaceBtn">
            <span><AiOutlinePlus /> New</span>
          </div>
          }

          {/* Append more using javascript */}
        </div>
      </span>

      <div id="options">
        <div>
          <h2 onClick={() => setOpenSettings(!openSettings)}>Settings <FaAngleDown /></h2>
          
          <div onClick={() => setOpenModel(!openModel)} id="model">
            <header>Model <TfiAngleDown /></header>
            <div>
              <span onClick={() => setModel('--v1')}>--v1 {isModel('--v1') && <BiCheck />}</span>
              <span onClick={() => setModel('--v2')}>--v2 {isModel('--v2') && <BiCheck />}</span>
              <span onClick={() => setModel('--v3')}>--v3 {isModel('--v3') && <BiCheck />}</span>
              <span onClick={() => setModel('--v4')}>--v4 {isModel('--v4') && <BiCheck />}</span>
              <span onClick={() => setModel('--v5')}>--v5 {isModel('--v5') && <BiCheck />}</span>
            </div>
          </div>

          <div onClick={() => setOpenQuality(!openQuality)} id="quality">
            <header>Quality <TfiAngleDown /></header>
            <div>
              <span onClick={() => setUpscale(true)}>High {isUpscale(true) && <BiCheck />}</span>
              <span onClick={() => setUpscale(false)}>Normal {isUpscale(false) && <BiCheck />}</span>
            </div>
          </div>

          <div onClick={() => setOpenDisplay(!openDisplay)} id="display">
            <header>Display <TfiAngleDown /></header>
            <div>
              <span onClick={() => setDisplay('landscape')}>Landscape {isDisplay('landscape') && <BiCheck />}</span>
              <span onClick={() => setDisplay('square')}>Square {isDisplay('square') && <BiCheck />}</span>
              <span onClick={() => setDisplay('portrait')}>Portrait {isDisplay('portrait') && <BiCheck />}</span>
            </div>
          </div>

          <div id="number">
            <header onClick={() => setOpenNumber(!openNumber)}>Number <TfiAngleDown /></header>
            <div>
              <span id="icon"><TfiAngleLeft /></span>
              <span id="count">1</span>
              <span id="icon"><TfiAngleRight /></span>
            </div>
          </div>
        </div>
      </div>

      {/* ad was once here */}

    </ImageGenCompStyle>
  );
}
 
export default ImageGenComp;