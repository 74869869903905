import { ApiKeyStyle } from "./styles/S_ApiKey";
import { Container } from '../../components/global/Container';
import TopBar from '../../components/topbar/TopBar';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { MdContentCopy, MdRefresh } from 'react-icons/md';
import { useEffect } from "react";
import useConstructor from "../../hooks/constructor/useConstructor";
import { useState } from "react";
import { BiCheck } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import usePopUpContext from "../../hooks/global/usePopUpContext";
import useAccess from "../../hooks/access/useAccess";
import useErrHandler from "../../hooks/err/useErrHandler";
import useProfile from "../../hooks/profile/useProfile";
import { AiOutlinePlus } from "react-icons/ai";

const ApiKey = () => {
  const [apiKey, setApiKey] = useState('');
  const [copied, setCopied] = useState(false);

  const [openGrapix, setOpenGrapix] = useState(false);
  const [grapix, setGrapix] = useState("");

  const { getRouteRA, putToRouteRA } = useConstructor();

  const { popUp } = usePopUpContext();

  const { userId } = useAccess();

  const { notifier } = useErrHandler();

  const { profileSetup } = useProfile();

  const navigate = useNavigate();

  useEffect(() => {
    getRouteRA((data) => {
      setApiKey(data);
    }, '/retrieve-api-key')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const copyApiKey = () => {
    const apiKey = document.querySelector('.apiKey');

    const range = document.createRange();
    range.selectNodeContents(apiKey);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand('copy');
    selection.removeAllRanges();

    setCopied(true);

    setTimeout(() => setCopied(false), 3000)
    
    console.log('Copied!')
  }

  const regenerateApiKey = () => {
    putToRouteRA((data) => {
      setApiKey(data);
    }, `/regenerate-api-key`)
  }

  useEffect(() => {
    setGrapix(userId.grapix);
  }, [userId])

  return (
    <ApiKeyStyle openGrapix={openGrapix}>
      <TopBar />

      <Container>
        <div>
          <h1>API Key</h1>

          <div id="apiContainer">
            <div>
              <p className="apiKey">{ apiKey }</p>
              <div>
                <span onClick={regenerateApiKey}><MdRefresh /></span>
                <span onClick={copyApiKey}>{ !copied ? <MdContentCopy /> : <BiCheck />}</span>
              </div>
            </div>

            <div onClick={() => navigate('/documentation#apiIntroduction')}>
              <p>Click here to read API documentation</p>
              <FaExternalLinkAlt />
            </div>

            <div onClick={() => popUp('tools')}>
              <p>Test our AI models on the playground</p>
              <FaExternalLinkAlt />
            </div>

          </div>

        <div id="grapixStatus">
          <div onClick={() => setOpenGrapix(!openGrapix)} id="select">
            <div>
              <div>
                <img src="/images/icons/grapix.png" alt="" />
                
                <span>{grapix}</span>

                <div>
                  <span>Grapix</span>
                  <small>Remaining</small>
                </div>
              </div>
            </div>

            { userId ? (
            <small onClick={() => {
              if(!userId) return notifier(false, 'Sign In to continue!')

              profileSetup(() => {
                popUp('grapix')
              })
            }}><AiOutlinePlus /> Top up grapix</small>
            ) : (
              <small onClick={() => navigate('/sign-in')}>Sign In</small>
            )}

          </div>

          <div onClick={() => setOpenGrapix(!openGrapix)} id="openSelectBtn">
            <img src="/images/icons/grapix.png" alt=" " />
            <span>{grapix}</span>
          </div>
        </div>

          <div id="slogan">Powering other apps with our AI tools through API</div>
        </div>
      </Container>
    </ApiKeyStyle>
  );
}
 
export default ApiKey;