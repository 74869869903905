import { FaFacebookSquare, FaInstagramSquare, FaLinkedin, FaTwitterSquare, FaYoutubeSquare } from 'react-icons/fa';
import { Container } from '../global/Container';
import useThemeContext from '../../hooks/useThemeContext';
import styled from 'styled-components';
import Button from '../button/Button';
import { Link } from 'react-router-dom';

const FooterStl = styled.div`
  background-image: linear-gradient(#000000AA, ${({ theme }) => theme.colors.main2}20), url("/images/background/footer.jpg");
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  & > div {
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    grid-gap: 50px;

    & > div {
      &:first-child {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 70px;

        @media (max-width: ${({ theme }) => theme.mobile.is992}) {
          grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: ${({ theme }) => theme.mobile.is580}) {
          grid-template-columns: repeat(1, 1fr);

          & > div {
            align-items: center;

            & a {
              text-align: center;
            }
          }

          & h3 {
            text-align: center;
          }

        }
        
        & h3 {
          color: #EEEEEE;
        }
        
        & > div {
          display: flex;
          flex-direction: column;
          gap: 30px;

          & a {
            color: #FFFFFF;
          }

          & > div[id="usefulLinks"] {
            display: flex;
            flex-direction: column;
            gap: 15px;
          }

          & > div[id="about"] {
            overflow: hidden;
            
            & p {
              color: #FFFFFF;
            }

            & img {
              max-height: 150px;
              width: 100%;

              object-fit: cover;
            }
          }

          & > div[id="followUs"] {
            display: flex;
            align-items: center;
            flex-flow: wrap;
            font-size: 30px;
            gap: 10px;
          }

          & > div[id="newsLetter"] {
            width: 100%;

            & > form {
              display: flex;
              flex-direction: column;
              grid-gap: 10px;

              & input {
                width: 100%;
                border: none;
                padding: 15px;
                box-shadow: 1px 1px 3px #00000025;
                font-size: 19px;
                color: #000000;
                background: #FFFFFF;
                border: 1px solid #FFFFFF;
                border-radius: 5px;
              }
              
              & > button {
                background: linear-gradient(to top right, ${({ theme }) => `${ theme.colors.main }50 , ${ theme.colors.main2 }50`});
                border-radius: 5px;
                padding: 15px;
                color: #FFFFFF;
   
                &:hover {
                  background: linear-gradient(to bottom, ${({ theme }) => `${ theme.colors.main2 }AA, ${ theme.colors.main }AA` });
                }
              }
            }
          }
        }
      }

      &:last-child {
        & p {
          color: #FFFFFF;
          text-align: center;

          & a {
            font-weight: 600;
            text-decoration: none;
            color: ${({ theme }) => theme.colors.main};
          }
        }
      }
    }
  }
`;

const Footer = () => {
  const { darkTheme } = useThemeContext();

  return (
    <FooterStl darkTheme={darkTheme}>
      <Container>
        <div>
            <div>
              <h3>Useful Links</h3>
              <div id="usefulLinks">
                <Link to="/">Home</Link>
                <Link to="/blog">Blog</Link>
                {/* <Link to="/top/designers">Top Designers</Link> */}
                {/* <Link to="/best/team">Best Team</Link> */}
                {/* <Link to="/jobs">Jobs</Link> */}
                <Link to="/documentation">Documentation</Link>
                {/* <Link to="/next-list">Next List</Link> */}
                {/* <Link to="/about">About</Link> */}
                <Link to="/privacy-policy">Privacy Policy</Link>
                <Link to="/terms-and-condition">Terms and condition</Link>
                <Link to="/content-policy">Content Policy</Link>
                <Link to="/copyright-policy">Copyright Policy</Link>
                {/* <Link to="/contact-us">Contact Us</Link> */}
              </div>
            </div>
            
            <div>
              <h3>About</h3>
              <div id="about">
                <p>Model Grapix connects creatives and provides a space for them to create, share, and connect with others.</p>
                <img src="/images/logos/20230125_072534.png" alt="" />
              </div>
            </div>
            
            <div>
              <h3>Follow Us</h3>
              <div id="followUs">
                <a href="https://www.facebook.com/modelgrapix"><FaFacebookSquare /></a>
                <a href="https://youtube.com/@modelgrapix"><FaYoutubeSquare /></a>
                <a style={{ opacity: "0.5", pointerEvents: "none" }} href="/"><FaLinkedin /></a>
                <a style={{ opacity: "0.5", pointerEvents: "none" }} href="/"><FaTwitterSquare /></a>
                <a style={{ opacity: "0.5", pointerEvents: "none" }} href="/"><FaInstagramSquare /></a>
              </div>
            </div>

            <div>
              <h3>Subscribe Newsletter</h3>
              <div id="newsLetter">
                <form>
                  <input type="text" placeholder="Enter Email" />
                  <Button>Subscribe</Button>
                </form>
              </div>
            </div>
          </div>
          
          <div>
            <p>&copy;2022/2024 <a href='https://www.facebook.com/modelgrapix'><strong>Model Grapix</strong></a> | by <a href='https://web.facebook.com/LuckyWebTech'>LuckyWebTech</a> | All Rights Reserved</p>
          </div>
      </Container>
    </FooterStl>
  )
}

export default Footer;

// https://www.linkedin.com/company/lucky-web-tech/
// https://web.facebook.com/LuckyWebTech/
// https://twitter.com/luckywebtech22
// https://www.youtube.com/channel/UC675LYgt3sUxf4n5fffrCCg
// https://www.instagram.com/luckywebtech22/