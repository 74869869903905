import Button from "../../../button/Button"
import { PaymentForm, PaymentStyle } from "./styles/S_Payment"
import { useTheme } from 'styled-components';
import useAccess from '../../../../hooks/access/useAccess';
import { useState } from "react";
import Pay from "./pay/Pay";
import usePopUpContext from '../../../../hooks/global/usePopUpContext';

const Payment = () => {
  const [phone, setPhone] = useState('');
  const [monthly, setMonthly] = useState(true);
  
  const theme = useTheme();

  const { userId } = useAccess();

  const { itemPayload } = usePopUpContext();

  ////////////////////////////////////////////////////////

  const currency = userId && userId.currency;
  const price = monthly ? (currency === "NGN" ? 2000 : 5) : (currency === "NGN" ? 5000 : 8);

  ////////////////////////////////////////////////////////

  const slideNext = () => {
    const element = document.getElementById("container");

    element.scrollTo({
      left: 1000,
      top: 0,
      behavior: "smooth"
    })

  }

  return (
    <PaymentStyle>
      <div id="container">
        { !itemPayload ? 
        <>
          <div id="intro">
            <div id="header">
              <h2>You are about to go Pro</h2>
            </div>

            <p>Explore the advantages that await you:</p>

            <div id="option">
              <div id="num">1.</div>
              <h2>Advanced Features</h2>
              <div id="text">
                <p>Pro users will have access to advanced features that are not available to free users</p>
              </div>
            </div>

            <div id="option">
              <div id="num">2.</div>
              <h2>Exclusive access</h2>
              <div id="text">
                <p>You'll have exclusive access to contents that are not available to free users, these includes premium articles and pro guides to help you meet portential clients to work with!</p>
              </div>
            </div>

            <div id="option">
              <div id="num">3.</div>
              <h2>Dedicated support team for pro users</h2>
              <div id="text">
                <p>Specialized help for pro user's specific issues</p>
              </div>
            </div>

            <Button run={slideNext} bg='#cf23cf'>Let's go &#8594;</Button>
          </div>

          <div id="payment">
            <p>Ensure to fill in correct informations!</p>

            { userId && 
            <PaymentForm>
              <div id="fieldContainer">
                <label htmlFor="username">Username</label>
                <input readOnly value={userId.userName} type="text" id="username" />
              </div>

              <div id="fieldContainer">
                <label htmlFor="emailAddress">Email Address: </label>
                <input readOnly value={userId.email} type="email" id="emailAddress" />
              </div>

              <div id="fieldContainer">
                <label htmlFor="phoneNumber">Phone Number: </label>
                <input className="inp" value={phone} onChange={(e) => setPhone(e.target.value)} type="number" id="phoneNumber" placeholder="Enter your phone number" />
              </div>
            
              <div id="plans"> {/* Plans */}
                <h4>Plans</h4>
                <div>
                  <div onClick={() => setMonthly(true)} style={{
                    border: `5px solid ${ monthly ? theme.colors.blue : "#FFFFFFAA" }`
                  }}>
                    <div id="monthly">
                      <h3>Monthly</h3>
                      <p>{currency + " " + (currency === "NGN" ? 2000 : 5)}</p>
                    </div>
                  </div>

                  <div onClick={() => setMonthly(false)} style={{
                    border: `5px solid ${ !monthly ? theme.colors.blue : "#FFFFFFAA" }`
                  }}>
                    <div id="anually">
                      <h3>Anually</h3>
                      <p>{currency + " " + (currency === "NGN" ? 5000 : 8)}</p>
                      <small>Save over 70%</small>
                    </div>
                  </div>
                </div>
              </div>

              <div id="plans"> {/* Plans */}
                <p>
                  This subscription will last for {monthly ? "2 Months " : "a Year "}
                  after that you'll have to 
                  subscribe to continure using our services
                </p>
              </div>

              <div id="total">  {/* Total */}
                <h4>Total</h4>
                <div>{currency + " " + price}</div>
              </div>

              <Button bg={theme.colors.blue}> Pay {currency} {price} </Button>
            </PaymentForm>
            }
            
          </div>
        </> : 
          <Pay />
        }
      </div>
    </PaymentStyle>
  )
}

export default Payment;