import { useEffect, useState } from "react";
import { PostStyle } from '../../../../pages/overview/post/styles/S_Post';
import useProxyContext from "../../../../hooks/global/useProxyContext";
import useImgHandler from "../../../../hooks/img/useImgHandler";
import Comments from "../../../../components/popup/modules/comments/Comments";
import PostInfo from '../../../../components/post/postInfo/PostInfo';
import { useNavigate } from 'react-router-dom';
import useAccess from "../../../../hooks/access/useAccess";
import useRefreshContext from "../../../../hooks/global/useRefreshContext";
import { useTheme } from 'styled-components';

import hljs from 'highlight.js';
import 'highlight.js/styles/atom-one-dark.css';
import { FaExternalLinkAlt, FaTimes } from "react-icons/fa";

import DOMPurify from 'dompurify';
import useVisits from "../../../../hooks/stats/visits/useVisits";
import { UploadPreviewStyle } from "./styles/S_UploadPreview";
import useConstructor from "../../../../hooks/constructor/useConstructor";
import Processing from "../../../loader/Processing";

const renderCode = (match, code) => {
  const highlightedCode = hljs.highlightAuto(code).value;
  return `<div id="code"><span id="copy">Copy</span><code id="code-preview" class="javascript">${highlightedCode}</code></div>`;
};

const UploadPreview = ({ details }) => {
  useVisits();

  const [overview, setOverview] = useState(null);

  const { imageEndpoint } = useProxyContext();

  const { getResolution, /* resolution */ addToStared, reachRoute } = useImgHandler();

  const { userId } = useAccess();

  const navigate = useNavigate();

  const { refresh } = useRefreshContext();

  const { getRoute, setUploadPreviewOpen } = useConstructor()

  const theme = useTheme();

  // add seen to upload
  useEffect(() => {
    if(!overview) return;
    if(!userId) return;

    reachRoute(() => {}, overview._id, '/add/seen/upload')
  }, [overview, userId, reachRoute])

  // fetch data
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if(!details.refetch) {
      setOverview(details);
      return;
    }

    getRoute((data) => {
      setOverview(data);
    }, `/get/image/${details._id}`, signal)

    return () => {
      controller.abort();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh])
      
  // check image natural height and natureal width (resolution) 
  useEffect(() => {
    getResolution(overview);

    setTimeout(() => {
      getResolution(overview);
    }, 3000);
  }, [overview, getResolution])

  // if overview
  useEffect(() => {
    const post = document.querySelector('#postStyle')

    if(post) {
      if(overview) {
        post.classList.add('fade-in');
      }
    }

  }, [overview])

  const preview = (e) => {
    // all image to preview
    const image = document.querySelectorAll('#image');

    // remove active color from all
    for (let i = 0; i < image.length; i++) {
      image[i].style.border = `5px solid ${theme.colors.black}50`
    }

    // The image previewd
    const previewContainer = document.querySelector('#previewImage')
    
    //Animation
    setTimeout(() => {
      previewContainer.style.opacity = 0;
      previewContainer.style.transform = "translateY(-5%)";
    }, 0);

    setTimeout(() => {
      previewContainer.style.transform = "translateY(5%)";
    }, 150);
    
    setTimeout(() => {
      previewContainer.style.opacity = 1;
      previewContainer.style.transform = "translateY(0%)";
      previewContainer.src = e.target.src;
    }, 300);
    
    // add active color to the clicked image 
    e.target.style.border = `5px solid ${theme.colors.main2}`
  }

  // Close preview
  const closeUploadPreview = () => {
    setUploadPreviewOpen(false);
  }
  
  // close preview on esc key pressed
  useEffect(() => {
    window.addEventListener('keydown', (e) => {
      if(e.key === 'Escape') {
        setUploadPreviewOpen(false);
      }
    })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const navigateTo = (url) => {
    setUploadPreviewOpen(false);
    navigate(url);
  }

  return (
    <UploadPreviewStyle>
      <div id="closeBtn" onClick={closeUploadPreview}>
        <FaTimes style={{
      }} /> <span>Close</span></div>

      { overview ? (
        <>

          <div>
            <PostStyle pro={overview.pro} id="postStyle">
              <div id="preview">
                <img id="previewImage" src={imageEndpoint + "/images/" + overview.src} alt=" "/>
                
                {/* <div id="resolution">
                  <span>{resolution}</span>
                </div> */}

                { overview.images && overview.images.length !== 1 &&
                <div id="images">
                  {overview.images.map((image, i) => (
                    <img id="image" onClick={preview} key={i} src={imageEndpoint + "/images/" + image} alt=" "/>
                  ))}
                </div>
                }
              </div>

              <div id="details">
                <PostInfo 
                  post={overview}
                  star={() => addToStared(overview._id, overview.src, overview.type)} 
                  userPP={ overview.ownerPP ? (imageEndpoint + "/images/" + overview.ownerPP) : ('/images/profile.jpg') }
                />

                <h2>{overview.name}</h2>

                <main>
                  <Desc content={overview.desc} />
                </main>

                <div id="addition">
                  {overview.pro && 
                    <div className="flex" id="price">
                      <h3>Price</h3>
                      <span><strong>{`${overview.currency} ${overview.price}`}</strong></span>
                    </div>
                  }

                  {overview.collectionName && 
                  <div className="flex">
                    <h3>Collection</h3>
                    <span 
                      style={{ cursor: 'pointer' }}
                      onClick={() => navigateTo(`/collection/${overview.collectionId}`)}
                      id="collection"
                    ><FaExternalLinkAlt /> <strong>{overview.collectionName}</strong>  </span>
                  </div>
                  }

                  <div className="flex">
                    <h3>Type</h3>
                    <span>{overview.type}</span>
                  </div>

                  <div id="tags">
                    {overview.tags.map((tag, i) => (
                      <span key={i}>{tag.value}</span>
                    ))}
                  </div>
                </div>

                <div>
                  <Comments post={overview} />
                </div>

              </div>
            </PostStyle>
          </div>

        </>
      ) : (
        <Processing />
      )}
    </UploadPreviewStyle>
  )
}

const Desc = ({ content }) => {

  function copyCode(codeElement, copyElement) {
    const range = document.createRange();
    range.selectNodeContents(codeElement);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand('copy');
    selection.removeAllRanges();
    copyElement.innerHTML = 'Copied!'
    setTimeout(() => copyElement.innerHTML = 'Copy', 3000)
  }
  
  useEffect(() => {
    const copyElement = document.querySelectorAll('#copy');
    const codeElement = document.querySelectorAll('#code-preview');

    for (let i = 0; i < copyElement.length; i++) {
      const copyEl = copyElement[i];
      const codeEl = codeElement[i];
      
      copyEl.addEventListener('click', () => {
        copyCode(codeEl, copyEl)
      })
    }
  }, [])
  
  // Replace code blocks with <pre><code> tags
  const contentWithCode = content.replace(
    /```([\s\S]*?)```/gm,
    (match, code) => renderCode(match, code)
  );

  // Replace URLs with <a> tags
  const contentWithLinks = contentWithCode.replace(
    /(https?:\/\/[^\s]+)/g,
    '<a id="linkTag" href="$1">$1</a>'
  );

  // Replace *text* with <strong> tags
  const contentWithStrong = contentWithLinks.replace(
    /\*\*(.+?)\*\*/g,
    '<strong>$1</strong>'
  );

  // Replace _text_ with <em> tags
  const contentWithEm = contentWithStrong.replace(
    /_(.+?)_/g,
    '<em>$1</em>'
  );

  // Encode less-than (<) and greater-than (>) characters in code blocks
  const contentWithEncodedCode = contentWithEm.replace(
    /<pre><code class="javascript">([\s\S]*?)<\/code><\/pre>/gm,
    match => match.replace(/</g, '&lt;').replace(/>/g, '&gt;')
  );

  // Wrap content in a <p> tag
  const contentWithP = `<p>${contentWithEncodedCode}</p>`;

  // Purify
  const sanitizedHTML = DOMPurify.sanitize(contentWithP);

  return <pre id="desc" dangerouslySetInnerHTML={{ __html: sanitizedHTML }} ></pre>

}

export default UploadPreview;