import styled from 'styled-components';

export const PopUpStyle = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  z-index: 300;
  user-select: none;

  &.fade-in { 
    animation: fadeIn 0.5s cubic-bezier(0.14, 1.28, 0.94, 1.53) forwards 
  }

  @keyframes fadeIn { 
    from { 
      background: ${({ theme }) => theme.colors.black}AA;
    } to { 
      background: #000000AA;
    }
  }
  
  @media(max-width: ${({ theme }) => theme.mobile.is550}) {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  & > div[id="createOptions"] {
    width: auto;

    @media(max-width: ${({ theme }) => theme.mobile.is600}) {
      width: calc(100% - 40px);
    }
    
    & > div {
      display: flex;
      grid-gap: 20px;

      @media(max-width: ${({ theme }) => theme.mobile.is600}) {
        flex-direction: column;
      }

      & > span {
        display: flex;
        align-items: center;
        grid-gap: 10px;
        font-size: 20px;
        padding: 20px;
        border: 1px solid ${({ theme }) => theme.colors.main2};
        border-radius: 10px;
        cursor: pointer;
        color: ${({ theme }) => theme.colors.black};
        
        & > svg {
          color: ${({ theme }) => theme.colors.main2};
        }
      }
    }
  }

  & > div[id="tools"] {
    & > div {
      display: flex;
      flex-direction: column;
      grid-gap: 20px;

      & > div {
        display: flex;
        align-items: center;
        grid-gap: 20px;
        padding: 10px;
        border: 1px solid ${({ theme }) => theme.colors.main2}50;
        border-radius: 10px;
        background: linear-gradient(to right bottom, ${({ theme }) => theme.colors.main}05, ${({ theme }) => theme.colors.main2}05);
        cursor: pointer;

        & > img {
          min-width: 130px;
          max-width: 130px;
          min-height: 130px;
          max-height: 130px;
          border-top-right-radius: 50%;
          border-bottom-right-radius: 10px;
          border-bottom-left-radius: 10px;
          border-top-left-radius: 10px;
        }

        & > div {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          & > span {
            & > h2 {
              font-size: 20px;
            }
            
            & > p {
              margin: 10px 0;
            }
          }

          & > small {
            text-align: right;
            color: ${({ theme }) => theme.colors.main2};
          }
        }
        
        @media(max-width: ${({ theme }) => theme.mobile.is480}) {
          flex-direction: column;
          
          & > img {
            min-width: 100%;
            max-height: 200px;
            height: 150px;
            border-radius: 10px;
          }

          & > div > span {
            & > h2 {
              font-size: 17px;
            }

            & > p {
              font-size: 15px;
            }
          }
        }
      }
    }
  }

  & > div[id="genPreview"] {

    & > div {
      & > img {
        border-radius: 5px;
        min-height: 250px;
        background: ${({ theme }) => theme.colors.black}10;
      }

      & > div[id="options"] {
        display: flex;
        grid-gap: 10px;
        margin-top: 10px;

        @media(max-width: ${({ theme }) => theme.mobile.is330}) {
          flex-direction: column;
        }

        & > button {
          width: 100%;

          &:last-child {
            color: ${({ theme }) => theme.colors.black}AA;
            background: linear-gradient(to top right, ${({ theme }) => `${ theme.colors.main }50 , ${ theme.colors.main2 }50`});
          }
        }
      }
    }
  }

  & > div[id="fundWallet"] {
    & > div {
      display: flex;
      flex-direction: column;
      grid-gap: 20px;

      & > header {
        & > h1 {
          margin-bottom: 10px;
        }
      }

      & > form {
        display: flex;
        flex-direction: column;
        grid-gap: 20px;

        & > input {
          padding: 15px;
          border-radius: 50px;
          font-size: 20px;
          width: 100%;
        }

        & > button {
          font-size: 20px;
          padding: 15px;

          background: linear-gradient(to top right, ${({ theme }) => `${ theme.colors.main }50 , ${ theme.colors.main2 }50`});
   
          &:hover {
            background: linear-gradient(to bottom, ${({ theme }) => `${ theme.colors.main2 }AA, ${ theme.colors.main }AA` });
          }
        }
      }
    }
  }

  & > div[id="initiatePaymentVerification"] {
    & > div {
      display: flex;
      flex-direction: column;
      grid-gap: 10px;

      & > button {
        background-color: ${({ theme }) => theme.colors.main2};
        color: white;
        padding: 20px;
        border: none;
        border-radius: 10px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
        transition: transform 0.1s ease-in-out;
        font-size: 20px;

        &:hover {
          transform: scale(0.9);
        }

        &[id="confirmVerification"] {
          background: ${({ theme }) => theme.colors.main};
        }
      }
    }
  }
`;

export const Frame = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  
  width: 500px;
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 20px;
    background: ${({ theme }) => theme.colors.lightgrey}55;
    border-radius: 50px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.black}20;
    border-radius: 50px;
  }
  
  padding: 40px;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 1px 1px 150px rgba(0,0,0,0.3);

  // animation
  opacity: 0;
  transform: translateY(5%);
  
  &.fade-in { opacity: 0; animation: fadeInFromBottom 0.5s cubic-bezier(0.14, 1.28, 0.94, 1.53) forwards }
  @keyframes fadeInFromBottom { from { opacity: 0; transform: translateY(5%) } to { opacity: 1; transform: translateY(0) }}

  & > div[id="items"] {
    display: flex;
    flex-direction: column;
    gap: 15px;

    & > div {
      background: ${({ theme }) => theme.colors.lightgrey}20;
      border-radius: 5px;
      border: 1px solid ${({ theme }) => theme.colors.black}50;
    
      &:hover {
        background: ${({ theme }) => theme.colors.black}20;
      }

      & > div[id="flex"] {
        padding: 12px;
        display: flex;
        justify-content: space-between;

        & p {
          font-weight: 600;
        }

        & > div:first-child {
        }

        & > div:last-child {
          display:flex;
          align-items: center;
          cursor: pointer;
  
          & > svg {
            color: ${({ theme }) => theme.colors.black};
          }
        }
      }

      & > div[id="types"] {
        padding: 12px;

        & p {
          font-weight: 600;
        }

        & > div {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        & svg[id="checkBoxIcons"] {
          color: ${({ theme }) => theme.colors.black};
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.mobile.is550}) {
    padding: 20px;
    width: 90%;
    margin-bottom: 20px;
    backdrop-filter: blur(25px);
  }
`;