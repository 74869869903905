import Posts from "./modules/posts/Posts";
import Search from "./modules/search/Search";
import TopBar from '../../components/topbar/TopBar';
import Footer from '../../components/footer/Footer';
import { Container } from '../../components/global/Container';
import { BlogStyle } from './styles/S_Blog';
import { useEffect, useState } from 'react';
import axios from 'axios';
import useProxyContext from '../../hooks/global/useProxyContext';
import { Helmet } from "react-helmet-async";
import useVisits from "../../hooks/stats/visits/useVisits";

const Blog = () => {
  useVisits();

  const [posts, setPosts] = useState(null);

  const { endpoint } = useProxyContext();

  useEffect(() => {
    (async function(){
      try {
        const res = await axios.get(`${endpoint}/all/blog/post`);

        setPosts(res.data);

      } catch (err) {
        console.log(err);
      }
    }())
  }, [endpoint])

  return (
    <BlogStyle>
      <Helmet>
        <title>Model Grapix | Blog</title>

        <meta name="description" content={ `Get the Inside Scoop on the Latest Trends` } />

        <meta property="og:title" content={ `Model Grapix | Blog` } />
        <meta property="og:description" content={ `Get the Inside Scoop on the Latest Trends` } />
        <meta property="og:image" content={ `/images/logos/20230125_074855.png` } />
        <meta property="og:url" content="htts://modelgrapix.com" />

        <link rel="icon" href='/images/logos/20230125_074855.png' />
      </Helmet>

      <TopBar />
      
      <Search />

      <Container>
        <Posts posts={posts} />
      </Container>

      <Footer />
    </BlogStyle>
  )
}

export default Blog;