import { createContext, useState } from "react";

export const DataContext = createContext();

const DataContextProvider = ({ children }) => {
  // Data from index page

  // Public collection
  const [publicCollections, setPublicCollections] = useState(null);

  const [uploads, setUploads] = useState(null);
  const [users, setUsers] = useState(null);

  return (
    <DataContext.Provider value={{
      publicCollections,
      setPublicCollections,

      uploads,
      setUploads,

      users,
      setUsers,
    }}> { children } </DataContext.Provider>
  )
}

export default DataContextProvider;