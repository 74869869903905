import useThemeContext from '../../hooks/useThemeContext';
import { useColorTheme } from './Color';
import { MobileTheme } from './Mobile';

export const useThemeHook = () => {
  const colorTheme = useColorTheme();
  const { darkTheme } = useThemeContext();

  const theme = {
    colors: colorTheme.colors,
    mobile: MobileTheme.mobile,
    dark: darkTheme
  }

  return theme;
}