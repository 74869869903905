import { createContext, useEffect, useState } from "react";
import useAuthContext from '../../hooks/auth/useAuthContext';
import useAdminAuthContext from '../../hooks/admin/auth/useAdminAuthContext';
import useProxyContext from '../../hooks/global/useProxyContext';
import useErrHandler from '../../hooks/err/useErrHandler';
import axios from 'axios';

export const AccessContext = createContext();

const AccessContextProvider = ({ children }) => {
  const [userId, setUserId] = useState(null);
  const [adminId, setAdminId] = useState(null);
  
  const { user } = useAuthContext();

  const { admin } = useAdminAuthContext();

  const { endpoint } = useProxyContext();

  const { notifier } = useErrHandler();

  useEffect(() => {
    if(!navigator.onLine) return;

    (async function() {
      try {
        if(user) {
          const res = await axios.get(`${endpoint}/userid`, {
            headers: { "Authorization": `Bearer ${user.token}` }
          });

          setUserId(res.data);
        }
      } catch (err) {
        if(err.code === "ERR_NETWORK") {
          notifier(true, 'Network Error!');
          
        } else {
          const errMessage = err.response.data.message;
          notifier(true, errMessage);
        }
      }
    }())
  }, [user, endpoint, notifier]) // recently added notifier ♦♦♦ 

  useEffect(() => {
    (async function () {
      try {
        if(admin) {
          const res = await axios.get(`${endpoint}/adminid`, {
            headers: { "Authorization": `Bearer ${admin.token}` }
          });

          setAdminId(res.data);
        }
      } catch (err) {
        localStorage.removeItem('admin');
        window.location.reload();
      }
    }())
  }, [admin, endpoint])

  const getUserId = async (callback) => {
    try {
      if(user) {
        const res = await axios.get(`${endpoint}/userid`, {
          headers: { "Authorization": `Bearer ${user.token}` }
        });
        
        callback(res.data);
      }
    } catch (err) {
      console.log(err);
      const errMessage = err.response.data.message;
      notifier(true, errMessage);
    }
  }

  return(
    <AccessContext.Provider value={{ userId, adminId, getUserId }}>
      {children}
    </AccessContext.Provider>
  )
}

export default AccessContextProvider;