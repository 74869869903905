import { useContext } from "react";
import { RefreshContext } from "../../contexts/global/RefreshContext";

const useRefreshContext = () => {
  const context = useContext(RefreshContext);

  if(!context) throw Error('You can only use this context inside its wrapped contents');

  return context;
}
 
export default useRefreshContext;