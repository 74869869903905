import styled from 'styled-components';

export const UploadStyle = styled.div`
  & > div:nth-child(2) { // container
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-top: 100px;
    padding-bottom: 100px;

    & > div {
      display: flex;
      gap: 40px;

      @media (max-width: ${({ theme }) => theme.mobile.is900}) {
        flex-flow: wrap;
      }
    }
  }

  & div[id="upload"] {
    display: flex;
    flex-direction: column;
    gap: 20px;

    & div[id="mainCover"] {
      
      & > div {
        border-radius: 10px;
        position: relative;
        height: 400px;
        width: 400px;
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;
        background: ${({ theme }) => theme.colors.white};
        border: 1px solid ${({ theme }) => theme.colors.main2};
        font-size: 30px;
        color: ${({ theme }) => theme.colors.main2};
        overflow: hidden;
  
        & > img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          display: none;
        }

        
        
        & input { display: none }
        
        & label {
          position: relative;
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          cursor: pointer;
        }
  
        & svg {
          font-size: 50px;
        }
      }

    }

    @media (max-width: ${({ theme }) =>  theme.mobile.is500}) {
      width: 100%;
      
      & div[id="mainCover"] {
        & > div {
          width: 100%;
          height: 35vh;

          & label {
            font-size: 20px;
          }

          & svg {
            font-size: 40px;
          }
        }
      }
    }

    & > div[id="others"] {
      display: flex;
      gap: 10px;
      overflow-x: auto;

      &::-webkit-scrollbar { display: none }
      
      & > div {
        position: relative;
        border-radius: 10px;
        height: 100px;
        min-width: 100px;
        background: ${({ theme }) => theme.colors.white};
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid ${({ theme }) => theme.colors.main2};
        overflow: hidden;

        & > img {
          display: none;
          height: 100%;
          border-radius: 10px;
      }

        & > input {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          cursor: pointer;
          opacity: 0;
        }
        
        & svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size 25px;
          color: ${({ theme }) => theme.colors.black}50;
        }
      }
    }
  }

  & div[id="details"] {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    & > div, input, select, textarea {
      background: ${({ theme }) => theme.colors.white};
      border: none;
      padding: 15px;
      box-shadow: 1px 2px 1px #00000025;
      font-size: 19px;
      color: ${({ theme }) => theme.colors.black};
      resize: none;
      // background: red;
      border-radius: 10px;
    }

    & > span {
      display: flex;
      flex-direction: column;
      gap: 10px;

      & > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    & div[id="tags"] {
      display: flex;
      flex-direction: column;
      gap: 10px;

      & > div {

        & > div {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          gap: 10px;
          
          & > div {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 10px;

            & span {
              padding: 12px 15px;
              display: flex;
              align-items: center;
              gap: 10px;
              background: linear-gradient(to top right, ${({ theme }) => `${ theme.colors.main }50 , ${ theme.colors.main2 }50`});
              border-radius: 30px;
  
              & > div {
                height: 20px;
                width: 20px;
                border-radius: 50%;
                background: ${({ theme }) => theme.colors.black}AA;
              }

              @media (max-width: ${({ theme }) =>  theme.mobile.is500}) {
                padding: 7px 15px;
                font-size: 15px;

                & > div {
                  height: 15px;
                  width: 15px;
                }
              }
  
            }
          }

          & input {
            box-shadow: none;
            outline: none;
          }
        }
      }
    }

    & div[id="pro"] {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    & > button {
      position: relative;

      background: linear-gradient(to top right, ${({ theme }) => `${ theme.colors.main }50 , ${ theme.colors.main2 }50`});
      
      &:hover {
        background: linear-gradient(to bottom, ${({ theme }) => `${ theme.colors.main2 }AA, ${ theme.colors.green }AA` });
      }
      
      & > svg {
        font-size: 20px;
      }
    }

    & div[id="collection"] {
      display: flex;
      justify-content: space-between;
      align-items: center;
      
    }
  }

  div[id="uploadMore"] {
    border-top: 1px solid ${({ theme }) => theme.colors.black};
    padding: 12px;
    display: flex;
    justify-content: center;

    & span {
      background: ${({ theme }) => theme.colors.lightgrey};
      height: 50px;
      width: 50px;
      display: grid;
      place-items: center;
      border-radius: 50%;

      &:hover {
        border: 1px solid ${({ theme }) => theme.colors.black};
      }
    }
  }
`;