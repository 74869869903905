import styled from 'styled-components';

export const CollectionsStl = styled.div`
  // padding: 100px 0;
`;

export const CollectionsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  // gap: 50px;
  
  & > div[id="btn"] {
    display: flex;
    justify-content: center;

    & > button { 
      margin: 50px 0;
      background: linear-gradient(to right top, #f32170, #cf23cf, #eedd44);
      color: #FFFFFF;
    }
  }
`;

export const CollectionStl = styled.div`
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Items = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  
  & > div[id="header"] {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    
    & > div[id="status"] {
      display: flex;
      align-items: center;
      overflow: hidden;
  
      & > header {
        white-space: nowrap;
        display: flex;
        align-items: center;
        gap: 20px;

        & img {
          height: 50px;
          min-width: 50px;
          max-width: 50px;
          border-radius: 5px;
          object-fit: cover;
          cursor: pointer;
          transition: all cubic-bezier(0.42, 0, 0, 1.57) 0.2s;

          &:hover {
            transform: scale(0.9);
            opacity: 0.7;
          }
        }

        & > span {
          & > div[id="collection_info"] {

            & > div {
              display: flex;
              align-items: center;
              gap: 10px;

              & > span {
                display: flex;
                align-items: center;
                font-family: "Jost";

                & > span {
                  display: flex;
                  align-items: center;
                  color: ${({ theme }) => theme.colors.black};
                  margin-left: 10px;

                  &:first-child {
                    margin-left: 0;
                    cursor: pointer;
                  }

                  &:nth-child(2) {
                    & > svg {
                      color: ${({ theme }) => theme.colors.main2}AA;
                    }
                  }

                  & > svg {
                    margin-right: 5px;
                  }

                  @keyframes bounce {
                    0% {
                      transform: scale(1);
                    }
                    50% {
                      transform: scale(1.2);
                    }
                    100% {
                      transform: scale(1);
                    }
                  }

                  &[id="likeBtn"].clicked {
                    animation: bounce 0.2s linear;
                  }
                }
              }
            }
          }
        }
        
        @media(max-width: ${({ theme }) => theme.mobile.is500}) {
          & > span {
            & h2 {
              font-size: 16px;
            }
          }

          & img {
            height: 40px;
            min-width: 40px;
            max-width: 40px;
          }
        }
      }
  
    }

    & > div[id="options"] {
      display: flex;
      gap: 10px;
      border-left: 10px solid ${({ theme }) => theme.colors.theme};
      overflow-x: scroll;
      scrollbar-width: none;

      &::-webkit-scrollbar { 
        display: none;
        width: 0px;
        height: 0px;
      }

      @media (max-width: ${({ theme }) => theme.mobile.is500}) {
        min-width: 100px;
        max-width: 100px;
      }

      & > span {
        display: flex;
        gap: 5px;
        align-items: center;
        padding: 10px;
        height: 40px;

        border-radius: 10px;
        color: ${({ theme }) => theme.colors.black};

        & svg {
          font-size: 19px;
        }

        @media (max-width: ${({ theme }) => theme.mobile.is640}) {
          & > small {
            display: none;
          }
        }
      }

      & > span[id="pending"] {
        background: ${({ approved }) => approved ? "#4bb54350" : "#FFA50050"};
      }

      & > span[id="stateSwitch"] {
        background: ${({ global }) => global ? "#4bb54350" : "#FFA50050"};
        cursor: pointer;
      }

      & > span[id="deleteBtn"] {
        background: linear-gradient(to top right, ${({ theme }) => `${ theme.colors.main }50 , ${ theme.colors.main2 }50`});
        
        &:hover {
          background: ${({ theme }) => theme.colors.main}AA;
        }

        cursor: pointer;
      }
    }
    
    & > div[id="publicOptions"] {
      border-left: 10px solid ${({ theme }) => theme.colors.theme};

      & > h4[id="showAll"] {
        white-space: nowrap;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        & > span {
          color: ${({ theme }) => theme.colors.main2};
        }

        & > svg {
          color: ${({ theme }) => theme.colors.main2};
        }
      }
    }

    & > div[id="optionsMenu"] {
      display: none;

      @media(max-width: ${({ theme }) => theme.mobile.is500}) {
        // display: block;
      }
    }
  }

  & > div[id="itemsContainer"] {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 100px;
      background: linear-gradient(to right, #FFFFFF00, ${({ theme }) => theme.colors.theme});
      z-index: 1;
      pointer-events: none;
    }

    & > div {
      display: flex;
      padding: 5px 0;
      scrollbar-width: none;

      & > div {
        margin-right: 30px;
        margin-bottom: 30px;

        &:last-child { 
          margin-right: 0
        }
      }

      overflow-x: auto;

      @media (max-width: ${({ theme }) => theme.mobile.is690}) {
        & div[id="post"] {
          max-width: 200px;
          min-width: 200px;

          & > main {
            height: 200px;
          }

          & > div[id="global"] {
            display: none;
          }
        }
      }

      &::-webkit-scrollbar { 
        display: none 
        width: 0px;
        height: 0px;
      }
    }
  }
`;