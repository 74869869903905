import { DocumentationStyle, ModulesStyle } from "./styles/S_Documentation"
import Topbar from '../../components/topbar/TopBar'
import Footer from '../../components/footer/Footer'
import { Container } from '../../components/global/Container'
import Nav from './modules/nav/Nav';
import { Helmet } from "react-helmet-async";
import useVisits from "../../hooks/stats/visits/useVisits";
import UseOfApi from "./modules/useOfApi/UseOfApi";
import UseOfPlatform from "./modules/useOfPlatform/UseOfPlatform";
import { useEffect } from "react";

const Documentation = () => {
  useVisits();

  useEffect(() => {
    const section = document.baseURI.split("#")[1];

    if(!section) return;

    const targetElement = document.querySelector(`#${section}`);

    setTimeout(() => {
      const targetDivOffset = targetElement.offsetTop;
      window.scrollTo({
        top: targetDivOffset,
        behavior: 'smooth',
      });
    }, 1000)

  }, [])

  return (
    <DocumentationStyle>
      <Helmet>
        <title>Model Grapix - Documentation</title>

        <meta name="description" content="A guide to using this platform" />

        <meta property="og:title" content="Model Grapix - Documentation" />
        <meta property="og:description" content="A guide to using this platform" />

        <link rel="icon" href='/images/logos/20230125_074855.png' />
      </Helmet>

      <Topbar />

      {/* Header */}
      <div id="header">
        <h2>Model Grapix Documentation</h2>
        <p>A guide to using this platform</p>
      </div>

      <Container id="container">

        <div id="wrapper"> {/* Wrapper nav and contents */}
          {/* Nav */}
          <div id="nav">
            <Nav />
          </div>

          {/* Body */}
          <ModulesStyle>
            {/* Use of Platform  */}
            <UseOfPlatform />

            {/* Use of API  */}
            <UseOfApi />
          </ModulesStyle>
        </div>

      </Container>

      <Footer />

    </DocumentationStyle>
  )
}

export default Documentation;