import{ createContext, useState } from 'react';

export const ErrHandler = createContext();

const ErrHandlerProvider = ({children}) => {
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const notifier = (err, mesg) => {
    setError(err);
    setMessage(mesg);
  }

  const removeError = () => {
    setMessage("");
  }

  return (
    <ErrHandler.Provider value={{ error, message, notifier, removeError }}>
      {children}
    </ErrHandler.Provider>
  )
}

export default ErrHandlerProvider;