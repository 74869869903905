import { useContext } from "react";
import { Processer } from "../../contexts/processer/Processer";

const useProcesser = () => {
  const context = useContext(Processer);

  if(!context) throw Error('You can only use this context inside its wrapped contents');

  return context;
}
 
export default useProcesser;