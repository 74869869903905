import { useEffect, useState } from "react";
import useRefreshContext from "../../hooks/global/useRefreshContext";
import { Collections, Posts, Reports, Visitors } from "./modules"
import Nav from "./modules/nav/Nav"
import { CpanelStyle } from "./styles/S_Cpanel"
import { Container } from '../../components/global/Container';
import useAccess from "../../hooks/access/useAccess";
import useAdminAuthContext from "../../hooks/admin/auth/useAdminAuthContext";
import axios from "axios";
import useProxyContext from "../../hooks/global/useProxyContext";
import Create from '../blog/Create'
import Update from '../blog/Update'
import Admin from '../blog/Admin'
import { Helmet } from "react-helmet-async";
import useVisits from "../../hooks/stats/visits/useVisits";

const Cpanel = () => {
  useVisits(); // won't work consider removing later

  const [collections, setCollections] = useState(null);

  const path = document.baseURI.split("/")[5];

  const { adminId } = useAccess();

  const { admin } = useAdminAuthContext();

  const { endpoint } = useProxyContext();

  const { refresh } = useRefreshContext();

  // FETCH ITEMS useEFFECTHOOK
  useEffect(() => {
    if(admin) {
      (async function(){
        try {
  
          const res = await axios.get(`${endpoint}/all/collections`, {
            headers: {
              'Authorization': `Bearer ${admin.token}`
            }
          });
          
          setCollections(res.data);
          
        } catch (err) {
          console.log(err);
        }
      }())
    }
  }, [admin, refresh, endpoint])

  return (
    <>
      {/* Nav */}
      <Nav />
      <Helmet>
        <title>Model Grapix | Admin</title>

        <link rel="icon" href='/images/logos/20230125_074855.png' />
      </Helmet>

      { adminId &&
      <CpanelStyle>
        <Container id="container">

          {/* Body */}

          {/* Collections */}
          {path === 'collections' && 
            <Collections className="general" collections={collections} />
          }

          {/* Posts */}
          {path === 'posts' && 
            <Posts />
          }

          {/* Visitors */}
          {path === 'visitors' && 
            <Visitors />
          }

          {/* Reports */}
          {path === 'reports' && 
            <Reports />
          }

        </Container>

          {/* Create post */}
          {path === 'create' && 
            <Create />
          }

          {/* Manage posts */}
          {path === 'manage' && 
            <Admin />
          }

          {/* Edit post */}
          {path.split('-')[0] === 'edit' && 
            <Update />
          }

      </CpanelStyle>
      }
    </>
  )
}

export default Cpanel