import { PostsStyle } from "./S_Posts";

const Posts = () => {
  return (
    <PostsStyle className="general">
      Posts
    </PostsStyle>
  )
}

export default Posts;