export const image_gen_js = 
`\`\`\`
const axios = require("axios");

async function generateImage() {
  const endpoint = "https://api.modelgrapix.com/api/image-gen-v1"
  
  try {
    const response = await axios.post(endpoint, {
      prompt: "A cute baby elephant",
      upscale: true,
      display: "square",
      paint: "PUBLIC"
    }, {
      headers: {
        Authorization: \`Bearer \${YOUR_API_KEY}\`, //Replace with your actual API key
      }
    });

    // Handle the response data here
    console.log("Response:", response.data);

  } catch (error) {
    // Handle any errors here
    console.error("Error:", error);
  }
}

// Call the function to generate the image
generateImage();
\`\`\``

export const bg_remover_js = 
`\`\`\`
const axios = require("axios");

async function removeBackground(imageFile) {
  const endpoint = "https://api.modelgrapix.com/api/bg-remover-v1";
  const formData = new FormData();

  formData.append("image_file", imageFile); // Make sure "imageFile" is defined

  try {
    const response = await axios.post(endpoint, formData, {
      headers: {
        Authorization: \`Bearer \${YOUR_API_KEY}\` //Replace with your actual API key
      }
    });

    // Handle the response data here
    console.log("Response:", response.data);

  } catch (error) {
    // Handle any errors here
    console.error("Error:", error);
  }
}

// Call the function to remove the background
const imageFile = ...; // Define your image file
removeBackground(imageFile);
\`\`\``

export const inpainting_js = 
`\`\`\`
const axios = require("axios");
const FormData = require("form-data");

async function performInpainting(originalImage, maskImage) {
  const endpoint = "https://api.modelgrapix.com/api/inpainting";
  
  const formData = new FormData();
  formData.append("image_file", originalImage);
  formData.append("mask_file", maskImage);

  try {
    const response = await axios.post(endpoint, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: \`Bearer \${YOUR_API_KEY}\`, // Replace with your actual API key
      },
    });

    // Handle the response data here
    console.log("Response:", response.data);
  } catch (error) {
    // Handle any errors here
    console.error("Error:", error);
  }
}

// Call the function to perform inpainting
const originalImage = ...; // Define your original image file
const maskImage = ...; // Define your mask image file
performInpainting(originalImage, maskImage);
\`\`\``