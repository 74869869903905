import styled from 'styled-components';

export const PostStyle = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  opacity: 0;
  transform: translateY(5%);

  &.fade-in {
    opacity: 0;
    animation: fadeInFromBottom 0.3s cubic-bezier(0.11, 0.79, 0.31, 1.45) forwards;
  }

  @keyframes fadeInFromBottom {
    from {
      opacity: 0;
      transform: translateY(5%);
    } to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  max-width: 1000px;
  margin: 100px auto;
  
  @media(max-width: ${({ theme }) => theme.mobile.is500}) {
    margin: 0;
  }
  
  & div[id="preview"] {
    padding: 20px; //

    @media(max-width: ${({ theme }) => theme.mobile.is500}) {
      border-radius: 0;
    }

    position: relative;
    border-radius: 10px;

    & img {
      width: 100%;
      border-radius: 10px;
      object-fit: cover;
      transition: 0.3s cubic-bezier(0.22, 0.25, 0, 1.01);
    }
    
    // & > div[id="resolution"] {
    //   position: absolute;
    //   left: 30px;
    //   right: 0;
    //   bottom: 35px;
    //   padding: 20px;

    //   & span {
    //     text-shadow: 1px 1px 0 ${({ theme }) => theme.colors.white};
    //   }
    // }

    & > div[id="images"] {
      padding: 20px;
      display: flex;
      gap: 20px;
      justify-content: center;

      & > img {
        max-width: 100px;
        border: 5px solid ${({ theme }) => theme.colors.black}50;
        cursor: pointer;
        
        &:first-child {
          border: 5px solid ${({ theme }) => theme.colors.main2};
        }
      }

      @media(max-width: ${({ theme }) => theme.mobile.is500}) {
        gap: 10px;

        & > img {
          max-width: 60px;
        }
      }
    }
  }

  & > div[id="details"] {
    padding: 20px; //

    position: relative;
    display: flex;
    flex-direction: column;
    grid-gap: 50px;

    & > main {
      & pre {
        white-space: pre-wrap;
        font-family: 'Poppins', sans-serif;
        font-size: 18px;
        line-height: 28px;
        color: ${({ theme }) => theme.colors.black};
        text-align: justify;
      }
  
      & div[id="code"] {
        position: relative;
        overflow-x: scroll;
        border-radius: 10px;
        background: ${({ theme }) => theme.colors.black};
        max-width: 100%;
    
        &::-webkit-scrollbar { display: none }
    
        & > span[id="copy"] {
          position: sticky;
          display: block;
          width: 100%;
          left: 0;
          text-align: right;
          font-size: 12px;
          margin-top: 0;
          padding: 0px 10px;
          background: #444444;
          color: #FFFFFF;
          cursor: pointer;
        }
        
        & code {
          display: block;
          width: 500%;
          border-radius: 10px;
          background: ${({ theme }) => theme.colors.black};
          color: ${({ theme }) => theme.colors.white};
          // margin-top: -35px;
          padding: 10px;
        }
      }

      & a[id="linkTag"] {
        border-radius: 30px;
        padding: 5px;
        margin-bottom: -10px;
        background: ${({ theme }) => theme.colors.black}05;
    
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 200px;
      }
    }

    & > div[id="addition"] {
      display: flex;
      flex-direction: column;
      grid-gap: 50px;
      padding: 20px;
      background: ${({ theme }) => theme.colors.white};
      border-radius: 10px;
      border: 1px solid ${({ theme }) => theme.colors.black}50;
    }

    & div[class="flex"] {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: ${({ theme }) => theme.colors.black};

      & > span[id="collection"] {
        overflow: hidden;
        white-space: nowrap;
        margin-left: 20px;
        display: flex;
        align-items: center;
        padding-right: 30px;

        & > svg {
          position: absolute;
          right: 0;
          font-size: 25px;
          background: ${({ theme }) => theme.colors.white};
          border-left: 10px solid ${({ theme }) => theme.colors.white};
        }
      }
    }
    
    & div[id="tags"] {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
  
      & span {
        padding: 10px 20px;
        border-radius: 50px;
        background: ${({ theme }) => theme.colors.black}10;
        color: ${({ theme }) => theme.colors.black};
        border: 1px solid ${({ theme }) => theme.colors.main2}AA;
      }
    }
  }
`;