import { UploadStyle } from "./styles/S_Upload";
import { Container } from '../../components/global/Container';
import TopBar from "../../components/topbar/TopBar";
import { IoMdCloudUpload } from 'react-icons/io';
import { FaPlus, FaAngleDown } from "react-icons/fa";
import Button from "../../components/button/Button";
import { useEffect, useState } from 'react';
import usePopUpContext from '../../hooks/global/usePopUpContext';
import useProxyContext from '../../hooks/global/useProxyContext';
import axios from 'axios';
import useAuthContext from "../../hooks/auth/useAuthContext";
import useErrHandler from "../../hooks/err/useErrHandler";
import { useNavigate } from "react-router-dom";
import useAccess from "../../hooks/access/useAccess";
import useProcesser from "../../hooks/processer/useProcesser";
import useProfile from "../../hooks/profile/useProfile";
import { Helmet } from "react-helmet-async";
import useVisits from "../../hooks/stats/visits/useVisits";
import useConstructor from "../../hooks/constructor/useConstructor";

const getNum = (str) => str.replace(/\D/g, ''); 
const trim = (str) => str.replace(/\.([^.]*$)/, "$1").replace(/[.\s]/g, "");

const Upload = () => {
  useVisits();

  const [file, setFile] = useState(false);
  const [name, setName] = useState('');
  const [desc, setDesc] = useState('');
  const [pro, setPro] = useState(false);
  const [downloadable, setDownloadable] = useState(false);
  const [price, setPrice] = useState('');
  const [src, setSrc] = useState('');

  // check status

  let [lengthUploaded, setLengthUploaded] = useState(0);

  // check status

  const [reload, setReload] = useState(false);

  const {endpoint } = useProxyContext();
  
  const { popUp, selectedType, selectedCollection } = usePopUpContext();

  const { user } = useAuthContext();

  const { notifier } = useErrHandler();

  const navigate = useNavigate();
  
  const { userId } = useAccess();
  
  const { process } = useProcesser();

  const { profileSetup } = useProfile();

  const { encodeImageToBlurhash } = useConstructor();

  useEffect(() => {
    notifier(false, "Image file must not be more than 2MB!")

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Create post
  const createPost = async () => {

    // first submit tags
    const tagsContainer = document.querySelector('#tagsContainer');
    let arr = []
    
    for (let i = 0; i < tagsContainer.children.length; i++) {
      const element = tagsContainer.children[i];

      const tags = {
        value: element.innerText
      }

      arr.push(tags);
    }

    try {
      const others = document.querySelector('#others');

      const imageUrl = others.children[0].children[0].getAttribute("src");

      const placeholder = await encodeImageToBlurhash(imageUrl);

      // check profile
      await profileSetup( async () => {
        if(!file) return notifier(true, "Select image! First field is required.");
      
        if(!src) return notifier(true, "Select image! First field is required.");

        if(name.length < 5) return notifier(true, "Complete name field");

        if(!selectedType) return notifier(true, "Select type");

        if(!selectedCollection) return notifier(true, "Select collection!");

        if(pro && !getNum(price)) return notifier(true, "Set Price for pro Design!");

        try {

          const res = await axios.put(`${endpoint}/upload/image`, {
            collection: selectedCollection, 
            name, 
            desc,
            src, 
            placeholder,
            type: selectedType, 
            pro,
            downloadable: pro ? true : downloadable,
            price: pro ? price : "", 
            tags: arr
          },{
            headers: {
              "Authorization": `Bearer ${user.token}`
            }
          });
          
          notifier(false, res.data.message);
          
          setTimeout(() => {
            postImages(res.data.postId);
          }, 1000);
          
        } catch (err) {
          process('ENDED');

          console.log(err)
          const errorMessage = err.response.data.message;
          notifier(true, errorMessage);
        }
      });

    } catch (err) {
      process('ENDED');

      console.log(err);
      // const errorMessage = err.response.data.message;
      // notifier(true, errorMessage);
      return
    }
  }

  // this function loops into all file inut and get all files
  const postImages = async (postId) => {
    notifier(false, 'Uploading images...')
    const others = document.querySelector('#others');

    // get the length of files selected
    for (let i = 0; i < others.children.length; i++) {
      const element = others.children[i];
      if (element.children[2].files[0]) {
        setLengthUploaded(lengthUploaded++)
      }
    }
    
    try {
      for (let i = 0; i < others.children.length; i++) {
        const element = others.children[i];

        let imageFile = element.children[2].files[0];
        let imageFileName = element.children[0].dataset.imgName
        
        // get exactly the number of inputs that has file
        if(element.children[2].files[0]) {

          try {
            const data = new FormData();
            data.append('name', imageFileName);
            data.append('file', imageFile);

            await axios.post(`${endpoint}/upload`, data, {
              headers: {
                'Authorization': `Bearer ${user.token}`
              }
            });

            notifier(false, `Image ${i + 1} Uploaded...`);
            
            // EACH SUCCESSFUL UPLOAD SHOULD PUSH TO OTHERS PROPERTY IN IMAGE COLLECTION
            try {
              await axios.put(`${endpoint}/push/image`, {
                postId,
                imageName: imageFileName
              }, {
                headers: {
                  'Authorization': `Bearer ${user.token}`
                }
              });

              // if this is runing the last time (which is equals to the number of files found)
              if(lengthUploaded === (i + 1)) {
                notifier(false, "Completed!");
                process('ENDED');
                setLengthUploaded(0)
                navigate(`/${userId.userName.split(" ")[0]}/profile/${userId._id}/uploads`);
              }

            } catch (err) {
              setLengthUploaded(0)
              console.log(err)
            }

          } catch (err) {
            notifier(true, `${err.response.data.message}, image ${i}`);
            
            setTimeout(() => {
              process('ENDED');
              setLengthUploaded(0)
              navigate(`/${userId.userName.split(" ")[0]}/profile/${userId._id}/uploads`);
            }, 2000);
          }
        }
      }


    } catch (err) {
      setLengthUploaded(0)
      console.log(err);
    }
  }

  // function that create an object url for file inputes and preview when image is detected
  const createImageObjectURL = (e) => {
    const element = e.target.parentElement;
    const img = element.children[0]
    const mainImg = document.querySelector('#mainImg');
    const main = document.querySelector('#main');
    
    const file = e.target.files[0];
    const fileSizeInMB = file.size / (1024 * 1024);

    // if the change comes with file
    if(file) {
      
      if(fileSizeInMB > 3) {
        notifier(true, "This selected file is too large. Please select a file under 2MB.")
        img.style.display = 'block'; // display image
        img.src = URL.createObjectURL(file);
        img.style.border = "5px solid red";
        return;
      }

      img.style.border = "none";
      
      setFile(true);
      const namingPattern = Math.random().toString(20).substring(2) + "-" + file.name
      const name = trim(namingPattern);

      img.src = URL.createObjectURL(file); // create object url to src of the image
      img.style.display = 'block'; // display image
      img.setAttribute('data-img-name', name);
      
      // if image id is equals to 'mainImg' which is the main image preview
      if(img.attributes.id && img.attributes.id.value === 'mainImg') {
        main.src = URL.createObjectURL(file)
        main.style.display = 'block'
        setSrc(name);
      }
      
      // if image id is equals to 'main' that is the first image under the list
      if(img.attributes.id && img.attributes.id.value === 'main') {
        mainImg.src = URL.createObjectURL(file)
        mainImg.style.display = 'block'
        setSrc(name);
      }
      
    } else {
      img.src = "";
      img.style.display = 'none';

      // if image id is equals to 'mainImg' which is the main image preview
      if((img.attributes.id && img.attributes.id.value === 'mainImg') || (img.attributes.id && img.attributes.id.value === 'main')) {
        main.src = ''
        main.style.display = 'none'
        mainImg.src = ''
        mainImg.style.display = 'none'
      }
    }
  }

  const setTags = async (e) => {
    e.preventDefault();
    
    let input = e.target.children[0].value;

    try {
      if(input.length <= 1) return notifier(true, 'Must be up to two characters!');
      if(input.length >= 21) return notifier(true, 'Too long!');

      const tagsContainer = document.querySelector('#tagsContainer')
      const newElement = document.createElement('span');
      
      const newTag = `
        ${input} <div></div>
      `;

      newElement.innerHTML = newTag;

      tagsContainer.appendChild(newElement);

      e.target.children[0].value = '';
      setReload(!reload);

    } catch (err) {
      console.log(err);
    }
  }
  
  useEffect(() => {
    const tagsContainer = document.querySelector('#tagsContainer');

    const removeTag = () => {

      for (let i = 0; i < tagsContainer.children.length; i++) {
        const element = tagsContainer.children[i];

        element.addEventListener('click', () => {
          element.remove();
        })
      }
    }

    if(tagsContainer.children === undefined) {
      setTimeout(() => {
        removeTag();
      }, 1000);
    } else {
      removeTag();
    }

  }, [reload])

  return (
    <UploadStyle>
      <Helmet>
        <title>Upload</title>
        <meta name='description' content='Let the world see your work - start uploading' />

        <meta property="og:title" content="Upload" />
        <meta property="og:description" content={`Let the world see your work - start uploading`} />

        <link rel="icon" href='/images/logos/20230125_074855.png' />
      </Helmet>

      <TopBar />

      <Container> {/* Container */}
        <div>
          <div id="upload"  >

            <div id="mainCover">
              <div className="box-shadow" style={{ pointerEvents: 'none' }}>
                {/* {file && ( <img src={file && URL.createObjectURL(file)} alt=" " /> )} */}
                <img id="mainImg" src="" alt="" />

                <label htmlFor="fileUpload">Select Image <IoMdCloudUpload /></label>
                <input onChange={createImageObjectURL} accept="image/*" type="file" id="fileUpload" />
              </div>
            </div>

            <div id="others">
              <div>
                <img id="main" src="" alt="" />
                <FaPlus />
                <input onChange={createImageObjectURL} type="file" />
              </div>

              <div>
                <img src="" alt="" />
                <FaPlus />
                <input onChange={createImageObjectURL} type="file" />
              </div>

              <div>
                <img src="" alt="" />
                <FaPlus />
                <input onChange={createImageObjectURL} type="file" />
              </div>
            </div>
            
          </div>

          <div id="details">
            <span>
              <input 
                type="text" 
                placeholder="name" 
                autoFocus={true}
                value={name}
                onChange={(e) => setName(e.target.value)}
                maxLength={100}
              ></input>

              <div>
                <small>{name.length}/100</small>
                <small>Minimum 5 characters</small>
              </div>
            </span>

            <span>
              <textarea 
                rows="3" 
                placeholder="About design" 
                value={desc}
                onChange={(e) => setDesc(e.target.value)}
                maxLength={3000}
              ></textarea>

              <div>
                <small>{desc.length}/3000</small>
                {/* <small>Minimum 50 characters</small> */}
              </div>
            </span>

            <div id="tags" style={{ position: "relative" }}>
              <p>Tags</p>
              <div>
                <div>
                  <div id="tagsContainer">
                    <span>3D Model <div></div> </span>
                    <span>UI Design <div></div> </span>   
                  </div>
                  <form onSubmit={setTags}>
                    <input style={{ width: "100%" }} id="tagInput" type="text" placeholder="Write"></input>
                  </form>
                </div>
              </div>
            </div>

            <div id="pro">
              <input 
                style={{height: "20px", width: "20px"}}
                type="checkbox" 
                onChange={(e) =>  setPro(e.target.checked)}
              />
              <p>Pro</p>
            </div>

            {pro && userId && (
              <input 
                type="text" 
                placeholder={userId.currency} 
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              ></input>
            )}

            {!pro && 
            <div id="pro">
              <input 
                style={{height: "20px", width: "20px"}}
                type="checkbox" 
                onChange={(e) =>  setDownloadable(e.target.checked)}
              />
              <p>Downloadable</p>
            </div>}


            <div id="collection" onClick={() => popUp("types", null) }>
              <div>{selectedType ? selectedType : "Type"}</div>
              <FaAngleDown />
            </div>

            <div id="collection" onClick={() => popUp("collections", null) }>
              <div>{selectedCollection ? selectedCollection : "Collection"}</div>
              <FaAngleDown />
            </div>

            <Button run={createPost} >Upload <IoMdCloudUpload /></Button>
          </div>
        </div>

        <div></div>

        <div id="uploadMore">
          <span><FaPlus /></span>
        </div>
      </Container>
    </UploadStyle>
  )
}

export default Upload;