import Button from "../../../../button/Button"
import { PaymentForm } from "../styles/S_Payment"
import { useTheme } from 'styled-components';
import useAccess from '../../../../../hooks/access/useAccess';
import { useEffect, useState } from "react";
import usePopUpContext from "../../../../../hooks/global/usePopUpContext";
import { FaPlus } from "react-icons/fa";
import useProxyContext from "../../../../../hooks/global/useProxyContext";
import { useNavigate } from "react-router-dom";
import useProcesser from "../../../../../hooks/processer/useProcesser";
import useConstructor from '../../../../../hooks/constructor/useConstructor';
import useErrHandler from "../../../../../hooks/err/useErrHandler";

const Pay = () => {
  const [phone, setPhone] = useState('');

  const [defaultCurrency, setDefaultCurrency] = useState('');
  const [walletBalance, setWalletBalance] = useState('');
  
  const theme = useTheme();

  const { userId } = useAccess();

  const { imageEndpoint } = useProxyContext();

  const { itemPayload, closePopup } = usePopUpContext();

  const { process, processing } = useProcesser();

  const { postToRouteRA } = useConstructor();

  const { notifier, error, message } = useErrHandler();

  const navigate = useNavigate();

  const addCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  useEffect(() => {
    if(!userId) return;
    setDefaultCurrency(userId.currency);

    if(!defaultCurrency) return;
    const balance = userId.wallet[defaultCurrency].balance.toFixed(2);
    setWalletBalance(addCommas(balance));

  }, [userId, defaultCurrency])

  ////////////////////////////////////////////////////////
  
  const currency = itemPayload && itemPayload.currency;
  const price = itemPayload && itemPayload.price ? JSON.parse(itemPayload.price.replace(/[^0-9]/g, "")) : (currency === 'NGN' ? 800 : 1);
  
  ////////////////////////////////////////////////////////

  const handleNavigate = (url) => {
    navigate(url);
    closePopup();
  }

  const handlePayment = async (e) => {
    e.preventDefault();

    if(processing) return;

    process('STARTED', 'Processing ...');

    await postToRouteRA((data) => {
      console.log(data);
      notifier(false, data.message);
    }, {
      amount: price,
      uploadId: itemPayload._id
    }, `/wallet/payment/upload`)

  }

  return (

    <div id="payment">

        { userId && 
        <PaymentForm onSubmit={handlePayment} message={message} error={error}>
          <div id="walletBalance" onClick={() => handleNavigate('/wallet')}>
            <h4>Wallet balance</h4>
            <h3>{defaultCurrency} {walletBalance}</h3>
          </div>

          <div id="fieldContainer">
            <label htmlFor="emailAddress">Email Address: </label>
            <input readOnly value={userId.email} type="email" id="emailAddress" />
          </div>

          <div id="fieldContainer">
            <label htmlFor="phoneNumber">Phone Number: </label>
            <input className="inp" value={phone} onChange={(e) => setPhone(e.target.value)} type="number" id="phoneNumber" placeholder="Enter your phone number" />
          </div>
        
          <div id="items"> {/* Plans */}
            <h4>Item you're paying for</h4>
            <div>

              {itemPayload && 
              <div id="item">
                <img src={imageEndpoint+ '/images/' + itemPayload.src } alt=""/>
                <div>
                  <h4>{itemPayload.name}</h4>
                  <p>{itemPayload.currency + ' ' + price}</p>
                </div>
              </div>}

              <div id="addMore"> { /* Add new button */}
                <FaPlus />
              </div>

            </div>
          </div>

          <div id="total">  {/* Total */}
            <h4>Total</h4>
            <div>{itemPayload.currency + ' ' + price}</div>
          </div>

          <Button color="#FFFFFF" bg={theme.colors.main2}> Pay {currency} {price} </Button>
          <small>{ message }</small>
        </PaymentForm>
        }

      </div>
  )
}

export default Pay;