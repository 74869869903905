import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import AuthContextProvider from './contexts/auth/AuthContext';
import ErrHandlerProvider from './contexts/err/ErrHandler';
import ProxyContextProvider from './contexts/global/ProxyContext';
import ThemeContextProvider from './contexts/ThemeContext';
import PopUpContextProvider from './contexts/global/PopUpContext';
import CountriesContextProvider from './contexts/global/CountriesContext';
import RefreshContextProvider from './contexts/global/RefreshContext';
import ProcesserProvider from './contexts/processer/Processer';
import AdminAuthContextProvider from './contexts/admin/auth/AuthContext'
import { HelmetProvider } from 'react-helmet-async';
import AccessContextProvider from './contexts/access/AccessContext';
import ConstructorContextProvider from './contexts/constructor/ConstructorContext';
import DataContextProvider from './contexts/global/DataContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render (
  <React.StrictMode>
    <HelmetProvider>

    <ProxyContextProvider>
    <ErrHandlerProvider>
    <AuthContextProvider>
    <AccessContextProvider>
    <RefreshContextProvider>
    <ThemeContextProvider>
    <PopUpContextProvider>
    <CountriesContextProvider>
    <ProcesserProvider>

    <ConstructorContextProvider>
    <AdminAuthContextProvider>
    <DataContextProvider>
    <App />
    </DataContextProvider>
    </AdminAuthContextProvider>
    </ConstructorContextProvider>

    </ProcesserProvider>
    </CountriesContextProvider>
    </PopUpContextProvider>
    </ThemeContextProvider>
    </RefreshContextProvider>
    </AccessContextProvider>
    </AuthContextProvider>
    </ErrHandlerProvider>
    </ProxyContextProvider>
    
    </HelmetProvider>
  </React.StrictMode>
)