import { useEffect } from "react";
import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";
import useAccess from '../../../../hooks/access/useAccess';
import usePopUpContext from "../../../../hooks/global/usePopUpContext";
import useRefreshContext from "../../../../hooks/global/useRefreshContext";
import useImgHandler from "../../../../hooks/img/useImgHandler";

const MyCollections = () => {

  const { userId } = useAccess();

  const { noclick, setShow, items, selectedCollection, setSelectedCollection, setCollectionData } = usePopUpContext();

  const { refresh, setRefresh } = useRefreshContext();

  return (
    <>
    <div id="items" className="myCollections">
      <h3>My collections</h3>
      {items && userId && items.filter(item => item.ownerId === userId._id).map((item, i) => (
        <div key={i} data-collection-id={JSON.stringify(item)} onClick={() => {
          if(!noclick) {
            setShow(false);
            setRefresh(!refresh)
            setSelectedCollection(item.header);
            setCollectionData(item);
          }
        }}>

        <Item item={item} selectedCollection={selectedCollection} noclick={noclick} />
          
        </div>
      ))}
    </div>
    </>
  )
}

const Item = ({ item, selectedCollection, noclick }) => {

  const { approvedItems, getLength } = useImgHandler();
  
  useEffect(() => {
    getLength(item._id);
  }, [item, approvedItems, getLength])

  return (
    <div id="flex">
      <div>
        <p>{item.header}</p>

        <small>{approvedItems} uploads</small>
      </div>

      <div>
        { !noclick && (selectedCollection === item.header ? <ImCheckboxChecked /> : <ImCheckboxUnchecked />) }
      </div>
    </div>
  )
}

export default MyCollections;