import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useTheme } from 'styled-components';
import Alerter from '../../../../components/alerter/Alerter';
import useErrHandler from '../../../../hooks/err/useErrHandler';
import useRefreshContext from '../../../../hooks/global/useRefreshContext';

const NavStyle = styled.nav`
  display: flex;
  gap: 20px;
  align-items: center;
  overflow-x: auto;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 2px 2px 25px rgba(0,0,0,0.3);
  padding: 0 20px;

  &::-webkit-scrollbar { display: none }
  
  & a {
    padding: 20px 0;
    color: #000000;
    text-decoration: none;
  }
`;

const Nav = () => {

  const { reRender } = useRefreshContext();

  const { error, message } = useErrHandler();

  const current = document.baseURI.split('/')[5];

  const theme = useTheme();
  
  const url = (path) => `/admin/cpanel/${path}`;

  return (
    <>
      <NavStyle>
        <Link onClick={reRender} style={{ color: current === 'collections' && theme.colors.main2 }} to={url("collections")}>Collections</Link>

        <Link onClick={reRender} style={{ color: current === 'posts' && theme.colors.main2 }} to={url("posts")}>Posts</Link>

        <Link onClick={reRender} style={{ color: current === 'reports' && theme.colors.main2 }} to={url("reports")}>Reports</Link>
        
        <Link onClick={reRender} style={{ color: current === 'visitors' && theme.colors.main2 }} to={url("visitors")}>Visitors</Link>
        
        <Link onClick={reRender} style={{ color: current === 'create' && theme.colors.main2 }} to={url("create")}>Create</Link>

        <Link onClick={reRender} style={{ color: current === 'manage' && theme.colors.main2 }} to={url("manage")}>Manage</Link>
      </NavStyle>

      {/* Notifier */}
      <Alerter message={message} error={error} />
    </>
  )
}

export default Nav;