import styled from 'styled-components';

export const DashboardStyle = styled.div``;

export const Profile = styled.div`
  padding: 100px 0;
  background: ${({ theme }) => theme.dark ? "#000000" : "#f7f7f7"};
  
  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;

    & div[id="accountInfo"] {
      @media (max-width: ${({ theme }) => theme.mobile.is500}) {
        width: 100%;
      }

      & > h2 {
        color: ${({ userProfile, theme }) => userProfile.pro ? theme.colors.main : theme.colors.blue}50;
        margin: 30px 0;
      }

      & > p[id="userEmail"] {
        margin-bottom: 20px;
      }
     }

    & button {
      padding: 20px;
      font-size: 19px;

      white-space: nowrap;
      box-shadow: -5px 5px 0 #00000025;

      background: ${({ theme }) => theme.dark ? "#efefef" : "#FFFFFF"};
      color: #000000;
      
      transition: all cubic-bezier(0.42, 0, 0, 1.57) 0.2s;

      & > svg {
        color: ${({ theme }) => theme.colors.main2};
      }

      & svg {
        font-size: 30px;
      }

      &:hover {
        transform: scale(0.9);
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.mobile.is950}) {
    padding: 50px 0;
    
    & > div {
      flex-direction: column;
      gap: 10px;

      & div[id="accountInfo"] {
        text-align: center;
      }
    }
  }

  & div[id="profile"] {
    display: flex;
    align-items: center;
    gap: 40px;
    width: 100%;

    & h1 {
      color: ${({ theme }) => theme.colors.black};

      @media (max-width: ${({ theme }) => theme.mobile.is500}) {
        font-size: 25px;
      }
    }

    & p {
    }

    @media (max-width: ${({ theme }) => theme.mobile.is950}) {
      flex-direction: column;
      gap: 20px;
    }
  }

  & div[id="profilePics"] {
    position: relative;
    min-width: 250px;
    min-height: 250px;

    & > img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      border-radius: 50%;
      box-shadow: 2px 2px 25px #00000025;
    }

    @media (max-width: ${({ theme }) => theme.mobile.is749}) {
      min-width: 170px;
      min-height: 170px;
    }
  }

`;

export const Activities = styled.div`
  & div[id="container"] { }

  & > div {
    display: flex;
    flex-direction: column;

    & > div {
      padding: 100px 0;
      display: flex;
      flex-direction: column;
      gap: 20px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.black};

      &:first-child { padding-top: 0 }

      & > div {
        @media (max-width: ${({ theme }) => theme.mobile.is640}) {
          & > h2 {
            font-size: 18px;
          }
  
          & > div > h3 {
            font-size: 18px;
          }
        }
      }

      &:last-child {
        border-bottom: none;
      }

      & > div:nth-child(1) {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > div[id="createBtn"] {
          padding: 10px 12px;
          display: flex;
          gap: 10px;
          align-items: center;
          border-radius: 5px;
          margin-right: 50px;
          background: linear-gradient(to top right, ${({ theme }) => `${ theme.colors.main }50 , ${ theme.colors.main2 }50`});

          & span {
            color: ${({ theme }) => theme.colors.black};
          }

          &:hover {
            background: linear-gradient(to bottom, ${({ theme }) => `${ theme.colors.main2 }AA, ${ theme.colors.main }AA` });
          }

          @media (max-width: ${({ theme }) => theme.mobile.is640}) {
            & span {
              display: none;
            }
          }
        }
        
        & > div {
          display: flex;
          align-items: center;
          gap: 10px;
          cursor: pointer;


          & svg {
            color: ${({ theme }) => theme.colors.main2};
            font-size: 19px;
          }
        }
      }

      & > div:nth-child(2)[class="flex"] {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        
        & > div {
          @media (max-width: ${({ theme }) => theme.mobile.is640}) {
            width: 100%;
          }

          & > main {
            position: relative;
            border-radius: 10px;

            opacity: 0;
            transform: translateY(5%);
          
            &.fade-in { opacity: 0; animation: fadeInFromBottom 0.5s ease-in forwards; }
            @keyframes fadeInFromBottom { from { opacity: 0; transform: translateY(5%); } to { opacity: 1; transform: translateY(0); } }
            
            &:hover div[id="optionsMenuBtn"] {
              opacity: 1;
            }
            
            &:hover img {
              filter: brightness(50%);
            }

            & > main[id="uploadImg"] {
              border-radius: 10px;
              height: 250px;
              width: 250px;
              overflow: hidden;
            }

            & img {
              height: 100%;
              background: ${({ theme }) => theme.colors.white};
            }
            
            @media (max-width: ${({ theme }) => theme.mobile.is640}) {
              width: 100%;

              & > main[id="uploadImg"] {
                width: 100%;
              }
            }

            & > div {

              position: relative;
              z-index: 99;

              &[id="stared"] {
                position: absolute;
                top: 10px;
                right: 10px;

                display: flex;
                flex-direction: column;
                gap: 10px;

                & > span {
                  height: 40px;
                  width: 40px;
                  display: grid;
                  place-items: center;
                  border-radius: 50%;
                  background: ${({ theme }) => theme.colors.black}20;
                  color: ${({ theme }) => theme.colors.white}AA;
                  cursor: pointer;
                }
              }

              &[id="uploads"] {
                position: absolute;
                bottom: 10px;
                right: 10px;
                display: flex;
                gap: 20px;
                z-index: 10;
                pointer-events: none;

                & > span[id="optionsMenu"] {
                  display: flex;
                  gap: 10px;

                  & > div[id="optionsMenuBtn"] {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    height: 40px;
                    width: 40px;
                    border-radius: 50%;
                    display: grid;
                    place-items: center;
                    color: #FFFFFF;
                    background: ${({ theme }) => theme.colors.black}20;
                    pointer-events: visible;
                  }

                  &:hover > div[id="options"] {
                    display: flex;
                  }

                  & > div[id="options"] {
                    margin-right: 30px;
                    background: ${({ theme }) => theme.colors.white};
                    padding: 10px;
                    display: flex;
                    flex-direction: column; 
                    gap: 5px;
                    display: none;
                    box-shadow: 2px 2px 25px rgba(0,0,0,0.3);
                    border-radius: 10px;
                    pointer-events: visible;
                    
                    & > div {
                      display: flex;
                      gap: 30px;
                      align-items: center;
                      justify-content: space-between;
                      background: ${({ theme }) => theme.colors.white};
                      border-radius: 10px;
                      cursor: pointer;
                      padding: 10px;
                      user-select: none;

                      // include animation
                      
                      & svg {
                        color: ${({ theme }) => theme.colors.black};
                      }

                      &:hover {
                        background: ${({ theme }) => theme.colors.lightgrey}25;
                        box-shadow: 2px 2px 25px rgba(0,0,0,0.3);
                      }
                    }
                  }
                }
              }
            }
          }
          
          & > header {
            width: 250px;

            @media (max-width: ${({ theme }) => theme.mobile.is640}) {
              width: 100%;
            }
          }
        }

      }

      & > div[id="btn"] {
        display: flex;
        justify-content: center;
    
        & > button { 
          margin: 50px 0;
          background: linear-gradient(to right top, #f32170, #cf23cf, #eedd44);
          color: #FFFFFF;
        }
      }
    }

    & div[id="stared"] {

    }
  }
`;