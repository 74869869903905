import styled from 'styled-components';

export const OptionsStyle = styled.div`
  margin-top: 50px;
  
  & > div {
    display: flex;
    justify-content: center;

    & > div {
      padding: 20px;
      display: flex;
      align-items: center;
      margin-left: 30px;

      &:first-child { margin-left: 0 }

      background: linear-gradient(to top right, ${({ theme }) => `${ theme.colors.main }05 , ${ theme.colors.main2 }05`});
      border: 1px solid ${({ theme }) => theme.colors.main2}20;

      border-radius: 20px;
      box-shadow: 2px 2px 2px #00000025;
      cursor: pointer;
      color: ${({ theme }) => theme.colors.black}AA;
      transition: all cubic-bezier(0.42, 0, 0, 1.57) 0.2s;
      
      @media(max-width: ${({ theme }) => theme.mobile.is800}) {
        padding: 15px;
        margin-left: 10px;
        // flex-direction: column;
        // border-radius: 5px;
        text-align: center;
        font-size: 12px;
      }

      @media(max-width: ${({ theme }) => theme.mobile.is460}) {
        padding: 15px;
        margin-left: 10px;
        flex-direction: column;
        width: 100%;
        text-align: center;
        font-size: 12px;
      }
      
      &:hover {
        background: linear-gradient(to bottom, ${({ theme }) => `${ theme.colors.main2 }20, ${ theme.colors.main }20` });
        transform: scale(0.9);
      }

      & > span {
        height: 40px;
        width: 40px;
        display: grid;
        place-items: center;
        // background: #FFFFFF50;
        border-radius: 5px;
        font-size: 20px;
      }
    }
  }
`;