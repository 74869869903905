import styled from "styled-components";

export const GrapixPopUpStyle = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;

  & > div[id="selector"] {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > span[id="icon"] {
      display: grid;
      place-items: center;
      height: 60px;
      width: 60px;
      border-radius: 50%;
      border: 1px solid ${({ theme }) => theme.colors.black}50;
      background: ${({ theme }) => theme.colors.main2}10;
      color: ${({ theme }) => theme.colors.main2};
    }

    & > span[id="option"] {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 20px;

      & > span {
        display: flex;
        align-items: center;
        padding: 15px;
        border: 1px solid ${({ theme }) => theme.colors.black}50;
        border-radius: 5px;
        margin-right: 10px;
        color: ${({ theme }) => theme.colors.main2};

        & > img {
          width: 20px;
        }
      }
    }
  }

  & > button {
    padding: 20px;
    font-size: 20px;
    color: #FFFFFF;
    border-radius: 10px;
    background: ${({ theme }) => theme.colors.main2};

    &[id="payPublic"] {
      background: ${({ theme }) => theme.colors.main}
    }
  }

  & > small {
    padding: 10px;
    border-radius: 5px;
    background: #FFA50070;
  }

  & > div[id="contributors"] {
    & > h2 {
      position: relative;
      margin: 0 0 20px 0;
      color: ${({ theme }) => theme.colors.black}AA;
      text-align: center;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 30px;
        width: 100px;
        background: ${({ theme }) => theme.colors.main2}20;
        border-radius: 50px;
        z-index: -1;
      }
    }

    & > div {
      display: flex;
      flex-direction: column;
      grid-gap: 30px;

      & > div { 
        display: flex;
        flex-direction: column;
        grid-gap: 20px;
        padding: 20px;
        border-radius: 30px;
        background: red;

        background: ${({ theme }) => theme.colors.main2}20;

        & > button {
          color: ${({ theme }) => theme.colors.main2};
          background: ${({ theme }) => theme.colors.white};
        }

        & > div {
          display: flex;
          grid-gap: 10px;
          
          & > img {
            min-width: 90px;
            max-width: 90px;
            min-height: 90px;
            max-height: 90px;
            border: 5px solid ${({ theme }) => theme.colors.white};
            border-radius: 50%;
          }

          & > div {
            display: flex;
            flex-direction: column;

            & > small[id="followers"] {
              color ${({ theme }) => theme.colors.main2};
            }
          }
        }
      }
    }
  }
`;

export const GrapixPurchaseConfirm = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;

  & > span {
    display: grid;
    place-items: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.colors.black}50;
    background: ${({ theme }) => theme.colors.main2}10;
    color: ${({ theme }) => theme.colors.main2};
    cursor: pointer;
  }

  & > small {
    padding: 10px;
    border-radius: 10px;
    font-size: 15px;
    background: ${({ theme, error }) => error ? "#FF000F" : theme.colors.main2}BB; 
    color: #FFFFFF;
    display: ${({ message }) => message ? 'block' : 'none'};
  }

  & > div {
    padding: 20px;
    background: ${({ theme }) => theme.colors.main2}20;
    border-radius: 10px;
    border: 2px solid ${({ theme }) => theme.colors.main2}50;
    cursor: pointer;
  }

  & > button {
    background: ${({ theme }) => theme.colors.main2};
    color: #FFFFFF;
    padding: 20px;
    border-radius: 10px;
  }
`