import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router";
import useProxyContext from '../../hooks/global/useProxyContext';
import useErrHandler from '../../hooks/err/useErrHandler';
import useAdminAuthContext from '../../hooks/admin/auth/useAdminAuthContext';

const trim = (str) => str.replace(/\.([^.]*$)/, "$1").replace(/[.\s]/g, "");

export const useEditor = ({ editorBody, postDefault, featuredImageName, path, tagsContainer }) => {
  const [posting, setPosting] = useState(false);
  const [edited, setEdited] = useState(true);
  
  const { endpoint } = useProxyContext();
  const { notifier } = useErrHandler();

  let postTitle = '';
  let featuredImgFile = null;

  let tags;
  let postContent = [] // post Body empty array
  let postContentFiles = []; // post Body files empty array
  let postContentFileNames = []; // post Body file names empty array

  const { admin } = useAdminAuthContext();

  // Id Generator 
  const getID = (base) => {
    return Math.random().toString(base).substr(2,10)
  }

  const navigate = useNavigate();

  const addSpace = async () => { // <<<< //
    setEdited(false)

    // select frame type template 
    const template = `
      <div>
        <div>
          <button> <img src="/images/create/camera.svg" /> <span>image</span> </button>
          <button> <img src="/images/create/paragraph.svg" /> <span>paragraph</span> </button>
          <button> <img src="/images/create/heading.svg" /> <span>Header</span> </button>
          <button> <img src="/images/create/pre.svg" /> <span>Pre</span> </button>

          <button> <img src="/images/create/ellipsis-vertical.svg" alt=" " /> </button>
          
        </div>

        <button> <img src="/images/create/close-button-icon.svg" alt=" " /> <span>Remove</span>  </button>
      </div>
    `;

    // image frame type template 
    const imageEditor = `
      <span></span>

      <form>
        <img src='/images/create/imageIcon.jpg' alt="" />

        <input
          type="file"
          accept="image/*" 
        />

      </form>
    `;

    // paragraph frame type template 
    const paragraphEditor = `
      <p contenteditable="true" >Paragraph ... </p>
    `;

    // header frame type template 
    const headerEditor = `
      <h1 contenteditable="true" >Header ... </h1>
    `;

    // a preview element frame type template
    const preEditor = `
      <pre contenteditable="true" >Pre ... </pre>
    `

    // CREATE NEW EMPTY ELEMENT 
    const newSpace = document.createElement('main');
    newSpace.innerHTML = template;
    const newSpaceParent = editorBody.current;
    newSpaceParent.appendChild(newSpace);

    // WAIT FOR NEW SPACE TO BE CREATED THEN PERFORM SOME OPERATIONS 
    try {
      // CALL NEW ELEMENTS CONTAINER
      // const container = await document.querySelector('.editableFrameContainer').children;
      const container = await editorBody.current.children;

      manipulateEditor({ container, newSpace, imageEditor, paragraphEditor, headerEditor, preEditor });

    } catch (err) {}
  }

  async function manipulateEditor({ container, newSpace, imageEditor, paragraphEditor, headerEditor, preEditor }) { // <<<< //
      
    // LOOP THROUGH OUTER CONTAINER 
    for(let i = 0; i < container.length; i++) {
      const element = container[i];
      let elementContents = element.children[0].children;

      // LOOP INTO FRAME TYPE BUTTONS AND PERFORM SOME OPERATIONS
      for(let i = 0; i < elementContents.length; i++) {
        try {
          // IMAGE FRAME TYPE EDITOR FUNCTION 
          elementContents[0].children[0].addEventListener('click', () => {
  
            setEdited(true);
            newSpace.innerHTML = imageEditor;
  
            const items = newSpace.children[1].children;
            const imgName = newSpace.children[0];
            imgName.style.display = 'none';

            const imgPreview = items[0];
            const imgInput = items[1];

            imgInput.addEventListener('change', (e) => {
              const file = e.target.files[0];
              const fileSizeInMB = file.size / (1024 * 1024);
              const generatedName = `${Date.now()}-${getID(36)}-${file.name}`

              if(fileSizeInMB > 3) {
                notifier(true, "This selected file is too large. Please select a file under 2MB.")
                imgPreview.src = URL.createObjectURL(file);
                imgName.innerHTML = '';
                imgPreview.style.border = "5px solid red";
                return;
              }
              
              imgPreview.src = URL.createObjectURL(file);
              imgName.innerHTML = trim(generatedName);
              imgPreview.style.border = "none";
            })
  
          })

          // PARAGRAPH FRAME TYPE EDITOR FUNCTION 
          elementContents[0].children[1].addEventListener('click', () => {
            setEdited(true);
            newSpace.innerHTML = paragraphEditor;
          })

          // HEADER FRAME TYPE EDITOR FUNCTION 
          elementContents[0].children[2].addEventListener('click', () => {
            setEdited(true);
            newSpace.innerHTML = headerEditor;
          })

          // HEADER FRAME TYPE EDITOR FUNCTION 
          elementContents[0].children[3].addEventListener('click', () => {
            setEdited(true);
            newSpace.innerHTML = preEditor;
          })

          // REMOVE EDITOR SELECTION FRAME FUNCTION 
          elementContents[1].addEventListener('click', () => {
            setEdited(true);

            newSpace.remove();

          })

        } catch (err) {}

      }
    }
  }

  const handlePublish = async () => { // <<<< //
    setPosting(true);

    if(!admin) return console.log('You must be authenticated to create or edit this post')

    const box = editorBody.current.children;

    // LOOP INTO HEADERS CONTENT GET REQUIRED ITEMS AND POST TO BACK-END
    (async function setHeadersContent() {
      try {
        const headers = postDefault.current.children;
      
        const headersTitle = headers[0].children[0].children[0].children;
        const headersFImg = headers[1].children[0].children[0].children;

        // get post title and send for processing
        postTitle = headersTitle[0].innerText;

        // get featured image file and file name  and send for processing
        if(headersFImg[0].tagName === 'FORM') {
          const imageFile = headersFImg[0].children[1].files[0];
          const generatedName = trim(`${Date.now()}-${getID(36)}-${imageFile.name}`);

          featuredImgFile = imageFile;
          featuredImageName = generatedName;
          

          // IF FEATURED IMAGE IS PROVIDED POST THEM TO THE BACKEND
          if(featuredImgFile) {
            const data = new FormData();
            data.append('name', featuredImageName);
            data.append('file', featuredImgFile);

            try {
              await axios.post(`${endpoint}/blog/upload`, data, {
                headers: {
                  'Authorization': `Bearer ${admin.token}`
                }
              })
            } catch (err) { 
              setPosting(false); 
              notifier(true, err.response.data.message);
            }

          } else {}
        }
        
      } catch (err) {}

    })();

    // LOOP INTO EDITOR BODY GET ALL REQUIRED ITEMS AND POST TO BACK-END
    for (let i = 0; i < box.length; i++) { //Loop into editor body children

      // GETTING ALL STRINGS FROM THE POST BODY
      function getTag(tag) { // get tagName of any element 
        return tag.tagName;
      }

      const requiredItem = { // Object to submit
        text: box[i].children[0].innerText, // get tag innerText
        htmlTag: getTag(box[i].children[0]) // get tag name
      }

      postContent.push(requiredItem); // push objects to postContent useState hook 

      // GETTING AND UPLOADING ALL FILES AND THEIR NAMES IN THE POST BODY TO BACK-END
      try {
        if(box[i].children[1].tagName === "FORM") {
          const postBodyFiles = box[i].children[1].children[1].files;
          

          if(postBodyFiles.length !== 0) { 
            async function pushItems() { // <<< // Pushing post content files and post content file images to the state variable

              try {
                postContentFiles.push(postBodyFiles[0]);
                postContentFileNames.push(requiredItem.text)
              } catch (err) {}
            
              return { postContentFiles, postContentFileNames };
            }

            (async function uploadPushedItems() { // <<< // Upload post content files and post content file images from our state variables to backend
              try {
                let filesItems = await pushItems();
                const postConFileNames = filesItems.postContentFileNames;
                const postConFiles = filesItems.postContentFiles;
              
                
                for (let i = 0; i < postConFileNames.length; i++) {
                  // Creating new form data for every file in the post body
                  const data = new FormData();
                  data.append('name', postConFileNames[i]);
                  data.append('file', postConFiles[i]);

                  try {
                    await axios.post(`${endpoint}/blog/upload`, data, {
                      headers: {
                        'Authorization': `Bearer ${admin.token}`
                      }
                    });

                  } catch (err) {
                    setPosting(true);
                    notifier(true, err.response.data.message);
                  }

                  await checkUploadStatus(i, postConFiles);
                }
              } catch (err) {}
            })();
          }
        }
      } catch (err) {}

    }

    tags = tagsContainer.current.textContent.split("#").filter(tag => tag.length > 0);

    const reqItems = {
      postFeaturedImg: featuredImageName,
      postTitle: postTitle,
      postBody: postContent,
      tags
    }

    try {
      path && await axios.put(`${endpoint}/update/post/${path}`, reqItems, {
        headers: {
          'Authorization': `Bearer ${admin.token}`
        }
      }) // send post Body to backend 
        .then((res) => {
            notifier(false, res.data.message);
            checkUploadStatus(null, null, res);
        })

      !path && await axios.post(`${endpoint}/create/post`, reqItems, {
        headers: {
          'Authorization': `Bearer ${admin.token}`
        }
      }) // send post Body to backend 
        .then((res) => {
          notifier(false, res.data.message);
          checkUploadStatus(null, null, res);
        })

    } catch (err) {
      setPosting(false);
      console.log(err);
      const errorMessage = err.response.data.message;

      notifier(true, errorMessage);
    }

    async function checkUploadStatus(i, postConFiles, res) {
      try {
        if(postConFiles && i) { // if post body files and iteration are available
          if((i === postConFiles.length - 1) && (res.status === 200)) { // if files uploaded successful
            navigate(`/admin/cpanel/manage`);
          } 
        } else if ( !(postConFiles) && res.status === 200 ) { // if post body files is not available and res status is OK
          // refresh mount context, notify  requester and navigate to c-panel
          setPosting(false);
          const requested = path ? "Post Updated" : "Post Published"
          notifier(false, requested);
          navigate(`/admin/cpanel/manage`);
        }
      } catch (err) {}
    };
  }

  return { addSpace, handlePublish, getID, edited, posting };

}