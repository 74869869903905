import { createContext, useEffect } from 'react';
import { useState } from 'react';
import useProxyContext from '../../hooks/global/useProxyContext';
import axios from 'axios';
import useRefreshContext from '../../hooks/global/useRefreshContext';
import useErrHandler from '../../hooks/err/useErrHandler';
import useAuthContext from '../../hooks/auth/useAuthContext';

export const PopUpContext = createContext();

const PopUpContextProvider = ({ children }) => {

  // clicked image info
  const [clickedImageInfo, setClickedImageInfo] = useState(null);

  // useState hook that holds the type of the popup
  const [type, setType] = useState('');

  // useState hook for collectiong items (currently holding items for types and collections)
  const [items, setItems] = useState(null);

  // when user want to buy item this hook holds the data of the item
  const [itemPayload, setItemPayload] = useState(null);

  // useState hook for togling show and hide of the popup
  const [show, setShow] = useState(false);

  // holds the value of the listDisplay current item
  const [selectedType, setSelectedType] = useState('');
  const [selectedCollection, setSelectedCollection] = useState('');

  // no click turns off clickable section buttons 
  // means i don't want to select an option so desable dose selection button
  const [noclick, setNoclick] = useState(false);

  // holds listDisplay current item data
  const [collectionData, setCollectionData] = useState(null);
  
  // holds the data of types
  const [types, setTypes] = useState(null);
  const [collections, setCollections] = useState(null);

  // Generated image preview url
  const [genPrevData, setGenPrevData] = useState('');

  // reference id
  const [reference, setReference] = useState('');
  
  // holds stage for setup popup
  const [stage, setStage] = useState("");

  const [message, setMessage] = useState('');
  
  const { endpoint } = useProxyContext();
  
  const { refresh, setRefresh } = useRefreshContext();

  const { removeError } = useErrHandler();

  const { user } = useAuthContext();

  // fetch all collections and types
  useEffect(() => {
    const fetchCollections = async () => {
      if(!user) return;
      
      try {
        const res = await axios.get(`${endpoint}/fetch/private/collections`, {
          headers: {
            'Authorization': `Bearer ${user.token}`
          }
        });

        setCollections(res.data);

      } catch (err) {
        console.log(err);
      }
    }

    const fetchTypes = async () => {
      setTypes([
        {header: "3D Model"},
        {header: "Illustration"}, 
        {header: "Icon"}, 
        {header: "UI/UX"},
        {header: "Graphics Design"},
        {header: "Logo Design"},
        {header: "Web Design"},
        {header: "Prototype"},
        {header: "Animation"},
      ])
    }
    
    fetchCollections();
    fetchTypes();
  }, [endpoint, refresh, user])

  // popup function handling popup types
  const popUp = async (type, extra, noclick) => {
    removeError();

    try {
      const popUpFor = (str) => type === str;
  
      if(popUpFor("collections")) { // if requested for "collections"
        // if extra ? it means the current or the active "collection" or "type"
        if(extra) { setSelectedCollection(extra) }
        if(noclick) { setNoclick(true); } else { setNoclick(false) }
        setItems(collections);

      } else if(popUpFor("types")) {
        setItems(types);

      } else if (popUpFor("setup")) {
        if(extra) setStage(extra);
        
      } else if (popUpFor("payment")) {
        if(extra) { setItemPayload(extra) } else { setItemPayload(null) }
      } else if (popUpFor('gen-preview')) {
        setGenPrevData(extra);
      } else if (popUpFor('initiate-payment-verification')) {
        setReference(extra);
      } else if (popUpFor('message')) {
        setMessage(extra);
      }

      setType(type);
      setShow(true);

    } catch (err) { console.log(err) }
    
  }

  const closePopup = () => {
    setShow(false);
    removeError();
    setRefresh(!refresh);
  }

  // disable scroll on show
  useEffect(() => {
    const html = document.documentElement;
    if(show) { html.style.overflow = 'hidden' } else { html.style.overflow = 'auto' }
  }, [show])

  // close popup on esc key pressed
  useEffect(() => {
    window.addEventListener('keydown', (e) => {
      if(e.key === 'Escape') removeError()
    })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PopUpContext.Provider value={{ 
      items,

      clickedImageInfo, 
      setClickedImageInfo,

      type, 
      popUp, 

      show, 
      setShow, 

      selectedType, 
      setSelectedType,
      
      selectedCollection, 
      setSelectedCollection, 

      noclick,
      
      collectionData,
      setCollectionData,

      genPrevData,

      reference,

      itemPayload,

      stage,
      setStage,

      message,

      closePopup
    }}>
      {children}
    </PopUpContext.Provider>
  )
}

export default PopUpContextProvider;