import { useState } from "react";
import useAccess from "../../../../hooks/access/useAccess";
import Button from "../../../button/Button";
import { useEffect } from "react";
import useAuthContext from "../../../../hooks/auth/useAuthContext";
import useProxyContext from "../../../../hooks/global/useProxyContext";
import axios from 'axios';
import useErrHandler from "../../../../hooks/err/useErrHandler";
import useProfile from "../../../../hooks/profile/useProfile";

const FundWallet = () => {
  const [amount, setAmount] = useState('');
  const [currency, setCurrency] = useState('$');

  const { userId } = useAccess();

  const { user } = useAuthContext();

  const { endpoint } = useProxyContext();

  const { notifier } = useErrHandler();

  const { profileSetup } = useProfile();

  const handleInputChange = (e) => {
    const rawValue = e.target.value;
    const currencyPattern = currency.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escape special characters in currency symbol
    const numericalValue = rawValue.replace(new RegExp(`${currencyPattern}\\s*`), ''); // Remove the currency prefix
    const formattedValue = addCommasToNumber(numericalValue);
    setAmount(`${currency} ${formattedValue}`);
  };

  const addCommasToNumber = (number) => {
    const parsedNumber = parseFloat(number.replace(/,/g, ''));
    if (isNaN(parsedNumber)) {
      return '';
    }
    return parsedNumber.toLocaleString();
  };

  useEffect(() => {
    if(!userId) return;
    const cncy = userId.currency;
    const currencyHandler = () => cncy === "NGN" ? "₦" : "$"
    setCurrency(currencyHandler())
  }, [userId])

  const payWithPaystack = async () => {
    if(!userId) return;

    const cleanedValue = amount.replace(/[^0-9.-]/g, "");
    
    notifier(false, "Initializing payment ...")

    try {
      const res = await axios.post(`${endpoint}/payment/use/paystack/initiate`, {
        email: userId.email,
        amount: cleanedValue,
        currency
      }, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      })

      window.open(
        res.data,
        "_self"
      )

    } catch (err) {
      console.log(err);
      const errMessage = err.response.data.message;
      notifier(true, errMessage);
    }
  }
  
/*   
  const payWithPaypal = async () => {
    if(!userId) return;
    
    const cleanedValue = amount.replace(/[^0-9.-]/g, "");
    
    notifier(false, "Initializing payment ...")
  
    try {
      const res = await axios.post(`${endpoint}/verify/use/paypal/topup/wallet`, {
        email: userId.email,
        amount: cleanedValue,
        currency
      }, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      })
  
      window.open(
        res.data,
        "_self"
      )
  
    } catch (err) {
      console.log(err);
      const errMessage = err.response.data.message;
      notifier(true, errMessage);
    }
  } */

  return (
    <div>
      <header>
        <h1>Top up your wallet.</h1>
        <p>You can use the funds in your wallet to make purchases within our platform. You can also withdraw your funds at any time</p>
      </header>

      <form onSubmit={(e) => e.preventDefault()}>
        <input 
          type="email" 
          defaultValue={userId && userId.email}
          readOnly
        />
        
        <input 
          value={amount}
          onChange={handleInputChange}
          type="text" 
          placeholder={`Amount in ${userId.currency}`} 
        />

        {userId.currency === 'NGN' ? (
          <Button run={() => profileSetup(() => payWithPaystack())}>Proceed with Paystack</Button>
        ) : (
          <p style={{
            background: '#FFA500AA',
            padding: '10px',
            borderRadius: '5px'
          }}>Dollar account top-up coming soon! Currently, only Naira account top-up is available. Stay tuned for updates.</p>
        )}
      </form>
    </div>
  );
}
 
export default FundWallet;