import styled from 'styled-components';

export const CreateStl = styled.div`

  & > div:nth-child(1) {
    padding-bottom: 100px;
    
    & > h1 {
      font-weight: 500;
      font-size: 30px;
      margin: 30px 0;
      text-align: center;
    }

    & > div {
      max-width: 800px;
      margin: auto;

      & > div {
        box-shadow: inset 0px 0px 3px rgba(0,0,0,0.5);
      }

      & > div:first-child {
        margin-bottom: 30px;
      }

      & > div[id="tags"] {
        padding: 12px;
      }
    }
  }

  @media (max-width: 550px) {
    & > div:nth-child(2) {
      padding: 0 0 100px 0;
    }
  }
`;

export const Editor = styled.div`
  background: #fff;
  overflow: auto;
  position: relative;

  & > div:first-child {
    position: sticky;
    top: 0;
    left: 0;
    margin-bottom: 20px;
    background: #fff;
    box-shadow: inset 0px 0px 3px rgba(0,0,0,0.3);
    padding: 12px;
    display: flex;
    justify-content: space-between;
    z-index: 99;

    & > button {
      padding: 9px;
      background: ${({ theme }) => theme.colors.main};
      color: #fff;
      border: none;
    }
  }

  & > div:nth-child(2),
      div:nth-child(3),
      div:nth-child(4) 
  {
    padding: 0 20px;
  }

  & > div main {
    position: relative;
    padding: 30px;
    background: #fff;
    margin-bottom: 10px;
    border-radius: 4px;
    font-size: 18px;
    line-height: 30px;

    & > form {
      
      & > input {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        margin: 30px;
      }

      & > img {
        border-radius: 20px;
      }
    }
    
    @media (max-width: 550px) {
      padding: 10px;
    }

    &:hover {
      box-shadow: 0px 1px 3px rgba(0,0,0,0.2);
    }

    & h1 {
      font-family: 'Jost', sans-serif;
      line-height: 1.3em;
    }

    & pre {
      white-space: pre-wrap;
    }
    
    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      
      & img {
        width: 20px;
        height: 20px;
        
      }
      
      & button {
        display: flex;
        gap: 5px;
        align-items: center;
        padding: 12px 17px;
        border-radius: 50px;
        border: 1px solid ${({ theme }) => theme.colors.black};
        background: #f6f9f5;
        cursor: pointer;
        
        &:last-child {
          padding: 12px;
          border-radius: 50px;

          & > img {
            object-fit: fill;
          }
        }
      }
      
      & > div { 
        padding: 12px 0;
        display: flex;
        gap: 10px;
        overflow-x: auto;

        &::-webkit-scrollbar { display: none }
        
      }
    }
  }

  @media (max-width: 740px) {
    & > div > main > div {

      & button {
        min-height: 50px;
        min-width: 50px;
        padding: 7px;
        display: grid;
        place-items: center;
        border-radius: 6px;
      }

      & span {
        display: none;
      }
    }
  }

  @media (max-width: 440px) {
    & > div > main {
      & h1 {
        font-size: 25px;
      }
    }
  }
`;

export const Add = styled.div`
  display: flex;
  align-items: center;
  margin: 30px 0;
  
  & > div:first-child {
    height: 40px;
    width: 40px;
    background: ${({ theme }) => theme.colors.main};
    border-radius: 50%;
    display: grid;
    place-items: center;

    & svg {
      font-size: 20px;
      color: ${({ theme }) => theme.colors.white };
    }
  }

  & > div:last-child {
    height: 3px;
    width: calc(100% - 40px);
    background: ${({ theme }) => theme.colors.main};
  }
`;

export const FormatingBoxStl = styled.div`
  padding: 20px;
  background: #fff;
  margin-bottom: 10px;
  border-radius: 4px;

  &:hover {
    box-shadow: 0px 1px 3px rgba(0,0,0,0.2);
  }

  @media (max-width: 550px) {
    padding: 10px;
  }
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  & > div {
    width: 100%;
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: center;
  }
  
  & p {
    font-size: 50px;
    font-style: italic;
    outline: none;
    text-align: center;
    color: ${({ theme }) => theme.colors.main};
  }
  
  & form {
    width: 100%;
    display: flex;
    gap: 15px;
    flex-direction: column;
    align-items: center; 

    & input {
      display: none;
    }

    & label {
      padding: 12px 17px;
      background: #f6f6f6;
      color: ${({ theme }) => theme.colors.black};
      border: 1px solid ${({ theme }) => theme.colors.black};
      border-radius: 50px;
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }

  & img {
    max-height: 300px;
    border-radius: 10px;
  }
  
  @media (max-width: 440px) {
    & p {
      font-size: 40px;
    }
  }
`;