import { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import useProxyContext from '../../hooks/global/useProxyContext';
import useAuthContext from '../../hooks/auth/useAuthContext';
import useErrHandler from '../../hooks/err/useErrHandler';
import useProcesser from '../../hooks/processer/useProcesser';
import { encode } from 'blurhash';

export const ConstructorContext = createContext();

const ConstructorContextProvider = ({ children }) => {
  const [expandChat, setExpandChat] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [chat, setChat] = useState(null);

  const [inforBar, setInforBar] = useState({
    title: '',
    src: '',
    subTitle: ''
  })
  
  const [message, setMessage] = useState({
    text: '',
    replyRef: {}
  });
  
  const [messages, setMessages] = useState([]);
  const [chats, setChats] = useState(null);
  const [autoScroll, setAutoScroll] = useState(true);
  
  const [optionsMenu, setOptionsMenu] = useState({
    open: false,
    myText: false,
    optionId: '',
    text: '',
  });

  const [textDeletion, setTextDeletion] = useState('');

  const [uploadPreviewDetails, setUploadPreviewDetails] = useState('');

  const [uploadPreviewOpen, setUploadPreviewOpen] = useState(false);


  const { endpoint } = useProxyContext();
  const { user } = useAuthContext();
  const { notifier } = useErrHandler();
  const { process } = useProcesser();

  const reachRoute = async (callback, prop, route, signal) => {
    if(!user) return notifier(false, "Please Sign In to continue!");

    try {
      const res = await fetch(`${endpoint}${route}`, {
        signal: signal,
        body: JSON.stringify( prop ),
          headers: { 'Authorization': `Bearer ${user.token}` }
        }, 
      );

      if(!res.ok) {
        process('ENDED', '', false);
        return;
      }
      
      process('ENDED', '', false);
      const data = res.json();
      callback(data, res);
      
    } catch (err) {
      process('ENDED', '', false);
    }
  }

  const postToRoute = async (callback, prop, route) => {
    if(!user) return notifier(false, "Please Sign In to continue!");

    try {
      const res = await axios.post(`${endpoint}${route}`, {
        prop
      }, { headers: { 'Authorization': `Bearer ${user.token}` }});

      callback(res.data);

    } catch (err) { 
      process('ENDED', '', false);
      const errMessage = err.response.data.message;

      notifier(true, errMessage);
    }
  }

  const postToRouteRA = async (callback, prop, route, signal) => {
    if(!user) return notifier(false, "Please Sign In to continue!");

    try {
      const res = await fetch(`${endpoint}${route}`, {
        signal: signal,
        method: 'POST',
        body: JSON.stringify( prop ),
        headers: { 'Authorization': `Bearer ${user.token}`, 'Content-Type': 'application/json' }
      })
  
      if(!res.ok) {
        const errorData = await res.json();
        notifier(true, errorData.message);
        process('ENDED', '', false);
        return;
      }
      
      process('ENDED', '', false);
      const data = await res.json();
      callback(data, res);
  
    } catch (err) {
      process('ENDED', '', false);
    }

  }

  const getRoute = async (callback, route, signal, error) => {
    if(!navigator.onLine) return;
    
    try {
      const res = await fetch(`${endpoint}${route}`, { signal: signal })

      const data = await res.json();
      
      if(!res.ok) {
        process('ENDED', '', false);
        error(data);
        return;
      }
      
      process('ENDED', '', false);
      callback(data, res);
  
    } catch (err) {
      process('ENDED', '', false);
    }
  }

  const getRouteRA = async (callback, route, signal, error) => {
    if(!user) return notifier(true, 'Sign in to continue!')

    if(!navigator.onLine) return;

    try {

      const res = await fetch(`${endpoint}${route}`, {
        signal: signal,
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      })
      
      const data = await res.json();

      if(!res.ok) {
        process('ENDED', '', false);
        error(data);
        return;
      }
      
      process('ENDED', '', false);
      callback(data);
      
    } catch (err) {
      process('ENDED', '', false);
      // handle error
    }
  }
  
  const putToRoute = async (callback, route, signal) => {
    if(!navigator.onLine) return;

    try {
      const res = await fetch(`${endpoint}${route}`, {
        method: 'PUT',
        signal: signal,
      })

      if(!res.ok) {
        process('ENDED', '', false);
        return;
      }
      
      process('ENDED', '', false);
      const data = await res.json();
      callback(data);
      
    } catch (err) {
      process('ENDED', '', false);
      // handle error
    }
  }

  const putToRouteRA = async (callback, route, signal) => {
    if(!user) return notifier(true, 'Sign in to continue!')

    if(!navigator.onLine) return;

    try {
      const res = await fetch(`${endpoint}${route}`, {
        method: 'PUT',
        signal: signal,
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      })

      if(!res.ok) {
        process('ENDED', '', false);
        return;
      }
      
      process('ENDED', '', false);
      const data = await res.json();
      callback(data);
      
    } catch (err) {
      process('ENDED', '', false);
      // handle error
    }
  }

  const loadImage = async src =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = (...args) => reject(args);
    img.src = src;
  });

  const getImageData = image => {
    const canvas = document.createElement("canvas");
    canvas.width = image.width;
    canvas.height = image.height;
    const context = canvas.getContext("2d");
    context.drawImage(image, 0, 0);
    return context.getImageData(0, 0, image.width, image.height);
  };

  const encodeImageToBlurhash = async (imageUrl) => {

    if(!imageUrl) return notifier(true, "Select image! First field is required.");

    const image = await loadImage(imageUrl);
    const imageData = getImageData(image);

    return encode(imageData.data, imageData.width, imageData.height, 4, 4);
  };

  const closeChat = () => {
    setMessages([]);
    setMessage(() => ({ text: '', replyRef: {} }))
    setOpenMessage(false);
    setInforBar(() => ({ title: 'Messages', src: '', subTitle: '' }))
  }

  const handleUploadPreview = (extra) => {
    setUploadPreviewDetails(extra);
    setUploadPreviewOpen(true);
  }

  // disable scroll on show
  useEffect(() => {
    const html = document.documentElement;
    if(uploadPreviewOpen) { html.style.overflow = 'hidden' } else { html.style.overflow = 'auto' }
  }, [uploadPreviewOpen])

  // handle Navigation
  const handleNavigation = () => {
    if(openMessage) {
      closeChat();
    } else {
      setExpandChat(!expandChat)
    }
  }
  
  return (
    <ConstructorContext.Provider value={{
      getRoute, // get to [route] then call [callback] with data reusable across multiple get requests
      getRouteRA, // get to [route] then call [callback] with data reusable across multipl get requies [RA] require authentication
      putToRoute, // put to [route] then call [callback] with data reusable across multiple put requests. 
      putToRouteRA, // put to [route] then call [callback] with data reusable across multiple put requests.[RA] require authentication
      reachRoute, // reach to [route] in item with  [_id] then call [callback] reusable across multiple put requests
      postToRouteRA,
      postToRoute, // post to [route] in item with [prop] and [route] then call [callback] reusable across multiple put requests

      // hook for chat component
      expandChat,
      setExpandChat,

      // hook for open messages
      openMessage,
      setOpenMessage,

      // hook for chat
      chat,
      setChat,

      // hook for message from input
      message,
      setMessage,

      // hook holds the array of messages when chat is opend
      messages,
      setMessages,

      // Close the ongoing conversation 
      closeChat,

      // hook holds chats from inboxes
      chats,
      setChats,

      // hook holds boolean of auto scroll 
      autoScroll, 
      setAutoScroll,

      // hook holds boolean for open options menu state
      optionsMenu,
      setOptionsMenu,

      // hook holds the value of deleted text
      textDeletion,
      setTextDeletion,

      // hook holds the value of inforbar OBJECT
      inforBar,
      setInforBar,

      // upload preview
      uploadPreviewDetails,
      uploadPreviewOpen,
      setUploadPreviewOpen,
      handleUploadPreview,

      // image to blurhash encoder
      encodeImageToBlurhash,

      // handle chats navigation
      handleNavigation
    }}>
      {children}
    </ConstructorContext.Provider>
  )
}

export default ConstructorContextProvider;