import styled from 'styled-components';

export const CommentsStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 0;

  & > div[id="commentBox"] {
    display: flex;
    flex-direction: column;
    gap: 60px;
    padding: 20px;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 10px;
    border: 1px solid ${({ theme }) => theme.colors.black}50;

    & > div {
      display: flex;
      flex-direction: column;
      gap: 10px;

      & > div[id="profile"] {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        & > div {
          display: flex;
          align-items: center;
          gap: 15px;

          & > img {
            height: 30px;
            min-width: 30px;
            max-width: 30px;
            border-radius: 50%;
            object-fit: cover;
          }
        }

        & > span {
          border-radius: 5px;
          background: ${({ theme }) => theme.colors.main2}20;
          height: 30px;
          width: 30px;
          display: grid;
          place-items: center;
          cursor: pointer;

          & > svg {
            font-size: 17px;
            color: ${({ theme }) => theme.colors.black};
          }
        }
      }

      & > div[id="comment"] {
        display: flex;

        & > div {
          display: flex;
          flex-direction column;
          padding: 10px;
          border-radius: 10px;
          background: ${({ theme }) => theme.colors.main2}20;
          color: ${({ theme }) => theme.colors.black};
        }
      }

      & > p[id="replyBtn"] {
        cursor: pointer;
      }
    }
  }
  
  & > form {
    margin-top: 30px;
    display: flex;
    align-items: center;

    & > input {
      padding: 17px;
      font-size: 19px;
      width: 70%;
      border: none;
      outline: none;
      border-bottom-left-radius: 50px;
      border-top-left-radius: 50px;
      background: ${({ theme }) => theme.colors.main2}10;
      color: ${({ theme }) => theme.colors.black};
    }

    & > button {
      padding: 17px;
      border-radius: 0;
      width: 30%;
      border-bottom-right-radius: 50px;
      border-top-right-radius: 50px;
      background: linear-gradient(to right top, #cf23cf, #eedd44);
    }
  }
`;