import styled from "styled-components";

export const UploadPreviewStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background: ${({ theme }) => theme.colors.theme};
  z-index: 1000;
  overflow: auto;

  & > div[id="closeBtn"] {
    position: fixed;
    top: 30px;
    right: 30px;
    padding: 10px 20px;
    border-radius: 50px;
    font-size: 17px;
    cursor: pointer;
    display: flex;
    align-items: center;
    grid-gap: 5px;
    color: ${({ theme }) => theme.colors.black}AA;
    background: ${({ theme }) => theme.colors.white}20;
    box-shadow: -5px 7px 25px rgba(0,0,0,0.3);
    z-index: 500;
    
    &:hover {
      color: ${({ theme }) => theme.colors.white};
      background: ${({ theme }) => theme.colors.main2};
    }

    @media(max-width: ${({ theme }) => theme.mobile.is500}) {
      padding: 10px;
      & span { display: none }
    }
  }
`;