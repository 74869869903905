import Button from "../../../button/Button";
import { CommentsStyle } from "./styles/S_Comments";
import { IoMdSend } from 'react-icons/io';
import { AiFillDelete } from 'react-icons/ai';
import { useEffect, useState } from 'react';
import axios from "axios";
import useAccess from "../../../../hooks/access/useAccess";
import useProxyContext from "../../../../hooks/global/useProxyContext";
import useErrHandler from "../../../../hooks/err/useErrHandler";
import useAuthContext from "../../../../hooks/auth/useAuthContext";
import useRefreshContext from "../../../../hooks/global/useRefreshContext";
import useProcesser from "../../../../hooks/processer/useProcesser";
import useDate from '../../../../hooks/global/useDate';

const trim = (str) => str.replace(/\s+/g, '');

const Comments = ({ post }) => {
  const [comment, setComment] = useState('');
  const [comments, setComments] = useState(null);

  const { userId } = useAccess();

  const { endpoint, imageEndpoint } = useProxyContext();

  const { notifier } = useErrHandler();

  const { user } = useAuthContext();

  const { refresh, reRender } = useRefreshContext();

  const { process } = useProcesser();

  const { getDate } = useDate();
  
  const postComment = async (e) => {
    e.preventDefault();

    if(!userId) return notifier(true, "You need to login first");

    if(trim(comment).length < 1) return;
    
    process('STARTED', 'Posting your comment...')

    try {
      await axios.put(`${endpoint}/post/comment`, {
        comment,
        postId: post._id,
      }, {
        headers: {
          'Authorization': `Bearer, ${user.token}`
        }
      })

      process('ENDED')

      notifier(false, 'Posted!')
      setComment("");
      reRender();

    } catch (err) {
      process('ENDED');
      console.log(err);
    }
  }
  
  // get comments
  useEffect(() => {
    (async function(){
      try {
        const res = await axios.get(`${endpoint}/comments/${post._id}`, {
          comment,
          postId: post._id,
        })
    
        setComments(res.data);
    
      } catch (err) {
        const errMessage = err.response.data.message;
        notifier(true, errMessage);
      }
    }())

  }, [endpoint, notifier, comment, post, refresh]) // note comments will refetch whey user is typing

  const deleteComment = async (commentId) => {
    try {
      await axios.put(`${endpoint}/del/comment`, {
        postId: post._id,
        commentId
      }, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      })

      reRender();

    } catch (err) {
      console.log(err);
    }
  }

  const replyBtn = (userName) => {
    setComment(`${userName} `);
    document.getElementById('commentInput').focus();
  }

  return (
    <CommentsStyle>
      <h4>Comments</h4>

      <div id="commentBox">
        {/* Comments */}
        
        {comments && comments.length > 0 ? comments.map((user, i) => (
          <div key={i}>
            <div id='profile'>
              <div>
                <img src={user.userPP ? `${imageEndpoint}/images/${user.userPP}` : '/images/profile.jpg'} alt=""/>
                <div>
                  <h4>{user.userName}</h4>
                </div>
              </div>
              { userId && (user.commenterId === userId._id) && <span onClick={() => deleteComment(user.commentId)}><AiFillDelete /></span> }
            </div>

            <div id='comment'>
              <div>
                <small>{ getDate(user._date) }</small>
                {user.comment}
              </div>
            </div>

            <p onClick={() => replyBtn(user.userName)} id="replyBtn">Reply</p>
          </div>
        )) : (
          <p>Be the first to comment 😊</p>
        )}
        
      </div>

      <form onSubmit={postComment}>
        <input 
          id="commentInput"
          type="text" 
          placeholder="Write a comment.." 
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        <Button>Send <IoMdSend /></Button>
      </form>
    </CommentsStyle>
  )
}

export default Comments;