import { createContext, useEffect, useReducer } from 'react'
import Reducer from'./Reducer';
import Cookies from 'js-cookie';

const userToken = Cookies.get('jwt');

const INITIAL_STATE = {
  user: (userToken !== undefined && JSON.parse(userToken)) || null,
  isFetching: false,
  error: false
}

export const AuthContext = createContext(INITIAL_STATE);

const AuthContextProvider = ({children}) => {
  const [state, dispatch] = useReducer(Reducer, INITIAL_STATE);

  useEffect(() => {
    Cookies.set('jwt', JSON.stringify(state.user), {
      secure: true,
      expires: 3
    })
  },[state.user])

  return (
    <AuthContext.Provider value={{ 
      user: state.user, 
      error: state.error,
      isFetching: state.isFetching,
      dispatch
    }}> 
      {children} 
    </AuthContext.Provider>
  )
}

export default AuthContextProvider;