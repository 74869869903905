import Button from "../../../button/Button";
import { useState } from "react";
import { useEffect } from "react";
import useAccess from "../../../../hooks/access/useAccess";
import { FaMinus, FaPlus } from "react-icons/fa";
import { PaintConfirmStyle, PaintStyle } from "./styles/S_Paint";
import { HiMinusSm, HiOutlineArrowLeft, HiOutlineArrowRight, HiPlusSm } from "react-icons/hi";
import useProxyContext from "../../../../hooks/global/useProxyContext";
import useAuthContext from "../../../../hooks/auth/useAuthContext";
import useProcesser from "../../../../hooks/processer/useProcesser";
import useErrHandler from "../../../../hooks/err/useErrHandler";
import useImgHandler from "../../../../hooks/img/useImgHandler";
import useDate from "../../../../hooks/global/useDate";
import useProfile from "../../../../hooks/profile/useProfile";
import { useNavigate } from "react-router-dom";
import usePopUpContext from "../../../../hooks/global/usePopUpContext";

const Paint = () => {
  const [defaultCurrency, setDefaultCurrency] = useState('');
  const [paints, setPaints] = useState(10);
  const [confirm, setConfirm] = useState(false);
  const [price, setPrice] = useState('');
  const [paintForPersonal, setPaintForPersonal] = useState(true);
  const [walletBalance, setWalletBalance] = useState('');
  const [confirmButtonText, setConfirmButtonText] = useState('Proceed');
  
  const { userId } = useAccess();

  const { endpoint } = useProxyContext();

  const { user } = useAuthContext();

  const { process, processing }  = useProcesser();

  const { notifier, message, error, removeError } = useErrHandler();

  const { closePopup } = usePopUpContext();

  const navigate = useNavigate();

  const paintInNGN = 99.00;
  const paintInUSD = 0.13;

  const addCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  useEffect(() => {
    if(!userId) return;
    setDefaultCurrency(userId.currency);

    if(!defaultCurrency) return;
    const balance = userId.wallet[defaultCurrency].balance.toFixed(2);
    setWalletBalance(addCommas(balance));

  }, [userId, defaultCurrency])

  // one paint is equal to 99 in naira and 0.13 in usd
  // create a function that increment paints value and update paints price base on the active curency (default currency)
  // two function one that increments and one that decrements

  const incrementPaints = () => {
    setPaints(prevPaints => prevPaints + 1);
  };
  
  const decrementPaints = () => {
    if (paints > 1) {
      setPaints(prevPaints => prevPaints - 1);
    }
  };
  
  const getPrice = () => {
    if(!defaultCurrency) return;
    
    let price;
    let currencySymbol;
    
    if (defaultCurrency === 'NGN') {
      price = paints * paintInNGN;
      currencySymbol = '₦';
    } else if (defaultCurrency === 'USD') {
      price = paints * paintInUSD;
      currencySymbol = '$';
    }

    const formattedPrice = price.toFixed(2);

    return `${currencySymbol}${addCommas(formattedPrice)}`;
  };

  const callConfirm = (value) => {
    setPrice(getPrice());
    setConfirm(true);
    setPaintForPersonal(value);  
    setConfirmButtonText('Proceed');
    removeError();
  }

  const handlePayment = async () => {
    if(processing) return;

    process('STARTED', 'Processing ...');

    const amountInNumber = parseFloat(price.replace(/[$,₦]/g, ''));

    const options = {
      method: 'POST',

      body: JSON.stringify({
        amount: amountInNumber,
        numberOfPaints: paints,
        paintForPersonal,
      }),

      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token}`
      }
    }

    try {
      const response = await fetch(`${endpoint}/wallet/payment/paint`, options);
      const data = await response.json();

      process('ENDED');
      
      if(response.status === 402) {
        notifier(true, data.message);
      } else {
        notifier(false, data.message);
        setConfirmButtonText('Pay again')
      }

    } catch (err) {
      process('ENDED');
      
      console.log(err);
    }
  }

  const handleNavigate = (url) => {
    navigate(url);
    closePopup();
  }

  return !confirm ? (
    <PaintStyle>
      <header>
        <h1>Get More Paints for Your Image Generator!</h1>
      </header>

      <p>Purchase Personal Paints</p>

      <div id="selector">
        <span onClick={decrementPaints} id="icon"><FaMinus /></span>
        <span id="option"> <span><img src="/images/badge/paint_personal.png" alt="personal" /> {paints} </span> </span>
        <span onClick={incrementPaints} id="icon"><FaPlus /></span>
      </div>
      
      <Button run={() => callConfirm(true)} id="payPersonal">Pay {getPrice()} </Button>

      <div style={{
        margin: '30px 0',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }} ><h3>. . .</h3><h3>. .</h3></div>

      <p>Contribute to the Public Pool - Empowering Everyone to Harness the Image Generator's Magic!</p>
      
      <small>Your Contribution to the Public Pool Will Be Appreciated by All! Join the Community of Generous Contributors Today!</small>
      
      <div id="selector">
        <span onClick={decrementPaints} id="icon"><FaMinus /></span>
        <span id="option"> <span><img src="/images/badge/paint_public.png" alt="public" /> {paints} </span> </span>
        <span onClick={incrementPaints} id="icon"><FaPlus /></span>
      </div>

      <Button run={() => callConfirm(false)} id="payPublic">Pay {getPrice()} </Button>

      <div style={{
        margin: '30px 0',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }} ><h3>. .</h3><h3>. . .</h3></div>

      <Contributors />

    </PaintStyle>
  ) : (
    <PaintConfirmStyle error={error} message={message}>
      <span onClick={() => setConfirm(false)}>
        <HiOutlineArrowLeft />
      </span>

      <div onClick={() => handleNavigate('/wallet')}>
        <h4>Wallet balance</h4>
        <h3>{defaultCurrency} {walletBalance}</h3>
      </div>

      <p>
        {paintForPersonal ? <>
          <strong>{price} </strong> will be deducted from your wallet for <strong>{paints} paints</strong>. Click <strong>Proceed</strong> to continue."
        </> : <>
          Thank you for your contribution towards the public paint. You are providing <strong>{paints} paints</strong> for the public and <strong>{price}</strong> will be deducted from your wallet. Please click <strong>Proceed</strong> to confirm.
        </>}
      </p>

      <Button run={() => handlePayment()}>{confirmButtonText} <HiOutlineArrowRight /></Button>
      
      <small>{ message }</small>
    </PaintConfirmStyle>
  );
}

const Contributors = () => {
  const [contributors, setContributors] = useState(null);

  const { endpoint } = useProxyContext();

  useEffect(() => {
    (async function(){
      const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      }

      try {
        const response = await fetch(`${endpoint}/gen/ai/public/paint/contributors`, options);
        const data = await response.json();
        
        setContributors(data);

      } catch (err) {
        console.log(err);
      }
    }())
  }, [endpoint])

  return contributors ? (
    <div id="contributors">
      <h2>Contributors</h2>

      <div>
        {contributors.map((contributor, i) => (
          <Contributor key={i} contributor={contributor} index={i} />
        ))}

        {/* More ... */}

      </div>
    </div>
  ) : (
    <div> Be the first to contribute </div>
  )
}

const Contributor = ({ contributor }) => {
  const { getDate } = useDate();

  return (
    <div>
      <User userId={contributor.userId} />

      <p>Contributed {contributor.numberOfPaints} paints {getDate(contributor.createdAt)}</p>

      <Directive contributorId={contributor.userId} />
    </div>
  )
}

const Directive = ({ contributorId }) => {
  const [user, setUser] = useState();

  const { getRoute } = useImgHandler();
  const { followButtonFunction, setupDirective, directive } = useProfile();
  const { userId } = useAccess();
  
  useEffect(() => {
    getRoute((data) => { setUser(data) }, `/user/profile/${contributorId}`)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contributorId])
  
  useEffect(() => { 
    setupDirective(user) 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  if(!user) return;

  return (
    <Button run={() => followButtonFunction(user)}>
      { userId && userId._id === user._id ? "" : (directive === 'Follow') ? <HiPlusSm /> : <HiMinusSm /> } 
      { userId && userId._id === user._id ? "Your Profile" : directive } 
    </Button>
  )
}

const User = ({ userId }) => {
  const [user, setUser] = useState(null)

  const { getRoute } = useImgHandler();
  const { imageEndpoint } = useProxyContext();
  
  const navigate = useNavigate();

  useEffect(() => {
    getRoute((data) => { setUser(data) }, `/user/profile/${userId}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  return user ? (
    <div>
      <img onClick={() => navigate(`/${user.userName.split(' ')[0]}/profile/${user._id}/uploads`)} src={`${imageEndpoint}/images/${user.profilePic}`} alt=" " />
      <div>
        <h2>{user.userName}</h2>
        <small id="followers">{user.followers.length} Followers</small>
        <small>{user.desc}</small>
      </div>
    </div>
  ) : ( <small>Loading ...</small> )
}

export default Paint;