import Button from "../../components/button/Button"
import { Container } from "../../components/global/Container"
import TopBar from "../../components/topbar/TopBar"
import Footer from '../../components/footer/Footer';
import { Activities, DashboardStyle, Profile } from "./styles/S_Dashboard"
import { IoMdCloudUpload } from 'react-icons/io';
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import useProxyContext from '../../hooks/global/useProxyContext';
import useAuthContext from '../../hooks/auth/useAuthContext';
import  useErrHandler from '../../hooks/err/useErrHandler';
import usePopUpContext from "../../hooks/global/usePopUpContext";
import useAccess from "../../hooks/access/useAccess";
import useRefreshContext from "../../hooks/global/useRefreshContext";
import Options from "./options/Options";
import Nav from "../modules/profile/nav/Nav";
import { About, Downloads, MyCollections, Purchases, Stared, Blog, Uploads } from "../modules/profile";
import useProfile from '../../hooks/profile/useProfile';
import { Helmet } from 'react-helmet-async';
import { MdWork } from "react-icons/md";
import UserOverview from '../../components/user/UserOverview';
import UserProfileSkeleton from "../../components/loader/skeleton/UserProfileSkeleton";
import useVisits from "../../hooks/stats/visits/useVisits";
import useConstructor from "../../hooks/constructor/useConstructor";

const Dashboard = () => {
  useVisits();
  
  // variables

  const [userProfile, setUserProfile] = useState(null);

  const { endpoint, imageEndpoint } = useProxyContext()

  const { refresh, reRender } = useRefreshContext();

  const { setCollectionData, setClickedImageInfo,  collectionData, clickedImageInfo } = usePopUpContext();
  
  const { user, dispatch } = useAuthContext();

  const { notifier } = useErrHandler();

  const { userId } = useAccess();

  const { profileSetup, getProfilePicture } = useProfile();
  
  const { getRoute } = useConstructor();

  const display = document.baseURI.split("/")[6];
  
  const profileId = document.baseURI.split("/")[5];
  // variables

  // get token
  useEffect(() => {

    // Parse URL to get query parameters
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    if(!token) return;

    const userToken = { token }
    
    dispatch({ type: 'LOGIN_SUCCESS', payload: userToken});

    // Remove token from URL in browser history
    const newUrl = window.location.href.split('?')[0];
    window.history.pushState({}, document.title, newUrl);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile])

  // fetch user
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    getRoute((data) => {
      setUserProfile(data);
    }, `/user/profile/${profileId}`, signal)

    window.onpopstate = function() {
      reRender();
    }

    return () => {
      controller.abort();
    }

    // disable warning for getRoute() missing dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reRender, endpoint, profileId, refresh])

  // Move Item 
  useEffect(() => {
    const moveItem = async () => {

      if(collectionData && clickedImageInfo) { // after move set back to null

        try {
          const res = await axios.put(`${endpoint}/mv/item`, {
            imageId: clickedImageInfo._id,
            collectionId: collectionData._id,
            collectionName: collectionData.header
          }, {
            headers: {
              'Authorization': `Bearer ${user.token}`
            }
          });
      
          // consider navigating user to collections after move
          setClickedImageInfo(null);
          setCollectionData(null);
          notifier(false, res.data.message)
      
        } catch (err) {
          const errMessage = err.response.data.message;
          notifier(true, errMessage);
        }
        
      }
    }

    moveItem();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endpoint, collectionData]);

  // ask user to complete setup where they left off
  useEffect(() => {
    setTimeout(() => {
      profileSetup(() => {});
    }, 4000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  useEffect(() => {
    const dashboardProfilePicture = document.querySelectorAll('#dashboardProfilePicture');
    getProfilePicture(dashboardProfilePicture, userProfile)
  },[userProfile, getProfilePicture])

  return (
    <DashboardStyle>

      <Helmet>
        <title>{ userProfile && `${ userProfile.userName } - Model Grapix - Profile` }</title>

        <meta name="description" content={ userProfile && `${userProfile.about}` } />
        
        <meta property="og:title" content={ userProfile && `${userProfile.userName} ModelGrapix - Profile` } />
        <meta property="og:description" content={ userProfile && `${userProfile.about}` } />
        <meta property="og:image" content={ userProfile && `${ (userProfile.profilePic ? `${imageEndpoint}/images/${userProfile.profilePic}` : '/images/profile.jpg')}` } />
        <meta property="og:url" content="htts://modelgrapix.com" />

        <link rel="icon" href={ `${userProfile && (userProfile.profilePic ? `${imageEndpoint}/images/${userProfile.profilePic}` : '/images/profile.jpg')}` } />
      </Helmet>

      <TopBar />

      {/* Profile */}
      {userProfile ? 
      (
      <>
      <Profile userProfile={userProfile}>
        <Container>
          <div id="profile">
            <div id="profilePics">
              <img id="dashboardProfilePicture" src="/images/profile.jpg" alt=""/>
            </div>

            <div id="accountInfo">
              <h1>{userProfile.userName}</h1>

              {/* <p id="userEmail">{userProfile.email}</p> */}
              
              <small>{userProfile.desc}</small>
              
              <UserOverview userData={userProfile} button={true} />
              
              <h2>{userProfile.pro ? "PRO" : "- FREE -"}</h2>
            </div>
          </div>
          
          {userId && userId._id === userProfile._id ? (
            <Link className="link" to="/upload" >
              <Button> Upload <IoMdCloudUpload /> </Button> 
            </Link>
          ):( userProfile &&
            <a className="link" href={userProfile.cta ? userProfile.cta : `/${userProfile.userName}/profile/${userProfile._id}/uploads`} >
              <Button> Let's Work <MdWork /></Button>  
            </a>
          )}
        </Container>
      </Profile>
      
      { userId && userId._id === userProfile._id && <Options /> }

      <Container>
        <Nav userProfile={userProfile} />
      </Container>
      
      {/* Activities Container */}
      <Activities>
        <Container id="container">

          {/* Items in Uploads */}
          {display === 'uploads' && 
            <Uploads userProfile={userProfile} />
          }

          {/* Collections created by this user */}
          {display === 'collections' && 
            userId && (userId._id === userProfile._id) && <MyCollections />
          }

          {/* Items in Donwnloads */}
          {display === 'downloads' && 
            userId && (userId._id === userProfile._id) && <Downloads downloads={userProfile.downloads} userProfile={userProfile} />
          }
        
          {/* Items i bought */}
          {display === 'purchases' && 
            userId && (userId._id === userProfile._id) && <Purchases purchases={userProfile.purchases} userProfile={userProfile} />
          }

          {/* Items in Stared */}
          {display === 'stared' && 
            <Stared stared={userProfile.stared} userProfile={userProfile} />
          }

          {/* Items in Blog */}
          {display === 'blog' && 
            userId && (userId._id === userProfile._id) && <Blog blog={userProfile.blog} />
          }

          {/* About */}
          {display === 'about' && 
            <About about={userProfile.about} />
          }

        </Container>
      </Activities>
      </>
      ) : (
        <UserProfileSkeleton />
      )}
      
      {/* Footer */}
      <Footer />

    </DashboardStyle>
  )
}

export default Dashboard 