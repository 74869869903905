import { useEffect } from "react";
import { ModulesStyle } from "../../styles/S_Documentation";

import hljs from 'highlight.js';
import 'highlight.js/styles/atom-one-dark.css';

import DOMPurify from "dompurify";

const renderCode = (match, code) => {
  const highlightedCode = hljs.highlightAuto(code).value;
  return `<div id="code"><span id="copy">Copy</span><code id="code-preview" class="javascript">${highlightedCode}</code></div>`;
};

const UseOfPlatform = () => {

  const content = 
`<h1 id="introduction">Introduction</h1>
<h2>Overview of Model Grapix</h2> <!-- Overview of Model Grapix -->
Welcome to Model Grapix! We're excited to have you join our community of creators, designers, and art lovers. This documentation will provide an overview of how to use our platform.

<h2>Purpose of this documentation</h2>
The purpose of the documentation page for Model Grapix is to provide users with a comprehensive guide to using the platform. The page is designed to help users get started with Model Grapix, understand its features and functionality, and use it effectively to achieve their goals.

The documentation page serves as a resource for users to reference as they navigate the platform and make the most of its features. It provides step-by-step instructions, tips and best practices, and troubleshooting guidance to help users overcome any challenges they may encounter while using Model Grapix.

<h1 id="gettingStarted">Getting Started</h1> <!-- getting started -->
<h2>Creating an account</h2>
You need to create an account to become part of our community and be able to have access to all of the platform's features and functionality

<h4>Below are steps in creating account:</h4>
**I. Step-by-Step Guide**

<ul>#Visit the Model Grapix website#
#Click on the **Sign In** button at the top right corner of the screen#
#Fill out the required information (e.g. username, email, password)#
#Verify your email address#
#Log in to your account#</ul>

**OR**
You can sign in with google

**II. Best Practices**

<ul>#Choosing a strong and unique password#
#Verifying your email address as soon as possible#
#Keeping your account information up-to-date#</ul>

<h2>Setting up your profile</h2>
Once you've created your account, you'll be asked to complete your profile setup by adding info like your profile picture, age range, gender, country, bio, and preferred currency. Completing this setup unlocks full access to the platform. 

Next, you can customise your profile even further in Settings under Personal Information. Here you can reselect your Profile picture, write an About section, add a Call to Action button to redirect people to your other platforms. 

With your profile ready to go, it's time to start engaging on Model Grapix!

<h2>Navigating Model Grapix</h2>
The Model Grapix platform provides a space for creatives to showcase their work, connect with others, and access AI tools. Here is an overview of navigating the key sections of the platform.

**User Profile**

Each user has a public profile displaying:

<ul>#Profile photo#
#Username#
#Follower/Upload counts#
#Buttons to access wallet, API key, and activities#
#Sections for uploads, collections, downloads, purchases, blog, favorites, and about#</ul>

**Home Feed**

The home feed shows recent uploads, collections, and user profiles to follow. You can filter the feed using the navigation buttons:

<ul>#All#
#Uploads#
#Collections#
#Users#</ul>

Click into an upload, collection, or user to view more details. Engage by **liking, commenting,** or **following.**

**Top Bar**

The top bar on desktop provides quick access to key sections:

<ul>#Logo - **Return to the home feed**#
#Create - **Upload content, create collections, or write articles**#
#Tools - **Access AI tools like image generation and background removal**#
#Notifications - **See notifications and interactions**#
#Profile - **Manage your profile and account settings**#</ul>

**Settings**

In your profile settings you can:

<ul>#Set up your profile#
#Select language, dark/light mode#
#Manage notifications#
#Add payment methods#
#View API keys#
#Log out or delete account#</ul>

<h1 id="usingModelGrapix">Using Model Grapix</h1>
<h2>Displaying your projects</h2>
**A. Uploading Content**

To upload your own designs, images, and other creations, go to the **Create** button in the top navigation bar. Click on **Upload** and you'll be taken to the upload page. Here you can select a file, give it a title and description, and configure any other settings. 

**B. Editing a upload**

If you need to make changes to an upload, simply go to your profile and click on the option menu of the upload you want to edit and select "Edit". You'll be redirected to the edit page where you can make changes to the project name, description, images, category e.t.c.

**C. Deleting a project**

If you want to remove an upload from your profile, go to your profile and click on the option menu of the upload you want to delete and select "Delete". Confirm that you want to delete the upload.

**D. Moving projects**

You can move upload from one collection to another, and to do this click on the option menu of the upload you want to move and select "Move". you'll see a popup asking to select the colletion where you want to move the upload.

When an upload is moved to a collection the public status goes back to private, meaning you'll have to switch it back to public.

Additionally when you move an upload to someone else collection they'll approve it before it's shown to the public.

<h2>Creating Collections</h2>
**Note:** Any upload shown to the public must be inside a public collection, meaning if your upload is not inside a public collection it is visible to only you.

Your collection must undergo approval before shown to the public and while creating a collection you need to provide what the collection is all about and also make sure your activities aligns with the platforms Content Policy.

Collections allow you to organize and group together uploads that share a theme or style. Make a collection for your product photography, landscape paintings, or abstract generative art. 

To make a collection, click on Create at the topbar or if you are on mobile find "Create" inside the options menu, or profile menu then click on Collection. Enter a name and description for your collection, select a cover image, choose a collection type, and start adding uploads! 

Collections go through approval before going live.

<h2>Interacting with Content </h2>
Model Grapix makes it easy to engage with creations from our talented community. You can like, star, and comment on uploads and collections. Visit a user's profile to see their full portfolio and hit follow to keep up with their latest work.

Discover an endless feed of creativity on the All page sorted by latest uploads, popular collections, and top users. Use the navigation bar to filter by just uploads, collections, or users as well. The search functionality also lets you find specific types of content or creators.

<h2>Using AI Tools</h2>
One of the most powerful features of Model Grapix is the array of AI tools available. Go to Tools in the top navigation bar to discover image generators, background removers, inpainting tools, and more. 

Many tools are ready to use for free or with grapix or paint for image generator. You can purchase grapix packs from your Wallet to unlock more capabilities. If you're a developer, you can also submit your own AI tools for approval and inclusion in the hub. 

<h2>Sharing your profile</h2>
Sharing your profile is an important part of building your brand and getting your work seen by others. With Model Grapix, it's easy to share your profile with friends, colleagues, and potential clients.

To share your profile, simply navigate to your profile page and copy the URL at the top of the page. You can then share this URL via email, social media, share with individual users on Model Grapix through our chat system or as a post, or any other method of your choice.

Additionally, you can choose to make your profile public, which means it will be visible to anyone who searches for you on Model Grapix. To make your profile public, simply navigate to your account settings and toggle the "public profile" option to "on".

Sharing your profile is a great way to get your work out there and attract new clients and collaborators. Make sure to take advantage of this feature and share your profile with your network today!

<h1 id="bestPractices">Best Practices</h1>
<h2>Optimizing your profile for search engines:</h2>
Use descriptive and specific collection and upload titles and descriptions
Use relevant keywords in your collection and upload titles, descriptions, and about section
Use alt tags for all of your images to help them show up in image searches
Link your Model Grapix profile to your personal website and social media profiles

<h2>Collaborating with other users:</h2>
Use the collections feature to work with other designers and showcase your work
Give and receive feedback from other users on your projects
Support and promote other designers within the Model Grapix community

<h1 id="troubleshooting">Troubleshooting</h1>
<h2>Common issues and solutions</h2>

Sometimes users may encounter issues. Here are some common problems and their solutions:

**A. Login issues:**
If you are unable to log in, make sure that you have entered your email address and password correctly. If you are still having issues, try resetting your password using the "forgot password" link on the login page.

**B. Profile picture upload issues:**
If you are having trouble uploading your profile picture, make sure that the file size and format are compatible with our system. We recommend using a JPEG or PNG file that is under 2 MB in size.

**C. Project upload issues:**
If you are having trouble uploading a project, make sure that the file size and format are compatible with our system. We recommend using a JPEG or PNG file that is under 2 MB in size. If you are still having issues, try clearing your browser cache or using a different browser.

<h2>Contacting suport</h2>
If you are experiencing any other issues, please reach out here **modelgrapix@gmail.com.** Please provide as much detail as possible about the issue you are experiencing, including screenshots if applicable.

Hope this section has helped you resolve any issues you may be experiencing. If you continue to have problems, please don't hesitate to reach out for assistance.

<h1 id="conclusion">Conclusion</h1>
<h2>Key features recap</h2>
Throughout this documentation, we've covered the key features of Model Grapix, which include creating an account, setting up your profile, displaying your projects, creating collections, using AI tools, adding a call-to-action button, sharing your profile and more. We've also highlighted some best practices for optimizing your profile, collaborating with other users, and monetizing your designs.

<h2>Final thoughts and suggestions</h2>
We hope this documentation has provided you with a comprehensive understanding of Model Grapix and how to use it effectively. If you have any further questions or encounter any issues while using the platform, please refer to the troubleshooting section or reach out here **modelgrapix@gmail.com** for assistance.


`

  return (
    <ModulesStyle>
      <Pre content={content} />
    </ModulesStyle>
  )
}

const Pre = ({ content }) => {

  function copyCode(codeElement, copyElement) {
    const range = document.createRange();
    range.selectNodeContents(codeElement);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand('copy');
    selection.removeAllRanges();
    copyElement.innerHTML = 'Copied!'
    setTimeout(() => copyElement.innerHTML = 'Copy', 3000)
  }
  
  useEffect(() => {
    const copyElement = document.querySelectorAll('#copy');
    const codeElement = document.querySelectorAll('#code-preview');

    for (let i = 0; i < copyElement.length; i++) {
      const copyEl = copyElement[i];
      const codeEl = codeElement[i];
      
      copyEl.addEventListener('click', () => {
        copyCode(codeEl, copyEl)
      })
    }
  }, [])

  // Replace code blocks with <pre><code> tags
  const contentWithCode = content.replace(
    /```([\s\S]*?)```/gm,
    (match, code) => renderCode(match, code)
  );

  // Replace URLs with <a> tags
  const contentWithLinks = contentWithCode.replace(
    /(https?:\/\/[^\s]+)/g,
    '<a id="linkTag" href="$1">$1</a>'
  );

  // Replace *text* with <strong> tags
  const contentWithStrong = contentWithLinks.replace(
    /\*\*(.+?)\*\*/g,
    '<strong>$1</strong>'
  );

  // Replace _text_ with <em> tags
  const contentWithEm = contentWithStrong.replace(
    /_(.+?)_/g,
    '<em>$1</em>'
  );

  // Replace #text# with <ul></ul>
  const contentWithLi = contentWithEm.replace(
    /#(.+?)#/g,
    '<li>$1</li>'
  );

  // Encode less-than (<) and greater-than (>) characters in code blocks
  const contentWithEncodedCode = contentWithLi.replace(
    /<pre><code class="javascript">([\s\S]*?)<\/code><\/pre>/gm,
    match => match.replace(/</g, '&lt;').replace(/>/g, '&gt;')
  );

  // Wrap content in a <p> tag
  const contentWithP = `<p>${contentWithEncodedCode}</p>`;

  // Purify
  const sanitizedHTML = DOMPurify.sanitize(contentWithP);
  

  return <pre dangerouslySetInnerHTML={{ __html: sanitizedHTML }} ></pre>
  
}

export default UseOfPlatform;