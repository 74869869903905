import { Helmet } from 'react-helmet-async';
import { Container } from '../../../components/global/Container';
import TopBar from '../../../components/topbar/TopBar'
import useVisits from '../../../hooks/stats/visits/useVisits';
import { BgRemoverStyle } from './styles/S_BgRemover';
import BgRemoverComp from '../../../components/tools/bgRemover/BgRemoverComp';

const BgRemover = () => {
  useVisits();

  return (
    <BgRemoverStyle>
      <Helmet>
        <title>Best AI-Powered Image Background Remover | Try for Amazing and Neat Results</title>

        <meta name="description" content="Experience the best in AI technology. Try our image background remover for stunningly neat results that will amaze you." />

        <meta property="og:title" content="Discover the Best AI Image Background Remover for Incredible Results" />
        <meta property="og:description" content="Unleash the power of AI with our top-notch image background remover" />
        <meta property="og:image" content="https://res.cloudinary.com/drrd1lwey/image/upload/v1677448174/model-grapix/images/23ii46c50aai1-20230125_074727png.png" />
        <meta property="og:url" content="htts://modelgrapix.com" />
      
        <link rel="icon" href="/images/logos/20230125_074855.png" />
      </Helmet>

      <TopBar />
      <Container>
        <BgRemoverComp />
      </Container>
    </BgRemoverStyle>
  );
}
 
export default BgRemover;