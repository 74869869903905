const useDate = () => {
  
  const getDate = (date, shorter) => {
    if (!date) return;

    const providedDate = new Date(date);
    const now = new Date();
    const elapsed = Math.floor((now - providedDate) / 1000);
  
    if (elapsed < 60) {
      return shorter ? "now" : "just now";
      

    } else if (elapsed < 3600) {
      const interval = Math.floor(elapsed / 60);
      if (interval === 1) {
        return shorter ? "1 min" : '1 minute ago';
        
      } else {
        return shorter ? `${interval} min` : `${interval} minutes ago`
        
      }

    } else if (elapsed < 86400) {
      const interval = Math.floor(elapsed / 3600);
      if (interval === 1) {
        return shorter ? "1 hr" : '1 hour ago';
        
      } else {
        return shorter ? `${interval} hrs` : `${interval} hours ago`;
        
      }

    } else if (elapsed < 2592000) {
      const interval = Math.floor(elapsed / 86400);
      if (interval === 1) {
        return shorter ? "Yesterday" : '1 day ago';
        
      } else {
        return shorter ? `${interval} dys` : `${interval} days ago`;
        
      }

    } else if (elapsed < 31536000) {
      const interval = Math.floor(elapsed / 2592000);
      if (interval === 1) {
        return shorter ? "1 mth" : '1 month ago';
        
      } else {
        return shorter ? `${interval} mth` : `${interval} months ago`;
      }

    } else {
      const interval = Math.floor(elapsed / 31536000);
      if (interval === 1) {
        return shorter ? "1 yr" : '1 year ago';
        
      } else {
        return shorter ? `${interval} yrs` : `${interval} years ago`;
        
      }
    }
  }

  return { getDate }
}

export default useDate;