import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";
import usePopUpContext from "../../../../hooks/global/usePopUpContext";

const Types = () => {

  const { items, selectedType, setShow, setSelectedType, setCollectionData } = usePopUpContext();

  return (
    <div id="items" className="typesContainer">
      <h3>Most popular types of posts</h3>
      {items && items.map((item, i) => (
        <div key={i} data-collection-id={JSON.stringify(item)} onClick={() =>  {
          setShow(false);
          setSelectedType(item.header);
          setCollectionData(item)
        }} >
          <div id="types">
            <div>
              <p>{item.header}</p>

              <div>
                { selectedType === item.header ? <ImCheckboxChecked id="checkBoxIcons" /> : <ImCheckboxUnchecked id="checkBoxIcons" /> }
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default Types;