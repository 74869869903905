import { useNavigate } from 'react-router-dom';
import useAccess from '../access/useAccess'
import usePopUpContext from '../global/usePopUpContext';
import useProxyContext from '../global/useProxyContext';
import useErrHandler from '../err/useErrHandler';
import useImgHandler from '../img/useImgHandler';
import { useState } from 'react';

const useProfile = ()=> {
  // variables for follow and directives
  const [directive, setDirective] = useState('');

  const { userId, getUserId } = useAccess();
  const { popUp } = usePopUpContext();
  const { imageEndpoint } = useProxyContext();
  const { notifier } = useErrHandler();
  const { reachRoute } = useImgHandler();
  
  const navigate = useNavigate();

  const profileSetup = async (callback) => {
    getUserId((user) => {
      if(!user) return; 

      if(!user.profilePic) return popUp("setup", "all") 

      if(!user.age) return popUp("setup", 'age');

      if(!user.gender) return popUp("setup", "gender");

      if(!user.country) return popUp("setup", "country");

      if(!user.desc) return popUp("setup", "desc")

      if(!user.currency) return popUp("setup", "currency")

      callback();
    })
  }

  const getProfilePicture = (userProfilePicture, userInfo) => {
    if(!userInfo) return;

    const src = userInfo.profilePic

    if(!src) return;
        
    for (let i = 0; i < userProfilePicture.length; i++) {
      const picture = userProfilePicture[i];
      picture.src = `${imageEndpoint}/images/${userInfo.profilePic}`
      picture.alt = `${userInfo.userName} Profile Picture`
    }
  }

  const followButtonFunction = (user) => {
    if(userId && userId._id === user._id) return navigate(`/${user.userName.split(' ')[0]}/profile/${user._id}/uploads`)
    if(!user) return;
    if(!userId) return notifier(true, "Want to follow this user? Please sign in to continue.")

    if(directive === 'Follow') {
      setDirective('Unfollow');
    } else {
      setDirective('Follow');
    }

    reachRoute(() => {}, user._id, '/toggle/follow')
  }

  const setupDirective = (user) => {
    if(!userId) return setDirective('Follow');
    if(!user) return;

    // check if user already followed 
    const follower = user.followers.some(follower => follower._id === userId._id);

    if(follower) { setDirective('Unfollow') } else { setDirective('Follow') }
  }

  return { profileSetup, getProfilePicture, followButtonFunction, setupDirective, directive };
}

export default useProfile;