import { Helmet } from 'react-helmet-async';
import { Container } from '../../../components/global/Container';
import TopBar from '../../../components/topbar/TopBar'
import useVisits from '../../../hooks/stats/visits/useVisits';
import { ImageToTextStyle } from './styles/S_ImageToText';
import ImageToTextComp from '../../../components/tools/imageToText/ImageToTextComp';

const ImageToText = () => {
  useVisits();

  return (
    <ImageToTextStyle>
      <Helmet>
        <title>Image to Text AI | The Magic of Automatic Descriptions</title>

        <meta name="description" content="The Magic of Automatic Descriptions" />

        <meta property="og:title" content="Image to Text AI" />
        <meta property="og:description" content="The Magic of Automatic Descriptions" />
        <meta property="og:image" content="https://res.cloudinary.com/drrd1lwey/image/upload/v1677448174/model-grapix/images/23ii46c50aai1-20230125_074727png.png" />
        <meta property="og:url" content="htts://modelgrapix.com" />
      
        <link rel="icon" href="/images/logos/20230125_074855.png" />
      </Helmet>

      <TopBar />
      <Container>
        <ImageToTextComp />
      </Container>
    </ImageToTextStyle>
  );
}
 
export default ImageToText;