import { FaCloudUploadAlt } from 'react-icons/fa';
import { AiFillFolderAdd } from 'react-icons/ai';
import { TfiWrite } from 'react-icons/tfi';
import usePopUpContext from '../../../../hooks/global/usePopUpContext';
import { useNavigate } from 'react-router-dom';

const CreateOps = () => {
  const { popUp, closePopup } = usePopUpContext();

  const navigate = useNavigate();

  const handleNavigate = (url) => {
    navigate(url);
    closePopup();
  }
  
  return (
    <div>
      <span onClick={() => handleNavigate('/upload')} ><FaCloudUploadAlt /> Upload</span>
      <span onClick={() => popUp("collections", null, true)} ><AiFillFolderAdd /> Collection</span>
      <span onClick={() => handleNavigate('/write')} ><TfiWrite /> Write</span>
    </div>
  );
}
 
export default CreateOps;