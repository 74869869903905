import { AiFillDelete, AiFillStar } from "react-icons/ai";
import { FaAngleDown, FaAngleRight, FaEdit, FaEllipsisV, FaInfoCircle } from "react-icons/fa";
import { FiDownload } from "react-icons/fi";
import { useNavigate } from "react-router";
import usePopUpContext from "../../../../hooks/global/usePopUpContext";
import useProxyContext from "../../../../hooks/global/useProxyContext";
import useAuthContext from "../../../../hooks/auth/useAuthContext";
import axios from 'axios';
import useRefreshContext from "../../../../hooks/global/useRefreshContext";
import useErrHandler from "../../../../hooks/err/useErrHandler";
import useImgHandler from "../../../../hooks/img/useImgHandler";
import useAccess from '../../../../hooks/access/useAccess';
import { useEffect } from "react";
import useProcesser from '../../../../hooks/processer/useProcesser';
import useDate from "../../../../hooks/global/useDate";
import { useState } from "react";
import Button from "../../../../components/button/Button";
import useConstructor from "../../../../hooks/constructor/useConstructor";
import Processing from '../../../../components/loader/Processing'
import Img from "../../../../components/image/Img";

const Uploads = ({ userProfile }) => {
  const [allUploads, setAllUploads] = useState(null);
  const [approvedUploads, setApprovedUploads] = useState(null);

  const [count, setCount] = useState(6);

  const { userId } = useAccess();

  const { getRoute, getRouteRA } = useConstructor();

  const { user } = useAuthContext();

  const { refresh } = useRefreshContext();

  // fetch all uploads for authenticated user
  useEffect(() => {
    if(!user) return;

    getRouteRA((data) => {
      setAllUploads(data);
    }, `/fetch/uploads/all`)

    // disable warning for getRouteRA()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, refresh])

  // fetch all uploads for unauthenticated user 
  useEffect(() => {
    getRoute((data) => {
      setApprovedUploads(data);
    }, `/fetch/uploads/approved?profileId=${userProfile._id}`)

    // disable warning for getRoute()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, userProfile, refresh])
  
  return (allUploads || approvedUploads) ? (
    <div>
      <div>
        <h2>Uploads</h2>
        <div>
          <h3>All</h3>
          <FaAngleDown />
        </div>
      </div>

      { userProfile &&  
      <div id="itemsContainer" className="flex">

        { userId && (userId._id === userProfile._id) ? (
          // all the posts in my collections
          allUploads && allUploads.slice(0, count).map((item, i) => (
            <Item key={i} item={item} userProfile={userProfile} />
          ))
        ):( approvedUploads &&
          // All my approved posts
          approvedUploads.slice(0, count).map((item, i) => (
            <Item key={i} item={item} userProfile={userProfile} />
          ))
        )}

      </div>
      }

      <div id="btn">
      { userId && allUploads && allUploads.length > count ? (
        <Button run={() => setCount(count + count)}>... Load More</Button>
      ):( approvedUploads && approvedUploads.length > count && (
        <Button run={() => setCount(count + count)}>... Load More</Button>
      ))}
      </div>

    </div>
  ) : (
    <Processing />
  )
}

const Item = ({ item, userProfile }) => {

  const navigate = useNavigate();
  
  const { popUp, setClickedImageInfo } = usePopUpContext();

  const { addToStared } = useImgHandler();

  const { endpoint } = useProxyContext();

  const { userId } = useAccess();

  const { user } = useAuthContext();

  const { refresh, setRefresh } = useRefreshContext();

  const { notifier } = useErrHandler();

  const { process, processing } = useProcesser();

  const { getDate } = useDate();

  const { handleUploadPreview } = useConstructor()

  // Delete Item
  const deleteItem = async (imageId) => {
    process('STARTED', 'Deleting...')

    if(processing) return;

    const confirm = window.confirm("This upload will be deleted from our server, do you want to proceed ?")

    if(!confirm) return process('ENDED');

    try {
      const res = await axios.put(`${endpoint}/del/item`, {
        imageId: imageId
      }, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      });
      
      process('ENDED')
      setRefresh(!refresh);
      notifier(false, res.data.message)
      
    } catch (err) {
      process('ENDED')
      const errMessage = err.response.data.message;
      notifier(true, errMessage);
    }
  }

  // animate
  useEffect(() => {
    const imgPreview = document.querySelectorAll('.itemPreview')
    
    if(item) {
      for (let i = 0; i < imgPreview.length; i++) {
        const element = imgPreview[i];
        element.classList.add('fade-in');
      }
    }

  }, [item])

  return (
    <div>
      <main className="itemPreview">

        <main id="uploadImg" className="box-shadow" onClick={() => handleUploadPreview(item)}>
          <Img src={item.src} placeholder={item.placeholder} />
        </main>
        
        <div id="uploads">
          <span id="optionsMenu">
            <div id="options">

              {/* If user and user id is same as current user id */}
              {userId && userId._id === userProfile._id &&
              <>
              <div onClick={() => navigate(`/edit/upload/${item._id}`)}>
                <p>Edit</p>
                <FaEdit />
              </div>

              <div onClick={() => {
                popUp("collections", item.collectionName);
                setClickedImageInfo(item);
              }}>
                <p>Move</p>
                <FaAngleRight />
              </div>

              <div onClick={() => handleUploadPreview(item)}>
                <p>Download</p>
                <FiDownload />
              </div>

              <div onClick={() => deleteItem(item._id)}>
                <p style={{
                  cursor: processing ? 'not-allowed' : 'pointer'
                }}>Delete</p>
                <AiFillDelete />
              </div>
              </>
              }
              
              <div onClick={() => handleUploadPreview(item)}>
                <p>Info</p>
                <FaInfoCircle />
              </div>

              <div onClick={() => addToStared(item._id, item.src, item.type)}>
                <p>Star</p>
                <AiFillStar />
              </div>
            </div>

            <div id="optionsMenuBtn">
              <FaEllipsisV />
            </div>
          </span>
        </div>
      </main>

      <header>
        <h4>{item.name}</h4>
        <small>{ getDate(item.createdAt) }</small>
      </header>
    </div>
  )
}

export default Uploads;