import { ChatStyle, SignInStyle } from "./styles/S_Chat";
import { BsFillChatTextFill, BsRecordCircleFill } from 'react-icons/bs'
import { FaAngleDown } from 'react-icons/fa';
import Button from '../../components/button/Button';
import useConstructor from "../../hooks/constructor/useConstructor";
import { useEffect } from "react";
import useAccess from '../../hooks/access/useAccess';
import useProxyContext from "../../hooks/global/useProxyContext";
import React from "react";
import { FcGoogle } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import { FiExternalLink } from "react-icons/fi";
import { Messages, OptionsMenu } from "./modules/Messages";
import { Inboxes } from "./modules/Inboxs";

const Chat = () => {
  const { expandChat, setExpandChat, openMessage, inforBar, optionsMenu } = useConstructor();

  const { userId } = useAccess();

  // disable scroll on show
  useEffect(() => {
    const html = document.documentElement;
    if(expandChat) { html.style.overflow = 'hidden' } else { html.style.overflow = 'auto' }
  }, [expandChat])

/*   // on long press on the document body toggle hide chat button 
  useEffect(() => {
    const draggableDiv = document.getElementById('draggableDiv')


    document.addEventListener('dblclick', togglePosition);
        
    function togglePosition() {
      console.log(expandChat);
      if(expandChat) {
        draggableDiv.classList.toggle('changePosition');
      }
    }

  }, [expandChat]) */

  // if opon-chat is set to true in query params automatically open chat
  useEffect(() => {
    const oponChat = document.baseURI.split("?")[1]

    if(oponChat === 'opon-chat') {
      setExpandChat(true);
    }

  }, [setExpandChat])

  return (
    <ChatStyle expandChat={expandChat} id="draggableDiv" openMessage={openMessage}>
      <ChatButton expandChat={expandChat} />
      
      {/* Chat Topbar  */}
      <InforBar title={inforBar.title} src={inforBar.src} subTitle={inforBar.subTitle} />

      {/* Body */}

      { userId ? (
        <>
          <Inboxes />
          {openMessage && 
          <Messages />
          }
        </>
        ):(
        <SignIn />
        )
      }

      {/* Popup Options memnu  */}
      { optionsMenu.open && <OptionsMenu />}

    </ChatStyle>
  );
}

const ChatButton = ({ expandChat }) => {
  const { openMessage, handleNavigation } = useConstructor();

  return (
    <Button run={() => handleNavigation()}>
      { expandChat ? (
        <FaAngleDown style={{
          transform: openMessage ? 'rotate(90deg)' : 'rotate(0deg)'
        }} />
      ):(
        <BsFillChatTextFill />) }
    </Button>
  )
}

const InforBar = ({ src, title, subTitle }) => {
  const { autoScroll, setAutoScroll } = useConstructor();

  return (
    <div id="inforBar">
      <div id="details">
        {src && <img src={src} alt="Profile" />}
        <span>
          <h3>{ title }</h3>
          <small>{ subTitle }</small>
        </span>
      </div>

      <div id="mode">
        <span onClick={() => setAutoScroll(!autoScroll)}>
          <BsRecordCircleFill style={{
            color: autoScroll ? '#FFFFFF' : "#000000AA"
          }} />
        </span>
      </div>
    </div>
  )
}

const SignIn = () => {
  const { endpoint } = useProxyContext();
  
  const { setExpandChat, setInforBar } = useConstructor();

  const navigator = useNavigate();

  
  const googleAuth = () => {
    window.open(
      `${endpoint}/auth/google`,
      "_self"
    )
  }

  useEffect(() => {
    setInforBar((options) => ({ ...options, title: "Sign in" }))
  }, [setInforBar])

  return (
    <SignInStyle>
      <span>You need to sign in to use this feature</span>

      <Button radius="10" run={googleAuth} shadow>
        <FcGoogle />
        <span>Sign in with Google</span>
      </Button>

      <Button run={() => {
        navigator('/sign-in');
        setExpandChat(false);
      }} radius="10" shadow>
        <span>Sign in page</span>
        <FiExternalLink />
      </Button>
    </SignInStyle>
  )
}
 
export default Chat;