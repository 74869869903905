import styled from 'styled-components';

export const ImagesStyle = styled.div`
  & > div[id="posts"] {
    display: flex;
    justify-content: center;

    & > div {
      display: grid;
      gap: 30px;
      grid-template-columns: repeat(3, 1fr);
      
      @media (max-width: ${({ theme }) => theme.mobile.is1070}) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (max-width: ${({ theme }) => theme.mobile.is749}) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }

  & > div[id="btn"] {
    display: flex;
    justify-content: center;

    & > button { 
      margin: 50px 0;
      background: linear-gradient(to right top, #f32170, #cf23cf, #eedd44);
      color: #FFFFFF;
    }
  }
`;