import styled from 'styled-components';

const AboutStyle = styled.div`
  & > pre {
    font-size: 19px;
    white-space: pre-wrap;

    font-family: 'Poppins', sans-serif;
  }
`;

const About = ({ about }) => {
  return (
    <AboutStyle>
      <div>
        <h2>About</h2>
        
      </div>

      <pre>
        { about }
      </pre>

    </AboutStyle>
  )
}

export default About;