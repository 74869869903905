import { useContext } from "react"
import { AuthContext } from "../../contexts/auth/AuthContext";

const useAuthContext = () => {
  const context = useContext(AuthContext);

  if(!context) throw Error("Cannot use this context here");

  return context;
}

export default useAuthContext;