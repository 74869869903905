import { FaMinus, FaRegEye } from 'react-icons/fa';
import { HiSwitchHorizontal } from 'react-icons/hi';
import useAccess from '../../hooks/access/useAccess';
import useProxyContext from '../../hooks/global/useProxyContext';
import useImgHandler from '../../hooks/img/useImgHandler';
import PostInfo from './postInfo/PostInfo';
import { Item } from './S_Post';
import { useEffect, useState } from 'react';
import useDate from '../../hooks/global/useDate';
import { BiTimeFive } from 'react-icons/bi';
import { useTheme } from 'styled-components';
import useConstructor from '../../hooks/constructor/useConstructor';
import Img from '../image/Img';
import useRefreshContext from '../../hooks/global/useRefreshContext';

export const Post = ({ item, fit }) => {
  const [seen, setSeen] = useState('');
  const [hadSeen, setHadSeen] = useState(false);
  
  const { imageEndpoint } = useProxyContext();

  const { addToStared } = useImgHandler();

  const { getDate } = useDate();

  const { userId } = useAccess();

  const { handleUploadPreview } = useConstructor();

  const { refresh } = useRefreshContext();

  const theme = useTheme();

  // set number of seen
  useEffect(() => {
    setSeen(item.seen.length)
  }, [item])

  // check if this user already liked
  useEffect(() => {
    if(!userId) return;

    // check if user already seen
    const seen = item.seen.some(sn => sn.userId === userId._id);

    // update had seen state 
    setHadSeen(seen);

  }, [userId, item])

  // animate
  useEffect(() => {
    const post = document.querySelectorAll('.post')

    if(item) {
      for (let i = 0; i < post.length; i++) {
        const element = post[i];
        element.classList.add('fade-in');
      }
    }

  }, [item, refresh])

  // To aspect ratio
  useEffect(() => {

    const post = document.querySelectorAll('#post > main')

    for (let i = 0; i < post.length; i++) {
      const element = post[i];
      
      const setHeightToWidth = () => {
        const width = element.clientWidth;
        element.style.minHeight = `${width}px`;
        element.style.maxHeight = `${width}px`;
      }
    
      setHeightToWidth();
  
      // Call the function initially and whenever the window is resized
      window.addEventListener('resize', setHeightToWidth);
    }

    return () => {
    }

  }, [item])

  return (
    <Item className="post" id="post" pro={item.pro} approved={item.approved} fit={fit}>
      
      <small><FaRegEye style={{
        color: hadSeen ? `${theme.colors.main2}AA` : theme.colors.black
      }} /> {seen} <BiTimeFive />{getDate(item.createdAt)}</small>
      <main id='main'>

        <div id="image" onClick={() => {
            handleUploadPreview(item)
          }
        }> 
        
          {/* The image */}
          <Img src={item.src} placeholder={item.placeholder} />

        </div>

      </main>

      {/* Item bottom info */}
      <div id="global">

        <PostInfo
          post={item}
          star={() => addToStared(item._id, item.src, item.type)} 
          userPP={ item.ownerPP ? (imageEndpoint + "/images/" + item.ownerPP) : ('/images/profile.jpg') }
        />


      </div>
      
      <h3 id='title'>
        {item.name}
      </h3>
    </Item>
  )
}

export const PostInPrivate = ({ item, fit }) => {

  const { imageEndpoint } = useProxyContext();

  const { addToStared, toggleItemStatus, removeItemFrColl } = useImgHandler();

  const { userId } = useAccess();
  
  const { handleUploadPreview } = useConstructor()

  // animate
  useEffect(() => {
    const post = document.querySelectorAll('.post')
    
    if(item) {
      for (let i = 0; i < post.length; i++) {
        const element = post[i];
        element.classList.add('fade-in');
      }
    }

  }, [item])

  // To aspect ratio
  useEffect(() => {

    const post = document.querySelectorAll('#post > main')

    for (let i = 0; i < post.length; i++) {
      const element = post[i];
      
      const setHeightToWidth = () => {
        const width = element.clientWidth;
        element.style.minHeight = `${width}px`;
        element.style.maxHeight = `${width}px`;
      }
    
      setHeightToWidth();
  
      // Call the function initially and whenever the window is resized
      window.addEventListener('resize', setHeightToWidth);
    }

    return () => {
    }

  }, [])

  return (
    <Item className="post" id="post" pro={item.pro} approved={item.approved} fit={fit}>
      <main id='main'>

        <div id="image" onClick={() => handleUploadPreview(item) }> 
        
          {/* The image */}
          <Img src={item.src} placeholder={item.placeholder} />
          
        </div>
        
        <div id="item_footer">
          <PostInfo 
            post={item}
            star={() => addToStared(item._id, item.src, item.type)} 
            userPP={ item.ownerPP ? (imageEndpoint + "/images/" + item.ownerPP) : ('/images/profile.jpg') }
          />
        </div>

      </main>

      {/* Item bottom info */}
      <div id="private">
        <div style={{
          background: userId && userId._id === item.ownerId ? "green" : "#FFA500"
        }}></div> {/* Identifier */}
        
        <span onClick={() => toggleItemStatus(item._id, item.approved)} id="status"> {item.approved ? "Public" : "Private"} 
          < HiSwitchHorizontal style={{
            transform: item.approved ? `rotate(360deg)` : `rotate(0deg)`,
            transition: "all ease-in-out 0.4s",
          }} />
        </span>
        
        <span onClick={() => removeItemFrColl(item._id, item.collectionName)} id="removeBtn"><FaMinus /></span>
      </div>

      <h3 id='title'>
        {item.name}
      </h3>

    </Item>
  )
}