import styled, { css } from 'styled-components';

const optionStyle = `
  & > div {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;

    & > span {
      padding: 10px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
`

export const ImageGenCompStyle = styled.div`
  position: relative;

  & > div[id="header"] {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    grid-gap: 5px;

    @media(max-width: ${({ theme }) => theme.mobile.is499}) {
      font-size: 15px;
    }
  }

  // Paint
  & > div[id="paintStatus"] {
    user-select: none;

    & > div[id="select"] {
      position: absolute;
      right: 0;
      top: 30px;
      border-radius: 5px;
      overflow: hidden;
      padding: 10px;
      z-index: 97;
      transition: 0.5s cubic-bezier(0, 1.14, 0.78, 0.82);
      opacity: 0;
      transform: scale(1.1) translateY(20px);
      pointer-events: none;

      box-shadow: 0px 17px 25px #00000025;
      background: ${({ theme }) => theme.colors.white};
      
      & > div {
        display: flex;
        flex-direction: column;
        background: ${({ theme }) => theme.colors.main2}10;
        padding: 5px 20px 5px 5px;
        border-radius: 5px;
        border: 1px solid ${({ theme }) => theme.colors.black}50;
        
        &:hover {
          background: ${({ theme }) => theme.colors.main2}20;
        }

        &:first-child { margin-bottom: 10px }
        
        & > div {
          display: flex;
          align-items: center;
          grid-gap: 10px;
          
          & > img {
            width: 30px;
          }

          & > span {
            font-size: 30px;
            font-weight: 600;
            color: ${({ theme }) => theme.colors.black}AA;
          }

          & > div {
            display: flex;
            flex-direction: column;
            width: 100%;
            text-align: right;

            & > span {
              font-weight: 600;

              color: ${({ theme }) => theme.colors.main2};
            }

            & > small {
              font-size: 10px;
            }
          }
        }
      }

      & > small {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: ${({ theme }) => theme.colors.main2};
        padding: 10px;

        & > svg {
          margin-right: 5px;
        }
      }

      ${({ openPaint }) => openPaint && css`
        opacity: 1;
        height: 190px;
        transform: scale(1) translate(0);
        pointer-events: visible;
        z-index: 98;
      `}
    }

    & > div[id="openSelectBtn"] {
      position: absolute;
      right: 0;
      top: 0;
      border-radius: 5px;
      height: 30px;
      background: red;
      display: flex;
      align-items: center;
      grid-gap: 5px;
      padding: 5px 10px 5px 5px;
      z-index: 97;
      cursor: pointer;

      box-shadow: 1px 1px 3px #00000025;
      background: ${({ theme }) => theme.colors.white};
      border: 1px solid ${({ theme }) => theme.colors.black}50;

      & > img {
        width: 20px;
      }

      & > span {
        color: ${({ theme }) => theme.colors.main2}
      }
    }
  }

  // Input field 
  & > form {
      position: sticky;
      top: 50px;
      width: 100%;
      padding: 30px 0;

    & > div {
      position: relative;
      display: flex;
      align-items: center;

      & > input {
        padding: 20px 170px 20px 20px;
        width: 100%;
        border-radius: 50px;
        font-size: 20px;
        box-shadow: 1px 1px 3px #00000025;
        color: ${({ theme }) => theme.colors.black};
        background: ${({ theme }) => theme.colors.white}CC;
        border: 1px solid ${({ theme }) => theme.colors.black}50;

        @media(max-width: ${({ theme }) => theme.mobile.is599}) {
          padding-right: 90px;
        }
      }

      & > span {
        right: 40px;
        position: absolute;
        display: flex;
        align-items: center;
        grid-gap: 10px;
        color: ${({ theme }) => theme.colors.black};
        cursor: pointer;
        padding: 5px;
        border-radius: 5px;

        &:hover {
          background: ${({ theme }) => theme.colors.black}20;
        }

        &::before {
          content: 'Generate ';
        }

        & > svg {
          font-size: 25px;
          color: ${({ theme }) => theme.colors.main2};
        }

        @media(max-width: ${({ theme }) => theme.mobile.is599}) {
          &::before {
            content: '';
          }
        }
      }
    }
  }

  // Generated images
  & > span {
    display: flex;
    flex-flow: wrap;
    grid-gap: 30px;
    margin-bottom: 100px;

    & > div[id="generatedImages"] {
      width: 100%;
      display: grid;
      grid-gap: 30px;
      grid-template-columns: repeat(4, 1fr);
      
      @media(max-width: ${({ theme }) => theme.mobile.is799}) {
        grid-template-columns: repeat(3, 1fr);
      }
      
      @media(max-width: ${({ theme }) => theme.mobile.is499}) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
      }
      
      @media(max-width: ${({ theme }) => theme.mobile.is330}) {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 20px;
      }

      & > div {
        border-radius: 5px;
        background: ${({ theme }) => theme.colors.white};
        box-shadow: 0px 2px 1px rgba(0,0,0,0.3);
        border: 1px solid ${({ theme }) => theme.colors.main2};
        
        & > div {
          height: 260px;
          border-radius: 5px;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          @media(max-width: ${({ theme }) => theme.mobile.is1199}) {
            height: 240px;
          }

          @media(max-width: ${({ theme }) => theme.mobile.is1099}) {
            height: 200px;
          }
          
          @media(max-width: ${({ theme }) => theme.mobile.is999}) {
            height: 180px;
          }

          @media(max-width: ${({ theme }) => theme.mobile.is899}) {
            height: 160px;
          }
          
          @media(max-width: ${({ theme }) => theme.mobile.is799}) {
            height: 200px;
          }

          @media(max-width: ${({ theme }) => theme.mobile.is699}) {
            height: 160px;
          }

          @media(max-width: ${({ theme }) => theme.mobile.is599}) {
            height: 140px;
          }
          
          @media(max-width: ${({ theme }) => theme.mobile.is499}) {
            height: 180px;
          }

          @media(max-width: ${({ theme }) => theme.mobile.is399}) {
            height: 140px;
          }

          @media(max-width: ${({ theme }) => theme.mobile.is330}) {
            height: 200px;
          }
        }
      }

      & > div[id="createSpaceBtn"] {
        display: grid;
        place-items: center;
        cursor: pointer;
  
        & > span {
          display: flex;
          align-items: center;
          grid-gap: 10px;
          font-size: 20px;
          color: ${({ theme }) => theme.colors.black};

          & > svg {
            color: ${({ theme }) => theme.colors.main2};
          }
        }
      }
    }
  }

  // Options in settings
  & > div[id="options"] {
    position: sticky;
    bottom: 20px;
    display: flex;
    
    & > div {
      position: absolute;
      height: ${({ openSettings }) => openSettings ? '220px' : '70px'};
      bottom: 0;
      left: 50%;
      transform: translate(-50%);
      border-radius: 10px;
      width: 300px;
      padding: 0 20px;
      box-shadow: 1px 1px 3px #00000025;
      background: ${({ theme }) => theme.colors.white};
      border: 1px solid ${({ theme }) => theme.colors.black}50;
      transition: height 0.3s ease;
      overflow-y: auto;

      scrollbar-width: none;

      &::-webkit-scrollbar { 
        display: none;
        width: 0px;
        height: 0px;
      }

      @media(max-width: ${({ theme }) => theme.mobile.is499}) {
        width: 100%;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      & > h2 {
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        margin-bottom: 10px;
        color: ${({ theme }) => theme.colors.black}AA;
      }
      
      & > div {
        height: 50px;
        width: 100%;
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 10px;
        background: ${({ theme }) => theme.colors.main2}20;
        padding: 0 10px 10px 10px;
        transition: height 0.3s ease;

        & > header {
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: ${({ theme }) => theme.colors.black}AA;
          cursor: pointer;
        }
      }

      & > div[id="display"] {
        height: ${({ openDisplay }) => openDisplay ? '220px' : '50px'};

        ${optionStyle}

        & > div > span {
          border: 1px solid ${({ theme }) => theme.colors.black}50;
          background: ${({ theme }) => theme.colors.white}AA;
          color: ${({ theme }) => theme.colors.black}AA;
        }
      }

      & > div[id="model"] {
        height: ${({ openModel }) => openModel ? '333px' : '50px'};

        ${optionStyle}

        & > div > span {
          border: 1px solid ${({ theme }) => theme.colors.black}50;
          background: ${({ theme }) => theme.colors.white}AA;
          color: ${({ theme }) => theme.colors.black}AA;
        }
      }

      & > div[id="quality"] {
        height: ${({ openQuality }) => openQuality ? '165px' : '50px'};

        ${optionStyle}

        & > div > span {
          border: 1px solid ${({ theme }) => theme.colors.black}50;
          background: ${({ theme }) => theme.colors.white}AA;
          color: ${({ theme }) => theme.colors.black}AA;
        }
      }

      & > div[id="number"] {
        height: ${({ openNumber }) => openNumber ? '100px' : '50px'};

        & > div {
          height: 40px;
          display: flex;
          align-items: center;
          border-radius: 5px;
          overflow: hidden;
          justify-content: space-between;
          color: ${({ theme }) => theme.colors.black};


          & > span[id="icon"] {
            background: ${({ theme }) => theme.colors.white}50;
            height: 100%;
            width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer; 

            &:hover {
              background: ${({ theme }) => theme.colors.white};
            }
          }
        }
      }
    }
  }
`;