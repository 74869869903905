import styled from 'styled-components';

const PostSkeletonStyle = styled.div`
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  gap: 30px;
  animation: fade 1s infinite;

  @keyframes fade {
    0% {
      opacity: 0.5;
    } 
    50% {
      opacity: 1;
    } 
    100% {
      opacity: 0.5;
    }
  }

  & > div {
    display: flex;
    flex-direction: column;
    gap 10px;
    width: 300px;
    
    @media (max-width: ${({ theme }) => theme.mobile.is749}) {
      width: 100%;
    }

    & > div:first-child {
      border-radius: 10px;
      height: 300px;
      width: 100%;
      background: ${({ theme }) => theme.colors.lightgrey}AA;
    }
    
    
    & > div:last-child {
      display: flex;
      justify-content: space-between;

      & > div:first-child {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background: ${({ theme }) => theme.colors.lightgrey}AA;
      }

      & > div:last-child {
        display: flex;
        gap: 10px;

        & > div {
          height: 40px;
          width: 40px;
          border-radius: 50%;
          background: ${({ theme }) => theme.colors.lightgrey}AA;
        }
      }
    }
  }
`;

const PostSkeleton = ()  => {
  return (
    <PostSkeletonStyle>
      <div> {/* Container */}
        <div></div> {/* Image display */}
        <div>
          <div></div>
          <div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>

      <div> {/* Container */}
        <div></div> {/* Image display */}
        <div>
          <div></div>
          <div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>

      <div> {/* Container */}
        <div></div> {/* Image display */}
        <div>
          <div></div>
          <div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>

      
    </PostSkeletonStyle>
  )
}

export default PostSkeleton;