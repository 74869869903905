import { CollectionStyle } from "./styles/S_Collection";
import useImgHandler from "../../../hooks/img/useImgHandler";
import { useEffect, useState } from "react";
import { Post, PostInPrivate } from "../../../components/post/Post";
import { Container } from '../../../components/global/Container';
import TopBar from '../../../components/topbar/TopBar';
import useProxyContext from "../../../hooks/global/useProxyContext";
import useAccess from "../../../hooks/access/useAccess";
import useRefreshContext from "../../../hooks/global/useRefreshContext";
import useErrHandler from "../../../hooks/err/useErrHandler";
import { Helmet } from 'react-helmet-async';
import useVisits from "../../../hooks/stats/visits/useVisits";
import Button from "../../../components/button/Button";
import Processing from '../../../components/loader/Processing';

const Collection = () => {
  useVisits();

  const [collection, setCollection] = useState(null);
  const [owner, setOwner] = useState(null);
  const [allUploads, setAllUploads] = useState(null);
  const [approvedUploads, setApprovedUploads] = useState(null);
  const [count, setCount] = useState(6);

  const { imageEndpoint } = useProxyContext();

  const { approvedItems, getLength, reachRoute, getRoute } = useImgHandler();

  const { userId } = useAccess();

  const { refresh } = useRefreshContext();

  const { notifier } = useErrHandler();

  const collectionId = document.baseURI.split('/')[4];

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if(!collectionId) return;
    if(!userId) return;


    getRoute((data) => {
      setAllUploads(data);
    }, `/fetch/all/uploads/in/collection?collectionId=${collectionId}`, signal)

    return () => {
      controller.abort();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionId, refresh, userId])

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal

    if(!collectionId) return;

    getRoute((data) => {
      setApprovedUploads(data);
    }, `/fetch/approved/uploads/in/collection?collectionId=${collectionId}`, signal)

    return () => {
      controller.abort();
    }

    // disable warning for getRoute()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionId])

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if(!collection) return;

    if(!userId) return;

    reachRoute(() => {}, collection._id, '/add/seen/collection', signal)

    return () => {
      controller.abort();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collection, userId])

  useEffect(() => {
    getLength(collectionId);

    // fetch this post - Do this chech in the server
    getRoute((data) => {
      if(data.collection.approved) {
        setCollection(data.collection);
        setOwner(data.owner)

        
      } else if(userId && (userId._id === data.collection.ownerId)) {
        setCollection(data.collection);
        setOwner(data.owner)

      } else {
        notifier(true, 'Not publicly Available!');
      }
    }, `/get/collection/${collectionId}`)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifier, refresh, userId, collectionId])

  return collection ? (
    <div>
      
      <Helmet>
        <title>{ `Collection - ${collection.header}` }</title>

        <meta name="description" content={collection.about} />

        <meta property="og:title" content={collection.header} />
        <meta property="og:description" content={collection.about} />
        <meta property="og:image" content={`${imageEndpoint}/images/${collection.icon}`} />
        <meta property="og:url" content="htts://modelgrapix.com" />

        <link rel="icon" href='/images/logos/20230125_074855.png' />
      </Helmet>

      <TopBar />
      <CollectionStyle>
        
        {collection &&
        <Container>
          <div id="collection_info">
            <img src={ imageEndpoint + "/images/" + collection.icon } alt=""/>
            
            <div>
              <h2>{collection.header}</h2>
              <small>By {owner.userName.split(" ")[0]} <div id="dot"></div> {approvedItems} {userId && collection.ownerId === userId._id && "approved"} uploads </small>
            </div>
          </div>

          <div id="items">
            <div>
              { userId && allUploads && collection.ownerId === userId._id ? (
                <>
                  { allUploads.slice(0, count).map((upload, i) => (
                    <PostInPrivate key={i} collection={collection} item={upload} fit />
                  ))}
                </>
                ) : ( approvedUploads &&
                <>
                  { approvedUploads.slice(0, count).map((upload, i) => (
                    <Post key={i} item={upload} fit />
                  ))}

                </>
              )}
            </div>
          </div>

          <div id="btn">
          { userId && allUploads && allUploads.length > count ? (
            <Button run={() => setCount(count + 6)}>... Load More</Button>
            ):( approvedUploads && approvedUploads.length > count &&
            <Button run={() => setCount(count + 6)}>... Load More</Button>
          )}
          </div>

        </Container>
        }
      </CollectionStyle>
    </div>
  ) : (
    <Processing />
  )
}

export default Collection;