export const MobileTheme = {
  mobile: {
    is290: '290px',
    is330: '330px', // ()
    is344: '344px',
    is350: '350px',
    is360: '360px', // ♦
    is380: '380px',
    is400: '400px', // ()
    is410: '410px',
    is420: '420px',
    is430: '430px',
    is444: '444px',
    is460: '460px', // ()
    is480: '480px', // ()
    is485: '485px',
    is500: '500px', // ()
    is530: '530px', // ()
    is532: '532px',
    is550: '550px', // ♦ ()
    is560: '560px', // ()
    is577: '577px', // ♦
    is580: '580px', // ()
    is630: '630px',
    is600: '600px', // ()
    is640: '640px', // ()
    is660: '660px', // ()
    is690: '690px', // ()
    is749: '749px', // ()
    is760: '760px', // ()
    is800: '800px', // ()
    is805: '805px',
    is854: '854px', // ()
    is860: '860px', // ()
    is900: '900px',
    is915: '915px', // ()
    is950: '950px', // ()
    is960: '960px', // ♦
    is992: '992px', // ()
    is1070: '1070px', // ()
    is1078: '1078px',
    is1150: '1150px', // ()
    is1160: '1160px',
    is1210: '1210px',
    is1255: '1255px',
    is1330: '1330px', 

    // mostly used in generated images
    is1199: '1199px',
    is1099: '1099px',
    is999: '999px',
    is899: '899px',
    is799: '799px',
    is699: '699px',
    is599: '599px',
    is499: '499px',
    is399: '399px',

    is359: '359px', // ()

    is340: '340px',
  }
}