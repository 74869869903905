import axios from "axios";
import { InPaintingCompStyle } from "../inPaintingComp/styles/S_InpaintingComp";
import { useState, useEffect } from "react";
import usePopUpContext from "../../../hooks/global/usePopUpContext";
import useProxyContext from "../../../hooks/global/useProxyContext";
import useProcesser from "../../../hooks/processer/useProcesser";
import { FaAngleLeft } from 'react-icons/fa';
import useErrHandler from "../../../hooks/err/useErrHandler";

const BgRemoverComp = () => {
  const [expandResults, setExpandResults] = useState(false);

  const [file, setFile] = useState(null);

  const { popUp, show } = usePopUpContext();

  const { endpoint } = useProxyContext();

  const { process, processing } = useProcesser();

  const { notifier } = useErrHandler();

  const removeBackground = async () => {
    if(processing) return;

    process('STARTED', 'Hold on tight, Removing background ...');

    const outputContainer = document.querySelector('#outputContainer');

    try{

      const form = new FormData()
      form.append('image_file', file)

      const res = await axios.post(`${endpoint}/gen/ai/process/bg-remover`, form, { 
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      process('ENDED');

      notifier(false, 'DONE! Hope you like it 🙂')

      const imageUrl = res.data;

      // Create new result
      const img = document.createElement('img');
      img.setAttribute(`data-set-image_url`, `${imageUrl}`)
      img.src = imageUrl;
      img.alt = "Preview"

      // append new result to outputContainer
      outputContainer.appendChild(img);

      // Expand results
      setExpandResults(true);

      const prompt = '- Bg Remover AI -'

      const extra = { imageUrl, prompt}
      popUp('gen-preview', extra);

    } catch (error) {
      process('ENDED');
      console.log(error);
    }
  }

  useEffect(() => {
    const outputContainer = document.querySelector('#outputContainer').children;

    for (let i = 0; i < outputContainer.length; i++) {
      const element = outputContainer[i];
      
      element.addEventListener('click', () => {
        const prompt = '- Inpainting AI -'
        const imageUrl = element.dataset.setImage_url;

        const extra = { imageUrl, prompt}
        popUp('gen-preview', extra);
      })
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandResults, show]);

  return (
    <InPaintingCompStyle expandResults={expandResults}>
      <div>
        <h1>Achieve Neat Results with Model Grapix AI powered Image Background Removal</h1>

        <div id="select">
          <input type="file" id="fileInput" onChange={(e) => {
            if(e.target.files[0]) {
              setFile(e.target.files[0])
            }
          }} />
          <label htmlFor="fileInput">Select Image file</label>
        </div>

        <div id="editor">
          <img
            src={file && URL.createObjectURL(file)}
            alt="Original"
            style={{
              opacity: file ? 1 : 0
            }}
          />
        </div>

        <button onClick={removeBackground} style={{
          opacity: file ? 1 : 0.5,
          bottom: processing ? '-100px' : '20px',
          pointerEvents: file ? 'visible' : 'none'
        }}>Remove Bg</button>

        <div id="launch_preview">
          <div onClick={() => setExpandResults(!expandResults)}><FaAngleLeft /></div>
          <div id="outputContainer">
            {/* Dynamically appended through Javascript */}
          </div>
        </div>

      </div>
    </InPaintingCompStyle>
  )

}

export default BgRemoverComp;