import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Poppins', sans-serif;
  background: ${({ theme }) => theme.colors.theme};
}

img {
  width: 100%;
  object-fit: cover;
}

h1, h2, h3, h4, h5, h6, li, a, small, p, pre, article {
  color: ${({ theme }) => theme.colors.black};
}

.text-shadow {
  text-shadow: 1px 2px 0 #00000025;
}

.box-shadow {
  box-shadow: 1px 2px 0 #00000025;
}

.link {
  color: inherit;
  text-decoration: none;
}
`;