import styled, { css, keyframes } from 'styled-components';

const fadeInFromBottom = keyframes`
  from {
    opacity: 0;
    transform: translateY(5%);
  } to {
    opacity: 1;
    transform: translateY(0);
  }
`
const fadeOutFromBottom = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  } to {
    opacity: 0;
    transform: translateY(5%);
  }
`

export const AiWriterCompStyle = styled.div`
    // height: calc(100vh - 70px);
    height: 100vh;
    overflow-y: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 10px;
      background: ${({ theme }) => theme.colors.lightgrey}55;
    }
    
    &::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.black}20;
    }

    & > span[id="backToPlatform"] {
      position: fixed;
      top: 18px;
      left: 20px;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      display: grid;
      place-items: center;
      background: ${({ theme }) => theme.colors.white};
      cursor: pointer;
      z-index: 2;

      box-shadow: inset 2px 2px 7px rgba(0,0,0,0.3),
      0px 20px 25px rgba(0,0,0,0.1);

      & > svg {
        font-size: 15px;
        color: ${({ theme }) => theme.colors.black}AA;
      }
    }

    & > div[id="menuButton"] {
      position: sticky;
      top: 0;
      z-index: 1;
      padding: 20px 80px;
      cursor: pointer;
      background: ${({ theme }) => theme.colors.theme};
      margin: auto;
      
      & > div {
        display: flex;
        grid-gap: 10px;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
        white-space: nowrap;
        color: ${({ theme }) => theme.colors.black}AA;
        font-weight: 600;

        & > svg {
          min-width: 20px;

          @media(max-width: ${({ theme }) => theme.mobile.is854}) {
          }
        }
      }
    }

    & > div[id="history"] {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      background: ${({ theme }) => theme.colors.theme};
      height: 100vh;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 10px;
        background: ${({ theme }) => theme.colors.lightgrey}55;
      }
      
      &::-webkit-scrollbar-thumb {
        background: ${({ theme }) => theme.colors.black}20;
      }

      opacity: 0;
      transform: translateY(5%);
      pointer-events: none;

      ${({ openMenu }) => openMenu ? css`
        pointer-events: visible;
        opacity: 0;
        animation: ${fadeInFromBottom} 0.5s cubic-bezier(0.11, 0.79, 0.31, 1.45) forwards;
      ` : css`
        
        pointer-events: none;
        opacity: 0;
        animation: ${fadeOutFromBottom} 0.5s cubic-bezier(0.11, 0.79, 0.31, 1.45) forwards;
      `}
      
      & > div {
        display: flex;
        align-items: center;
        width: 100%;
        color: ${({ theme }) => theme.colors.black};

        display: flex;
        flex-direction: column;

        & > div {
          width: 400px;
        }

        & > div:nth-child(1) {
          position: sticky;
          top: 0;
          padding: 20px 80px;
          display: flex;
          grid-gap: 10px;
          align-items: center;
          justify-content: space-between;
          background: ${({ theme }) => theme.colors.theme};
          color: ${({ theme }) => theme.colors.black}AA;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          font-weight: 600;
          cursor: pointer;
          z-index: 5;

          &::before {
            content: '';
            position: absolute;
            right: 0;
            height: 100%;
            width: 80px;
            background: ${({ theme }) => theme.colors.theme};
          }

          & > svg {
            min-width: 20px;
          }
        }

        & > div:nth-child(2) {
          // position: sticky;
          // top: 7px;
          display: flex;
          align-items: center;
          grid-gap: 10px;
          margin-bottom: 20px;
          z-index: 2;
          

          & > span:nth-child(1) {
            justify-content: left;
            flex: 7;
          }
          
          & > span:nth-child(2) {
            flex: 3;
          }
          
          @media (max-width: ${({ theme }) => theme.mobile.is530}) {
            width: 100%;
            padding: 0 20px;
          }

          & > span {
            justify-content: center;
            width: 100%;
            display: flex;
            align-items: center;
            grid-gap: 10px;
            padding: 10px;
            border-radius: 50px;
            background: ${({ theme }) => theme.colors.white};
            cursor: pointer;
            box-shadow: 0px 2px 3px rgba(0,0,0,0.1);
          }
        }

        & > div[id="recent"] {
          display: flex;
          flex-direction: column;
          grid-gap: 10px;
          padding: 0 0 10px 0;
          
          @media (max-width: ${({ theme }) => theme.mobile.is530}) {
            width: 100%;
            padding: 0 20px 10px 20px;
          }

          & > span {
            display: flex;
            grid-gap: 10px;
            align-items: center;
            justify-content: space-between;
            padding: 10px;
            border-radius: 5px;
            background: ${({ theme }) => theme.colors.white};
            cursor: pointer;
            box-shadow: 0px 20px 25px rgba(0,0,0,0.1);
            
            &:hover {
              transform: scale(1.0);
              box-shadow: none;
              border: 1px solid ${({ theme }) => theme.colors.black}50;
            }

            & > span {
              position: relative;
              display: flex;
              grid-gap: 10px;
              align-items: center;
              white-space: nowrap;
              width: 90%;
              overflow: hidden;

              &::before {
                content: '';
                position: absolute;
                right: 0;
                width: 100px;
                height: 100%;
                background: linear-gradient(to right, #FFFFFF00, ${({ theme }) => theme.colors.white});
              }

              & > svg {
                min-width: 20px;
              }
            }
          }
        }
      }
    }

    & > div[id="chatContainer"] {
      min-height: 100%;

      // chat body
      & > div[id="chatBody"] {
        max-width: 700px;
        margin: auto;
        display: flex;
        flex-direction: column;
        margin-bottom: 500px;
        width: 100%;

        & > div[id="watermark"] {
          display: flex;
          justify-content: center;
          padding: 40px 0;
          
          & > div {
            padding: 20px;
            border-radius: 10px;
            display: flex;          
            align-items: center;
            grid-gap: 10px;
            background: ${({ theme }) => theme.colors.white};
            width: 100%;
            box-shadow: inset 2px 2px 7px rgba(0,0,0,0.3),
                              0px 20px 25px rgba(0,0,0,0.1);
            
            & > img {
              height: 50px;
              width: 50px;
              border-radius: 50%;
              padding: 5px;
              border: 2px dashed ${({ theme }) => theme.colors.black}AA;
            }

            & > div {
              display: flex;
              flex-direction: column;

              & > span {
                font-weight: 600;
                color: ${({ theme }) => theme.colors.black};
              }
            }

            @media(max-width: ${({ theme }) => theme.mobile.is500}) {
              flex-direction: column;
              text-align: center;
              
              & > div {
                grid-gap: 10px;
              }
            }
          }
        }
    
        & > div[id="paste"] {
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          padding: 20px 0;
          
          @media(max-width: ${({ theme }) => theme.mobile.is500}) {
            padding: 10px 0;
          }

          & > div {
            display: flex;
            align-items: center;
            grid-gap: 10px;
            font-size: 20px;
            font-weight: 600;

            & > img {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              padding: 2px;
              border: 1px dashed ${({ theme }) => theme.colors.black}AA;
            }

            & > span {
              color: ${({ theme }) => theme.colors.black}CC;
              font-size: 17px;

              @media(max-width: ${({ theme }) => theme.mobile.is500}) {
                font-size: 16px;
              }
            }
          }

          & > pre {
            white-space: pre-wrap;
            font-family: 'Poppins', sans-serif;
            font-size: 18px;
            line-height: 28px;
            color: ${({ theme }) => theme.colors.black};
            margin-left: 15px;
            padding-left: 25px;
            border-left: 1px dashed ${({ theme }) => theme.colors.black}AA;
            letter-spacing: -0.5px;

            @media(max-width: ${({ theme }) => theme.mobile.is500}) {
              font-size: 16px;
              line-height: 25px;
            }

            @keyframes typing {
              from {
                width: 0;
              }
            }
          
            .typing-animation {
              overflow: hidden;
              white-space: nowrap;
              animation: typing 2s steps(40, end);
            }
          }
        }
      }
      
      // Input component
      & > div[id="inputComp"] {
        background: ${({ theme }) => theme.colors.theme};
        position: sticky;
        max-width: 700px;
        margin: auto;
        bottom: 0;
        
        & > div {
          & > textarea {
            margin-bottom: 10px;
            font-size: 17px;
            font-family: 'Poppins', sans-serif;
            width: 100%;
            padding: 20px 75px 20px 20px;
            border-radius: 10px;
            color: ${({ theme }) => theme.colors.black}CC;
            background: ${({ theme }) => theme.colors.white};
            border: none;
            outline: none;
            resize: none;
            box-shadow: inset 2px 2px 7px rgba(0,0,0,0.3),
                              0px 20px 25px rgba(0,0,0,0.1);
            
            scrollbar-width: none;

            &::-webkit-scrollbar { display: none }
          }
          
          & > span {
            position: absolute;
            bottom: 25px;
            right: 10px;
            padding: 10px;
            border-radius: 50px;
            font-size: 13px;
            color: ${({ theme }) => theme.colors.black}CC;
            cursor: pointer;
            display: flex;
            align-items: center;
            grid-gap: 10px;
            background: ${({ theme }) => theme.colors.main2};
            color: #FFFFFF;
            transition: 0.3s cubic-bezier(0.14, 1.28, 0.94, 1.53);

            &:hover {
              transform: scale(0.9);
            }
          }
        }
      }

    }
`;