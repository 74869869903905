import { FaAngleDown, FaTimes } from "react-icons/fa";
import { FiDownload } from "react-icons/fi";
import useProxyContext from "../../../../hooks/global/useProxyContext";
import axios from 'axios';
import useErrHandler from "../../../../hooks/err/useErrHandler";
import useAuthContext from "../../../../hooks/auth/useAuthContext";
import useRefreshContext from "../../../../hooks/global/useRefreshContext";
import useConstructor from "../../../../hooks/constructor/useConstructor";
import { useEffect } from "react";
import useAccess from "../../../../hooks/access/useAccess";
import useProcesser from "../../../../hooks/processer/useProcesser";

const Purchases = ({ purchases }) => {

  const { endpoint } = useProxyContext();

  const { notifier } = useErrHandler();

  const { user } = useAuthContext();

  const { reRender } = useRefreshContext();

  // Remove from purchases
  const removeItemFrUserProp = async (itemId, prop) => {
    const confirm = window.confirm('Note: you cannot undo this operation! Do you want to proceed?');

    if(!confirm) return;

    try {
      const res = await axios.put(`${endpoint}/rm/item/fr/user/props`, {
        itemId: itemId,
        prop: prop
      }, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      });
  
      reRender();
      notifier(false, res.data.message)
  
    } catch (err) {
      const errMessage = err.response.data.message;
      notifier(true, errMessage);
    }
  }

  return (
    <div>
      <div>
        <h2>Purchases</h2>
        <div>
          <h3>All</h3>
          <FaAngleDown />
        </div>
      </div>

      {/* Getting back to here */}
      
      {purchases && <div className="flex">
        {purchases.map((item, i) => (
          <Item key={i} item={item} removeItemFrUserProp={removeItemFrUserProp} />
        ))}
      </div>}
    </div>
  )
}

const Item = ({ item, removeItemFrUserProp }) => {
  const { endpoint, imageEndpoint } = useProxyContext();

  const { userId } = useAccess();

  const { user } = useAuthContext();

  const { notifier } = useErrHandler();

  const { handleUploadPreview } = useConstructor()

  const { process, processing } = useProcesser();

  // animate
  useEffect(() => {
    const imgPreview = document.querySelectorAll('.itemPreview')
    
    if(item) {
      for (let i = 0; i < imgPreview.length; i++) {
        const element = imgPreview[i];
        element.classList.add('fade-in');
      }
    }
  }, [item])
  
  async function downloadImage() {
    if(!userId) return notifier(false, "Login to download!");

    if(processing) return;

    process('STARTED', "Downloading ...")
    
    if(item.images && item.images.length > 0) {
      for (let i = 0; i < item.images.length; i++) {
        const src = item.images[i];
        const imageUrl = `${imageEndpoint}/images/${src}`;

        const a = document.createElement("a");

        a.href = await toDataURL(imageUrl)

        a.download = item.src;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    
        try {
          const res = await axios.put(`${endpoint}/download`, {
            imageId: item._id, 
            src: item.src, 
            type: ""
          }, {
            headers: {
              'Authorization': `Bearer ${user.token}`
            }
          })
    
          notifier(false, res.data.message);
          
        } catch (err) {
          const errMessage = err.response.data.message;
          notifier(true, errMessage);
        }
      }

      process('ENDED')

    } else {
      const imageUrl = `${imageEndpoint}/images/${item.src}`;

      const a = document.createElement("a");
      a.href = await toDataURL(imageUrl);
      a.download = item.src;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
  
      try {
        const res = await axios.put(`${endpoint}/download`, {
          imageId: item._id, 
          src: item.src, 
          type: ""
        }, {
          headers: {
            'Authorization': `Bearer ${user.token}`
          }
        })
  
        notifier(false, res.data.message);
        
      } catch (err) {
        const errMessage = err.response.data.message;
        notifier(true, errMessage);
      }
    }

    process('ENDED');
  }


  async function toDataURL(url) {
    return fetch(url)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        return URL.createObjectURL(blob)
      })
  }

  return (
    <div>
      <main className="itemPreview">

        <main id="uploadImg" className="box-shadow" onClick={() => {
          item.refetch = true;
          handleUploadPreview(item)
        }}>
          <img src={imageEndpoint + "/images/" + item.src} alt=""/>
        </main>

        <div id="stared">
          <span onClick={() => removeItemFrUserProp(item._id, 'PURCHASES')}><FaTimes /></span>
          <span id="downloadBtn" onClick={downloadImage}>
            <FiDownload />
          </span>
        </div>
      </main>
    </div>
  )
}

export default Purchases;