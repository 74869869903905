import TopBar from "../../components/topbar/TopBar";
import Footer from "../../components/footer/Footer";
import { Container } from "../../components/global/Container";
import styled from 'styled-components';
import { Helmet } from "react-helmet-async";
import useVisits from "../../hooks/stats/visits/useVisits";

const PrivacyPolicyStyle = styled.div`
  // HEADER
  & > div[id="header"] {
    padding: 50px 0;
    margin: 0 0 20px 0;
    text-align: center;
    background: linear-gradient(to bottom right, ${({ theme }) => theme.colors.main }10, ${({ theme }) => theme.colors.main2 }10);
    
    & > h2 {
      font-weight: 900;
      background: linear-gradient(to right, #f32170, #ff6b08, #cf23cf, #eedd44);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
    }
  }

  & > div:nth-child(3) {
    display: flex;
    flex-direction: column;
    gap: 20px;

    & > pre {
      font-family: 'Poppins', sans-serif;
      font-size: 19px;
      line-height: 2em;
      white-space: pre-wrap;
      
      & li {
        margin-left: 20px;
      }
    }
  }
`;

const PrivacyPolicy = () => {
  useVisits();

  const policy = 
`
<h3>Privacy Policy</h3>
At Model Grapix, we value and respect the privacy of our users. This Privacy Policy describes how we collect, use, and disclose information that we receive from users of our website and services. By using our website or services, you agree to the terms of this Privacy Policy.

<h3>Information Collection and Use</h3> 
We collect the following types of information from users:

<strong>Personal Information:</strong> We may collect personal information such as your name, email address, and contact information when you register for an account or use certain features of our website or services.
<strong>Usage Information:</strong> We may collect information about how you use our website or services, including the pages you visit, the features you use, and the actions you take.
<strong>Device Information:</strong> We may collect information about the device you use to access our website or services, including the type of device, operating system, and browser you use.

<h3>We may use the information we collect for the following purposes:</h3><ul>
<li>To provide and improve our website and services</li><li>To communicate with you about our website and services</li><li>To personalize your experience on our website</li><li>To analyze usage and trends on our website and services</li><li>To comply with legal obligations</li><li>Information Sharing and Disclosure</li>
</ul><h3>We may share information with third parties for the following purposes:</h3><ul>
<li>With service providers who assist us in operating our website and services</li><li>With our affiliates and partners for marketing purposes</li><li>In response to a legal request, such as a court order or subpoena</li><li>To protect the safety and security of our website and services, or to investigate and prevent fraud</li><li>We do not sell your personal information to third parties.</li>
</ul><h3>Data Retention</h3>
We retain your personal information for as long as necessary to provide our website and services to you, or as required by law.

<h3>Security</h3>
We use reasonable security measures to protect the information we collect from users. However, no method of transmission over the Internet or electronic storage is completely secure, and we cannot guarantee the absolute security of your information.

<h3>Changes to this Privacy Policy</h3>
We may update this Privacy Policy from time to time by posting a new version on our website. We will notify you of any material changes to this Privacy Policy by email or through our website.

<h3>Contact Us</h3>
If you have any questions or concerns about this Privacy Policy, please contact us at support@modelgrapix.com or modelgrapix@gmail.com.

Last updated: <strong>27th, Feb. 2023</strong>

`;

  return (
    <PrivacyPolicyStyle>
      <Helmet>
        <title>Model Grapix - Privacy Policy</title>

        <meta name="description" content={policy} />

        <meta property="og:title" content="Model Grapix - Privacy Policy" />
        <meta property="og:description" content={policy} />

        <link rel="icon" href='/images/logos/20230125_074855.png' />
      </Helmet>

      <TopBar />

      <div id="header">
        <h2>Privacy Policy</h2>
        <p>Protecting Your Data and Your Rights: Our Commitment to Privacy Policy</p>
      </div>

      <Container>
        <pre dangerouslySetInnerHTML={{ __html: policy }}></pre>
      </Container>

      <Footer />

    </PrivacyPolicyStyle>

  )
}

export default PrivacyPolicy;