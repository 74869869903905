import useAuthContext from "../../../../hooks/auth/useAuthContext";
import useErrHandler from "../../../../hooks/err/useErrHandler";
import useProxyContext from "../../../../hooks/global/useProxyContext";
import useRefreshContext from "../../../../hooks/global/useRefreshContext";
import axios from 'axios';
import { FaAngleDown, FaTimes } from "react-icons/fa";
import useAccess from "../../../../hooks/access/useAccess";
import { useEffect } from "react";
import { BsFillInfoCircleFill } from "react-icons/bs";
import useDate from "../../../../hooks/global/useDate";
import useConstructor from "../../../../hooks/constructor/useConstructor";

const Stared = ({ downloads, userProfile }) => {

  return (
    <div>
      <div>
        <h2>Downloads</h2>
        <div>
          <h3>All</h3>
          <FaAngleDown />
        </div>
      </div>

      {downloads && <div className="flex">
        {downloads.map((item, i) => (
          <Item key={i} item={item} userProfile={userProfile} />
        ))}
      </div>}
    </div>
  )
}

const Item = ({ item, userProfile }) => {

  const { endpoint, imageEndpoint } = useProxyContext();

  const { user } = useAuthContext();

  const { reRender } = useRefreshContext();

  const { notifier } = useErrHandler();

  const { userId } = useAccess();

  const { getDate } = useDate();
  
  const { handleUploadPreview } = useConstructor()

  // Remove from stared
  const removeItemFrUserProp = async (itemId, prop) => {
    try {
      const res = await axios.put(`${endpoint}/rm/item/fr/user/props`, {
        itemId: itemId,
        prop: prop
      }, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      });
  
      reRender();
      notifier(false, res.data.message)
  
    } catch (err) {
      const errMessage = err.response.data.message;
      notifier(true, errMessage);
    }
  }

  // animate
  useEffect(() => {
    const imgPreview = document.querySelectorAll('.itemPreview')
    
    if(item) {
      for (let i = 0; i < imgPreview.length; i++) {
        const element = imgPreview[i];
        element.classList.add('fade-in');
      }
    }
  }, [item])

  return (
    <div>
      <main className="itemPreview">

        <main id="uploadImg" className="box-shadow">
          <img src={imageEndpoint + "/images/" + item.src} alt=""/>
        </main>
        
        {userId && userId._id === userProfile._id &&
        <div id="stared">
          <span onClick={() => removeItemFrUserProp(item.id, 'DOWNLOADS')}><FaTimes /></span>
          <span id="downloadBtn" onClick={() => {
            item._id = item.id;
            item.refetch = true;
            handleUploadPreview(item)
          }}>
            <BsFillInfoCircleFill />
          </span>
        </div>
        }
      </main>

      <header>
        <h4>{item.name}</h4>
        <small>{ getDate(item._date) }</small>
      </header>

    </div>
  )
}

export default Stared;