import { Container } from "../../components/global/Container"
import { WalletStyle } from "./styles/S_Wallet"
import TopBar from '../../components/topbar/TopBar';
import { GiWallet } from "react-icons/gi";
import { GoSignOut } from "react-icons/go";
import { FaWallet } from "react-icons/fa";
import useAccess from "../../hooks/access/useAccess";
import Button from '../../components/button/Button';
import { HiOutlineArrowPathRoundedSquare } from 'react-icons/hi2'
import { IoMdAdd } from "react-icons/io";
import usePopUpContext from "../../hooks/global/usePopUpContext";
import { useEffect } from "react";
import { useState } from "react";
import Cookies from "js-cookie";
import useDate from "../../hooks/global/useDate";
import useProxyContext from "../../hooks/global/useProxyContext";
import useVisits from "../../hooks/stats/visits/useVisits";
import useProfile from "../../hooks/profile/useProfile";

const Wallet = () => {
  useVisits();

  const [balance, setBalance] = useState('');
  const [withdrawn, setWithdrawn] = useState('');
  const [total, setTotal] = useState('');
  const [currency, setCurrency] = useState('');

  const [proceed, setProceed] = useState(false);

  const { userId } = useAccess();

  const { popUp } = usePopUpContext();

  const { imageEndpoint } = useProxyContext();
  
  const { getDate } = useDate();

  const { profileSetup } = useProfile();

  // check for reference
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const reference = urlParams.get('reference');
    
    // if found save it for 3 days
    if(reference) {
      Cookies.set('unsettled_ref', reference, {
        secure: true,
        expires: 3
      })
    }
    
    // check for unsettled ref
    let unsettled_ref = Cookies.get('unsettled_ref');

    // if found call verification initiator 
    if(!(unsettled_ref === undefined)) {
      popUp('initiate-payment-verification', unsettled_ref)
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const addCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  
  useEffect(() => {
    if(!userId) return;
    
    profileSetup(() => {
      const currency = userId.currency;
      const wallet = userId.wallet[currency];

      const balance = wallet.balance.toFixed(2);
      const withdrawn = wallet.withdrawn.toFixed(2);
      const total = (wallet.balance + wallet.withdrawn).toFixed(2);

      setCurrency(currency);
      setBalance(addCommas(balance));
      setWithdrawn(addCommas(withdrawn));
      setTotal(addCommas(total));

      setProceed(true);
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  if(!userId) return;

  if(!proceed) return;

  const filtered = userId.transactionHistory && userId.transactionHistory.filter(ref => ref.currency === currency);

  const transactions = filtered.sort((a, b) => b._date - a._date)

  return (
    <WalletStyle>
      <TopBar />

      {
      <Container id="container">
          <div id="wallet"> {/* Wallet info */}
            <h2>My Wallet</h2>

            <div id="walletInfo">
              <div>
                <GiWallet />
                <small>Current Balance</small>
                <h2>{`${currency} ${balance}`}</h2>
              </div>

              <div>
                <GoSignOut />
                <small>Withdrawn</small>
                <h2>{`${currency} ${withdrawn}`}</h2>
              </div>

              <div>
                <FaWallet />
                <small>Total</small>
                <h2>{`${currency} ${total}`}</h2>
              </div>
            </div>

            <div id="button">
              <Button run={() => popUp('fund-wallet')}><IoMdAdd /> Fund wallet</Button>
              <Button><HiOutlineArrowPathRoundedSquare /> Request Withdrawal</Button>
            </div>
          </div>

          <div id="transactions"> {/* Transactions */}
            <h2>Transactions</h2>

            <div>

              {transactions.map((ref, i) => (
                <div key={i}>

                  {/* payer [profilePic, name and email] */}
                  <div id="payer">
                    <img src={`${ref.transaction_type === 'GRAPIX_PURCHASE' ? (
                      '/images/icons/grapix.png'
                    ) : (ref.transaction_type === 'PERSONAL_PAINT_PURCHASE' ? (
                      '/images/badge/paint_personal.png'
                    ) : (ref.transaction_type === 'PUBLIC_PAINT_PURCHASE' ? (
                      '/images/badge/paint_public.png'
                    ) : (
                      `${imageEndpoint}/images/${userId.profilePic}`
                    )))}`} alt="" />
                    <div>
                      <h3>{ref.message}</h3>
                    </div>
                  </div>

                  {/* Time */}
                  <div><p>{ getDate(ref._date) }</p></div>

                  {/*status */}
                  <div>
                    <p style={{
                      color: ref.success ? "#4bb543AA" : "#FFA500"
                    }}>{ref.success ? "Completed" : "Failed"}</p>
                  </div>

                  {/*Amount paid */}
                  <div id="date">
                    <p style={{
                      color: ref.credit ? "green" : "red"
                    }}> { ref.credit ? "+" : "-"} {currency} {addCommas(ref.amount)} </p>
                  </div>

                  {ref.verified ? (
                    <Button>Details</Button>
                  ) : (
                    <Button>Verify</Button>
                  )}

                </div>
              ))}

            </div>
          </div>

        </Container>
      }
    </WalletStyle>
  )
}

export default Wallet;