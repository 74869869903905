export const image_gen_py = 
`\`\`\`
import requests

def generate_image():
    endpoint = "https://api.modelgrapix.com/api/image-gen-v1"
    YOUR_API_KEY = "YOUR_API_KEY"  # Replace with your actual API key

    try:
        headers = {
            "Authorization": f"Bearer {YOUR_API_KEY}",
            "Content-Type": "application/json"
        }

        data = {
            "prompt": "A cute baby elephant",
            "upscale": True,
            "display": "square",
            "paint": "PUBLIC"
        }

        response = requests.post(endpoint, json=data, headers=headers)

        # Handle the response data here
        print("Response:", response.json())

    except Exception as error:
        # Handle any errors here
        print("Error:", error)

# Call the function to generate the image
generate_image()
\`\`\``

export const bg_remover_py = 
`\`\`\`
import requests

def remove_background(image_file_path):
    endpoint = "https://api.modelgrapix.com/api/bg-remover-v1"
    YOUR_API_KEY = "YOUR_API_KEY"  # Replace with your actual API key

    try:
        headers = {
            "Authorization": f"Bearer {YOUR_API_KEY}"
        }

        files = {
            "image_file": ("image.jpg", open(image_file_path, "rb"))
        }

        response = requests.post(endpoint, headers=headers, files=files)

        # Handle the response data here
        print("Response:", response.json())

    except Exception as error:
        # Handle any errors here
        print("Error:", error)

# Call the function to remove the background
image_file_path = "path_to_your_image.jpg"  # Replace with the path to your image file
remove_background(image_file_path)
\`\`\``

export const inpainting_py = 
`\`\`\`
import requests

def perform_inpainting(original_image_path, mask_image_path):
    endpoint = "https://api.modelgrapix.com/api/inpainting"
    YOUR_API_KEY = "YOUR_API_KEY"  # Replace with your actual API key

    try:
        headers = {
            "Authorization": f"Bearer {YOUR_API_KEY}"
        }

        files = {
            "image_file": ("original_image.jpg", open(original_image_path, "rb")),
            "mask_file": ("mask_image.jpg", open(mask_image_path, "rb"))
        }

        response = requests.post(endpoint, headers=headers, files=files)

        # Handle the response data here
        print("Response:", response.json())

    except Exception as error:
        # Handle any errors here
        print("Error:", error)

# Call the function to perform inpainting
original_image_path = "path_to_original_image.png"  # Replace with the path to your original image file
mask_image_path = "path_to_mask_image.png"  # Replace with the path to your mask image file
perform_inpainting(original_image_path, mask_image_path)
\`\`\``