import styled from 'styled-components';

export const SignInStyle = styled.div`
  & > div:nth-child(2) {
    padding-top: 100px;
    padding-bottom: 100px;
    
    & > form {
      max-width: 400px;
      display: flex;
      flex-direction: column;
      margin-left: 50%;
      transform: translate(-50%);
      gap: 20px;

      & h2 {
        text-align: center;
        color: ${({ theme }) => theme.colors.main2};
      }
      
      & > input {
        background: ${({ theme }) => theme.colors.lightgrey}50;
        border: none;
        padding: 15px;
        box-shadow: 1px 2px 1px #00000025;
        font-size: 19px;
        color: ${({ theme }) => theme.colors.black};
        resize: none;
        border-radius: 10px;
      }

      & > p {
        text-align: center;

        & strong {
          color: ${({ theme }) => theme.colors.main2};
          cursor: pointer;
        }
      }
    }

    @media (max-width: ${({ theme }) => theme.mobile.is760}) {
      & > form {
        min-width: 100%;
      }
    }

    & > button {
      background: linear-gradient(to top right, ${({ theme }) => `${ theme.colors.main }50 , ${ theme.colors.main2 }50`});
      
      &:hover {
        background: linear-gradient(to bottom, ${({ theme }) => `${ theme.colors.main2 }AA, ${ theme.colors.green }AA` });
      }
    }
  }
`;