import { CollectionsStl, CollectionsWrapper } from "./S_Collections";
import Collection from "./Collection";
import useAccess from "../../../../hooks/access/useAccess";
import CollectionsSkeleton from "../../../../components/loader/skeleton/CollectionsSkeleton";
import { useEffect, useState } from "react";
import useRefreshContext from "../../../../hooks/global/useRefreshContext";
import Button from "../../../../components/button/Button";
import useConstructor from "../../../../hooks/constructor/useConstructor";
import useDataContext from "../../../../hooks/global/useDataContext";

const Collections = ({ global }) => {
  const [privateCollections, setPrivateCollections] = useState(null);
  const [count, setCount] = useState(3)
  
  const { userId } = useAccess();

  const { getRouteRA, getRoute } = useConstructor();

  const { refresh } = useRefreshContext();

  const { publicCollections, setPublicCollections } = useDataContext();

  useEffect(() => {
    if(publicCollections) return;

    const controller = new AbortController();
    const signal = controller.signal;

    getRoute((data) => setPublicCollections(data), '/fetch/public/collections', signal)

    return () => {
      controller.abort();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, userId])

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if(!userId) return;

    getRouteRA((data) => setPrivateCollections(data), '/fetch/private/collections', signal)

    return () => {
      controller.abort();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh])

  return (publicCollections || privateCollections) ? (
    <CollectionsStl>
      <CollectionsWrapper>

        { publicCollections && global ? (<>
          {
          publicCollections.slice(0, count).map((publicCollection, i) => (
            <Collection collection={publicCollection} key={i} global={global} />
          ))
          }


          { publicCollections.length > count &&
          <div id="btn">
            <Button run={() => setCount(count + 3)}>... Load More</Button>
          </div>
          }

        </>):( privateCollections && <>
          {
          privateCollections.slice(0, count).map((collection, i) => (
            <Collection collection={collection} key={i} global={global} />
          ))
          }

          { privateCollections.length > count &&
          <div id="btn">
            <Button run={() => setCount(count + 3)}>... Load More</Button>
          </div>
          }
        </>)}

      </CollectionsWrapper>
    </CollectionsStl>
  ):(
    <CollectionsSkeleton />
  );
}

export default Collections;