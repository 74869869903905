import { createContext, useState } from 'react';

export const CountriesContext = createContext();

const CountriesContextProvider = ({ children }) => {
  const [showCountries, setShowCountries] = useState(false);
  const [selected, setSelected] = useState(null);

  return (
    <CountriesContext.Provider value={{
      showCountries, 
      setShowCountries, 
      selected, 
      setSelected
    }}>
      {children}
    </CountriesContext.Provider>
  )
}

export default CountriesContextProvider;