import styled from 'styled-components'

export const VisitorsStyle = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;

  & > div {
    display: flex;
    grid-gap: 30px;

    @media(max-width: ${({ theme }) => theme.mobile.is800}) {
      flex-direction: column;
    }
    
    & > div {
      width: 50%;
      border-radius: 10px;
      background: lightgrey;
      padding: 20px;
      height: 70vh;
      
      @media(max-width: ${({ theme }) => theme.mobile.is800}) {
        width: 100%;
        height: 60vh;
      }

      @media(max-width: ${({ theme }) => theme.mobile.is400}) {
        height: 50vh;
      }

      
      &:first-child {
        display: flex;
        flex-direction: column;
        grid-gap: 20px;
        overflow: auto;

        & > main {
          border-radius: 5px;
          padding: 10px;
          color: ${({ theme }) => theme.colors.blue};
          background: ${({ theme }) => theme.colors.white}AA;
          
          & small {
            word-wrap: break-word;
            overflow: hidden;
          }
        }

        & > div {
          display: flex;
          justify-content: space-between;
          font-size: 30px;

          @media(max-width: ${({ theme }) => theme.mobile.is400}) {
            font-size: 20px;
          }
        }
      }

      &:last-child {
        display: flex;
        flex-direction: column;
        grid-gap: 20px;
        overflow: auto;

        & > main {
          border-radius: 5px;
          padding: 10px;
          color: ${({ theme }) => theme.colors.blue};
          background: ${({ theme }) => theme.colors.white}AA;
          cursor: pointer;

          & small {
            word-wrap: break-word;
            overflow: hidden;
          }
        }
        
      }
    }
  }
`;