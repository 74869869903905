import { useEffect } from "react";
import { useState } from "react";
import useImgHandler from '../../../../hooks/img/useImgHandler';
import { useNavigate } from "react-router-dom";
import usePopUpContext from "../../../../hooks/global/usePopUpContext";

const Tools = () => {
  const [genCount, setGenCount] = useState('..');

  const { getRoute } = useImgHandler();

  const { closePopup } = usePopUpContext();
  
  const navigate = useNavigate();

  useEffect(() => {
    getRoute((data) => {
      setGenCount(data);
    }, '/gen/ai/get/gen-count');
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleNavigate = (url) => {
    navigate(url);
    closePopup();
  }
  
  return (
    <div>

      <div onClick={() => handleNavigate('/tools/ai-writer')}>
        <img src='/images/tools/aiWriter.png' alt='AI Writer' />
        <div>
          <span>
            <h2>AI Writer</h2>
            <small>by Goodluck Chiemeria</small>
            <p>Experience the Future of Writing with AI Technology</p>
          </span>
          <small>Running ...</small>
        </div>
      </div>

      <div onClick={() => handleNavigate('/tools/image-gen')}>
        <img src='/images/tools/imageGen.png' alt='AI Virtuals' />
        <div>
          <span>
            <h2>Image generator</h2>
            <small>by Goodluck Chiemeria</small>
            <p>Power Your Design Projects with AI-Generated Visuals.</p>
          </span>
          <small>{genCount} Images generated</small>
        </div>
      </div>

      <div onClick={() => handleNavigate('/tools/inpainting')}>
        <img src='/images/tools/inpainting.png' alt='Inpainting' />
        <div>
          <span>
            <h2>Inpainting AI Tool</h2>
            <small>by Goodluck Chiemeria</small>
            <p>Transform Your Photos by Removing Unwanted Elements.</p>
          </span>
          <small>Running ...</small>
        </div>
      </div>

      <div onClick={() => handleNavigate('/tools/bg-remover')}>
        <img src='/images/tools/bg-remover.png' alt='Bg Remover' />
        <div>
          <span>
            <h2>Bg Remover</h2>
            <small>by Goodluck Chiemeria</small>
            <p>AI-Powered Image Background Remover</p>
          </span>
          <small>Running ...</small>
        </div>
      </div>

      <div onClick={() => handleNavigate('/tools/image-to-text')}>
        <img src='/images/tools/imageToText.png' alt='Inpainting' />
        <div>
          <span>
            <h2>Image to Text AI</h2>
            <small>by Goodluck Chiemeria</small>
            <p>The Magic of Automatic Descriptions</p>
          </span>
          <small>Running ...</small>
        </div>
      </div>

      <div>
        <img src='/images/tools/introduce.png' alt='' />
        <div>
          <span>
            <h2>Introduce your AI tool</h2>
            <p>Email us at modelgrapix@gmail.com to include your tool</p>
          </span>
          <small>Approval takes up to 24 hours</small>
        </div>
      </div>

    </div>
  );
}
 
export default Tools;