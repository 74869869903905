import styled from "styled-components";

export const InPaintingCompStyle = styled.div` 

  & > div {
    & > h1 {
      margin-top: 50px;
      text-align: center;
    }

    & > div[id="select"] {
      position: relative;
      padding: 20px;
      border-radius: 50px;
      border: 5px dashed ${({ theme }) => theme.colors.main2};
      display: grid;
      place-items: center;
      overflow: hidden;

      & > input {
        background: red;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
      }

      & > label {
        font-size: 20px;
        color: ${({ theme }) => theme.colors.black};
      }
    }
    
    & > div[id="editor"] {
      border-radius: 10px;
      position: relative;
      min-height: 1000px;

      & > div[id="prev_text"] {
        color: ${({ theme }) => theme.colors.black};
        height: 200px;
        border-radius: 10px;
        border: 1px solid ${({ theme }) => theme.colors.lightgrey};
        display: grid;
        place-items: center;
        padding: 40px;
        text-align: center;
      }

      & > canvas {
        border-radius: 10px;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 1;
        background-color: transparent;
        touch-action: none; // This prevents browser handling of touch events
      }

      & > img {
        border-radius: 10px;
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
      }
    }

    max-width: 500px;
    margin: auto;
    display: flex;
    flex-direction: column;
    grid-gap: 50px; 

    & > button {
      border-radius: 5px;
      padding: 20px 50px;
      position: fixed;
      left: 50%;
      transform: translateX(-50%);
      box-shadow: 2px 2px 12px rgba(0,0,0,0.3);
      border: none;
      color: ${({ theme }) => theme.colors.main2};
      font-size: 22px;
      background: ${({ theme }) => theme.colors.white};
      cursor: pointer;
      transition: 0.7s cubic-bezier(0.14, 1.28, 0.94, 1.53);
    }

    & > div[id="launch_preview"] {
      position: fixed;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      display: flex;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      overflow: hidden;
      width: ${({ expandResults }) => expandResults ? "auto" : "20px"};
      
      & > div {
        height: 100px;
      }

      & > div:first-child {
        min-width: 20px;
        background: ${({ theme }) => theme.colors.lightgrey};
        display: grid;
        place-items: center;
        cursor: pointer;

        &:hover {
          opacity: 0.7;
        }
      }

      & > div:last-child {
        display: flex;

        max-width: 250px;
        overflow: auto;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }

        & > img {
          width: 100px;
          height: 100px;
          margin-left: 10px;
          border-radius: 5px;
        }
      }
    }

    & > div[id="editor_options"] {
      margin-bottom: -30px;
      display: flex;
      grid-gap: 10px;

      & > span {
        height: 40px;
        width: 40px;
        border-radius: 5px;
        border: 1px solid ${({ theme }) => theme.colors.main2}AA;
        background: ${({ theme }) => theme.colors.main2}10;
        display: grid;
        place-items: center;
        cursor: pointer;

        & > svg {
          color: ${({ theme }) => theme.colors.black}AA;
        }
      }

      & > span[class="active"] {
        background: ${({ theme }) => theme.colors.main2};
        
        & > svg {
          color: ${({ theme }) => theme.colors.white};
        }
      }

      & > input {
        border-radius: 5px;
        text-align: center;
        font-size: 20px;
        max-width: 100px;
      }
    }
  }
`;