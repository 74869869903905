import React, { useEffect, useState } from "react";
import useAccess from "../../../hooks/access/useAccess";
import useConstructor from "../../../hooks/constructor/useConstructor";
import useProxyContext from "../../../hooks/global/useProxyContext";
import { OptionsMenuStyle, TextStyle } from "../styles/S_Chat";
import { BiExpandAlt } from "react-icons/bi";
import { io } from 'socket.io-client';
import { FaLocationArrow } from "react-icons/fa";

let socket;

// const ENDPOINT = process.env.REACT_APP_SERVER_ENDPOINT;
const ENDPOINT = 'https://model-grapix-api.onrender.com';

export const Messages = () => {
  const { getRouteRA, putToRouteRA, chat, setChats, setInforBar, message, setMessage, messages, setMessages, closeChat, autoScroll } = useConstructor();

  const { userId } = useAccess();

  const { imageEndpoint } = useProxyContext();

  const opponentId = chat.members.filter(member => member !== userId._id)

  const name = userId.userName;

  const updateSeen = (signal) => { 
    putToRouteRA(() => {
      getRouteRA((data) => {setChats(data)}, '/chats/fetch', signal);
    }, `/chats/${chat._id}/seen/add`, signal) 
  }

  // Retrieve messages and retreve user profile
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    getRouteRA((data) => { 
      setMessages(data) 
    }, `/chats/retrieve/messages/${chat._id}`, signal)

    getRouteRA((data) => {
      setInforBar(() => ({ title: data.userName, src: data.profilePic ? `${imageEndpoint}/images/${data.profilePic}` : `/images/profile_1.jpg`, subTitle: (data.online ? 'Online' : 'Offline') }))
    }, `/user/profile/${opponentId}`, signal)


    return () => { controller.abort() }

    // preventing warning for getRouteRA(), setInforBar(), setMessages() missing dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chat, imageEndpoint])

  // Connect socket.io and handle join
  useEffect(() => {
  if(!userId) return;

  socket = io(ENDPOINT, {
    forceNew: true,
    reconnection: true,
    reconnectionDelay: 500,
    reconnectionAttempts: 20
  });

  // Join chat
  const data = {
    name, 
    chat: chat._id, 
    userId: userId._id
  }

  socket.emit('join', data, () => {
    socket.emit('endUserSeen', {}, () => {})
  })

  return () => {
    socket.off();
  }

  }, [chat, name, userId]); // ENDPOINT was once here

  // Spread a single message 
  useEffect(() => {
    if(!socket) return;

    socket.on('message', (message) => {
      
      if(message.senderId === userId._id) {
        setMessages((prevMessages) => {
          return prevMessages.map((prevMessage) => {
            // Check if the message IDs match
            if (prevMessage.sent === false) {
              // Replace the old message with the new one, updating the 'sent' property
              return message;
            } else {
              // Keep the existing message if it's not the one you want to replace
              return prevMessage;
            }
          });
        });
      } else {
        setMessages([...messages, message]);
      }
    })

    return(() => {
      socket.off();
    })

    // preventing warning for setMessages() missing dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  // function for sending messages
  const sendMessage = () => {
    const trim = (str) => str.trim();

    let text = message.text
    
    setMessage(() => ({ text: '', replyRef: {} }))
    
    if(!trim(text)) return;

    setMessages([...messages, {
      text: text,
      sent: false,
      senderId: userId._id,
      createdAt: Date.now(),
      _id: Date.now(),
      replyTo: ""
    }])

    const data = {
      message: text, 
      chat,
      userId: userId._id,
      opponentId,
      messageType: 'TEXT',
      replyRef: message.replyRef
    }
    
    socket.emit('sendMessage', data, () => {});
  }

  // Update seen
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    updateSeen(signal);

    return () => {
      controller.abort();
    }

    // preventing warning for updateSeen() missing dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages])

  // check socket for disconnection
  useEffect(() => {
    if(!socket) return;

    socket.on('disconnect', () => {
      closeChat();
    });

    // preventing warning for closeChat() missing dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chat, message.text]);

  // Handle scroll 
  useEffect(() => {
    const container = document.querySelector('#messages');

    if (container && autoScroll) {
      const scrollOptions = {
        top: container.scrollHeight,
        behavior: 'smooth',
      };

      const scrollSmoothly = () => {
        container.scroll(scrollOptions);
      };

      // Use requestAnimationFrame to ensure smooth scroll
      requestAnimationFrame(scrollSmoothly);
    }

  }, [messages, autoScroll]);

  return messages && (
    <div id="messages">
      {/* Messages  */}
      <Message messages={messages} />

      {/* Input  */}
      <Input sendMessage={sendMessage} />
    </div>
  )
}

const Message = ({ messages }) => {
  let currentDate = null; // Initialize currentDate to null

  const { textDeletion,  setMessages } = useConstructor();

  ///// Refresh messages on text deletion
  useEffect(() => {
    if(!(messages.length > 0)) return;
    if(!socket) return;

    socket.on('textDeletion', (textId) => {
      const updatedMessages = messages.filter((message) => message._id !== textId);
      setMessages(updatedMessages);
    })

    return () => {
      socket.off()
    }

    // disable warning for setMessages() mising dipendencis
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textDeletion, messages])

  return (
    <div id="message">
      {messages.map((message, i) => {

        const { createdAt } = message;

        const date = new Date(createdAt);
        const userLocale = navigator.language; // Get the user's preferred locale
        const formattedTime = date.toLocaleTimeString(userLocale, { hour: '2-digit', minute: '2-digit', hour12: true });
        const formattedDate = date.toLocaleDateString(userLocale, { year: 'numeric', month: 'long', day: 'numeric' });

        let dateHeader = null; // Initialize dateHeader to null

        if (currentDate !== formattedDate) {
          
          currentDate = formattedDate;
          dateHeader = <div id="date"><span>{formattedDate}</span></div>; // Create a date header if date changes
        }

        return (
          <React.Fragment key={i}>
            { dateHeader }
            <Text message={message} formattedTime={formattedTime}/>
          </React.Fragment>
        )
      })}
    </div>
  )
}

const Text = ({ message: { text, sent, senderId, messageType, _id, replyTo }, formattedTime }) => {

  const { setOptionsMenu } = useConstructor();

  const { userId } = useAccess();

  return (
    <TextStyle messageType={messageType}>
      {senderId === userId._id ? (
          <div className='messageContainer' id="justifyEnd">
            <div>
              <div>
                { replyTo.text && <pre>{ replyTo.text }</pre> }
                <div className='messageBox' id="backgroundMain" onClick={() => { 
                  if(sent && messageType !== 'ADMIN') {
                    
                    setOptionsMenu(() => ({ open: true , myText: true, optionId: _id, text }))
                  }
                }}>
                  <pre className='messageText'>{text}</pre>
                </div>
              </div>
              { !(sent && messageType === 'ADMIN') && <small>{ formattedTime }</small>}
            </div>
          </div>   
        ) : (
          <div className='messageContainer' id='justifyStart'>
            <div>
              <div>
                { replyTo.text && <pre>{ replyTo.text }</pre> }
                <div className='messageBox' id="backgroundLight" onClick={() => {
                  if(messageType !== 'ADMIN') {
                    setOptionsMenu(() => ({ open: true, myText: false, optionId: _id, text }))
                  }
                }}>
                  <pre className='messageText'>{text}</pre>
                </div>
              </div>
              { !(messageType === 'ADMIN') && <small>03:40 PM</small>}
            </div>
          </div>
        )}
    </TextStyle>
  )
}

const Input = ({ sendMessage }) => {
  const [expandTextarea, setExpandTextarea] = useState(false);

  const { message, setMessage } = useConstructor();

  return (
    <div id="input">
      { message.replyRef && message.replyRef.text &&
      <div onClick={() => setMessage((options) => ({ ...options, replyRef: {} }))} id="replyRef">
        <pre>{message.replyRef.text}</pre>
      </div> 
      }

      <span onClick={() => setExpandTextarea(!expandTextarea)} id="toggleExpand"><BiExpandAlt /></span>

      <textarea 
        onChange={(e) => setMessage((options) => ({ ...options, text: e.target.value }))}
        value={message.text}
        rows={ expandTextarea ? 4 : 1}
        onFocus={() => setExpandTextarea(true)}
        placeholder="Type your message ..."
      ></textarea>

      <span id="sendBtn" onClick={() => {
        sendMessage();
        setExpandTextarea(false);
      }}><FaLocationArrow /></span>
    </div>
  )
}

export const OptionsMenu = () => {
  const { optionsMenu, setOptionsMenu, setMessage, setTextDeletion } = useConstructor();
  
  const { myText, optionId, text } = optionsMenu;

  // Copy option Text to clipborad
  const copyToClipboard = () => {
    navigator.clipboard.writeText(text)
      .then(() => {
        // handle error
      })
      .catch(err => {
        // handle error
      });
  };
  
  // Add option Text and id to reply hook
  const addToReply = () => {
    setMessage((options) => ({ ...options, replyRef: { textId: optionId, text } }))
  }

  // handle Text deletetion
  const deleteText = () => {
    socket.emit('deleteText', { textId: optionId }, (callback) => {
      setTextDeletion(optionId);
    })
  }

  return (
    <OptionsMenuStyle onClick={() => setOptionsMenu((option) => option.open = false)}>
      <div>
        <div>
          <span onClick={copyToClipboard}>Copy</span>
          <span onClick={addToReply}>Reply</span>
          { !myText && <span>React</span> }
          { myText && <span>Edit</span>}
          { !myText && <span>Report</span> }
          { myText && <span onClick={deleteText}>Delete</span> }
        </div>
      </div>
    </OptionsMenuStyle>
  )
}