import { Container } from "../../components/global/Container";
import { AdminStl, MostRecent, Notifications, Options } from "./styles/S_Admin";
import { IoEllipsisHorizontalSharp } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { useState, useEffect } from "react";
import axios from 'axios';
import useAdminAuthContext from '../../hooks/admin/auth/useAdminAuthContext';
import useProxyContext from '../../hooks/global/useProxyContext';
import useErrHandler from '../../hooks/err/useErrHandler';
import useRefreshContext from '../../hooks/global/useRefreshContext';
import { HiSwitchHorizontal } from "react-icons/hi";

const Admin = () => {
  const [posts, setPosts] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [count, setCount] = useState(3);

  const makeRefresh = () => { setRefresh(!refresh) }

  const { admin } = useAdminAuthContext();

  const { endpoint, imageEndpoint } = useProxyContext()

  const { notifier } = useErrHandler();

  const { reRender } = useRefreshContext();

  // Manipulate options menu 
  useEffect(() => {
    if(!posts) return;

    const optionsBtn = document.querySelectorAll('#optionsBtn');
    const options = document.querySelectorAll('#options');
    const postFrame = document.querySelector('#postFrame').children;

    for (let i = 0; i < optionsBtn.length; i++) {
      const element = optionsBtn[i];

      async function checkActiveOption () {
        let allOptions = postFrame;

        for (let i = 0; i < allOptions.length; i++) {
          const o = allOptions[i];

          // select only elements with tagName = 'MAIN'
          if(o.tagName === 'MAIN') {
            
            // if another options menu is open close it
            if(o.children[0].children[1].children[1].style.display === 'flex') {
              o.children[0].children[1].children[1].style.display = 'none';
            }

          }
        }
      }

      element.addEventListener('mouseover', () => {
        checkActiveOption();

        options[i].style.display = 'flex';

        options[i].addEventListener('mouseleave', () => {
          options[i].style.display = 'none';
        })
      })
    }
    
  }, [posts, count])

  // Fetch all posts
  useEffect(() => {
    (async function(){
      try {
        const res = await axios.get(`${endpoint}/all/blog/post`);

        setPosts(res.data);

      } catch (err) {
        console.log(err);
      }
    }())
  }, [endpoint, refresh])

  // delete post
  const handleDelete =  async (event, postId) => {
    event.preventDefault();

    const proceed = window.confirm('This blog will be deleted! are you sure you want to proceed ?');
    // Log Error or success if true or false

    if(proceed) {
      try {
        if(!admin) return console.log('You must be authenticated to delete this post!')

        const res = await axios.put(`${endpoint}/delete/post/${postId}`, {}, {
          headers: { 'Authorization': `Bearer ${admin.token}` }
        });

        notifier(false, res.data.message);
        makeRefresh();
        
      } catch (err) {
        // LOG ERROR IF ANY
      }
    }
  }

  const toggleApproved = async (postId, status) => {
    try {
      const res = await axios.put(`${endpoint}/toggle/blog/post/approved`, {
        postId,
        status
      }, {
        headers: { 'Authorization': `Bearer ${admin.token}` }
      });

      setRefresh(!refresh)
      notifier(false, res.data.message);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <AdminStl>
        <Container>
          <h1>Manage Posts and Activities</h1>
          <Notifications>
            <span>27 Total Likes</span>
            <span>427 Total Comments</span>
            <span>71 Unapproved Comments</span>
          </Notifications>
        </Container>

          <MostRecent>
            <div>
              <div>
                <h2>Most Recent Posts</h2>
                <Link onClick={reRender} to={`/admin/cpanel/create`}>
                  <button>Create New</button>
                </Link>
              </div>
            </div>

            { posts ? (
            <Container id="postFrame">

              {posts.slice(0, count).map((blog, i) => (
                <main key={i}>
                  <div>
                    <main>
                      <img src={ blog.postFeaturedImg ? `${imageEndpoint}/images/${blog.postFeaturedImg}` : "/images/background.jpg"} alt="" />

                      <div>
                        <h1>{ blog.postTitle }</h1>
                        <article>
                        
                        {
                          (blog.postBody[0] && blog.postBody[0].htmlTag === 'P' && blog.postBody[0].text.slice(0, 100)) 
                        || (blog.postBody[1] && blog.postBody[1].htmlTag === 'P' && blog.postBody[1].text.slice(0, 100)) 
                        || (blog.postBody[2] && blog.postBody[2].htmlTag === 'P' && blog.postBody[2].text.slice(0, 100)) 
                        || (blog.postBody[3] && blog.postBody[3].htmlTag === 'P' && blog.postBody[3].text.slice(0, 100)) 
                        // can append more if needed
                        
                        }</article>
                      </div>
                    </main>

                    <div>
                      <IoEllipsisHorizontalSharp id="optionsBtn" />

                      <Options id="options" style={{ display: 'none' }}>
                        <div></div>
                        <Link onClick={reRender} to={`/admin/cpanel/edit-${blog._id}`}>Edit</Link>
                        <Link onClick={reRender} to="/#">Duplicate</Link>
                        <Link onClick={reRender} to={`/blog/post/${blog._id}`}>Preview</Link>
                        <a href={`/#`} onClick={(e) => handleDelete(e, blog._id)}>Delete</a>

                        <span onClick={() => toggleApproved(blog._id, blog.approved)}> { blog.approved ? "Public" : "Private" } <HiSwitchHorizontal /> </span>
                      </Options>
                    </div>
                  </div>
                  
                </main>
              ))}


            {!(count >= posts.length) && 
            <button onClick={() => setCount(count + 3)}>Show more</button>
            }

            </Container>
            ):(
              <h2>Loading...</h2>
            )}
          </MostRecent>
      
      </AdminStl>
    </>
  )
}

export default Admin;