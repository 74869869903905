import { Helmet } from 'react-helmet-async';
import { Container } from '../../../components/global/Container';
import ImageGenComp from '../../../components/tools/imageGenComp/ImageGenComp';
import TopBar from '../../../components/topbar/TopBar'
import useVisits from '../../../hooks/stats/visits/useVisits';
import { ImageGenStyle } from './styles/S_ImageGen';

const ImageGen = () => {
  useVisits();

  return (
    <ImageGenStyle>
      <Helmet>
        <title>Model Grapix Image Generator - Create Stunning Images for FREE</title>

        <meta name="description" content="Generate unique images with our easy-to-use Simple Image Generator. Customize and generate images effortlessly for your projects, presentations, or social media." />

        <meta property="og:title" content="Model Grapix Image Generator - Generate realistic visuals through text input" />
        <meta property="og:description" content="Generate unique images with our easy-to-use Simple Image Generator. Customize and generate images effortlessly for your projects, presentations, or social media." />
        <meta property="og:image" content="https://res.cloudinary.com/drrd1lwey/image/upload/v1677448174/model-grapix/images/23ii46c50aai1-20230125_074727png.png" />
        <meta property="og:url" content="htts://modelgrapix.com" />
      
        <link rel="icon" href="/images/logos/20230125_074855.png" />
      </Helmet>

      <TopBar />
      <Container>
        <ImageGenComp />
      </Container>
    </ImageGenStyle>
  );
}
 
export default ImageGen;