import { FaAngleDown, FaPlus } from "react-icons/fa";
import usePopUpContext from "../../../../hooks/global/usePopUpContext";
import Collections from "../../../home/sections/collections/Collections";

const MyCollections = () => {

  const { popUp } = usePopUpContext();
  
  return (
    <div>
      <div>
        <h2>My Collections</h2>
        <div onClick={() => popUp("collections", null, true)} id="createBtn"><FaPlus /> <span>Create</span></div>

        <div>
          <h3>All</h3>
          <FaAngleDown />
        </div>
      </div>

    <Collections global={false} />
    </div>
  )
}

export default MyCollections;