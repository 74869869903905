import React, { useEffect, useState } from "react";
import { Container } from "../../global/Container";
import { AiWriterCompStyle } from "./styles/S_AiWriterComp";
import { FaAngleDown, FaLocationArrow } from "react-icons/fa";
import useProcesser from "../../../hooks/processer/useProcesser";
import axios from 'axios';
import useProxyContext from '../../../hooks/global/useProxyContext';
import useAccess from '../../../hooks/access/useAccess';
import useAuthContext from "../../../hooks/auth/useAuthContext";
import { AiOutlinePlus } from 'react-icons/ai';
import { HiExternalLink, HiOutlineMenuAlt2 } from 'react-icons/hi';
import { MdChatBubbleOutline } from "react-icons/md";
import useConstructor from "../../../hooks/constructor/useConstructor";
import { useLocation, useNavigate } from "react-router-dom";
import { LiaTimesSolid } from "react-icons/lia";

const AiWriterComp = () => {
  let [history, setHistory] = useState(``)
  const [openMenu, setOpenMenu] = useState(false);

  const [chats, setChats] = useState(null);
  const [conversation, setConversation] = useState(null);
  const [newGenFound, setNewGenFound] = useState(false);

  const { process, processing } = useProcesser();

  const { endpoint, imageEndpoint } = useProxyContext();

  const { userId } = useAccess()

  const { user } = useAuthContext();

  const { getRouteRA } = useConstructor();

  const navigate = useNavigate();

  useEffect(() => {
    setConversation(null);
  }, [])

  // handle submit
  // append user input on submit
  // append AI response on response

  const removeLoader = () => {
    const loader = document.querySelector('.loader');

    if(loader) {
      loader.remove();
    }
  }

  const paste = (message, profilePic, alt, span) => `
    <div>
      <img src="${profilePic}" alt="${alt}" />
      <span>${span}</span>
    </div>

    <pre class="typing-animation">${message}</pre>
  `

  const loader = () => {
    let defaultText = ".";
    const chatBody = document.querySelector("#chatBody");
  
    const loaderTemplate = document.createElement("div");
    loaderTemplate.setAttribute("id", "paste");
    loaderTemplate.setAttribute("class", "loader");
    loaderTemplate.innerHTML = paste(defaultText, '/images/tools/aiWriter.png', "Model Grapix AI Writer", "Model Grapix AI Writer");
  
    chatBody.appendChild(loaderTemplate);
  
    const typingElement = loaderTemplate.querySelector(".typing-animation");
    const words = [".", ".", "."]; // Replace with your words
  
    // Define a function for the typing animation
    const typeWords = async () => {
      for (let index = 0; index < words.length; index++) {
        typingElement.textContent = defaultText + " " + words.slice(0, index + 1).join(" ");
        await new Promise((resolve) => setTimeout(resolve, 200)); // Adjust the typing speed here
      }
      // Restart the typing animation after all words have been typed
      typingElement.textContent = defaultText;
      setTimeout(typeWords, 1000); // Delay before restarting
    };
  
    // Start the typing animation immediately
    typeWords();
  };
  

  const appendUserInput = (message) => {
    const chatBody = document.querySelector('#chatBody');

    const userFieldTemplate = document.createElement('div');
    userFieldTemplate.setAttribute('id', 'paste')
    userFieldTemplate.innerHTML = paste(message, `${imageEndpoint}/images/${userId.profilePic}`, "User", "You")

    chatBody.appendChild(userFieldTemplate);

    loader();
  }

  const appendAIResponse = (message) => {
    removeLoader();

    const chatBody = document.querySelector("#chatBody");
  
    const AIFieldTemplate = document.createElement("div");
    AIFieldTemplate.setAttribute("id", "paste");
    AIFieldTemplate.innerHTML = paste(message, '/images/tools/aiWriter.png', "Model Grapix AI Writer", "Model Grapix AI Writer");
  
    chatBody.appendChild(AIFieldTemplate);
  
    const typingElement = AIFieldTemplate.querySelector(".typing-animation");
    const words = message.split(" ");
  
    // Start the typing animation immediately
    typingElement.textContent = "";
  
    // Delay the typing animation for each word
    words.forEach(async (word, index) => {
      await new Promise((resolve) => setTimeout(resolve, index * 100));
      typingElement.textContent = words.slice(0, index + 1).join(" ");
    });

    process('ENDED');
  };

  function scrollToBottom() {
    setTimeout(() => {
      const userInput = document.querySelectorAll('#paste');
      const lastElement = userInput.item(userInput.length - 2);

      if (lastElement) {
        lastElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest'
        });
      }
    }, 1000)
  }

  const handleSubmit = async() => {
    if(processing) return;
    
    let textInput = document.querySelector('#textInput');
    
    let prompt = textInput.value;
    textInput.value = '';
    let trim = (str) => str.trim();
    
    if(trim(prompt)) {
      scrollToBottom();
      process('STARTED')
      appendUserInput(prompt);
      if(!userId) return appendAIResponse('Log in or create an account to access this content.')

      history += `\n<|user|>\n${prompt}\n<|assistant|>\n`
  
      try {
        const res = await axios.post(`${endpoint}/ai/writer/conversation/${conversationId}`, {
          prompt,
          history,
        }, {
          headers: {
            'Authorization': `Bearer ${user.token}`
          }
        })

        if(res.data.newChat) {
          window.location.replace(`/tools/ai-writer/conversation/${res.data.newChatId}`)
        }

        setNewGenFound(true)

        // append new data to history
        const newToken = `${res.data.generated}`
        
        history += newToken
        setHistory(history);
        
        appendAIResponse(res.data.generated);

      } catch (err) {
        process('ENDED')
        appendAIResponse('An error occured! Please try again!')
        console.log(err);
      }
    }
  }

  useEffect(() => {
    const interval = setTimeout(() => {
      // appendAIResponse("Hi there! Welcome to the conversation. I'm Model Grapix AI Writer, how can I help you today?")
    })

    return () => {
      clearTimeout(interval);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // fetch all chats
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    getRouteRA((data) => {
      setChats(data);
    }, `/ai/chats/fetch`, signal)

    return () => {
      controller.abort();
    }

    // disable warning for getRouteRA() missing dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const location = useLocation();
  const conversationId = location.pathname.split('/')[4];

  // if conversation Id fetch the conversation with the ID
  useEffect(() => {
    if(!conversationId) return;

    const controller = new AbortController();
    const signal = controller.signal;

    getRouteRA((data) => {
      setConversation(data.chat);
      setHistory(data.formatted);
    }, `/ai/chats/conversation/${conversationId}`, signal, (err) => {
      console.log(err);
      window.location.replace('/tools/ai-writer');
    })

    return () => {
      controller.abort();
    }

    // disable warning for getRouteRA() missing dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, conversationId])

  const navigateTo = (src, newChat) => {
    navigate(src);
    setOpenMenu(false);

    if(newChat) return setConversation(null);
  }

  // let handlePushNotification = () => {
  //   Notification.requestPermission().then((perm) => {
  //     if(perm === "granted") {
  //       let notification = new Notification("Goodluck You have new Post to read", {
  //         body: "How did all this came to becoem true in the year we never expected it all to happen... We felt so disapointed by all this for sure we do",
  //         data: { hello: "world" },
  //         icon: "/images/logo.png",
  //         tag: "Welcome Message"
  //       })

  //       notification.addEventListener('close', (e) => {
  //         console.log(e);
  //       })
  //     }
  //   })
  // }

  return (
    <AiWriterCompStyle id="container" openMenu={openMenu}>
      <div id="menuButton" onClick={() => setOpenMenu(true)}>
        <div><HiOutlineMenuAlt2 /><span>{ conversation ? conversation.title : "Untitled" }</span><span><FaAngleDown /></span></div>
      </div>

      <span id="backToPlatform" onClick={() => navigate('/')}><LiaTimesSolid /></span>

      <div id="history">
        
        <div>
          <div onClick={() => setOpenMenu(false)}><HiOutlineMenuAlt2 /><span>{ conversation ? conversation.title : "Untitled" }</span><span><FaAngleDown /></span></div>

          <div>
            <span onClick={() => {
              if(newGenFound) return window.location.replace('/tools/ai-writer');
              navigateTo('/tools/ai-writer', true)
            }}><AiOutlinePlus/> New Chat</span>
            <span onClick={() => setOpenMenu(false)}>Close</span>
          </div>

          {chats && chats.map((chat, i) => (
            <div key={i} id="recent">
              <span onClick={() => {
                if(newGenFound) return window.location.replace(`/tools/ai-writer/conversation/${chat._id}`);
                navigateTo(`/tools/ai-writer/conversation/${chat._id}`)
              }}> <span><MdChatBubbleOutline /> {chat.title}</span> <HiExternalLink /></span>
            </div>
          ))}

        </div>
      </div>

      <Container id="chatContainer">
        <div id="chatBody">
          {conversation ? (
          conversation.messages.map((message, i) => (
            
            <React.Fragment key={i}>
              {message.user ? (
                <div id="paste">
                  <div>
                    <img src={`${imageEndpoint}/images/${userId && userId.profilePic}`} alt="Model Grapix AI Writer" />
                    <span>You</span>
                  </div>

                  <pre className="typing-animation">{message.user}</pre>
                </div>
              ) : (
                <div id="paste">
                  <div>
                    <img src="/images/tools/aiWriter.png" alt="Model Grapix AI Writer" />
                    <span>Model Grapix AI Writer</span>
                  </div>
              
                  <pre className="typing-animation">{message.assistant}</pre>
                </div>
              )}
            </React.Fragment>

          ))):(
            <div id="watermark">
              <div>
                <img src="/images/tools/aiWriter.png" alt="Model Grapix AI Writer" />
                <div>
                  <span>Model Grapix AI Writer</span>
                  <small>Experience the Future of Writing with AI Technology</small>
                </div>
              </div>
            </div>
          )}
        </div>

        <div id="inputComp">
          <div>
            <textarea id="textInput" rows={3} placeholder="Write ..."></textarea>
            <span onClick={handleSubmit}>
              <span>Send</span>
              <FaLocationArrow />
            </span>
          </div>
        </div>

        {/* <div></div> */}
      </Container>
    </AiWriterCompStyle>
  );
}
 
export default AiWriterComp;